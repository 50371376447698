import React from "react";
import {
  lpfaSection__amenityList,
  lpfaSection__amenityList__itemSafety,
} from "../LocationsProfileFeaturedAmenitiesModal.module.scss";
import get from "lodash/get";

const List = ({ slice }) => {
  return (
    <div className={`${lpfaSection__amenityList}`}>
      <h6>{get(slice, "primary.title", "")}</h6>

      {get(slice, "items", []).map((item, idx) => (
        <div className={`${lpfaSection__amenityList__itemSafety}`} key={idx}>
          <p className={"ms-0"}>● &nbsp;{get(item, "text", "")}</p>
        </div>
      ))}
    </div>
  );
};

export default List;
