import React, { useEffect, useState } from "react";
import {
  hpFuture,
  hpFuture__carouselItem,
  hpFuture__carouselItem__active,
  hpFuture__description,
  hpFuture__image,
  hpFuture__title,
} from "../../index.module.scss";
import { CarouselHomeFutureOfWorkspace } from "../../../../components/CarouselMulti/CarouselMulti";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";

const HomePageCarousel = ({ slice }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const items = get(slice, "items", []);

  return (
    <div className={`container-fluid ${hpFuture}`}>
      <div className={`container`}>
        <div className={hpFuture__title}>
          <PrismicRichText field={get(slice, "primary.title", [])} />
        </div>
        <CarouselHomeFutureOfWorkspace
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          maxIndex={items.length}
        >
          {items.map((item, index) => (
            <div
              key={index}
              className={`${hpFuture__carouselItem} ${
                index === activeIndex ? hpFuture__carouselItem__active : ""
              }`}
            >
              <div>
                <img
                  alt=""
                  src={get(item, "image.url", "")}
                  className={hpFuture__image}
                  loading={"lazy"}
                />
              </div>
            </div>
          ))}
        </CarouselHomeFutureOfWorkspace>
        <div className={`text-center`}>
          <h2 className={`${hpFuture__title} text-uppercase`}>
            {get(slice, `items[${activeIndex}].title`, "")}
          </h2>
          <h3 className={`${hpFuture__description}`}>
            {get(slice, `items[${activeIndex}].description`, "")}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default HomePageCarousel;
