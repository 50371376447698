import React from "react";
import { blog_rich_text, contentSection } from "./Content.module.scss";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";

const Content = ({ blogContent = false, slice, audioFile = "" }) => {
  const richTextContent = get(slice, "primary.content", []);

  return (
    <div className={`container-xxl ${contentSection}`}>
      <div className="row">
        <div
          className={`col-lg-8 ${blogContent ? `offset-lg-3` : `offset-lg-1`}`}
        >
          <div className={blog_rich_text}>
            <PrismicRichText field={richTextContent} />
          </div>
          {!!audioFile && (
            <audio controls className={"mt-5 w-100"}>
              <source src={audioFile} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
      </div>
    </div>
  );
};

export default Content;
