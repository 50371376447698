import { useEffect } from "react";
import axios from "axios";

const useBambooHr = () => {
  useEffect(() => {
    let rootId = "BambooHR-ATS";
    const el = document.getElementById("BambooHR");

    if (!el) {
      return;
    }

    const domain = "iqoffices.bamboohr.com";
    const version = el.getAttribute("data-version") || "1.0.0";

    if (!domain) {
      return;
    }

    const root = document.createElement("div");
    root.id = rootId;
    el.appendChild(root);

    const footer = document.createElement("div");
    footer.style =
      'font-family: "Lato", sans-serif; font-size: 12px; color: #999; text-align: right; margin: 9px 9px 0px 0px;';
    const poweredBy = document.createTextNode("Powered by");
    const logo = document.createElement("img");
    logo.src = "https://resources.bamboohr.com/images/footer-logo.png";
    logo.alt = "BambooHR - HR software";
    logo.style = "display:inline;";
    const link = document.createElement("a");
    link.href = "http://www.bamboohr.com";
    link.target = "_blank";
    link.rel = "external";

    link.appendChild(logo);
    footer.appendChild(poweredBy);
    footer.appendChild(link);

    el.appendChild(footer);

    let embedUrl =
      "https://" +
      domain +
      "/jobs/embed2.php?version=" +
      encodeURIComponent(version) +
      `&timestamp=${new Date().getTime()}`;

    axios
      .get(embedUrl)
      .then((res) => {
        if (res?.data) {
          root.innerHTML = res?.data;
        }
      })
      .catch((e) => {
        console.error("Failed to fetch from BambooHR");
        console.log(e);
      });
  }, []);
};

export default useBambooHr;
