import React from "react";
import { memberPortalUrl } from "../../../config";
import cx from "classnames";
import * as s from "../../pages/locations/index.module.scss";
import i18n from "../../utils/i18n";

const LocationsPageSticky = () => {
  return (
    <div
      onClick={() => window.open(memberPortalUrl, "_blank").focus()}
      className={cx(
        s.stickyAam,
        [`d-xl-none d-flex justify-content-center`],
        [`w-100 position-fixed`],
      )}
    >
      <span className={`py-2`}>{i18n.t("AlreadyMember")}</span>
    </div>
  );
};

export default LocationsPageSticky;
