import React from "react";
import resolvePrismicLink from "../../../../utils/resolvePrismicLink";
import CustomPrismicLink from "../../../../components/CustomPrismicLink";
import CustomModal from "../../../../components/CustomModal";

/**
 *
 * @param visible
 * @returns {JSX.Element}
 * Show the popup when visible === true
 * Example styles can be seen from the subscription popup in the footer section.
 */

const MeetingRoomPopup = ({ slice }) => {
  const [showModal, setShowModal] = React.useState(false);

  const { link, target } = resolvePrismicLink(slice.primary.link);

  const handleCloseModal = () => setShowModal(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShowModal(true);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <CustomModal show={showModal} onHide={handleCloseModal}>
      <h3 className={"mb-4 pb-2"}>{slice?.primary?.title}</h3>
      <p className={"mb-4"}>{slice?.primary?.description}</p>
      <CustomPrismicLink
        to={link}
        title={slice?.primary?.button_label}
        target={target}
        className={`btn btn-flex btn-outline-light`}
      >
        <span>{slice?.primary?.button_label}</span>
      </CustomPrismicLink>
    </CustomModal>
  );
};

export default MeetingRoomPopup;
