import React from "react";
import get from "lodash/get";
import {
  bocContainer,
  bocItem,
  bocTitle,
} from "./BenefitsOfCoworking.module.scss";
import { PrismicRichText } from "@prismicio/react";
import resolvePrismicLink from "../../utils/resolvePrismicLink";
import { Link } from "gatsby";
import cx from "classnames";
import CustomPrismicLink from "../CustomPrismicLink";

const BenefitsOfCoworking = ({ slice = {} }) => {
  const items = get(slice, "items", []);
  const data = get(slice, "primary", {});
  const { link, target } = resolvePrismicLink(data.button_link);
  return (
    <div className={`container-xxl bg-primary `}>
      <div className={`${bocContainer} px-4 px-md-0`}>
        <div className="row">
          <div
            className={cx(
              `d-flex flex-column align-items-start justify-content-between`,
              `offset-md-1 col-md-4`,
            )}
          >
            <div className={`text-white ${bocTitle}`}>
              <PrismicRichText field={data.title} />
            </div>
            <CustomPrismicLink
              to={link}
              target={target}
              className="d-none d-md-inline-block btn btn-flex btn-fixed btn-outline-light"
              title={data.button_label}
            >
              {data.button_label}
            </CustomPrismicLink>
            {/*<Link*/}
            {/*  to={link}*/}
            {/*  className="d-none d-md-inline-block btn btn-flex btn-fixed btn-outline-light"*/}
            {/*  target={target}*/}
            {/*  title={data.button_label}*/}
            {/*>*/}
            {/*  {data.button_label}*/}
            {/*</Link>*/}
          </div>
          <div
            className={cx(
              `offset-md-1 col-md-4`,
              `d-flex flex-column align-items-start mt-4 mt-md-0`,
            )}
          >
            {items.map(({ icon, text }, idx) => (
              <div className={bocItem} key={idx}>
                <img alt={text} src={icon.url} />
                <h4>{text}</h4>
              </div>
            ))}
          </div>
        </div>
        <div className={`mt-4 d-flex d-md-none`}>
          <Link
            to={link}
            className={cx(`btn mx-auto btn-flex btn-outline-light`)}
            target={target}
            title={data.button_label}
          >
            <h6 className={`text-white`}>{data.button_label}</h6>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BenefitsOfCoworking;
