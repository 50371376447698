import React from "react";
import get from "lodash/get";
import * as s from "./LocationsContactForm.module.scss";
import { Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import resolvePrismicLink from "../../utils/resolvePrismicLink";
import i18n from "../../utils/i18n";
import cx from "classnames";
import { getContactFormOptions } from "../../templates/componentWrappers/ContactForm";
import FormContact from "../FormContact/FormContact";

export const LocationsContactFormNoResult = ({ show }) => {
  const contactFormOptions = getContactFormOptions();

  if (!show) return null;

  return (
    <div className={s.locationsContactFormNoResult}>
      <h3>{i18n.t("LetUsHelpYouFindThePerfectWorks")}</h3>
      <p>{i18n.t("LetUsHelpYouFindThePerfectWorksDesc")}</p>
      <FormContact
        phoneNumber
        marketingEmails
        selectedForm={contactFormOptions[1]}
        fullWidth
        mostRecentSource={"Website - Contact Us - General Inquiry"}
      />
    </div>
  );
};

export const LocationsContactFormBottom = ({ cta = {} }) => {
  const { link, target } = resolvePrismicLink(
    get(cta, "primary.button_link", {}),
  );

  if (!get(cta, "primary.title", false)) return null;

  return (
    <div className={`container-fluid bg-offwhite bg-lg-white p-lg-5`}>
      <div
        className={`container-xxl bg-lg-offwhite ${s.locationsContactFormBottom}`}
      >
        <div className={`row`}>
          <div className={cx(s.letUsHelpYouColumn, `col-lg-5 offset-lg-2`)}>
            <PrismicRichText field={get(cta, "primary.title", [])} />
          </div>
        </div>
        <div className={`row mt-4`}>
          <div className={cx(`col-lg-5 offset-lg-2`, `col-md-7`)}>
            <p className={`mb-0`}>{get(cta, "primary.description", [])}</p>
          </div>
          <div
            className={cx(
              `d-flex justify-content-start align-items-start`,
              `col-lg-2 offset-lg-1`,
              `col-md-3 offset-md-2`,
            )}
          >
            <Link
              title={get(cta, "primary.button_label", "")}
              to={link}
              target={target}
              className={cx(`btn btn-flex btn-primary`, `mt-4 mt-md-0`)}
            >
              <span>{get(cta, "primary.button_label", "")}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
