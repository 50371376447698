import React from "react";
import ArrowRightWhite from "../../../images/design-components/Arrow_R_WHT.svg";
import ArrowRightPrimary from "../../../images/design-components/Arrow_R.svg";
import ArrowRightWhiteChevron from "../../../images/design-components/chevron-right-arrow-svgrepo-com.svg";
import {
  customRightArrow,
  customRightArrowChevron,
} from "./Custom.module.scss";

export const CustomRightArrow = ({ onClick, ...rest }) => {
  // const {
  //   onMove,
  //   carouselState: { currentSlide, deviceType },
  // } = rest;
  return (
    <button onClick={() => onClick()} className={customRightArrow}>
      <img alt="" src={ArrowRightWhite} />
    </button>
  );
};

export const CustomRightArrowPrimary = ({ onClick, ...rest }) => {
  // const {
  //   onMove,
  //   carouselState: { currentSlide, deviceType },
  // } = rest;
  return (
    <button onClick={() => onClick()} className={customRightArrow}>
      <img alt="" src={ArrowRightPrimary} />
    </button>
  );
};

export const CustomRightArrowChevron = ({ onClick }) => {
  return (
    <button onClick={() => onClick()} className={customRightArrowChevron}>
      <img alt="" src={ArrowRightWhiteChevron} />
    </button>
  );
};
