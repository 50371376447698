// extracted by mini-css-extract-plugin
export var border = "LocationsProfileReviews-module--border--08443";
export var header = "LocationsProfileReviews-module--header--1215a";
export var lprSection = "LocationsProfileReviews-module--lpr-section--af5b1";
export var lprSection__title = "LocationsProfileReviews-module--lpr-section__title--10e08";
export var nextReviews = "LocationsProfileReviews-module--next-reviews--98579";
export var previews = "LocationsProfileReviews-module--previews--f1940";
export var rating = "LocationsProfileReviews-module--rating--02823";
export var seeReviews = "LocationsProfileReviews-module--see-reviews--456ec";
export var star = "LocationsProfileReviews-module--star--784ca";
export var starsContainer = "LocationsProfileReviews-module--stars-container--5a302";
export var total = "LocationsProfileReviews-module--total--268ad";
export var writeReview = "LocationsProfileReviews-module--write-review--2f461";