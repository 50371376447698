import React, { useEffect, useState } from "react";
import DefaultBlogImage from "./default-blog-image.jpg";
import * as s from "./BlogBrowse.module.scss";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import get from "lodash/get";
import Down from "../../../images/design-components/Down.svg";
import i18n from "../../../utils/i18n";
import truncate from "lodash/truncate";

const blogsPerPage = 6;

const BlogBrowse = ({ blogList = [] }) => {
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);

  useEffect(() => {
    setMaxPages(Math.ceil(blogList.length / blogsPerPage));
  }, [blogList]);

  return (
    <>
      <div className={`container-xxl`}>
        <div className="row">
          <div className={`offset-lg-1 col-lg-10 d-flex flex-wrap`}>
            <div className="row">
              {blogList.slice(0, page * blogsPerPage).map((blog, idx) => {
                const image = getImage(
                  get(blog, "data.cover_image.localFile", null),
                );
                const title = get(blog, "data.title", "");
                const date = get(blog, "data.post_date", "");
                const blogSummary = get(blog, "data.summary", "");
                const isFrench = get(blog, "lang", "") === "fr-ca";
                const slug = get(blog, "uid", "");

                return (
                  <div className={s.blogRow} key={idx}>
                    <Link
                      to={`${isFrench ? "/fr" : ""}/blog/${slug}`}
                      className={`${s.blogCard} row`}
                    >
                      <div className="col-lg-3 col-md-4">
                        {image ? (
                          <GatsbyImage
                            className={s.blogImage}
                            image={image}
                            alt={title}
                          />
                        ) : (
                          <img
                            className={s.blogImage}
                            src={DefaultBlogImage}
                            alt={title}
                          />
                        )}
                        <h6 className="mt-2 opacity-25 d-none d-md-block d-lg-none">
                          {date}
                        </h6>
                      </div>
                      <div
                        className={`col-lg-8 col-md-8 d-flex flex-column justify-content-between`}
                      >
                        <h6 className="mb-3 mt-3 mt-md-0 opacity-25 d-block d-md-none d-lg-block">
                          {date}
                        </h6>
                        <div className="">
                          <h4 className="mb-3 mb-lg-0">{title}</h4>
                          {blogSummary && (
                            <p>
                              {truncate(blogSummary, {
                                length: 130,
                                separator: " ",
                              })}
                            </p>
                          )}
                        </div>
                        <p
                          className={`text-decoration-underline fw-bold d-none d-lg-block`}
                        >
                          {i18n.t("ReadMore")}
                        </p>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl">
        <div className="row">
          <div className="col-lg-4 offset-lg-1 text-center text-md-start">
            <button
              className={`btn-link ${s.moreBlogPostsButton}`}
              onClick={() => {
                if (page < maxPages) {
                  setPage(page + 1);
                }
              }}
              disabled={page >= maxPages}
            >
              <div className="d-flex d-md-block flex-column-reverse align-items-center">
                <img alt={""} src={Down} width="13" />
                <h6 className="d-inline ps-0 ps-md-3 pb-2 pb-md-0">
                  {i18n.t("MoreBlogPosts")}
                </h6>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogBrowse;

export const BlogBrowseFooter = ({ blogList = [] }) => {
  const [moreBlogsCount, setMoreBlogsCount] = useState(2);

  return (
    <div className={`container-fluid ${s.blogBrowseFooterSection}`}>
      <div className={`container-xxl`}>
        <div className={`row`}>
          <div className={`offset-lg-3 col-lg-9 col-xl-8 col-12`}>
            <h4 className="mb-5">{i18n.t("MoreReading")}</h4>

            {blogList
              .slice(0, moreBlogsCount)
              .map(
                (
                  {
                    blogTitle,
                    blogImage,
                    blogDate,
                    blogUrl = "/blog",
                    summary,
                  },
                  idx,
                ) => (
                  <Link to={blogUrl} className={"row mb-5"} key={idx}>
                    <div className={`col-md-4`} role="button" tabIndex="0">
                      <img
                        alt={blogTitle}
                        src={blogImage || DefaultBlogImage}
                        className={s.blogFooterImage}
                      />
                      <h6
                        className={`mt-2 opacity-25 d-none d-md-block d-lg-none`}
                      >
                        {blogDate}
                      </h6>
                    </div>
                    <div className="col d-flex flex-column">
                      <h6
                        className={`mt-3 mt-md-0 mb-3 opacity-25 d-block d-md-none d-lg-block`}
                      >
                        {blogDate}
                      </h6>
                      <div>
                        <h4 className="mb-3 mb-lg-0">{blogTitle}</h4>
                        {summary && (
                          <p>
                            {truncate(summary, { length: 130, separator: " " })}
                          </p>
                        )}
                      </div>
                      <p className="text-capitalize text-decoration-underline mt-auto d-none d-lg-block">
                        {i18n.t("ReadMore")}
                      </p>
                    </div>
                  </Link>
                ),
              )}

            <div className="text-center text-md-start mb-5">
              {moreBlogsCount < blogList.length && (
                <button
                  className={`btn-link ${s.moreBlogPostsButton}`}
                  onClick={() => {
                    setMoreBlogsCount(16);
                  }}
                >
                  <div className="d-flex d-md-block flex-column-reverse align-items-center">
                    <img alt={""} src={Down} width="13" />
                    <h6 className="d-inline ps-0 ps-md-3 pb-2 pb-md-0">
                      {i18n.t("MoreBlogPosts")}
                    </h6>
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
