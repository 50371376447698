import React from "react";
import { Modal } from "react-bootstrap";
import * as s from "./OverviewItemModal.module.scss";
import Less from "../../images/design-components/Less_1.svg";
import cx from "classnames";
import { Link } from "gatsby";

const OverviewItemModal = ({
  show,
  setShow,
  title,
  description,
  image = "",
  button,
}) => {
  return (
    <Modal
      centered
      show={show}
      onHide={setShow}
      className={"modal-z-index"}
      backdropClassName={s.backdrop}
      dialogClassName={s.dialog}
    >
      <Modal.Body className={`position-relative ${s.body}`}>
        <div className={`container ${s.content}`}>
          <button
            className={`btn-link ${s.closeButton}`}
            onClick={() => setShow(false)}
          >
            <img alt="" src={Less} />
          </button>
          <div className={`row`}>
            <div
              className={cx(
                `d-flex flex-column align-items-start`,
                `col-lg-6`,
                `order-lg-first order-last`,
              )}
            >
              <h5 className={`d-none d-lg-inline-block`}>{title}</h5>
              <p>{description}</p>
              {button.link && button.label && (
                <Link
                  className={`${s.content__button} mt-4 btn btn-flex btn-outline-primary`}
                  to={button.link}
                  target={button.target}
                  title={button.label}
                >
                  <span>{button.label}</span>
                </Link>
              )}
            </div>
            <div className={cx(`col-lg-6`, `order-lg-last order-first`)}>
              <h5 className={`d-lg-none d-block`}>{title}</h5>
              {image && (
                <img alt="" src={image} className={`w-100 my-3 my-lg-0`} />
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OverviewItemModal;
