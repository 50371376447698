import React from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { getDay } from "date-fns";
import { idpCustomContainer } from "./InlineDatePicker.module.scss";

const InlineDatePickerCustomContainer = ({ className, children }) => {
  return (
    <div className={idpCustomContainer}>
      <CalendarContainer className={`${className}`}>
        <div className={`position-relative`}>{children}</div>
      </CalendarContainer>
    </div>
  );
};

const InlineDatePicker = ({
  selectedDate,
  setSelectedDate,
  minDate,
  maxDate,
}) => {
  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  return (
    <>
      <DatePicker
        formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 1)}
        dateFormat="yyyy-MM-dd"
        showPopperArrow={false}
        filterDate={isWeekday}
        placeholderText="Start & End Date"
        minDate={minDate || new Date()}
        maxDate={maxDate}
        calendarClassName={"customIQ"}
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        calendarContainer={InlineDatePickerCustomContainer}
        inline
      />
    </>
  );
};

export default InlineDatePicker;
