import React from "react";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";
import * as s from "./index.module.scss";

const CustomFeatureSection = ({ slice }) => {
  return (
    <div className={s.custom_feature_section_wrapper}>
      <div className="container-xxl">
        <div>
          <div className="row justify-content-center">
            <div className="col-sm-11 col-xl-10">
              <div className="row">
                <div className="col-sm-12 d-lg-none">
                  <PrismicRichText field={get(slice, "primary.title", [])} />
                  <div className={s.description}>
                    <PrismicRichText
                      field={get(slice, "primary.summary", [])}
                    />
                  </div>
                </div>
                <div className="col-sm-6 order-sm-2 d-flex align-items-center justify-content-start col-xxl-5 offset-xxl-1">
                  <div>
                    <div className="d-none d-lg-block">
                      <PrismicRichText
                        field={get(slice, "primary.title", [])}
                      />
                      <div className={s.description}>
                        <PrismicRichText
                          field={get(slice, "primary.summary", [])}
                        />
                      </div>
                    </div>
                    <PrismicRichText
                      field={get(slice, "primary.description", [])}
                    />
                  </div>
                </div>
                <div className="col-sm-6 order-sm-1 d-flex align-items-xxl-center">
                  <img
                    src={get(slice, "primary.image.url")}
                    alt=""
                    className={`${s.image} mt-4 mt-sm-0`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomFeatureSection;
