import React from "react";
import { Nav, NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import get from "lodash/get";
import { Link } from "gatsby";
import cx from "classnames";
import {
  benefitsOfCoworking,
  bookATour,
  dropdownMegaMenuBox,
  iconContainer,
  iconMember,
  iconPhone,
  iconPhoneTooltip,
  imageContainer,
  item2ImageContainer,
  languageSwitcher,
  menuItem1Products,
  menuItem1Whyiq,
  menuItem2Products,
  menuItem2Whyiq,
  overlay,
  overviewOfIq,
  overviewOfProducts,
  productDesc,
  productLink,
  productTitle,
} from "./index.module.scss";
import Member from "./user-default.svg";
import MemberActive from "./user-hover.svg";
import Phone from "./phone-default.svg";
import PhoneActive from "./phone-hover.svg";
import { linkResolver } from "../../../utils/prismic/linkResolver";
import splitNl2Array from "../../../utils/splitNl2Array";
import { memberPortalUrl } from "../../../../config";
import { isBrowser } from "../../../utils";
import i18n from "../../../utils/i18n";

export const productPageCategories = [
  "Dedicated Workspaces",
  "On-demand Workspaces",
  "Other",
];

function HeaderDesktop({ pages, activeDocMeta, bookTour }) {
  const pathname = isBrowser ? window.location.pathname : "";

  const isWhyIqPage = pathname.indexOf("/why-iq/") > -1;
  const isProductPage = pathname.indexOf("/products/") > -1;

  // const productPageCategories = uniq(
  //   get(pages, "productPages", []).map((page) => page.data.product_type)
  // );
  const productPageCategories = [
    { category: "Dedicated Workspaces", label: i18n.t("DedicatedWorkspaces") },
    { category: "On-demand Workspaces", label: i18n.t("OnDemandWorkspaces") },
    { category: "Other", label: i18n.t("Other") },
  ];
  const whyIqPages = get(pages, "whyIqPages", []).filter((page) =>
    get(page, "data.display_in_main_menu", false),
  );
  const productPages = get(pages, "productPages", []).filter((pg) =>
    get(pg, "data.display_in_header_menu", false),
  );
  const customPages = get(pages, "customPages", []);
  const menuPages = customPages
    .filter((page) => get(page, "data.show_in_main_menu", false))
    .sort(function (a, b) {
      return (
        get(a, "data.main_menu_order", 0) - get(b, "data.main_menu_order", 0)
      );
    });

  const whyIqLandingPage = get(pages, "whyIqLandingPage[0]", {});
  const productsLandingPage = get(pages, "productsLandingPage[0]", {});

  const isEnglish = get(activeDocMeta, "lang", "") !== "fr-ca";

  return (
    <Nav className="align-items-center">
      <NavDropdown
        className={cx("dropdown-mega-menu header-menu-why-iq", {
          "active-mega-menu": isWhyIqPage,
        })}
        title={isEnglish ? "WHY iQ" : "POURQUOI iQ"}
      >
        <div className={`container-xxl d-flex ${dropdownMegaMenuBox}`}>
          <div className={benefitsOfCoworking}>
            <span className="arrow-right-primary me-2" />
            <NavDropdown.Item as={"div"}>
              {/*This was a p tag*/}
              <p className="mb-0">
                <Link
                  to={linkResolver(
                    get(
                      whyIqLandingPage,
                      "dataRaw.menu_link_2_button_link",
                      "",
                    ),
                  )}
                >
                  {splitNl2Array(
                    get(
                      whyIqLandingPage,
                      "dataRaw.menu_link_2_button_label",
                      "",
                    ) || "",
                  ).map((text) => {
                    return (
                      <React.Fragment key={text}>
                        {text}
                        <br />
                      </React.Fragment>
                    );
                  })}
                </Link>
              </p>
            </NavDropdown.Item>
          </div>
          <div className={overviewOfIq}>
            <span className="arrow-right-muted me-2" />
            <NavDropdown.Item as={"div"}>
              <Link
                to={linkResolver(
                  get(whyIqLandingPage, "dataRaw.menu_link_3_button_link", ""),
                )}
                className="mb-0"
                title={get(
                  whyIqLandingPage,
                  "dataRaw.menu_link_3_button_label",
                  "",
                )}
              >
                {get(whyIqLandingPage, "dataRaw.menu_link_3_button_label", "")}
              </Link>
            </NavDropdown.Item>
          </div>
          <NavDropdown.Item as={"div"} className={menuItem1Whyiq}>
            <h5>{get(whyIqLandingPage, "dataRaw.menu_link_1_title", "")}</h5>
            <p>{get(whyIqLandingPage, "dataRaw.menu_link_1_text", "")}</p>
            <div>
              <span className="arrow-right-primary" />{" "}
              <Link
                className="mb-5"
                to={linkResolver(
                  get(whyIqLandingPage, "dataRaw.menu_link_1_button_link", ""),
                )}
                titl={get(
                  whyIqLandingPage,
                  "dataRaw.menu_link_1_button_label",
                  "",
                )}
              >
                {get(whyIqLandingPage, "dataRaw.menu_link_1_button_label", "")}
              </Link>
            </div>
            <div className={imageContainer}>
              <img
                alt=""
                src={get(whyIqLandingPage, "dataRaw.menu_image.url", "")}
                className="w-100"
              />
            </div>
          </NavDropdown.Item>
          {whyIqPages.slice(0, 4).map((page, idx) => (
            <NavDropdown.Item
              as={"div"}
              className={`${menuItem2Whyiq}`}
              key={idx}
            >
              <Link
                to={page.url}
                title={get(page, "data.menu_link_name", "")}
                className={`header-nav-link-${page.uid}`}
              >
                <div className={item2ImageContainer}>
                  <GatsbyImage
                    image={getImage(
                      get(page, "data.menu_link_image.localFile", null),
                    )}
                    alt={get(page, "data.menu_link_name", "")}
                  />
                  <div className={overlay} />
                </div>
                <h3>{get(page, "data.menu_link_name", "")}</h3>
                <p>{get(page, "data.menu_link_description", "")}</p>
              </Link>
            </NavDropdown.Item>
          ))}
        </div>
      </NavDropdown>
      <NavDropdown
        className={cx("dropdown-mega-menu header-menu-products", {
          "active-mega-menu": isProductPage,
        })}
        title={isEnglish ? "PRODUCTS" : "PRODUITS"}
      >
        <div className={`container-xxl d-flex ${dropdownMegaMenuBox}`}>
          <div className={overviewOfProducts}>
            <span className="arrow-right-muted me-2" />
            <NavDropdown.Item as={"div"} className={"p-0"}>
              <Link
                to={linkResolver(
                  get(productsLandingPage, "dataRaw.main_menu_button_link", ""),
                )}
                className="mb-0"
                title={get(
                  productsLandingPage,
                  "dataRaw.main_menu_button_label",
                  "",
                )}
              >
                {get(productsLandingPage, "dataRaw.main_menu_button_label", "")}
              </Link>
            </NavDropdown.Item>
          </div>
          <div className={menuItem1Products}>
            <img
              alt=""
              src={get(productsLandingPage, "dataRaw.main_menu_image.url", "")}
            />
          </div>
          {productPageCategories.map(({ category, label }) => (
            <div className={menuItem2Products} key={category}>
              <h6>{label}</h6>
              {productPages
                .filter((page) => page.data.product_type === category)
                .map((page) => (
                  <NavDropdown.Item as={"div"} key={page.id}>
                    <Link
                      title={get(page, "data.menu_link_name", "")}
                      to={page.url}
                      className={`${productLink} header-nav-link-${page.uid}`}
                    >
                      <p className={productTitle}>
                        {get(page, "data.menu_link_name", "")}
                      </p>
                      <p className={productDesc}>
                        {get(page, "data.menu_link_description", "")}
                      </p>
                    </Link>
                  </NavDropdown.Item>
                ))}
            </div>
          ))}
        </div>
      </NavDropdown>
      {menuPages.map((page) => (
        <Link
          key={page.id}
          to={page.url}
          className={`nav-link text-uppercase header-nav-link-${page.uid}`}
          title={get(page, "data.link_label")}
          activeClassName={"active-item"}
        >
          {get(page, "data.link_label")}
        </Link>
      ))}
      <Link
        className={`${bookATour} bookatourclasses nav-link pe-2 ps-2 tour-btn`}
        to={`${isEnglish ? "" : "/fr"}/locations`}
        onClick={(e) => {
          if (typeof bookTour === "function") {
            e.preventDefault();
            bookTour();
          }
        }}
        title={isEnglish ? "BOOK A TOUR" : "RÉSERVEZ"}
      >
        {isEnglish ? "BOOK A TOUR" : "RÉSERVEZ"}
      </Link>
      <div className="d-flex flex-column flex-xl-row">
        <Link
          to={linkResolver(get(activeDocMeta, "alternateLanguages[0]", {}))}
          className={`lang-toggle ${
            isEnglish ? "lang-fr" : "lang-en"
          } ${languageSwitcher}`}
          title={`Switch to ${isEnglish ? "FR" : "EN"}`}
          onClick={() => {
            if (get(activeDocMeta, "alternateLanguages[0].type", false)) {
              i18n.changeLanguage(isEnglish ? "fr" : "en");
            } else {
              i18n.changeLanguage("en");
            }
          }}
        >
          {isEnglish ? "FR" : "EN"}
        </Link>
        <Nav.Link
          bsPrefix={"my-nav-link"}
          className={`iQ-phone-Number ${iconContainer}`}
          href={"tel:18009333176"}
        >
          <div
            style={{ backgroundImage: `url(${Phone})` }}
            className={iconPhone}
          >
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className={iconPhoneTooltip} id={"tooltip-top"}>
                  <div>1-800-933-3176</div>
                </Tooltip>
              }
            >
              <img alt="" className={iconPhone} src={PhoneActive} />
            </OverlayTrigger>
          </div>
        </Nav.Link>
        <Nav.Link
          bsPrefix={"my-nav-link"}
          className={iconContainer}
          href={memberPortalUrl}
          target={"_blank"}
        >
          <div
            style={{ backgroundImage: `url(${Member})` }}
            className={iconMember}
          >
            <img alt="" className={iconMember} src={MemberActive} />
          </div>
        </Nav.Link>
      </div>
    </Nav>
  );
}

export default HeaderDesktop;
