import React, { useEffect, useState } from "react";
import get from "lodash/get";
import * as s from "../../../pages/contact/index.module.scss";
import {
  buttonGroup,
  checkboxOptionsLabel,
  contactColumnInfo,
  contactDropdownHeader,
  contactDropdownHeaderWhite,
  contactDropdownItem,
  contactFormSection,
  contactRow,
} from "../../../pages/contact/index.module.scss";
import { Accordion, ButtonGroup, ToggleButton } from "react-bootstrap";
import FormContact from "../../../components/FormContact/FormContact";
import cx from "classnames";
import i18n from "../../../utils/i18n";
import Down from "../../../images/design-components/Down.svg";
import CheckboxEmpty from "../../../images/design-components/Checkbox_1.svg";
import CheckboxChecked from "../../../images/design-components/Checkbox_2.svg";
import "./customContactForm.scss";

export const getContactFormOptions = () => [
  {
    name: i18n.t("ProductInquiry"),
    value: "1",
    customClassName: "contact-us-product-inquiry",
    formIds: {
      en: "8071d937-2bd5-4376-ad87-f946ead37073",
      fr: "9b30e6c4-458e-4c36-ac9f-5d905863be9f",
    },
  },
  {
    name: i18n.t("GeneralInquiry"),
    value: "2",
    customClassName: "general-inquiry",
    formIds: {
      en: "5e025a57-7fc4-4484-b680-7e429a357f2d",
      fr: "724f3d0f-541d-4059-99c5-063660f900c8",
    },
  },
];

const ContactFormWrapper = ({ slice }) => {
  const [customActiveKey, setCustomActiveKey] = useState("closed-on-init");

  const contactFormOptions = getContactFormOptions();

  const [accordionPlaceholder, setAccordionPlaceholder] = useState(
    i18n.t("HowCanWeHelpYou"),
  );

  const [optionsValue, setOptionsValue] = useState("0");

  const [form, setForm] = useState(null);

  const [accordionActive, setAccordionActive] = useState(false);

  useEffect(() => {
    if (optionsValue === "0") {
      return;
    }

    let obj = contactFormOptions.find((option) => {
      return option.value === optionsValue;
    });

    if (!obj) return;

    setAccordionPlaceholder(obj.name);

    // Switch statement based on obj[0].value
    switch (obj.value) {
      case "1": // Looking for a workspace
        setForm(
          <FormContact
            companyName
            clientIndustry
            locationInterest
            foundOutIQ
            isProductInquiry
            marketingEmails
            productInterest
            phoneNumber
            mostRecentSource={"Website - Contact Us - Product Inquiry"}
            selectedForm={contactFormOptions[0]}
            customEmailFields={[
              {
                label: "Contact form type",
                value: obj.name,
              },
            ]}
            submitButtonClassName={get(obj, "customClassName", "")}
          />,
        );
        break;
      case "2": // Looking for something else
        setForm(
          <FormContact
            phoneNumber
            marketingEmails
            mostRecentSource={"Website - Contact Us - General Inquiry"}
            selectedForm={contactFormOptions[1]}
            customEmailFields={[
              {
                label: "Contact form type",
                value: obj.name,
              },
            ]}
            submitButtonClassName={get(obj, "customClassName", "")}
          />,
        );
        break;
      default: // Looking for something else
        setForm(
          <FormContact
            phoneNumber
            marketingEmails
            customEmailFields={[
              {
                label: "Contact form type",
                value: obj.name,
              },
            ]}
            submitButtonClassName={get(obj, "customClassName", "")}
          />,
        );
        break;
    }
  }, [optionsValue]);

  return (
    <div className={s.howCanWeHelpYouSection}>
      <div className={`container-xxl mt-5 mt-xl-0`}>
        <div className={`row ${contactRow}`}>
          <div className={`col-lg-8 offset-lg-1 col-md-7 offset-md-1`}>
            {/*<h4 className="d-md-none d-inline-block mb-5">*/}
            {/*  {i18n.t("ContactUs")}*/}
            {/*</h4>*/}
            <h4 className="text-start mb-4">{i18n.t("HowCanWeHelpYou")}</h4>
            <Accordion
              defaultActiveKey="0"
              activeKey={customActiveKey}
              onClick={() => {
                if (customActiveKey === "0") {
                  setCustomActiveKey("closed-on-init");
                } else {
                  setCustomActiveKey("0");
                }
              }}
              className="accordion-info"
            >
              <Accordion.Item bsPrefix={contactDropdownItem} eventKey="0">
                <Accordion.Header
                  as="p"
                  bsPrefix={
                    optionsValue > 0
                      ? contactDropdownHeader
                      : `${contactDropdownHeader} ${contactDropdownHeaderWhite}`
                  }
                  onClick={() => {
                    setAccordionActive(!accordionActive);
                  }}
                >
                  <img
                    alt=""
                    src={Down}
                    className={cx(`me-3 rotate-transition`, {
                      [`rotate-180`]: accordionActive,
                    })}
                  />
                  <p className={`mb-n1`}>{accordionPlaceholder}</p>
                </Accordion.Header>
                <Accordion.Body>
                  <ButtonGroup className={buttonGroup}>
                    {contactFormOptions.map((radio, idx) => (
                      <ToggleButton
                        key={idx}
                        id={`options-${idx}`}
                        type="radio"
                        name="radio"
                        className={checkboxOptionsLabel}
                        value={radio.value}
                        checked={optionsValue === radio.value}
                        onChange={(e) => {
                          // First collapse the accordion
                          setCustomActiveKey("1");
                          // Second change the placeholder based on which one was selected

                          // Third, change optionsValue to reflect real value
                          setOptionsValue(e.currentTarget.value);
                        }}
                      >
                        {/*<input*/}
                        {/*  className="form-check-input pe-none me-4"*/}
                        {/*  type="checkbox"*/}
                        {/*  checked={optionsValue === radio.value}*/}
                        {/*  readOnly*/}
                        {/*/>*/}
                        <div className={`d-flex align-items-center`}>
                          {optionsValue === radio.value ? (
                            <img alt="" src={CheckboxChecked} />
                          ) : (
                            <img alt="" src={CheckboxEmpty} />
                          )}
                          <p className={`ms-2 mb-0`}>{radio.name}</p>
                        </div>
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div
            className={`d-lg-none d-md-flex d-none col-sm-4 ${contactColumnInfo}`}
          >
            <div className="ps-4">
              <p>
                150 King St. W. <br /> Floor 2 <br /> Toronto, ON <br /> M5H 1J9
              </p>
              <br /> <br />
              <p>
                <a href="tel:18009333176">1-800-933-3176</a>{" "}
                <a href="mailto:sales@iqoffices.com">sales@iqoffices.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={`container-xxl ${contactFormSection}`}>
        {/* FORMS START*/}
        <div className={`row`}>
          <div className="col-lg-8 offset-lg-1 col-md-7 offset-md-1">
            <div
              className={cx(s.contactFormSection__border, {
                [`d-none`]: optionsValue === "0",
              })}
            ></div>
            {form}
          </div>
        </div>
      </div>

      <div className={`container d-md-none`}>
        <div className={`mt-5 col-12 border-top border-primary`}></div>
        <div className={`row `}>
          <div className={`col-4 ${contactColumnInfo}`}>
            <p>
              150 King St. W. <br /> Floor 2 <br /> Toronto, ON <br /> M5H 1J9
            </p>
          </div>
          <div className={`offset-1 col-5 ${contactColumnInfo}`}>
            <p>
              <a href="tel:18009333176">1-800-933-3176</a>
              <br />
              <a href="mailto:sales@iqoffices.com">sales@iqoffices.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactFormWrapper;
