import React, { useState } from "react";
import {
  productsSelector,
  productsSelectorActive,
} from "../../../pages/products/index.module.scss";
import get from "lodash/get";
import OverviewItems from "../../../components/OverviewItem/OverviewItems";
import i18n from "../../../utils/i18n";

const ProductLandingMenu = ({ context }) => {
  const [activeProduct, setActiveProduct] = useState(0);

  const categories = get(context, "productPageCategories", []);

  if (!categories || categories.length === 0) return null;

  return (
    <div>
      <div
        className={`container-xxl d-flex justify-content-around sticky-top ${productsSelector}`}
      >
        {categories.map((category, idx) => {
          const titleArray = category.split(" ");

          return (
            <div
              key={idx}
              className={activeProduct === idx ? productsSelectorActive : ``}
            >
              <a
                href={`#section_${category
                  .toLowerCase()
                  .replaceAll(" ", "_")
                  .replaceAll("-", "_")}`}
              >
                <h6 onClick={() => setActiveProduct(idx)}>
                  {i18n.t(get(titleArray, "[0]", category))}
                </h6>
              </a>
            </div>
          );
        })}
      </div>
      <OverviewItems
        products={get(context, "productPages", []).filter((p) =>
          get(p, "data.display_in_header_menu", false),
        )}
        descriptionTabletRow={true}
        categories={get(context, "productPageCategories", [])}
      />
    </div>
  );
};

export default ProductLandingMenu;
