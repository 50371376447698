const getOfficePriceRange = (space, long = false) => {
  if (space.price) {
    return `$${space.price.toLocaleString("en")}`;
  }
  const p_min = space.price_min
    ? Number(space.price_min)
    : Number(space.price_per_desk);
  const p_max = space.price_max
    ? Number(space.price_max)
    : Number(space.price_per_desk);

  if (p_min && p_max) {
    if (p_max <= p_min) {
      return `$${p_min.toLocaleString("en")}`;
    } else {
      return long
        ? `$${p_min.toLocaleString("en")} - $${p_max.toLocaleString("en")}`
        : `$${p_min.toLocaleString("en")}+`;
    }
  }
  return "N/A";
};

export default getOfficePriceRange;
