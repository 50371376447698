import React, { useState } from "react";
import {
  safetyInAction,
  safetyInActionMatterport,
} from "../../pages/whyiq/whyiq.module.scss";
import { CarouselMultiMatterport } from "../CarouselMulti/CarouselMulti";
import get from "lodash/get";
import { PrismicRichText } from "@prismicio/react";

const SafetyInAction = ({ slice }) => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <div className={`container-fluid bg-primary ${safetyInAction}`}>
      <div className="container-xxl">
        <PrismicRichText field={get(slice, "primary.title", null)} />
      </div>
      <div className="container-xxl">
        <iframe
          src={get(slice, `items[${activeItem}].iframe_link.url`, null)}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <div className={safetyInActionMatterport}>
          <CarouselMultiMatterport
            setActiveItem={setActiveItem}
            carouselItems={get(slice, "items", []).map((image) => {
              return {
                name: image.name,
                image: image.thumbnail.url,
              };
            })}
          />
        </div>
        {/*<h1 className="bg-white">{carouselItems[activeItem].name}</h1>*/}
      </div>
    </div>
  );
};

export default SafetyInAction;
