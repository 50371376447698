import * as React from "react";
import { useEffect, useState } from "react";
import Metadata from "./Metadata";
import Footer from "./Footer";
import Header from "./Header";
import {
  commonPageFooter,
  locationFooter,
  main,
  mainContent,
} from "./index.module.scss";
import FooterSlogan from "./FooterSlogan/FooterSlogan";
import FooterNav from "./FooterNav/FooterNav";
import PromoModal from "../../templates/HomePage/components/PromoModal";
import get from "lodash/get";
import { isBrowser } from "../../utils";

const Layout = ({
  children,
  activeDocMeta,
  pages,
  footerNav,
  home,
  modalData = null,
  bookTour,
  customCss = "",
  locationProfile = false,
  subscriptionModalData = {},
}) => {
  const isBannerVisible = get(modalData, "is_banner_visible", false);

  const [promoBar, setPromoBar] = useState(isBannerVisible);

  useEffect(() => {
    if (!isBrowser) {
      return;
    }
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  return (
    <div>
      <Metadata metadata={activeDocMeta} customCss={customCss} />

      {home && isBannerVisible ? (
        <PromoModal
          promoBar={promoBar}
          setPromoBar={setPromoBar}
          modalData={modalData}
        />
      ) : (
        ""
      )}

      <Header
        home={home}
        promoBar={promoBar}
        activeDocMeta={activeDocMeta}
        pages={pages}
        bookTour={bookTour}
      />

      <div className={locationProfile ? locationFooter : commonPageFooter}>
        <main className={`${mainContent} ${home ? "" : ""}`}>{children}</main>

        <FooterSlogan activeDocMeta={activeDocMeta} />

        {footerNav ? (
          <FooterNav
            nextLabel={get(footerNav, "nextPage.data.menu_link_name", "")}
            nextLink={get(footerNav, "nextPage.url", "")}
            previousLabel={get(footerNav, "prevPage.data.menu_link_name", "")}
            previousLink={get(footerNav, "prevPage.url", "")}
          />
        ) : (
          ""
        )}

        <Footer
          activeDocMeta={activeDocMeta}
          pages={pages}
          subscriptionModalData={subscriptionModalData}
        />
      </div>
    </div>
  );
};

export default Layout;
