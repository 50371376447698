// extracted by mini-css-extract-plugin
export var bgOffwhite = "MembershipsOverviewItem-module--bg-offwhite--5f693";
export var imageC2c2 = "MembershipsOverviewItem-module--image-c2c-2--5f013";
export var imageContainer = "MembershipsOverviewItem-module--image-container--1bc86";
export var imageMobileReady = "MembershipsOverviewItem-module--image-mobile-ready--28e10";
export var imageMobileReady2 = "MembershipsOverviewItem-module--image-mobile-ready-2--11aac";
export var meOviSection = "MembershipsOverviewItem-module--me-ovi-section--f8ccd";
export var meOviSection__description = "MembershipsOverviewItem-module--me-ovi-section__description--fd18b";
export var meOviSection__iconsContainer = "MembershipsOverviewItem-module--me-ovi-section__icons-container--d7277";
export var meOviSection__iconsContainer__iconContainer = "MembershipsOverviewItem-module--me-ovi-section__icons-container__icon-container--6ca16";
export var meOviSection__title = "MembershipsOverviewItem-module--me-ovi-section__title--c9ab6";