import {
  differenceInBusinessDays,
  differenceInMinutes,
  isBefore,
} from "date-fns";

const calculateGapInDaysOrHours = (from, to, isHourly = false) => {
  if (isBefore(to, from)) return 1;
  const gapInDays = differenceInBusinessDays(to, from) + 1;
  const gapInHours = gapInDays === 1 ? differenceInMinutes(to, from) / 60 : 0;

  return isHourly ? gapInHours : gapInDays;
};

export default calculateGapInDaysOrHours;
