import get from "lodash/get";
import { getQueryParams, hsSource } from "../index";

const getHubSpotContactData = (
  selectedOption,
  user,
  profile,
  priceRange = null,
) => {
  const userNameArray = get(user, "fullName", "").split(" ");
  const params = getQueryParams();
  const teamSize = params.get("team_size");
  const city = params.get("location");
  const productInterest = get(user, "productInterest.label", null);

  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const date = d.getDate();

  const utm_medium = params.get("utm_medium") || "";
  const utm_campaign = params.get("utm_campaign") || "";
  const utm_content = params.get("utm_content") || "";
  const utm_source = params.get("utm_source") || "";
  const utm_term = params.get("utm_term") || "";

  // const today = zonedTimeToUtc(
  //   new Date(year, month, date, 0, 0, 0, 0),
  //   "UTC"
  // ).getTime();

  return [
    { property: "utm_medium", value: utm_medium },
    { property: "utm_campaign", value: utm_campaign },
    { property: "utm_content", value: utm_content },
    { property: "utm_source", value: utm_source },
    { property: "utm_term", value: utm_term },
    { property: "engaged_", value: "Not Engaged Yet" },
    { property: "email", value: user.email },
    { property: "firstname", value: userNameArray[0] || user?.firstname || "" },
    { property: "lastname", value: userNameArray[1] || user?.lastname || "" },
    { property: "phone", value: user.phoneNumber },
    { property: "hs_analytics_source", value: hsSource },
    {
      property: "which_service_you_are_most_interested_in_",
      value:
        productInterest || params.get("space_type") || teamSize
          ? "Private Offices"
          : "",
    },
    { property: "message", value: user.additionalNotes || "" },
    {
      property: "budget",
      value: get(selectedOption, "priceInt", priceRange || ""),
    },
    { property: "city", value: city || teamSize ? "Toronto" : "" },
    {
      property: "company_size",
      value: teamSize || get(user, "customTeamSize.label", null) || "",
    },
    {
      property: "outreach_source",
      value: get(outreaches, `[${profile.id}].deal`),
    },
    {
      property: "outreach_date",
      value: Date.UTC(year, month, date),
    },
  ];
};

export default getHubSpotContactData;

const outreaches = {
  "b7688ef8-4fa4-4fac-9107-9bbc9b1aca47": {
    deal: "Book a Tour 150 King S. W: https://iqoffices.com/150-king-st-west-toronto/",
    contact:
      "Book a Tour 150 King S. W: https://iqoffices.com/150-king-st-west-toronto/",
  },
  "8d2725bf-5bd9-4ce4-a96e-e137ce5798a0": {
    deal: "Book a Tour 545 King St. W: https://iqoffices.com/545-king-street-west-toronto/",
    contact:
      "Book a Tour 545 King St. W: https://iqoffices.com/545-king-street-west-toronto/",
  },
  "9c6af54e-c5e1-4c67-955f-69bafd5b2e53": {
    deal: "Book a Tour 250 University Ave.: https://iqoffices.com/250-university-avenue/",
    contact:
      "Book a Tour 250 University Ave.: https://iqoffices.com/250-university-avenue/",
  },
  "4fb21075-735c-4218-824e-3e974fa9ac48": {
    deal: "Book a Tour 140 Yonge St.: https://iqoffices.com/140-yonge-street/",
    contact:
      "Book a Tour 140 Yonge St.: https://iqoffices.com/140-yonge-street/",
  },
  "bce1e89b-2442-4fa9-a8ea-37c413ff5600": {
    deal: "Book a Tour 1055 WG St.: https://iqoffices.com/1055-west-georgia-vancouver/",
    contact:
      "Book a Tour 1055 WG St.: https://iqoffices.com/1055-west-georgia-vancouver/",
  },
  "3f9785f2-634d-48cd-95fd-d0581ab0253d": {
    deal: "Book a Tour 550 Robson St.: https://iqoffices.com/550-robson-street/",
    contact:
      "Book a Tour 550 Robson St.: https://iqoffices.com/550-robson-street/",
  },
  "66c05eb6-cd26-4eb7-ac30-c8041b0707c9": {
    deal: "Book a Tour 1155 Metcalfe St.: https://iqoffices.com/1155-metcalfe-street/",
    contact:
      "Book a Tour 1155 Metcalfe St.: https://iqoffices.com/1155-metcalfe-street/",
  },
  "b785df9d-4bcf-425f-abfa-f599e3fec455": {
    deal: "Book a Tour 222 Queen St.: https://iqoffices.com/222-queen-street/",
    contact:
      "Book a Tour 222 Queen St.: https://iqoffices.com/222-queen-street/",
  },
};
