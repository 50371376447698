import React, { useEffect, useState } from "react";
import get from "lodash/get";

const HubSpotEmbeddedForm = ({ slice, index }) => {
  const [hubSpotFormRendered, setHubSpotFormRendered] = useState(false);

  const formId = get(slice, "primary.form_guid", null);

  const initializeHubspotForm = () => {
    if (hubSpotFormRendered) return;
    if ("hbspt" in window && formId) {
      setHubSpotFormRendered(true);
      window.hbspt.forms.create({
        portalId: "5982937",
        formId,
        target: `#hubSpotFormContainer_${index}`,
      });
    } else {
      setTimeout(initializeHubspotForm, 500);
    }
  };

  useEffect(() => {
    if (typeof window === "undefined") return;
    initializeHubspotForm();
  }, []);
  return (
    <div className={"container-xxl"}>
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div id={`hubSpotFormContainer_${index}`} className={"pt-5"} />
        </div>
      </div>
    </div>
  );
};

export default HubSpotEmbeddedForm;
