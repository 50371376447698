import * as React from "react";
import get from "lodash/get";

import WrapWithProviders from "./gatsby-wrap-with-providers";

export const wrapRootElement = ({ element }) => (
  <WrapWithProviders>{element}</WrapWithProviders>
);

export const shouldUpdateScroll = ({ pathname, prevRouterProps }) => {
  // this is to prevent page jump when query params are changed
  const prevPathname = get(prevRouterProps, "location.pathname", null);
  return pathname !== prevPathname;
};
