import { getQueryParams } from "../../../../utils";
import { convertDateTimeToMeetingRoomTz } from "../../getMeetingRoomBookingDataForOfficeRnD";

const redirectToOfficeRndCalendar = (
  timezone,
  token,
  officeRndSlug = "iqoffices",
  setErrorMessage = (value) => value,
  automaticRedirect = false,
) => {
  const params = getQueryParams();
  const rndLocationId = params.get("rnd_location_id");

  if (!rndLocationId) return;

  let url = getOfficeRndCalenderLinkFromToken(token, timezone);

  setErrorMessage(url);

  if (automaticRedirect) {
    window.location.href = url;
  }

  return true;
};

export default redirectToOfficeRndCalendar;

export const getOfficeRndCalenderLinkFromToken = (
  token,
  timezone = "America/Toronto",
) => {
  const params = getQueryParams();
  const rndLocationId = params.get("rnd_location_id");
  const teamSize = params.get("team_size");

  const start = params.get("start");
  const startDt = new Date(start);

  if (!rndLocationId) {
    return null;
  }

  const startDateTime = convertDateTimeToMeetingRoomTz(
    startDt,
    timezone || "America/Toronto",
  ).toISOString();

  let url = `https://members.iqoffices.com/calendar?access_token=${token}&office=${rndLocationId}&participants=${
    teamSize || 1
  },41`;

  if (startDateTime) {
    url += `&start=${startDateTime}`;
  }

  return url;
};
