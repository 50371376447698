import React, { useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import get from "lodash/get";
import {
  accordion,
  accordion__perkBody,
  accordion__perkIcon,
  accordion__perkItem,
  accordion__perkTitle,
  escape,
  membershipsPerksModalContent,
  mePerksSection,
  mePerksSection__content,
  mePerksSection__title,
} from "./MembershipsPerksModal.module.scss";
import Escape from "../../images/memberships/close.svg";
import { PrismicRichText } from "@prismicio/react";
import i18n from "../../utils/i18n";

const MembershipsPerksModal = ({
  show,
  items = [],
  setPerksModalShow = () => null,
  modalText = "",
  onHide = () => null,
  ...otherProps
}) => {
  const [activeIndex, setActiveIndex] = useState("");

  return (
    <Modal
      {...otherProps}
      show={show}
      onHide={onHide}
      fullscreen
      className={"modal-z-index"}
      contentClassName={membershipsPerksModalContent}
    >
      <Modal.Body>
        <div className={`container-xxl ${mePerksSection}`}>
          <div className={escape}>
            <button
              className={`btn-as-link`}
              tabIndex="0"
              onClick={() => setPerksModalShow(false)}
              onKeyDown={() => setPerksModalShow(false)}
            >
              <img alt="" src={Escape} />
            </button>
            <span>{modalText}</span>
          </div>
          <div className={`row`}>
            <div
              className={`[ offset-lg-2 col-lg-8 ] [ offset-md-1 col-md-10 ] [ col-12 ] ${mePerksSection__content}`}
            >
              <div className={`d-flex flex-column`}>
                <h4 className={mePerksSection__title}>{i18n.t("OurPerks")}</h4>
                <Accordion bsPrefix={accordion}>
                  {items.map((item, index) => (
                    <Accordion.Item
                      className={
                        activeIndex === index.toString()
                          ? accordion__perkItem
                          : ""
                      }
                      key={index}
                      eventKey={index.toString()}
                      onClick={() => {
                        if (activeIndex === index.toString()) {
                          setActiveIndex("");
                        } else {
                          setActiveIndex(index.toString());
                        }
                      }}
                    >
                      <Accordion.Header as="p" className="container-xxl">
                        {get(item, "icon_for_modal.url", false) && (
                          <img
                            alt=""
                            src={get(item, "icon_for_modal.url", "")}
                            className={accordion__perkIcon}
                          />
                        )}
                        <p className={accordion__perkTitle}>
                          {get(item, "title", "")}
                        </p>
                      </Accordion.Header>
                      <Accordion.Body className="container-xxl">
                        <div className={accordion__perkBody}>
                          <img alt="" src={get(item, "image.url", "")} />
                          <div>
                            <PrismicRichText
                              field={get(item, "description", [])}
                            />
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MembershipsPerksModal;
