import React from "react";
import { sytsText } from "../../../pages/locations/index.module.scss";
import DropdownTeamSize from "../../DropdownTeamSize/DropdownTeamSize";
import useQueryParams from "../../../utils/useQueryParams";
import teamSizes from "./teamSizes";
import i18n from "../../../utils/i18n";

const Filters = ({
  teamSizeSpaceType,
  setTeamSizeSpaceType,
  spaceTypeAfterTeamSize,
  setSpaceTypeAfterTeamSize,
}) => {
  const [teamSize, setTeamSize] = useQueryParams("team_size", "Team size");
  const [spaceType, setSpaceType] = useQueryParams(
    "space_type",
    "Private offices",
  );
  return (
    <>
      <div className={`${sytsText} d-xl-inline-block d-none`}>
        <p>{i18n.t("SelectTeamSizeToViewPrices")}</p>
      </div>
      <div className={`team-size-remove-gap`}>
        <DropdownTeamSize
          teamSize={teamSize}
          setTeamSize={setTeamSize}
          teamSizeSpaceType={teamSizeSpaceType}
          setTeamSizeSpaceType={setTeamSizeSpaceType}
          withSpaceTypePrompt
          spaceType={spaceType}
          setSpaceType={setSpaceType}
          spaceTypeAfterTeamSize={spaceTypeAfterTeamSize}
          setSpaceTypeAfterTeamSize={setSpaceTypeAfterTeamSize}
          teamSizes={teamSizes}
        />
      </div>
    </>
  );
};

export default Filters;
