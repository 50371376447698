// extracted by mini-css-extract-plugin
export var closeButton = "LocationsProfileTitle-module--close-button--3c875";
export var custom = "LocationsProfileTitle-module--custom--7c5e0";
export var input = "LocationsProfileTitle-module--input--65b40";
export var lptSection = "LocationsProfileTitle-module--lpt-section--c7d94";
export var lptSection__description = "LocationsProfileTitle-module--lpt-section__description--f4838";
export var lptSection__title = "LocationsProfileTitle-module--lpt-section__title--af943";
export var lptSection__title__location = "LocationsProfileTitle-module--lpt-section__title__location--73ddd";
export var lptSection__title__locationAndReview = "LocationsProfileTitle-module--lpt-section__title__location-and-review--7a07a";
export var modal = "LocationsProfileTitle-module--modal--a4472";
export var modalContent = "LocationsProfileTitle-module--modal-content--ed20f";
export var modalDialog = "LocationsProfileTitle-module--modal-dialog--0d773";
export var review = "LocationsProfileTitle-module--review--e4626";
export var shareButton = "LocationsProfileTitle-module--share-button--06ab1";
export var socialButton = "LocationsProfileTitle-module--social-button--16ecd";