import get from "lodash/get";

const getFormattedData = (mrData = {}, voData = {}) => {
  const title = get(mrData, "name", null);
  const description = get(
    get(mrData, "description", "").split("|"),
    "[0]",
    null,
  );
  const image = get(mrData, "image", null);
  const address = get(mrData, "location.address", null);
  const dailyRate = get(mrData, "rateDetails[0].dailyRate", []);
  const hourlyRate = get(mrData, "rateDetails[0].hourlyRate", []);

  return {
    title,
    description,
    image: `https://${image}`,
    address,
    dailyRate,
    hourlyRate,
  };
};

export default getFormattedData;
