// extracted by mini-css-extract-plugin
export var blogPageTitle = "BlogTitle-module--blog-page-title--a2cce";
export var blogSummary = "BlogTitle-module--blog-summary--45b62";
export var blogSummaryRow = "BlogTitle-module--blog-summary-row--cd5cd";
export var blogTitle = "BlogTitle-module--blog-title--ab31a";
export var blogTitleImage = "BlogTitle-module--blog-title-image--b17e8";
export var blogTitleReadMore = "BlogTitle-module--blog-title-read-more--e5f71";
export var blogTitleSection = "BlogTitle-module--blog-title-section--be643";
export var blog_date = "BlogTitle-module--blog_date--6d12e";
export var closeButton = "BlogTitle-module--close-button--8fda3";
export var modal = "BlogTitle-module--modal--f7aff";
export var modalContent = "BlogTitle-module--modal-content--0b94a";
export var modalDialog = "BlogTitle-module--modal-dialog--1d885";
export var shareButton = "BlogTitle-module--share-button--6c1b3";
export var socialButton = "BlogTitle-module--social-button--9b630";