import React from "react";
import * as s from "./styles.module.scss";
import CloseButton from "../../images/design-components/Less_1_WHT.svg";
import { Modal } from "react-bootstrap";

const CustomModal = ({ children, show, onHide }) => {
  return (
    <Modal
      centered
      dialogClassName={s.dialogModal}
      className={s.subscriptionModal}
      show={show}
      onHide={onHide}
    >
      <button className={`btn-as-link ${s.closeButton}`} onClick={onHide}>
        <img alt="" src={CloseButton} />
      </button>
      <div className={s.subscriptionModal__content}>{children}</div>
    </Modal>
  );
};

export default CustomModal;
