// extracted by mini-css-extract-plugin
export var mrSection = "index-module--mr-section--0a4e2";
export var omSection = "index-module--om-section--f8479";
export var omSection__description = "index-module--om-section__description--81d6c";
export var omSection__footerNote = "index-module--om-section__footer-note--66abf";
export var omSection__iconsContainer = "index-module--om-section__icons-container--0b5be";
export var omSection__iconsContainer__iconContainer = "index-module--om-section__icons-container__icon-container--38dff";
export var omSection__image = "index-module--om-section__image--703b9";
export var omSection__imageDesc = "index-module--om-section__image-desc--d35c5";
export var omSection__perksLink = "index-module--om-section__perks-link--edeb3";
export var omSection__productsContainer = "index-module--om-section__products-container--0c998";
export var omSection__productsContainer__productButton = "index-module--om-section__products-container__product-button--5400c";
export var omSection__title = "index-module--om-section__title--4202f";