import React from "react";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";
import * as s from "./index.module.scss";

const HeroSection = ({ slice }) => {
  const image = get(slice, "primary.image.url", "");
  const title = get(slice, "primary.title", []);
  return (
    <div>
      <div className="container-lg">
        <div className={s.block}>
          {image && <img src={image} alt="" />}
          <div>
            <PrismicRichText field={title} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
