// extracted by mini-css-extract-plugin
export var formColumn = "BookMeetingRooms-module--form-column--e058d";
export var formColumn__alreadyAMember = "BookMeetingRooms-module--form-column__already-a-member--be61e";
export var formColumn__contactSection = "BookMeetingRooms-module--form-column__contact-section--f7825";
export var formColumn__location = "BookMeetingRooms-module--form-column__location--438d2";
export var formColumn__locationImageSection = "BookMeetingRooms-module--form-column__location-image-section--3d623";
export var formColumn__locationName = "BookMeetingRooms-module--form-column__location-name--9b464";
export var formColumn__price = "BookMeetingRooms-module--form-column__price--3d49d";
export var formColumn__priceSection = "BookMeetingRooms-module--form-column__price-section--0f78a";
export var formColumn__title = "BookMeetingRooms-module--form-column__title--6b4bd";
export var summaryColumn = "BookMeetingRooms-module--summary-column--e3b04";
export var summaryColumn__content = "BookMeetingRooms-module--summary-column__content--bc894";
export var summaryColumn__content__amenitiesButton = "BookMeetingRooms-module--summary-column__content__amenities-button--bca0c";
export var summaryColumn__content__cancellationPolicy = "BookMeetingRooms-module--summary-column__content__cancellation-policy--19ef1";
export var summaryColumn__content__paySection = "BookMeetingRooms-module--summary-column__content__pay-section--96169";
export var summaryColumn__content__paySection__ccForm = "BookMeetingRooms-module--summary-column__content__pay-section__cc-form--b647f";
export var summaryColumn__content__paySection__poweredByStripe = "BookMeetingRooms-module--summary-column__content__pay-section__powered-by-stripe--72ada";
export var summaryColumn__content__paySection__submitButton = "BookMeetingRooms-module--summary-column__content__pay-section__submit-button--1396e";
export var summaryColumn__content__paySection__youWontBeChargedYet = "BookMeetingRooms-module--summary-column__content__pay-section__you-wont-be-charged-yet--08878";
export var summaryColumn__content__pricingSection = "BookMeetingRooms-module--summary-column__content__pricing-section--29d19";
export var summaryColumn__content__pricingSection__priceItems = "BookMeetingRooms-module--summary-column__content__pricing-section__price-items--75824";
export var summaryColumn__content__pricingSection__priceTotal = "BookMeetingRooms-module--summary-column__content__pricing-section__price-total--f70b3";
export var summaryColumn__content__selectTeamSize = "BookMeetingRooms-module--summary-column__content__select-team-size--52082";
export var summaryColumn__content__termsAndConditions = "BookMeetingRooms-module--summary-column__content__terms-and-conditions--629ab";
export var summaryColumn__content__title = "BookMeetingRooms-module--summary-column__content__title--adf8d";
export var summaryColumn__priceBox = "BookMeetingRooms-module--summary-column__price-box--52dc2";