import * as React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import activeDocMeta from "../utils/activeDocMeta";
import Layout from "../../components/Layout";
import get from "lodash/get";
import HeroSection from "./components/HeroSection";
import ProductInfo from "../../components/Products/ProductInfo";
import ContactFormWrapper from "../componentWrappers/ContactForm";
import Faq from "../../components/FAQ/Faq";
import Divider from "../../components/FooterPages/Divider";
import BlogTitle from "../../components/FooterPages/Blog/BlogTitle";
import BlogBrowse from "../../components/FooterPages/Blog/BlogBrowse";
import CustomFeatureSection from "./components/CustomFeatureSection";
import BambooHrEmbedCode from "./components/BambooHrEmbedCode";
import {
  isBlogPage,
  isLocationsPage,
  isMeetingRoomsPage,
  isVirtualOfficesPage,
} from "./constants";
import LocationsLanding from "../../pages/locations/_index";
import TitleSection from "./components/TitleSection";
import MembershipSection from "../../components/MembershipSection";
import MembershipsOverviewItem from "../../components/MembershipsOverviewItem/MembershipsOverviewItem";
import SearchMeetingRooms from "../../pages/locations/_MeetingRooms";
import VirtualOffices from "../../components/VirtualOffices";
import AdditionalServices, {
  AdditionalServicesFooter,
} from "../../components/AdditionalServices/AdditionalServices";
import HubSpotEmbeddedForm from "./components/HubSpotEmbeddedForm";
import LocationsPageSticky from "../../components/LocationsPageSticky";
import RichText from "./components/RichText";
import CustomHubSpotForm from "../../components/CustomHubSpotForm";

const components = {
  hero_section: HeroSection,
  product_features: ProductInfo,
  contact_form: ContactFormWrapper,
  rich_text_field: RichText,
  divider: Divider,
  faq_list: Faq,
  page_title_section: TitleSection,
  feature_section: MembershipSection,
  text_grid_section: MembershipsOverviewItem,
  features_with_images: MembershipsOverviewItem,
  careers_form: () => null,
  "form_-_brokers": () => null,
  "form_-_landlords": () => null,
  "form_-_referral_program": () => null,
  custom_hubspot_form: CustomHubSpotForm,
  additional_services_list: AdditionalServices,
  "additional_services_-_footer_description": AdditionalServicesFooter,
  hubspot_form_embed: HubSpotEmbeddedForm,
  featured_description_section: CustomFeatureSection,
  bamboo_hr_embed_code: BambooHrEmbedCode,
};

const CustomPageTemplate = ({ data = {}, pageContext }) => {
  // const pageData = get(data, "prismicCustomPage.dataRaw", {})

  const customComponent = get(
    data,
    "prismicCustomPage.dataRaw.custom_worksimply_component",
    false,
  );

  const isContactPage =
    get(data, "prismicCustomPage.uid", false) === "contact-us";

  const slices = get(data, "prismicCustomPage.dataRaw.body", []);

  const productPages = get(data, "allPrismicProductPages.nodes", []);

  const blogs = get(data, "allPrismicBlogPost.nodes", []);

  const locations = get(data, "allPrismicLocation.nodes", []).filter((loc) => {
    const locationUid = loc?.uid || "";
    if (!locationUid) {
      return false;
    }
    if (process.env.GATSBY_STAGE === "production") {
      return locationUid.indexOf("ws-test-") === -1;
    } else {
      return locationUid.indexOf("ws-test-") > -1;
    }
  });

  const hubSpotFormComponents = slices.filter(
    (slice) => slice.slice_type === "hubspot_form_embed",
  );

  const customCss = hubSpotFormComponents
    .map((item) => get(item, "primary.custom_css[0].text", ""))
    .join("");

  return (
    <Layout
      activeDocMeta={activeDocMeta(get(data, "prismicCustomPage", {}))}
      pages={{
        whyIqPages: get(data, "allPrismicWhyIqPages.nodes", []),
        productPages: get(data, "allPrismicProductPages.nodes", []),
        customPages: get(data, "allPrismicCustomPage.nodes", []),
        whyIqLandingPage: get(data, "allPrismicWhyIqLanding.nodes", []),
        productsLandingPage: get(
          data,
          "allPrismicProductsLandingPage.nodes",
          [],
        ),
      }}
      customCss={customCss}
      subscriptionModalData={data?.prismicHomePage?.data || {}}
    >
      {!customComponent && (
        <SliceZone
          slices={slices}
          components={components}
          context={{
            isContactPage,
            productPages,
          }}
        />
      )}

      {customComponent && (
        <>
          {isLocationsPage(customComponent) && (
            <>
              <LocationsPageSticky />
              <LocationsLanding
                locations={locations}
                cta={slices.find((slice) => slice.slice_type === "cta")}
              />
            </>
          )}
          {isBlogPage(customComponent) && (
            <>
              <SliceZone
                slices={slices}
                components={{
                  page_title_section: TitleSection,
                }}
              />
              <BlogTitle blog={get(blogs, "[0]")} />
              <BlogBrowse blogList={blogs.slice(1)} blogWidth={[536, 340]} />
            </>
          )}
          {isMeetingRoomsPage(customComponent) && (
            <>
              <LocationsPageSticky />
              <SearchMeetingRooms cmsLocations={locations} />
            </>
          )}
          {isVirtualOfficesPage(customComponent) && (
            <>
              <LocationsPageSticky />
              <VirtualOffices cmsLocations={locations} />
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export const query = graphql`
  query CustomPageTemplate($lang: String, $uid: String) {
    allPrismicLocation(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        url
        uid
        lang
        _previewable
        alternate_languages {
          id
          uid
          type
          lang
        }
        data {
          google_place_id
          location_id
          office_rnd_id
          location_title {
            text
          }
        }
        dataRaw
      }
    }
    prismicHomePage(lang: { eq: $lang }) {
      _previewable
      data {
        subscription_form_title
        subscription_form_description
        subscription_form_button_label
        subscription_footer_button_label
        blog_page_modal_display_delay
      }
    }
    allPrismicProductPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        uid
        _previewable
        alternate_languages {
          id
          uid
          type
          lang
        }
        data {
          menu_link_name
          menu_link_description
          product_type
          display_in_header_menu
        }
      }
    }
    allPrismicWhyIqLanding(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicBlogPost(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___post_date, order: DESC }
    ) {
      nodes {
        data {
          title
          summary
          post_date(formatString: "MMM DD, yyyy")
          cover_image {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 800, formats: AUTO, height: 450)
              }
            }
          }
        }
        uid
        lang
      }
    }
    allPrismicProductsLandingPage(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicWhyIqPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        uid
        type
        _previewable
        data {
          display_in_main_menu
          menu_link_name
          menu_link_description
          menu_link_image {
            url
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 192, formats: AUTO, height: 128)
              }
            }
          }
        }
      }
    }
    allPrismicCustomPage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        uid
        lang
        type
        url
        dataRaw
        data {
          main_menu_order
          link_label
          show_in_main_menu
          show_in_mobile_submenu
          mobile_submenu_order
        }
      }
    }
    prismicCustomPage(lang: { eq: $lang }, uid: { eq: $uid }) {
      id
      uid
      lang
      url
      type
      _previewable
      dataRaw
      alternate_languages {
        id
        uid
        type
        lang
      }
      data {
        main_menu_order
        link_label
        show_in_main_menu
        meta_title
        meta_description
        meta_image {
          url
        }
      }
    }
  }
`;

export default withPrismicPreview(CustomPageTemplate);
