import React from "react";
import get from "lodash/get";
import resolvePrismicLink from "../../../utils/resolvePrismicLink";
import { PrismicRichText } from "@prismicio/react";
import cx from "classnames";
import CustomPrismicLink from "../../../components/CustomPrismicLink";

const BookNow = ({ slice }) => {
  const brochureText = get(slice, "primary.brochure_text", "");
  const { link: brochureLink } = resolvePrismicLink(
    get(slice, "primary.brochure", {}),
  );

  const { link: ctaLink, target } = resolvePrismicLink(
    get(slice, "primary.button_link", {}),
  );

  return (
    <div>
      <div className={`container-xxl my-5`}>
        <div className={`row`}>
          <div className={cx(`col-lg-7 offset-lg-1 `, `col-md-9`, `col-12`)}>
            <PrismicRichText field={get(slice, "primary.description", [])} />
            <p className={`mt-lg-0 mb-0`}>
              {brochureLink && (
                <a
                  className={`pt-4 d-block color-secondary-turquoise text-decoration-underline`}
                  href={brochureLink}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  {brochureText || "See PDF Brochure"}
                </a>
              )}
            </p>
            {ctaLink && (
              <CustomPrismicLink
                to={ctaLink}
                target={target}
                className={cx(
                  `btn btn-flex btn-outline-primary mt-6`,
                  `d-inline-block d-md-none`,
                )}
              >
                <span className={`heavy-upper`}>
                  {get(slice, "primary.button_label", "Book Now")}
                </span>
              </CustomPrismicLink>
            )}
          </div>
          <div
            className={cx(
              `col-lg-2 offset-lg-1`,
              `col-md-3`,
              `d-none d-md-block`,
            )}
          >
            {ctaLink && (
              <CustomPrismicLink
                to={ctaLink}
                target={target}
                className={`btn btn-flex btn-outline-primary`}
              >
                <span className={`heavy-upper`}>
                  {get(slice, "primary.button_label", "Book Now")}
                </span>
              </CustomPrismicLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookNow;
