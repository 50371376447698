import React, { useEffect, useState } from "react";
import * as s from "../../index.module.scss";
import {
  hphContainer,
  hphContainer__categories,
  hphContainer__categories__activeCategory,
  hphContainer__categoriesProducts,
  hphContainer__categoriesProducts__activeProduct,
  hphContainer__title,
  hphSection,
} from "../../index.module.scss";
import HomePageCover from "../../../../images/home/home_page_cover.png";
import ProductWidget from "../../../../components/Products/ProductWidget";
import { Link, navigate } from "gatsby";
// import ArrowRight from "../../../../images/design-components/Arrow_R_WHT.svg";
// import ArrowLeft from "../../../../images/design-components/Arrow_L _WHT.svg";
import Info from "../../../../images/design-components/Info_3.svg";
import cx from "classnames";
import { Button } from "react-bootstrap";
import HomeMobileModal from "../../../../components/HomeMobileModal";
import i18n from "../../../../utils/i18n";
import get from "lodash/get";

const HomeHeroSection = ({ title, bgImage = HomePageCover, bgImage2 }) => {
  const tabs = [
    {
      label: i18n.t("locationPage.DedicatedWorkspaces"),
      mobileLabel: i18n.t("homePage.DedicatedWorkspaceMobile"),
      value: "Private Offices",
      productList: [
        i18n.t("locationPage.PrivateOffices"),
        i18n.t("homePage.HqByIq"),
        i18n.t("locationPage.DedicatedDesks"),
      ],
    },
    {
      label: i18n.t("locationPage.OnDemandWorkspaces"),
      mobileLabel: i18n.t("homePage.OnDemandWorkspaceMobile"),
      value: "Meeting Rooms",
      productList: [i18n.t("homePage.MeetingRooms")],
    },
    {
      label: i18n.t("homePage.Other"),
      mobileLabel: i18n.t("homePage.Other"),
      value: "Virtual Offices",
      productList: [i18n.t("profilePage.VirtualOffices")],
    },
  ];

  const [tabIndex, setTabIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  // Mobile Info Modal
  const [mobileModalShow, setMobileModalShow] = useState(false);

  const isOnDemand = get(activeTab, "mobileLabel", "") === "On-Demand";

  useEffect(() => {
    setActiveTab(tabs[tabIndex]);
  }, [tabIndex]);

  return (
    <>
      <div
        className={`${hphSection}`}
        style={{
          backgroundImage: `url(${bgImage}), url(${bgImage2})`,
          backgroundSize: isOnDemand ? "0% 0%, cover" : `cover, 0% 0%`,
        }}
      >
        <div className={`container-xxl ${hphContainer}`}>
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className={hphContainer__title}>{title}</div>

              <div className={hphContainer__categories}>
                <div className={"d-none d-md-flex w-100"}>
                  {tabs.slice(0, 2).map((tab, index) => (
                    <button
                      key={index}
                      className={`btn-link ${
                        activeTab.label === tab.label
                          ? hphContainer__categories__activeCategory
                          : ``
                      }`}
                      onClick={() => setActiveTab(tab)}
                      onKeyDown={() => setActiveTab(tab)}
                    >
                      <h6
                        className={cx(`opacity-25`, {
                          [`opacity-100`]: activeTab.label === tab.label,
                        })}
                      >
                        {tab.label}
                      </h6>
                    </button>
                  ))}

                  <Link
                    title={i18n.t("homePage.Other")}
                    className={`btn-link`}
                    to={`${i18n.t("/lang/")}products/virtual-offices`}
                  >
                    <h6 className={`opacity-25`}>{i18n.t("homePage.Other")}</h6>
                  </Link>
                </div>
                <div className={"d-flex d-md-none w-100"}>
                  {tabs.map((tab, index) => (
                    <button
                      key={index}
                      className={cx(`btn-link`, s.mobileCategoryBtn, {
                        [hphContainer__categories__activeCategory]:
                          tabIndex === index,
                      })}
                      onClick={() => {
                        if (index !== tabIndex) {
                          setTabIndex(index);
                        } else {
                          setMobileModalShow(true);
                        }
                      }}
                    >
                      <div
                        className={cx(`d-flex flex-column`, {
                          [`me-auto`]: index === 0,
                          [`align-items-center`]: index === 1,
                          [`ms-auto align-items-end`]: index === 2,
                          [`text-muted`]: index === tabIndex,
                        })}
                      >
                        <img
                          className={cx(`mb-1`, s.mobileInfoBtn, {
                            [`opacity-0`]: index !== tabIndex,
                          })}
                          alt=""
                          src={Info}
                        />
                        <h6
                          className={cx({ [`opacity-25`]: index !== tabIndex })}
                        >
                          {tab.mobileLabel}
                        </h6>
                      </div>
                    </button>
                  ))}

                  <HomeMobileModal
                    activeTab={activeTab}
                    show={mobileModalShow}
                    setShow={setMobileModalShow}
                  />
                </div>
              </div>

              {/*ProductWidget section*/}
              {activeTab.value === "Virtual Offices" ? (
                <div className={s.voText}>
                  <h6>{i18n.t("profilePage.VirtualOffices")}</h6>
                  <Button variant={"light"}>
                    <Link
                      className={"homePageCta"}
                      title={i18n.t("homePage.Search")}
                      to={`${i18n.t("/lang/")}products/virtual-offices`}
                    >
                      <span>{i18n.t("homePage.Search")}</span>
                    </Link>
                  </Button>
                </div>
              ) : (
                <ProductWidget defaultProduct={activeTab.value} homePage />
              )}

              <div className={hphContainer__categoriesProducts}>
                {tabs.slice(0, 2).map((tab, index) => (
                  <button
                    key={index}
                    className={`btn-link ${
                      activeTab.value === tab.value
                        ? hphContainer__categoriesProducts__activeProduct
                        : ``
                    }`}
                    onClick={() => setActiveTab(tabs[index])}
                    onKeyDown={() => setActiveTab(tabs[index])}
                  >
                    {tab.productList.map((item) => (
                      <h6
                        className={cx(`text-white opacity-25`, {
                          [`opacity-100`]: activeTab.value === tab.value,
                        })}
                        key={item}
                      >
                        {item}
                      </h6>
                    ))}
                  </button>
                ))}

                <button
                  className={`btn-link`}
                  onClick={async () =>
                    await navigate("/products/virtual-offices")
                  }
                >
                  <h6 className={`text-white opacity-25`}>
                    {i18n.t("profilePage.VirtualOffices")}
                  </h6>
                  {/*<span>Event Venues</span>*/}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeHeroSection;
