import removeCompanyName from "../removeCompanyName";
import {getQueryParams} from "../index";
import get from "lodash/get";
import getFinalPrice from "./getFinalPrice";

/**
 *
 * @param location - location fetched from /view-sp
 * @param office - selected office if available
 * @param user - data collected from the form
 * @returns {string} - returns the HTML body for the calendar invitation.
 * Note: All the spaces and linebreaks will be stripped out when the invitation is sent.
 */

const getBookTourCalendarInvitationBody = (
  location = {},
  office = {},
  user = {},
  isNote = false,
) => {
  if (typeof window === "undefined") {
    return "";
  }

  const params = getQueryParams();

  const searchTeamSize = params.get("team_size");
  const interestedIn = params.get("space_type");

  const validPrice = getFinalPrice(office);

  return `${location.monthly_tour_email_intro}

    <h3>Tour details</h3>
    
    <ul>
    <li>Submitted from: <a target="_blank" href="${
      typeof window !== "undefined" ? window.location.href : ""
    }">${removeCompanyName(location.space_name)}</a></li>
    <li>Name: ${user?.firstname} ${user?.lastname}</li>
    <li>Email: ${get(user, "email", "N/A")}</li>
    <li>Phone: ${get(user, "phoneNumber", "N/A")}</li>
    <li>Company: ${get(user, "companyName", "N/A")}</li>
    <li>Industry: ${get(user, "industry", "N/A")}</li>
    ${isNote ? `<li>Tour date : ${get(user, "date", "N/A")}</li>` : ""}
    ${isNote ? `<li>Tour time : ${get(user, "time", "N/A")}</li>` : ""}
    <li>Interested in: ${
      interestedIn || get(user, "productInterest.label", "N/A")
    }</li>
    <li>Team size: ${
      searchTeamSize || get(user, "customTeamSize.label", "N/A")
    }</li>
    <li>Price: ${validPrice}</li>
    <li>Floor name: ${get(office, "floor_name", "N/A") || "N/A"}</li>
    <li>Office ID: ${get(office, "office_label", "N/A") || "N/A"}</li>
  
    <li>Message: ${user.additionalNotes || "N/A"}</li>
    </ul>
    
    ${location.monthly_tour_email_footer || ""}
    `;
};

export default getBookTourCalendarInvitationBody;
