import React from "react";
import { productsTitle } from "../../../pages/products/index.module.scss";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";

const TextSection = ({ slice }) => {
  const title = get(slice, "primary.title", []);
  return (
    <div className={`container text-center ${productsTitle}`}>
      <div className={`row justify-content-center`}>
        <div className={`col-lg-8`}>
          <PrismicRichText field={title} />
        </div>
      </div>
    </div>
  );
};

export default TextSection;
