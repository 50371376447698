import React from "react";
import ArrowLeftWhite from "../../../images/design-components/Arrow_L _WHT.svg";
import ArrowLeftPrimary from "../../../images/design-components/Arrow_L.svg";
import ArrowLeftWhiteChevron from "../../../images/design-components/chevron-left-arrow-svgrepo-com.svg";
import { customLeftArrow, customLeftArrowChevron } from "./Custom.module.scss";

export const CustomLeftArrow = ({ onClick, ...rest }) => {
  return (
    <button onClick={() => onClick()} className={customLeftArrow}>
      <img alt="" src={ArrowLeftWhite} />
    </button>
  );
};

export const CustomLeftArrowPrimary = ({ onClick, ...rest }) => {
  return (
    <button onClick={() => onClick()} className={customLeftArrow}>
      <img alt="" src={ArrowLeftPrimary} />
    </button>
  );
};

export const CustomLeftArrowChevron = ({ onClick }) => {
  return (
    <button onClick={() => onClick()} className={customLeftArrowChevron}>
      <img alt="" src={ArrowLeftWhiteChevron} />
    </button>
  );
};
