import * as Yup from "yup";
import i18n from "../i18n";

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validationContactForm = [
  {
    fullName: Yup.string().required("Required"),
    email: Yup.string()
      .email(i18n.t("InvalidEmail"))
      .required(i18n.t("EmailRequired")),
    phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  },
];
