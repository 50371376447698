import React from "react";
import get from "lodash/get";
import { lpfaSection__title } from "../LocationsProfileFeaturedAmenitiesModal.module.scss";

const AmenitiesSectionTitle = ({ slice }) => {
  return (
    <div>
      <h4 className={`text-center ${lpfaSection__title}`}>
        {get(slice, "primary.section_title", "")}
      </h4>
    </div>
  );
};

export default AmenitiesSectionTitle;
