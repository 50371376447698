// extracted by mini-css-extract-plugin
export var customButtonGroupHomeFeatured = "Custom-module--custom-button-group-home-featured--b2eef";
export var customButtonGroupHomeFuture = "Custom-module--custom-button-group-home-future--3dd7c";
export var customButtonGroupMultiInfos = "Custom-module--custom-button-group-multi-infos--2f485";
export var customDotActivePrimary = "Custom-module--custom-dot-active-primary--050b5";
export var customDotActiveWhite = "Custom-module--custom-dot-active-white--49d43";
export var customDotPrimary = "Custom-module--custom-dot-primary--c7ef1";
export var customDotWhite = "Custom-module--custom-dot-white--7e54e";
export var customLeftArrow = "Custom-module--custom-left-arrow--e1d32";
export var customLeftArrowChevron = "Custom-module--custom-left-arrow-chevron--270a0";
export var customRightArrow = "Custom-module--custom-right-arrow--72285";
export var customRightArrowChevron = "Custom-module--custom-right-arrow-chevron--9d239";