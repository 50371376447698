const getAvailabilities = async (calendarId) => {
  const requestBody = {
    calendarId: calendarId,
  };

  const request = await fetch(
    `${process.env.GATSBY_API_URL}/get-availabilities`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    },
  )
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.log(err);
    });

  return { events: request?.busy || [], calendar: request?.calendar || {} };
};

export default getAvailabilities;
