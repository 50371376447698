import React, { useState } from "react";
import {
  lpgSection,
  first_row,
  second_row,
} from "./LocationsProfileGallery.module.scss";
import get from "lodash/get";
import cx from "classnames";
import Lightbox from "react-image-lightbox";

const LocationsProfileGallery = ({ data }) => {
  const images = get(data, "items", []);

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  if (!images || images.length === 0) return null;
  return (
    <>
      <div className={`${lpgSection} d-md-none`}>
        <img
          src={get(images, "[0].image.url", "")}
          alt=""
          className={"w-100"}
        />
      </div>
      <div className="d-none d-md-block">
        <div className={`container-xxl ${lpgSection}`}>
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="row">
                <div className={`col-11 ${first_row}`}>
                  {images.slice(0, 2).map(({ image }, idx) => (
                    <img
                      key={idx}
                      role={"presentation"}
                      alt={image.alt}
                      src={image.url}
                      className={cx("cursor-pointer")}
                      onClick={() => {
                        setPhotoIndex(idx);
                        setIsOpen(true);
                      }}
                    />
                  ))}
                </div>
                <div className={`col-11 offset-1 ${second_row}`}>
                  {images.slice(2, 4).map(({ image }, idx) => (
                    <img
                      role={"presentation"}
                      alt={image.alt}
                      src={image.url}
                      key={idx}
                      className={cx("cursor-pointer")}
                      onClick={() => {
                        setPhotoIndex(idx);
                        setIsOpen(true);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].image.url}
          nextSrc={images[(photoIndex + 1) % images.length].image.url}
          prevSrc={
            images[(photoIndex + images.length - 1) % images.length].image.url
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
};

export default LocationsProfileGallery;
