import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as s from "./LocationsProfileModal.module.scss";
import BookATour from "../BookATour/BookATour";
import Close from "../../images/design-components/Less_1_WHT.svg";
import get from "lodash/get";
import i18n from "../../utils/i18n";
import cx from "classnames";

const LocationsProfileModal = ({
  option,
  profile,
  cmsProfile,
  busyTimes,
  selectedType,
  show,
  close = () => null,
  setShow,
  selectedOption = {},
  availableProducts,
  ...props
}) => {
  const types = [i18n.t("profilePage.BookATour")];

  const isOptionSelected = !!get(selectedOption, "title", false);
  const [activeTab, setActiveTab] = useState(selectedType || types[0]);

  useEffect(() => {
    setActiveTab(selectedType);
  }, [selectedType]);

  return (
    <Modal
      {...props}
      show={show}
      onHide={close}
      fullscreen
      centered
      className={"modal-z-index"}
      contentClassName={`${s.locationsProfileModal} position-relative`}
    >
      <Modal.Body>
        <div className={`container ${s.locationsProfileContainer}`}>
          <div className="row h-100">
            <div className={`${s.title} d-none d-lg-block`}>
              {isOptionSelected ? (
                <h4>
                  {get(selectedOption, "title", "")} -{" "}
                  {get(selectedOption, "office_label", "")}
                </h4>
              ) : (
                <h4>{get(cmsProfile, "location_title[0].text", "")}</h4>
              )}
            </div>
            <div className={`${s.infoColumn}  col-lg-6 col-xl-7`}>
              <button
                className={`btn-link p-0 ${s.closeButton}`}
                onClick={close}
              >
                <img alt="Close" src={Close} />
              </button>

              <div className={s.content}>
                <div className={`${s.title} d-block d-lg-none`}>
                  {isOptionSelected ? (
                    <h4>
                      {get(selectedOption, "title", "")} -{" "}
                      {get(selectedOption, "office_label", "")}
                    </h4>
                  ) : (
                    <h4>{get(cmsProfile, "location_title[0].text", "")}</h4>
                  )}
                </div>
                <div className={`${s.info} d-flex flex-column flex-md-row`}>
                  <h5>{get(selectedOption, "price", false)}</h5>
                  {get(selectedOption, "ts", false) && (
                    <p className={`ms-md-auto mt-2 mt-md-0`}>
                      {get(selectedOption, "ts", false)}
                    </p>
                  )}
                </div>
                <div className={s.pictureContainer}>
                  <img
                    alt={get(cmsProfile, "location_title[0].text", "")}
                    src={
                      get(selectedOption, "images[0].url", "") ||
                      get(profile, "hero_image_url", "")
                    }
                  />
                </div>
                <div
                  className={cx(
                    s.details,
                    `d-flex flex-column flex-md-row`,
                    `justify-content-md-between`,
                  )}
                >
                  <h5>{get(profile, "office_address.address", "")}</h5>
                </div>
              </div>
            </div>
            <div className={cx(s.formColumn, `col-lg-6 col-xl-5 col-xxl-5`)}>
              <div className={`${s.formColumn__batGitTab}`}>
                {types.map((type) => (
                  <button
                    key={type}
                    className={`cursor-default btn-link ${
                      s.formColumn__batGitTab__tab
                    } ${
                      activeTab === type
                        ? s.formColumn__batGitTab__tabActive
                        : ""
                    }`}
                    onClick={() => setActiveTab(type)}
                  >
                    <h5 className={`text-white`}>{type}</h5>
                  </button>
                ))}
              </div>
              <BookATour
                profile={profile}
                cmsProfile={cmsProfile}
                busyTimes={busyTimes}
                selectedOption={selectedOption}
                availableProducts={availableProducts}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LocationsProfileModal;
