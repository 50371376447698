const { defaultLanguage } = require("../../../prismic-configuration");

/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 *
 * @type import('@prismicio/helpers').LinkResolverFunction
 */
// eslint-disable-next-line no-undef
exports.linkResolver = (doc) => {
  const customLangSlugs = {
    "fr-ca": "fr",
    "en-us": "",
  };
  switch (doc.type) {
    case "home_page": {
      return doc.lang === defaultLanguage
        ? "/"
        : `/${customLangSlugs[doc.lang] || ""}`;
    }

    case "blog_post": {
      if (!doc.uid) return "/blog";
      return doc.lang === defaultLanguage
        ? `/blog/${doc.uid}`
        : `/${customLangSlugs[doc.lang]}/blog/${doc.uid}`;
    }

    case "why_iq_pages": {
      if (!doc.uid) return "/why-iq";
      return doc.lang === defaultLanguage
        ? `/why-iq/${doc.uid}`
        : `/${customLangSlugs[doc.lang]}/why-iq/${doc.uid}`;
    }

    case "product_pages": {
      if (!doc.uid) return "/products";
      return doc.lang === defaultLanguage
        ? `/products/${doc.uid}`
        : `/${customLangSlugs[doc.lang]}/products/${doc.uid}`;
    }

    case "event_venue": {
      if (!doc.uid) return "/products";
      return doc.lang === defaultLanguage
        ? `/products/event-venues/${doc.uid}`
        : `/${customLangSlugs[doc.lang]}/products/event-venues/${doc.uid}`;
    }

    case "products_landing_page": {
      return doc.lang === defaultLanguage
        ? `/products`
        : `/${customLangSlugs[doc.lang]}/products`;
    }

    case "why_iq_landing": {
      return doc.lang === defaultLanguage
        ? `/why-iq`
        : `/${customLangSlugs[doc.lang]}/why-iq`;
    }

    case "custom_page": {
      if (!doc.uid) return "/";
      return doc.lang === defaultLanguage
        ? `/${doc.uid}`
        : `/${customLangSlugs[doc.lang]}/${doc.uid}`;
    }

    case "location": {
      if (!doc.uid) return "/locations";
      return doc.lang === defaultLanguage
        ? `/locations/${doc.uid}`
        : `/${customLangSlugs[doc.lang]}/locations/${doc.uid}`;
    }

    default:
      if (!doc.uid) return "/";
      return doc.lang === defaultLanguage
        ? `/pages/${doc.uid}`
        : `/${customLangSlugs[doc.lang]}/pages/${doc.uid}`;
  }
};
