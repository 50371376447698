import React from "react";
import { PrismicRichText } from "@prismicio/react";
import * as s from "./index.module.scss";
import get from "lodash/get";
import resolvePrismicLink from "../../../../utils/resolvePrismicLink";
import { Link } from "gatsby";

const TeamSection = ({ slice }) => {
  return (
    // <div className={s.teamContainer}>
    //   <div className={`container-xxl ${s.aboutTeam}`}>
    <div className={`container-xxl ${s.aboutTeam}`}>
      <PrismicRichText field={get(slice, "primary.title", null)} />
      <div className="row">
        <div
          className={`offset-lg-1 col-lg-10 d-flex flex-wrap justify-content-center`}
        >
          {get(slice, "items", []).map(
            ({ name, designation, image, profile_link }, idx) => {
              const { link, target } = resolvePrismicLink(profile_link || {});
              return (
                <WrapInLink link={link} target={target} key={idx} name={name}>
                  <img alt={name} className="img-fluid" src={image.url} />
                  <h5>{name}</h5>
                  <p>{designation}</p>
                </WrapInLink>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamSection;

const WrapInLink = ({ link = null, target = "", children, name = "" }) => {
  if (link && target) {
    if (target === "_blank") {
      return (
        <a
          className={s.aboutTeamPersonContainer}
          href={link}
          target={target}
          title={name}
        >
          {children}
        </a>
      );
    }
    return (
      <Link
        className={s.aboutTeamPersonContainer}
        to={link}
        target={target}
        title={name}
      >
        {children}
      </Link>
    );
  }
  return <div className={s.aboutTeamPersonContainer}>{children}</div>;
};
