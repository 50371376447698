import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import * as s from "./LocationsProfileTitle.module.scss";
import cx from "classnames";
import { CarouselMulti } from "../CarouselMulti/CarouselMulti";
import get from "lodash/get";
import RatingStar from "../../images/design-components/RatingStar_1_TURQ.svg";
import nl2br from "../../utils/nl2br";
import { Button, Modal } from "react-bootstrap";
import { FiShare } from "react-icons/fi";
import CloseButton from "../../images/design-components/Less_1_WHT.svg";
import Facebook from "../../images/design-components/Facebook-Line-WHT.svg";
import FacebookHover from "../../images/design-components/Facebook-WHT.svg";
import Linkedin from "../../images/design-components/LinkedIn-Line-WHT.svg";
import LinkedinHover from "../../images/design-components/LinkedIn-WHT.svg";
import Twitter from "../../images/design-components/Twitter-WHT.svg";
import TwitterHover from "../../images/design-components/Twitter-WHT_1.svg";
import Email from "../../images/design-components/Email-Line-WHT.svg";
import EmailHover from "../../images/design-components/Email-WHT.svg";

const LocationsProfileTitle = (props) => {
  const {
    title,
    carouselImages,
    reviews,
    city,
    description,
    slug,
    plainTitle,
    address,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // ----------- MODAL start ------------
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [urlValue, setUrlValue] = useState("");
  useEffect(() => {
    setUrlValue(window.location.href);
  }, []);
  // ----------- MODAL end ------------

  return (
    <>
      <Modal
        centered
        show={shareModalOpen}
        onHide={setShareModalOpen}
        contentClassName={s.modalContent}
        dialogClassName={s.modalDialog}
        className={`${s.modal} modal-z-index`}
      >
        <button
          className={`${s.closeButton}`}
          onClick={() => setShareModalOpen(false)}
        >
          <img alt="" src={CloseButton} />
        </button>
        <h3 className="border-bottom border-white mb-4 pb-4">{plainTitle}</h3>
        <div className="d-flex flex-column flex-lg-row">
          <h5 className="mb-1">Share</h5>
          <div className="d-flex flex-md-row flex-column mx-auto">
            <div className="d-flex justify-content-around">
              <button
                className={`d-flex flex-column align-items-center me-2 mt-2 ${s.socialButton}`}
                onClick={() => {
                  window.open(
                    `https://www.facebook.com/sharer.php?u=${urlValue}`,
                  );
                }}
              >
                <div style={{ backgroundImage: `url(${Facebook})` }}>
                  <img alt="" width={48} height={48} src={FacebookHover} />
                </div>
                <p className="mt-3">Facebook</p>
              </button>
              <button
                className={`d-flex flex-column align-items-center me-2 mt-2 ${s.socialButton}`}
                onClick={() => {
                  window.open(
                    `https://www.linkedin.com/shareArticle?url=${urlValue}&title=Test`,
                  );
                }}
              >
                <div style={{ backgroundImage: `url(${Linkedin})` }}>
                  <img alt="" width={48} height={48} src={LinkedinHover} />
                </div>
                <p className="mt-3">Linkedin</p>
              </button>
            </div>
            <div className="d-flex justify-content-around">
              <button
                className={`d-flex flex-column align-items-center me-2 mt-2 ${s.socialButton}`}
                onClick={() => {
                  window.open(
                    `https://twitter.com/share?url=${urlValue}&text=Test`,
                  );
                }}
              >
                <div style={{ backgroundImage: `url(${Twitter})` }}>
                  <img alt="" width={48} height={48} src={TwitterHover} />
                </div>
                <p className="mt-3">Twitter</p>
              </button>
              <button
                className={`d-flex flex-column align-items-center me-2 mt-2 ${s.socialButton}`}
                onClick={() => {
                  window.open(
                    `mailto:?subject=iQ Offices&body=Check out this iQ Offices’ location ${urlValue}`,
                  );
                }}
              >
                <div style={{ backgroundImage: `url(${Email})` }}>
                  <img alt="" width={48} height={48} src={EmailHover} />
                </div>
                <p className="mt-3">Email</p>
              </button>
            </div>
          </div>
        </div>
        <div className={`w-100 border-top border-white my-4`}></div>

        <h5 className="mb-3">Direct Link</h5>
        <div className="d-flex flex-wrap flex-lg-nowrap">
          <input
            className={`form-control ${s.input}`}
            value={urlValue}
            disabled
          />
          <Button
            className="ms-lg-3 mt-lg-0 mt-3"
            variant="outline-light"
            onClick={() => navigator.clipboard.writeText(urlValue)}
          >
            COPY LINK
          </Button>
        </div>
      </Modal>

      <div className={`container-fluid [ d-flex flex-column ] ${s.lptSection}`}>
        <div className={`container-xxl`}>
          <div className={`row`}>
            <div className={``}>
              <CarouselMulti
                primary
                images={carouselImages.map((item) => item.thumbnail)}
                onClick={(value) => {
                  setIsOpen(true);
                  setPhotoIndex(value);
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={cx(
            `container-xxl`,
            `order-first order-lg-0`,
            s.lptSection__title,
          )}
        >
          <div className={`row`}>
            {city && <h5 className={`d-block d-lg-none mb-3`}>{city}</h5>}
            <div className={`col-lg-8 offset-lg-1`}>
              {title}
              {description && (
                <p className="mt-4 d-lg-block d-none">
                  {nl2br(description || "")}
                </p>
              )}
            </div>
            {address && (
              <div className={`col-lg-2 offset-lg-1`}>
                <div
                  className={cx(
                    `d-none d-lg-inline-block`,
                    s.lptSection__title__locationAndReview,
                  )}
                >
                  <h6 className="mb-4">{nl2br(address)}</h6>
                  {get(reviews, "rating", null) &&
                    get(reviews, "total", null) && (
                      <>
                        <img alt="" src={RatingStar} />
                        <span className={"text-nowrap p"}>
                          {Number(get(reviews, "rating", 0)).toFixed(1)} (
                          {get(reviews, "total", 0)} reviews)
                        </span>
                      </>
                    )}

                  <div>
                    <button
                      className={`p-0 mt-3 ${s.shareButton}`}
                      onClick={() => setShareModalOpen(true)}
                    >
                      <FiShare />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`container-xxl ${s.lptSection__description}`}>
          <div className={`row`}>
            <div className={`col-lg-8 offset-lg-1 col-md-9`}>
              {description && (
                <p className="d-block d-lg-none">{nl2br(description || "")}</p>
              )}
            </div>
            {city && get(reviews, "rating", null) && (
              <div className={`d-lg-none col-md-3 order-md-last order-first`}>
                <div className={`${s.lptSection__title__locationAndReview}`}>
                  <h6 className="mb-4 d-md-block d-none">{address}</h6>
                  <div className="d-flex d-md-none">
                    <h6 className="mb-3 w-50">{address}</h6>
                    <div className={"ms-auto"}>
                      <button
                        className={`p-0 ${s.shareButton}`}
                        onClick={() => setShareModalOpen(true)}
                      >
                        <FiShare />
                      </button>
                    </div>
                  </div>
                  <img alt="" src={RatingStar} />
                  <span className={s.review}>
                    {get(reviews, "rating", 0)} ({get(reviews, "total", 0)}{" "}
                    reviews)
                  </span>

                  <div className="d-md-block d-none">
                    <button
                      className={`p-0 mt-3 ${s.shareButton}`}
                      onClick={() => setShareModalOpen(true)}
                    >
                      <FiShare />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={carouselImages[photoIndex].image}
            nextSrc={
              carouselImages[(photoIndex + 1) % carouselImages.length].image
            }
            prevSrc={
              carouselImages[
                (photoIndex + carouselImages.length - 1) % carouselImages.length
              ].image
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + carouselImages.length - 1) %
                  carouselImages.length,
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % carouselImages.length)
            }
          />
        )}
      </div>
    </>
  );
};

export default LocationsProfileTitle;
