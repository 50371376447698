import React from "react";
import { Modal } from "react-bootstrap";
import { SliceZone } from "@prismicio/react";
import {
  escape,
  lpAmenitiesModal,
  lpfaSection,
  lpfaSection__modalContent,
  lpfaSection__modalRow,
  lpfaSection__title,
} from "./LocationsProfileFeaturedAmenitiesModal.module.scss";
import Escape from "../../images/memberships/close.svg";
import WithIcons from "./slices/WithIcons";
import WithLogos from "./slices/WithLogos";
import List from "./slices/List";
import AmenitiesSectionTitle from "./slices/Title";

const LocationsProfileFeaturedAmenitiesModal = ({
  modalTitle,
  slices,
  locationCity,
  locationName,
  ...props
}) => {
  return (
    <Modal
      {...props}
      fullscreen
      className={"modal-z-index"}
      contentClassName={lpAmenitiesModal}
    >
      <div className={`container-xxl ${lpfaSection}`}>
        <div className={escape}>
          <button
            className={`btn-as-link`}
            tabIndex="0"
            onClick={() => props.setAmenitiesModalShow(false)}
            onKeyDown={() => props.setAmenitiesModalShow(false)}
          >
            <img alt="Close" src={Escape} />
          </button>
          <span>
            {locationName} <br /> {locationCity}
          </span>
        </div>
        <div className={`row ${lpfaSection__modalRow}`}>
          <div
            className={`[ offset-lg-2 col-lg-8 ] [ offset-md-1 col-md-10 ] [ px-0 ] ${lpfaSection__modalContent}`}
          >
            <div className={`container`}>
              <SliceZone
                slices={slices}
                components={{
                  "amenities_list_-_with_logos": WithLogos,
                  "amenities_list_-_with_icons": WithIcons,
                  amenities_list: List,
                  amenity_title_section: AmenitiesSectionTitle,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LocationsProfileFeaturedAmenitiesModal;
