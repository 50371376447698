const gtm = (event, data = {}) => {
  if (typeof window === "undefined" || !window.dataLayer) return true;

  try {
    window.dataLayer.push({
      event,
      ...data,
    });
  } catch (e) {
    console.log("failed to track the event");
    console.log(e);
  }

  return true;
};

export default gtm;
