import React, { useEffect, useState } from "react";
import iqApi from "../../services/iqApi";
import get from "lodash/get";
import BookingComponent from "../BookingComponent";
import SuccessModal from "../SuccessModal";
import { setNestedObjectValues, useFormik } from "formik";
import { formatISO, startOfDay } from "date-fns";
import { loadStripe } from "@stripe/stripe-js";
import gtm from "../../services/gtm";
import { v4 as uuidv4 } from "uuid";
import { formValue, isProd } from "../../utils";
import worksimplyApi from "../../services/worksimplyApi";
import getEmailData from "./getEmailData";
import i18n from "../../utils/i18n";
import { navigate } from "gatsby";
import * as Yup from "yup";

const uuid = uuidv4();

export const stripePk = isProd
  ? {
      "5b7d486a87daf911009c3014": "pk_live_DFRqp6GJJsXiTVFuAOzA3dGJ",
      "5bc6ea703c59071000c48e26": "pk_live_7f4FJbCvqS0NiJZmioeVa2W5",
      "5bd9e70f36cc5810004fdb05": "pk_live_eE8sWDVcH5ZDJBryTbf9TGtv",
      "5bd9e91936cc5810004fdc2e": "pk_live_73wvAzlYSx2UJjBFTPhMrx8C",
      "5bd9e7c6534e260f00adc7b2": "pk_live_jxtc3E34BTy4urqMjEi8UvKz",
      "5d038c4f0f5ba00011d5b380": "pk_live_gCm92Y7tIFMw7CqoieFKNADG002nYHdztP",
      "5d02534816a73600105d21ff": "pk_live_jkSbnNBCsYGLX3320iyWNN7c00E2UphERB",
    }
  : {
      "5b7d486a87daf911009c3014": "pk_test_AKIVUgjYGPXXLyEE48fZ1cEZ004ol8DCcL",
      "5bd9e7c6534e260f00adc7b2": "pk_test_AKIVUgjYGPXXLyEE48fZ1cEZ004ol8DCcL",
    };

const VirtualOfficeCheckout = ({
  office = {},
  addOns = [],
  back = () => null,
  plan,
  billing,
  cmsLocations = [],
}) => {
  const [loginLink, setLoginLink] = useState("");
  const [timeoutError, setTimeoutError] = useState(false);

  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    const location = cmsLocations.find(
      (l) => l?.dataRaw?.office_rnd_id === office?._id,
    );
    if (location && location?.url) {
      setRedirectUrl(location?.url);
    } else {
      setRedirectUrl(i18n.t("whyIqUrl"));
    }
  }, [cmsLocations, office]);

  const formik = useFormik({
    initialValues: {
      fullName: formValue("John Smith"),
      email: formValue(`hello-${uuid}@worksimply.com`),
      phoneNumberCountry: "CA",
      phoneNumber: formValue("714714714"),
      companyName: formValue("Worksimply"),
      password: formValue("Pass@1234"),
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required(i18n.t("NameRequired")),
      email: Yup.string()
        .email(i18n.t("InvalidEmail"))
        .required(i18n.t("EmailRequired")),
      companyName: Yup.string().required(i18n.t("CompanyNameRequired")),
      password: Yup.string()
        .min(8, i18n.t("PasswordValidationText"))
        .required(i18n.t("PasswordRequired"))
        .matches(/^(?=.*[a-z])/, i18n.t("PasswordValidationText"))
        .matches(/^(?=.*[A-Z])/, i18n.t("PasswordValidationText"))
        .matches(/^(?=.*[0-9])/, i18n.t("PasswordValidationText")),
      phoneNumber: Yup.string().required(i18n.t("PhoneRequired")),
    }),
    onSubmit: () => null,
    validateOnMount: false,
  });

  const [progress, setProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [subscriptionError, setSubscriptionError] = useState(false);
  const [creditCardError, setCreditCardError] = useState(false);
  const [creditCardErrorMessage, setCreditCardErrorMessage] = useState("");

  const checkout = async (stripeTokenData = {}) => {
    const formErrors = await formik.validateForm();

    if (Object.keys(formErrors).length > 0) {
      await formik.setTouched(setNestedObjectValues(formErrors, true));
      return;
    }

    const reqData = {
      email: get(formik, "values.email", ""),
      password: get(formik, "values.password", ""),
      name: get(formik, "values.fullName", ""),
      company: get(formik, "values.companyName", ""),
      phone: get(formik, "values.phoneNumber", ""),
      office: office._id,
      addOns: addOns.map((a) => a._id),
      startDate: formatISO(startOfDay(new Date())),
      cardData: {
        id: stripeTokenData?.id,
        stripe_token: stripeTokenData?.id,
        last4: stripeTokenData?.card?.last4,
        brand: stripeTokenData?.card?.brand,
      },
      isAlreadyRegistered: subscriptionError,
    };
    setProgress(true);
    const response = await iqApi("create-subscription", reqData);

    const type = get(response, "type", "error");
    const message = get(
      response,
      "message",
      "Failed to create the subscription. Please contact us.",
    );

    setSubscriptionError(response?.creditCardError || false);
    setCreditCardError(response?.creditCardError || false);
    setCreditCardErrorMessage(response?.creditCardErrorMessage || "");

    if (response?.creditCardError) {
      // send mail
      const emailData = getEmailData(
        formik.values,
        stripeTokenData,
        response?.creditCardErrorMessage,
      );
      const formattedData = {
        to: isProd
          ? ["ar@iqoffices.com", "sales@iqoffices.com."]
          : ["mahesh.samudra@worksimply.com", "mahesh@ensodigital.ca"],
        userEmail: formik.values.email,
        sp_slug: "iq",
        replyTo: formik.values.email,
        emailBodyDescription:
          "Please note the following prospect was unable to complete their registration due to invalid payment card." +
          `<br><br>` +
          "They’ve been directed to contact sales@iqoffices.com for support, OR to input their correct CC on the Billing Page of the Member portal.",
        emailTitle: "Virtual Offices Subscription - Credit Card Error",
        emailData: [...emailData],
      };
      await worksimplyApi("handle-form", formattedData);
    }

    if (type === "success") {
      const { bookingId, createdAt } = response;
      setLoginLink(get(response, "loginLink", null));
      gtm("ws_meeting_room_reservation", { bookingId, createdAt });
    } else {
      setTimeoutError(true);
      // setProgress(false);
      // setErrorMessage(
      //   message || "Failed to create the subscription. Please contact us."
      // );
    }
  };

  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (!office?._id) {
      return;
    }
    loadStripe(
      stripePk[office?._id || "5b7d486a87daf911009c3014"] ||
        "pk_test_AKIVUgjYGPXXLyEE48fZ1cEZ004ol8DCcL",
      { locale: i18n.t("lang") === "" ? "en" : "fr" },
    )
      .then((res) => {
        setStripePromise(res);
      })
      .catch((error) => console.log(error));
  }, [office]);

  if (!stripePromise) return null;

  return (
    <div>
      <BookingComponent
        stripePromise={stripePromise}
        back={back}
        voData={{
          office,
          addOns,
          checkout,
          loginLink,
          progress,
          formik,
          plan,
          billing,
          errorMessage,
          subscriptionError,
        }}
        submitButtonClass={"btnOfficeRndExit"}
      />

      <SuccessModal
        show={progress}
        hide={() => {
          setProgress(false);
          if (redirectUrl) {
            navigate(redirectUrl);
          }
        }}
        loginLink={loginLink}
        creditCardError={creditCardError}
        creditCardErrorMessage={creditCardErrorMessage}
        timeoutError={timeoutError}
      />
    </div>
  );
};

export default VirtualOfficeCheckout;
