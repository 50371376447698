import React from "react";
import * as s from "./ProductInfo.module.scss";
import get from "lodash/get";
import MultiInfos from "../MultiInfos";
import i18n from "../../utils/i18n";

const ProductInfo = ({ productInfo, slice, context, fullWidth = false }) => {
  const prismicItems = get(slice, "items", []);

  const isContactPage = get(context, "isContactPage", false);

  return (
    <div
      className={
        isContactPage ? "d-none d-lg-block mt-5" : s.productInfoContainerSection
      }
    >
      <div className={s.productInfoContainer}>
        <div className={`container-xxl`}>
          <div className={`row justify-content-center`}>
            <div className={`${fullWidth ? `col-lg-12` : `col-lg-10`}`}>
              <MultiInfos
                isContactPage={isContactPage}
                infoItems={
                  productInfo ||
                  prismicItems.map((item) => {
                    return {
                      infoTitle: item.title,
                      infoDescList: get(item, "text", "").split("\n"),
                      infoTitleIcon: item.display_plus_icon,
                    };
                  })
                }
              />
            </div>
          </div>
        </div>

        {/*BAR start*/}
        {slice?.primary?.capacity || slice?.primary?.flexible_term ? (
          <div className="bg-offwhite border-top border-primary py-3">
            <div className="container-xxl d-flex flex-row justify-content-center align-items-center">
              <div className="d-sm-flex d-none align-items-center">
                <h6>{i18n.t("Capacity")}</h6>
                <p className="ms-3">{slice?.primary?.capacity}</p>
              </div>
              <div className="d-sm-flex d-none align-items-center">
                <h6 className="ms-4">{i18n.t("Flexible Term")}</h6>
                <p className="ms-3">{slice?.primary?.flexible_term}</p>
              </div>
              <div className="d-flex flex-column d-sm-none align-items-end">
                <h6 style={{ lineHeight: "22px" }}>{i18n.t("Capacity")}</h6>
                <h6 style={{ lineHeight: "22px" }}>
                  {i18n.t("Flexible Term")}
                </h6>
              </div>
              <div className="ms-4 d-flex flex-column d-sm-none">
                <p>{slice?.primary?.capacity}</p>
                <p>{slice?.primary?.flexible_term}</p>
              </div>
            </div>
          </div>
        ) : null}
        {/*BAR end*/}
      </div>
    </div>
  );
};

export default ProductInfo;
