import axios from "axios";

const iqApi = async (slug, data = null) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  return axios({
    method: "post",
    url: `${process.env.GATSBY_IQ_API}/${slug}`,
    headers,
    data,
  })
    .then((r) => r.data)
    .catch((e) => {
      console.log(e);
      return { type: "error", isRndApiError: true };
    });
};

export default iqApi;
