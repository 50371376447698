import React, { useEffect, useState } from "react";
import * as s from "./BlogTitle.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import get from "lodash/get";
import { Link } from "gatsby";
import DefaultBlogImage from "./default-blog-image.jpg";
import cx from "classnames";
import i18n from "../../../utils/i18n";
import CloseButton from "../../../images/design-components/Less_1_WHT.svg";
import Facebook from "../../../images/design-components/Facebook-Line-WHT.svg";
import FacebookHover from "../../../images/design-components/Facebook-WHT.svg";
import Linkedin from "../../../images/design-components/LinkedIn-Line-WHT.svg";
import LinkedinHover from "../../../images/design-components/LinkedIn-WHT.svg";
import Twitter from "../../../images/design-components/Twitter-WHT.svg";
import TwitterHover from "../../../images/design-components/Twitter-WHT_1.svg";
import Email from "../../../images/design-components/Email-Line-WHT.svg";
import EmailHover from "../../../images/design-components/Email-WHT.svg";
import { Button, Modal } from "react-bootstrap";
import { FiShare } from "react-icons/fi";

const BlogTitle = ({ blog = {}, blogPage }) => {
  const image = getImage(get(blog, "data.cover_image.localFile", null));
  const title = get(blog, "data.title", "");
  const date = get(blog, "data.post_date", "");
  const blogSummary = get(blog, "data.summary", "");
  const slug = get(blog, "uid", "");
  const isFrench = get(blog, "lang", "") === "fr-ca";

  // ----------- MODAL start ------------
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [urlValue, setUrlValue] = useState("");
  useEffect(() => {
    setUrlValue(window.location.href);
  }, []);
  // ----------- MODAL end ------------

  return (
    <>
      <Modal
        centered
        show={shareModalOpen}
        onHide={setShareModalOpen}
        contentClassName={s.modalContent}
        dialogClassName={s.modalDialog}
        className={`${s.modal} modal-z-index`}
      >
        <button
          className={`${s.closeButton}`}
          onClick={() => setShareModalOpen(false)}
        >
          <img alt="" src={CloseButton} />
        </button>
        <h3 className="border-bottom border-white mb-4 pb-4">{title}</h3>
        <div className="d-flex flex-column flex-lg-row">
          <h5 className="mb-1">Share</h5>
          <div className="d-flex flex-md-row flex-column mx-auto">
            <div className="d-flex justify-content-around">
              <button
                className={`d-flex flex-column align-items-center me-2 mt-2 ${s.socialButton}`}
                onClick={() => {
                  window.open(
                    `https://www.facebook.com/sharer.php?u=${urlValue}`,
                  );
                }}
              >
                <div style={{ backgroundImage: `url(${Facebook})` }}>
                  <img alt="" width={48} height={48} src={FacebookHover} />
                </div>
                <p className="mt-3">Facebook</p>
              </button>
              <button
                className={`d-flex flex-column align-items-center me-2 mt-2 ${s.socialButton}`}
                onClick={() => {
                  window.open(
                    `https://www.linkedin.com/shareArticle?url=${urlValue}&title=Test`,
                  );
                }}
              >
                <div style={{ backgroundImage: `url(${Linkedin})` }}>
                  <img alt="" width={48} height={48} src={LinkedinHover} />
                </div>
                <p className="mt-3">Linkedin</p>
              </button>
            </div>
            <div className="d-flex justify-content-around">
              <button
                className={`d-flex flex-column align-items-center me-2 mt-2 ${s.socialButton}`}
                onClick={() => {
                  window.open(
                    `https://twitter.com/share?url=${urlValue}&text=Test`,
                  );
                }}
              >
                <div style={{ backgroundImage: `url(${Twitter})` }}>
                  <img alt="" width={48} height={48} src={TwitterHover} />
                </div>
                <p className="mt-3">Twitter</p>
              </button>
              <button
                className={`d-flex flex-column align-items-center me-2 mt-2 ${s.socialButton}`}
                onClick={() => {
                  window.open(
                    `mailto:?subject=iQ Offices Blog Post&body=Check out this iQ Offices’ blog post ${urlValue}`,
                  );
                }}
              >
                <div style={{ backgroundImage: `url(${Email})` }}>
                  <img alt="" width={48} height={48} src={EmailHover} />
                </div>
                <p className="mt-3">Email</p>
              </button>
            </div>
          </div>
        </div>
        <div className={`w-100 border-top border-white my-4`}></div>

        <h5 className="mb-3">Direct Link</h5>
        <div className="d-flex flex-wrap flex-lg-nowrap">
          <input className={`form-control`} value={urlValue} disabled />
          <Button
            className="ms-lg-3 mt-lg-0 mt-3"
            variant="outline-light"
            onClick={() => navigator.clipboard.writeText(urlValue)}
          >
            COPY LINK
          </Button>
        </div>
      </Modal>

      <div
        className={`container-xxl ${blogPage ? "my-5" : ""} ${
          s.blogTitleSection
        }`}
      >
        {!blogPage ? (
          <>
            <div className="row">
              <div className="col-lg-6 offset-lg-1 col-md-9">
                {!image ? (
                  <img alt="blog image" src={DefaultBlogImage} />
                ) : (
                  <GatsbyImage image={image} alt={title} />
                )}
              </div>
              <div
                className={cx(
                  `col-lg-4 col-md-8 d-flex flex-column justify-content-between`,
                  `mt-4 mt-lg-0`,
                )}
              >
                <>
                  <h6 className="opacity-25">{date}</h6>
                  <div>
                    <h3 className={`${s.blogTitle} mb-3 mt-3 mt-md-4 mt-lg-0`}>
                      {title}
                    </h3>
                    {blogSummary && <p>{blogSummary}</p>}
                  </div>
                  <Link
                    to={`${isFrench ? "/fr" : ""}/blog/${slug}`}
                    className={`${s.blogTitleReadMore} text-primary mt-3 mt-md-4 mt-lg-0`}
                    title={i18n.t("ReadMore")}
                  >
                    {i18n.t("ReadMore")}
                  </Link>
                </>
              </div>
              <div className="offset-lg-1 col-lg-10 my-5">
                <div className="container-xxl border-bottom-primary"></div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className={`${s.blogTitleImage} mb-3 mb-md-4 mb-lg-5`}>
                  {!image ? (
                    <img alt="blog image" src={DefaultBlogImage} />
                  ) : (
                    <GatsbyImage image={image} alt={title} />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2 offset-lg-1 d-flex justify-content-between">
                <h6 className={s.blog_date}>{date}</h6>
                <button
                  className={`p-0 d-block d-lg-none ${s.shareButton}`}
                  onClick={() => setShareModalOpen(true)}
                >
                  <FiShare />
                </button>
              </div>
              <div className="col-12 col-md-10 col-lg-6 ">
                <h2 className={`${s.blogPageTitle} mt-3 mt-md-4 mt-lg-0`}>
                  {title}
                </h2>
              </div>
            </div>
            <div className={`row ${s.blogSummaryRow}`}>
              <div className="col-lg-2 offset-lg-1">
                <button
                  className={`p-0 d-none d-lg-block ${s.shareButton}`}
                  onClick={() => setShareModalOpen(true)}
                >
                  <FiShare />
                </button>
              </div>
              <div className="col-lg-8">
                <p className={`border-bottom-primary ${s.blogSummary}`}>
                  {blogSummary}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BlogTitle;
