const teamSizes = [
  { label: "1", size: "1" },
  { label: "2", size: "1" },
  { label: "3", size: "1" },
  { label: "4", size: "1" },
  { label: "5", size: "1" },
  { label: "6", size: "1" },
  { label: "7", size: "1" },
  { label: "8", size: "1" },
  { label: "9", size: "1" },
  { label: "10", size: "1" },
  { label: "11", size: "1" },
  { label: "12", size: "1" },
  { label: "13", size: "1" },
  { label: "14", size: "1" },
  { label: "15", size: "1" },
  { label: "16", size: "1" },
  { label: "17", size: "1" },
  { label: "18", size: "1" },
  { label: "19", size: "1" },
  { label: "20", size: "1" },
  { label: "21-25", size: "2" },
  { label: "26-30", size: "2" },
];

export default teamSizes;
