import React from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import * as s from "./DropdownLocation.module.scss";
import i18n from "../../utils/i18n";

const DropdownLocation = ({
  location,
  setLocation,
  locationOptions,
  productWidget,
  show,
  setShow = () => null,
}) => {
  return (
    <>
      <DropdownButton
        autoClose
        drop={"down"}
        show={show}
        onToggle={(isOpen, event) => {
          setShow(!show);
        }}
        defaultShow={false}
        title={
          !productWidget ? (
            <span>{location}</span>
          ) : (
            <div className={s.locationDropdownButtonProductWidgetTitle}>
              <h6>{i18n.t("homePage.City")}</h6>
              <p>{location}</p>
            </div>
          )
        }
        id={"dropdown_location_picker"}
        className={
          !productWidget
            ? s.locationDropdownButton
            : s.locationDropdownButtonProductWidget
        }
        bsPrefix={"dropdown-location-button"}
      >
        <Dropdown.Menu className={`${s.locationDropdownMenu} border-primary`}>
          {locationOptions.map((location, index) => (
            <div key={index}>
              <Dropdown.Item
                className={`bg-transparent`}
                onClick={() => setLocation(location.name)}
              >
                <Button className={`py-2 w-100`}>{location.name}</Button>
              </Dropdown.Item>
            </div>
          ))}
        </Dropdown.Menu>
      </DropdownButton>
    </>
  );
};

export default DropdownLocation;
