import React from "react";
import LeftArrow from "../../../images/design-components/Left.svg";
import * as s from "./BackToAllBlog.module.scss";
import { Link } from "gatsby";
import i18n from "../../../utils/i18n";

const BackToAllBlogButton = ({ isFrench = false }) => {
  return (
    <>
      <div className={`container-xxl d-lg-none ${s.backToAllBlogButton}`}>
        <div className="row">
          <div className={"col-lg-5 offset-lg-1 col-md-9"}>
            <Link
              to={`${isFrench ? "/fr" : ""}/blog`}
              title={"Back to all blog posts"}
            >
              <span>
                <img alt="" src={LeftArrow} /> {i18n.t("BackToAllBlogPosts")}
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div
        className={`d-none d-lg-block container-xxl ${s.backToAllBlogButtonSticky}`}
      >
        <div className="row">
          <div className="col-lg-2 offset-lg-1">
            <Link
              to={`${isFrench ? "/fr" : ""}/blog`}
              title={"Back to all blog posts"}
              className="d-flex align-items-start"
            >
              <img alt="" src={LeftArrow} className="pt-1" />
              <span className={s.text}>{i18n.t("BackToAllBlogPosts")}</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BackToAllBlogButton;
