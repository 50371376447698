/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */

import {componentResolverFromMap} from "gatsby-plugin-prismic-previews";

import {prismicRepo} from "../../../prismic-configuration";
import {linkResolver} from "./linkResolver";

import HomepageTemplate from "../../templates/HomePage";
import WhyIqPageTemplate from "../../templates/WhyIqPages";
import ProductSubPageTemplate from "../../templates/Products/Product";
import ProductLandingPage from "../../templates/Products/LandingPage";
import WhyIqLandingPageTemplate from "../../templates/WhyIqPages/LandingPage";
import CustomPageTemplate from "../../templates/CustomPage";
import BlogPostTemplate from "../../templates/BlogPostTemplate";
import LocationTemplate from "../../templates/LocationTemplate";
import EventVenuePageTemplate from "../../templates/EventVenue";

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: prismicRepo,
    linkResolver,
    componentResolver: componentResolverFromMap({
      home_page: HomepageTemplate,
      why_iq_pages: WhyIqPageTemplate,
      product_pages: ProductSubPageTemplate,
      products_landing_page: ProductLandingPage,
      custom_page: CustomPageTemplate,
      why_iq_landing: WhyIqLandingPageTemplate,
      blog_post: BlogPostTemplate,
      location: LocationTemplate,
      event_venue: EventVenuePageTemplate,
    }),
  },
];
