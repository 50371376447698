import { getQueryParams } from "../index";
import formatPrice from "../formarPrice";
import get from "lodash/get";

const getFinalPrice = (selectedOption = null) => {
  const params = getQueryParams();
  const paramsPrice = params.get("price_min");
  const validParamsPrice =
    paramsPrice && paramsPrice.length > 2 && !isNaN(Number(paramsPrice));
  if (!selectedOption || !get(selectedOption, "priceInt", false)) {
    return validParamsPrice ? `$${formatPrice(Number(paramsPrice))}` : "N/A";
  }

  const optionPrice = get(selectedOption, "priceInt", false);
  return optionPrice
    ? `$${formatPrice(Number(optionPrice))}`
    : validParamsPrice
      ? `$${formatPrice(Number(paramsPrice))}`
      : "N/A";
};

export default getFinalPrice;
