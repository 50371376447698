import {
  addMinutes,
  format,
  isBefore,
  isSameMinute,
  isValid,
  parse,
} from "date-fns";

const getTimeSlots = (dateObj = null, options) => {
  let timeSlots = [];

  if (!isValid(dateObj)) return timeSlots;

  const {
    gap = 30,
    startTime = "8:30 AM",
    endTime = "5:00 PM",
    pop = false,
    shift = false,
  } = options;

  const date = format(dateObj, "yyyy-MM-dd");
  const openingTimeString = `${date} ${startTime}`;
  const closingTimeString = `${date} ${endTime}`;
  let ot = parse(openingTimeString, "yyyy-MM-dd hh:mm a", new Date());
  const ct = parse(closingTimeString, "yyyy-MM-dd hh:mm a", new Date());

  while (isBefore(ot, ct) || isSameMinute(ot, ct)) {
    timeSlots.push(ot);
    ot = addMinutes(ot, gap);
  }

  if (pop) {
    timeSlots.pop();
    timeSlots.pop(); // remove last item (4:30pm since minimum meeting time is 1 hour)
  }
  if (shift) {
    timeSlots.shift();
  }

  return timeSlots;
};

export default getTimeSlots;
