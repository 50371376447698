import React from "react";
import {
  safetyFaqAccordion,
  safetyFaqSection,
  safetyFaqSection__title,
} from "../../../../pages/whyiq/whyiq.module.scss";
import * as s from "./index.module.scss";
import { Accordion } from "react-bootstrap";
import get from "lodash/get";
import { PrismicRichText } from "@prismicio/react";
import cx from "classnames";

const FaqSection = ({ slice }) => {
  return (
    <div className={`container-fluid p-0 overflow-hidden ${safetyFaqSection}`}>
      <div className={`container-xxl ${safetyFaqSection__title}`}>
        <div className={`row justify-content-center`}>
          <div className={`col-lg-10 col-md-11`}>
            <PrismicRichText field={get(slice, "primary.title", null)} />
          </div>
        </div>
      </div>

      <Accordion bsPrefix={safetyFaqAccordion}>
        {get(slice, "items").map((item, idx) => (
          <div className={`position-relative`} key={idx}>
            {idx === 0 ? <div className={s.borderTop}></div> : ""}
            <div className={`container-xxl`}>
              <div className={`row justify-content-center`}>
                <div className={cx(`col-lg-10`, `col-md-11`)}>
                  <Accordion.Item className={`bg-transparent`} eventKey={idx}>
                    <Accordion.Header as="p">{item.question}</Accordion.Header>
                    <Accordion.Body className={cx(s.accordionBody, `p-0`)}>
                      <PrismicRichText field={get(item, "answer", null)} />
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              </div>
            </div>
            <div className={s.borderBottom}></div>
          </div>
        ))}
      </Accordion>
    </div>
  );
};

export default FaqSection;
