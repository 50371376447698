import get from "lodash/get";

const activeDocMeta = (pageData = {}) => {
  const {
    id = null,
    uid,
    lang,
    type,
    url,
    alternate_languages: alternateLanguages,
  } = pageData;

  const metaTitle = get(pageData, "data.meta_title");
  const metaDescription = get(pageData, "data.meta_description");
  const metaImage = get(pageData, "data.meta_image.url");
  return {
    id,
    uid,
    lang,
    type,
    url,
    alternateLanguages,
    metaTitle,
    metaDescription,
    metaImage,
  };
};

export default activeDocMeta;
