import React from "react";
import Select from "react-select";
import "./CustomSelect.css";
import "../../styles/fonts/fonts.css";
import CheckboxCheck from "../../images/design-components/Checkbox_2.svg";
import ArrowDown from "../../images/design-components/Down.svg";

const customStyles = {
  container: (provided) => ({
    ...provided,
    height: "100%",
  }),
  control: (provided, { selectProps }) => {
    if (selectProps.productWidget) {
      return {
        ...provided,
        padding: "16px",
        height: "100%",
        cursor: "pointer",
        boxShadow: "none",
        border: "none",
        ":hover": {
          borderColor: "red",
        },
      };
    } else {
      return {
        ...provided,
        border: "1px solid #1C2A59",
        paddingTop: "7px",
        paddingRight: "12px",
        paddingBottom: "6px",
        paddingLeft: "20px",
        borderRadius: 0,
        height: "100%",
        cursor: "pointer",
      };
    }
  },
  valueContainer: (provided, { selectProps }) => {
    if (selectProps.productWidget) {
      return {
        ...provided,
        paddingLeft: "80px",
        paddingTop: "0px",
        paddingBottom: "0px",
        marginTop: "-1px",
        marginBottom: "-1px",
      };
    } else {
      return {
        ...provided,
        paddingLeft: "24px",
        paddingTop: "0px",
        paddingBottom: "0px",
        marginTop: "-1px",
        marginBottom: "-1px",
      };
    }
  },
  placeholder: (provided, { selectProps }) => {
    if (selectProps.productWidget) {
      return {
        ...provided,
        marginTop: 0, // 20
        fontFamily: '"Avenir Regular", sans-serif',
        fontSize: "16px",
        color: "black",
        opacity: "50%",
      };
    } else {
      return {
        ...provided,
        fontFamily: '"Avenir Regular", sans-serif',
        fontSize: "16px",
        color: "black",
        opacity: "50%",
      };
    }
  },
  singleValue: (provided, { selectProps }) => {
    if (selectProps.productWidget) {
      return {
        ...provided,
        marginTop: 0,
        fontSize: "16px",
        color: "#1C2A59",
        whiteSpace: "pre",
      };
    } else {
      return {
        ...provided,
        marginTop: 0,
        fontSize: "16px",
        color: "#1C2A59",
        whiteSpace: "pre",
      };
    }
  },
  indicatorsContainer: (provided, { selectProps }) => {
    if (selectProps.productWidget) {
      return {
        ...provided,
        position: "absolute",
        top: "29px",
        left: "40px",
        ":before": {
          content: '""',
          position: "absolute",
          // top: "29px",
          left: "10px",
          width: 16,
          height: 15,
          backgroundImage: `url(${ArrowDown})`,
          backgroundSize: "cover",
        },
      };
    } else {
      return {
        ...provided,
        position: "absolute",
        top: "12px",
        left: "6px",
        ":before": {
          content: '""',
          position: "absolute",
          top: "4px",
          left: "10px",
          width: 13,
          height: 12,
          backgroundImage: `url(${ArrowDown})`,
          backgroundSize: "cover",
        },
      };
    }
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: "none",
    borderRadius: "0",
    border: "1px solid #1C2A59",
    fontSize: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",
    paddingLeft: "32px",
    paddingRight: "32px",
    marginTop: "0px",
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isFocused ? "#F2F2F2" : isSelected ? "white" : "",
    paddingLeft: "32px",
    color: "#1C2A59",
    width: "100%",
    whiteSpace: "normal",
    display: "flex",
    ":before": {
      position: "relative",
      top: "3px",
      left: "-24px",
      content: '""',
      display: "inline-block",
      minWidth: "18px",
      minHeight: "18px",
      width: "18px",
      height: "18px",
      border: isSelected ? "" : "1px solid #1C2A59",
      backgroundImage: isSelected ? `url(${CheckboxCheck})` : "",
      backgroundPosition: "center",
    },
  }),
  clearIndicator: () => ({
    display: "none",
  }),
};

export const CustomSelectSingle = ({
  onChange,
  options,
  value,
  className,
  disabled = false,
  hasError = false,
  ...rest
}) => {
  const defaultValue = (options, value) => {
    return options ? options.find((option) => option.value === value) : "";
  };

  const { placeholder, isMenuOpen, phoneNumber, productWidget } = rest;

  return (
    <Select
      menuIsOpen={isMenuOpen} // Use this to properly inspect the dropdown and customize
      styles={customStyles}
      className={`custom-select-container ${hasError ? "has-error" : ""} `}
      placeholder={placeholder}
      isDisabled={disabled}
      value={defaultValue(options, value)}
      onChange={(value) => onChange(value)}
      options={options}
      // controlShouldRenderValue={false}
      productWidget={productWidget}
    />
  );
};

export const CustomSelectMulti = ({
  onChange,
  options,
  value,
  className,
  hasError = false,
  ...rest
}) => {
  const { placeholder } = rest;

  return (
    <Select
      // menuIsOpen // Use this to properly inspect the dropdown and customize
      isMulti
      styles={customStyles}
      className={`custom-select-container ${hasError ? "has-error" : ""} `}
      placeholder={placeholder}
      onChange={(value) => onChange(value)}
      options={options}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
    />
  );
};
