import React, { useState } from "react";
import get from "lodash/get";
import { PrismicRichText } from "@prismicio/react";
import * as s from "../../index.module.scss";
import HomeMapMarker from "../../../../components/HomeMapMarker";
import { navigate } from "gatsby";
import { Button } from "react-bootstrap";
import i18n from "../../../../utils/i18n";

const HomeMapSection = ({ slice }) => {
  const [locationHovered, setLocationHovered] = useState("");

  return (
    <div className={`${s.hpMapSection}`}>
      <div className={`${s.hpMapSection__title} container-xxl`}>
        <PrismicRichText field={get(slice, "primary.title", [])} />
      </div>
      <div
        className={`${s.hpMapSection__locations} 
        d-flex position-relative justify-content-md-center flex-md-row  align-items-center flex-column `}
      >
        {get(slice, "items", []).map((item, index) => (
          <React.Fragment key={index}>
            {index !== 0 && (
              <h5 className={`mx-2 d-md-block d-none`}>&#x2022;</h5>
            )}
            <Button
              className={`btn-link btn-link-hover-none p-0 ${
                locationHovered === get(item, "location_name", "") &&
                `color-secondary-turquoise`
              }`}
              onClick={async () => {
                if (window.innerWidth > 1024) {
                  await navigate(
                    `${i18n.t("/lang/")}locations?location=${get(
                      item,
                      "location_name",
                      "",
                    )}`,
                  );
                } else if (locationHovered === get(item, "location_name", "")) {
                  await navigate(
                    `${i18n.t("/lang/")}locations?location=${get(
                      item,
                      "location_name",
                      "",
                    )}`,
                  );
                } else {
                  setLocationHovered(get(item, "location_name", ""));
                }
              }}
              onMouseEnter={() => {
                if (window.innerWidth > 1024) {
                  setLocationHovered(get(item, "location_name", ""));
                }
              }}
              onMouseLeave={() => {
                if (window.innerWidth > 1024) {
                  setLocationHovered("");
                }
              }}
            >
              <h5>{get(item, "location_name", "")}</h5>
            </Button>
          </React.Fragment>
        ))}
      </div>
      <div className={`${s.hpMapSection__map} container-xxl`}>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xxl-8 col-md-8">
            <div className="position-relative">
              <img
                src={get(slice, "primary.map_image.url", "")}
                alt=""
                className={"w-100 d-block"}
              />
              {get(slice, "items", []).map((item, index) => (
                <div
                  key={index}
                  className={s.hpMapSection__map__markerContainer}
                  style={{
                    left: `${get(item, "x")}%`,
                    top: `${get(item, "y")}%`,
                  }}
                >
                  <Button
                    className={`btn-link btn-link-hover-none`}
                    onClick={async () => {
                      if (window.innerWidth > 1024) {
                        await navigate(
                          `${i18n.t("/lang/")}locations?location=${get(
                            item,
                            "location_name",
                            "",
                          )}`,
                        );
                      } else if (
                        locationHovered === get(item, "location_name", "")
                      ) {
                        await navigate(
                          `${i18n.t("/lang/")}locations?location=${get(
                            item,
                            "location_name",
                            "",
                          )}`,
                        );
                      } else {
                        setLocationHovered(get(item, "location_name", ""));
                      }
                    }}
                  >
                    <HomeMapMarker
                      location={get(item, "location_name")}
                      hovered={locationHovered === get(item, "location_name")}
                    />
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMapSection;
