import * as React from "react";
import { Helmet } from "react-helmet";
import get from "lodash/get";

const Metadata = ({ metadata, customCss = null }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        {get(metadata, "metaTitle", "Welcome to iQ Offices") ||
          "Welcome to iQ Offices"}
      </title>
      <meta
        name="description"
        content={
          get(metadata, "metaDescription", "Welcome to iQ Offices") ||
          "Welcome to iQ Offices"
        }
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta property="og:image" content={get(metadata, "metaImage", "")} />
      <meta
        name="facebook-domain-verification"
        content="h0rwle7e605phtwgfuamkgmc0jn87s"
      />

      <script
        type="text/javascript"
        src="https://js.hsforms.net/forms/shell.js"
        defer
      />

      {customCss && typeof customCss === "string" && <style>{customCss}</style>}
    </Helmet>
  );
};

export default Metadata;
