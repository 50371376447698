import * as React from "react";
import { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import activeDocMeta from "../utils/activeDocMeta";
import Layout from "../../components/Layout";
import get from "lodash/get";
import Content from "../../components/FooterPages/Content";
import BlogTitle from "../../components/FooterPages/Blog/BlogTitle";
import BackToAllBlogButton from "../../components/FooterPages/Blog/BackToAllBlogButton";
import { BlogBrowseFooter } from "../../components/FooterPages/Blog/BlogBrowse";

const BlogPostTemplate = ({ data = {}, pageContext }) => {
  const pageData = get(data, "prismicBlogPost.data", {});

  const [moreReading, setMoreReading] = useState([]);

  const isFrench = get(data, "prismicBlogPost.lang", "") === "fr-ca";

  const docData = activeDocMeta(get(data, "prismicBlogPost", {}));

  const allBlogs = get(data, "allPrismicBlogPost.nodes", []);

  useEffect(() => {
    if (!allBlogs || allBlogs.length === 0) return;
    for (let i = 0; i < allBlogs.length; i++) {
      const blog = allBlogs[i];
      if (blog.uid === get(data, "prismicBlogPost.uid", "")) {
        setMoreReading(allBlogs.slice(i + 1, i + 9));
        break;
      }
    }
  }, [allBlogs]);

  return (
    <Layout
      activeDocMeta={{
        ...docData,
        metaTitle: docData.metaTitle || pageData.title,
        metaDescription: docData.metaDescription || pageData.summary,
        metaImage: docData.metaImage || get(pageData, "cover_image.url", ""),
      }}
      pages={{
        whyIqPages: get(data, "allPrismicWhyIqPages.nodes", []),
        productPages: get(data, "allPrismicProductPages.nodes", []),
        customPages: get(data, "allPrismicCustomPage.nodes", []),
        whyIqLandingPage: get(data, "allPrismicWhyIqLanding.nodes", []),
        productsLandingPage: get(
          data,
          "allPrismicProductsLandingPage.nodes",
          [],
        ),
      }}
      subscriptionModalData={data?.prismicHomePage?.data || {}}
    >
      <BackToAllBlogButton isFrench={isFrench} />
      <BlogTitle
        blogPage
        blog={{
          data: pageData,
          uid: get(data, "prismicBlogPost.uid", null),
          lang: get(data, "prismicBlogPost.uid", null),
        }}
      />
      <Content
        blogContent
        slice={{
          primary: {
            content: get(data, "prismicBlogPost.dataRaw.body", {}),
          },
        }}
        audioFile={get(pageData, "audio_video_file.url", null)}
      />

      <BlogBrowseFooter
        blogList={moreReading.slice(0, 8).map((blog) => ({
          blogDate: blog?.data?.post_date || "",
          blogTitle: blog?.data?.title || "",
          blogImage: blog?.data?.cover_image?.url || "",
          blogUrl: blog?.url || null,
          summary: blog?.data?.summary || "",
        }))}
      />
    </Layout>
  );
};

export const query = graphql`
  query BlogPostTemplate($lang: String, $uid: String) {
    prismicBlogPost(lang: { eq: $lang }, uid: { eq: $uid }) {
      uid
      id
      url
      lang
      alternate_languages {
        id
        uid
        type
        lang
      }
      _previewable
      dataRaw
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        title
        summary
        post_date(formatString: "MMM DD, yyyy")
        audio_video_file {
          url
          type
          size
          raw
        }
        cover_image {
          url
          localFile {
            url
            childImageSharp {
              gatsbyImageData(width: 800, formats: AUTO, height: 450)
            }
          }
        }
      }
      uid
      lang
    }
    prismicHomePage(lang: { eq: $lang }) {
      _previewable
      data {
        subscription_form_title
        subscription_form_description
        subscription_form_button_label
        subscription_footer_button_label
        blog_page_modal_display_delay
      }
    }
    allPrismicProductPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        uid
        _previewable
        data {
          menu_link_name
          menu_link_description
          product_type
          display_in_header_menu
        }
      }
    }
    allPrismicWhyIqLanding(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicBlogPost(
      filter: { lang: { eq: $lang } }
      limit: 1000
      sort: { fields: data___post_date, order: DESC }
    ) {
      nodes {
        url
        data {
          title
          summary
          post_date(formatString: "MMM DD, yyyy")
          cover_image {
            url
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 800, formats: AUTO, height: 450)
              }
            }
          }
        }
        uid
        lang
      }
    }
    allPrismicProductsLandingPage(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicWhyIqPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        uid
        type
        _previewable
        data {
          display_in_main_menu
          menu_link_name
          menu_link_description
          menu_link_image {
            url
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 192, formats: AUTO, height: 128)
              }
            }
          }
        }
      }
    }
    allPrismicCustomPage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        uid
        lang
        type
        url
        dataRaw
        data {
          main_menu_order
          link_label
          show_in_main_menu
        }
      }
    }
  }
`;

export default withPrismicPreview(BlogPostTemplate);
