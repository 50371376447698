import React, { useState } from "react";
import * as s from "./Hero.module.scss";
import {
  heroContainer,
  heroModal,
  heroModal__body,
  heroModal__escape,
  heroText,
  heroWithBackdrop,
  heroWithoutBackdrop,
} from "./Hero.module.scss";
import { Button, Modal } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";
import Escape from "../../images/memberships/close.svg";
import cx from "classnames";

const Hero = ({
  image,
  backdrop,
  title,
  ctaLabel,
  ctaLink,
  slice,
  className = "",
  venueSubPage,
  poppyText = {},
  context,
}) => {
  const [show, setShow] = useState(false);

  const poppyLine1 = get(poppyText, "line1", "");
  const poppyLine2 = get(poppyText, "line2", "");
  const poppyLine3 = get(poppyText, "line3", "");

  const isPoppy = poppyLine1 || poppyLine2 || poppyLine3;

  const prismicImage = get(slice, "primary.image.url", "");

  const buttonLabel = get(slice, "primary.button_label", "");
  const buttonLink = get(slice, "primary.button_video_url", "");

  const prismicRichText = (
    <PrismicRichText field={get(slice, "primary.title", "")} />
  );

  return (
    <>
      <div
        className={`${className} container-lg ${
          backdrop || prismicImage ? heroWithBackdrop : heroWithoutBackdrop
        }`}
        style={{ backgroundImage: `url(${image || prismicImage})` }}
      >
        <div
          className={`${
            isPoppy ? `container-fluid px-0 position-relative` : `container-xxl`
          } ${heroContainer}`}
        >
          {/*<h1 className={heroText}>{title}</h1>*/}
          {isPoppy && (
            <div
              className={`w-100 d-flex flex-column align-items-center ${s.poppy}`}
            >
              {poppyLine1 !== "" ? (
                <h3 className={`mb-2`}>{poppyLine1}</h3>
              ) : (
                ""
              )}
              {poppyLine2 && (
                <div className={`w-100 bg-white`}>
                  <h2 className={`text-center py-1`}>{poppyLine2}</h2>
                </div>
              )}
              {poppyLine3 && <h3 className={`mt-2`}>{poppyLine3}</h3>}
            </div>
          )}
          <div
            className={`text-primary text-start d-md-block d-none  ${heroText} ${
              venueSubPage && `d-md-none`
            }`}
          >
            {venueSubPage ? <h1>{title}</h1> : title || prismicRichText}
          </div>
          {buttonLabel && buttonLink && (
            <>
              <div className={"pt-3 d-md-block d-none"}>
                <Button
                  onClick={() => setShow(true)}
                  variant="outline-light mt-4"
                >
                  {buttonLabel}
                </Button>
              </div>
            </>
          )}
          <div>
            <Modal
              size={"lg"}
              centered
              show={show}
              onHide={() => setShow(false)}
              className={`${heroModal} modal-z-index`}
              backdropClassName={`modal-backdrop`}
            >
              <Modal.Body className={"p-0"}>
                <div className={heroModal__body}>
                  <div className={heroModal__escape}>
                    <button
                      className={`btn-as-link`}
                      tabIndex="0"
                      onClick={() => setShow(false)}
                      onKeyDown={() => setShow(false)}
                    >
                      <img alt="Close" src={Escape} />
                    </button>
                  </div>
                  <iframe
                    src={buttonLink}
                    title="Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>

      {/*Poppy section*/}
      <div
        className={cx(`d-block d-lg-none text-center`, {
          [`d-none`]: !poppyLine1,
        })}
      >
        <h5 className={`my-2`}>{poppyLine1}</h5>
        <h2 className={`py-3 fst-italic bg-offwhite`}>{poppyLine2}</h2>
        <h5 className={`mt-2 w-75 mx-auto`}>{poppyLine3}</h5>
      </div>

      <div
        className={`container-xxl d-md-none d-block mt-4 ${s.mobileHeroText}`}
      >
        <div
          className={`text-primary w-75 ${heroText} ${
            venueSubPage && `d-md-none`
          }`}
        >
          {venueSubPage ? <h1>{title}</h1> : title || prismicRichText}
        </div>
        {buttonLabel && buttonLink && (
          <Button
            onClick={() => setShow(true)}
            className={`mt-4 btn-fixed`}
            variant="primary"
          >
            {buttonLabel}
          </Button>
        )}
      </div>
    </>
  );
};

export default Hero;
