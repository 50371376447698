import React from "react";
import { Button } from "react-bootstrap";
import ProgressDots from "../components/ProgressDots";
import cx from "classnames";
import * as s from "./index.module.scss";

const SubmitButton = ({
  label = "Submit",
  successLabel = "Submitted",
  onClick = () => null,
  disabled = false,
  progress = false,
  success = false,
  variant = "primary",
  className = "",
}) => {
  const isLightBg = variant === "light" || variant === "outline-primary";
  return (
    <Button
      variant={variant}
      className={`text-uppercase position-relative ${className}`}
      type="submit"
      onClick={onClick}
      disabled={disabled || success || progress}
    >
      {progress && (
        <div className="position-absolute top-50 start-50 translate-middle">
          <ProgressDots darkBg={!isLightBg} />
        </div>
      )}
      <span className={cx({ [s.hidden]: progress })}>
        {success ? successLabel : label}
      </span>
    </Button>
  );
};

export default SubmitButton;
