import countryNames from "react-phone-number-input/locale/en.json";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";

export const getOptionsPhoneNumberCountry = () => {
  const phoneCountryArray = [];
  getCountries().map((country) => {
    const a = getCountryCallingCode(country).padEnd(5, " ");

    const x = {
      label: `+${a}  ${countryNames[country]}`,
      value: country,
      test: "1",
    };
    phoneCountryArray.push(x);
  });
  return phoneCountryArray;
};

export const optionsPhoneNumberCountry = getOptionsPhoneNumberCountry();
