import React from "react";
import { Modal } from "react-bootstrap";
import * as s from "./HomeMobileModal.module.scss";
import Less from "../../images/design-components/Less_1_WHT.svg";
import cx from "classnames";

const HomeMobileModal = ({ activeTab, show, setShow }) => {
  return (
    <Modal
      centered
      show={show}
      onHide={setShow}
      className={"modal-z-index"}
      contentClassName={s.content}
      backdropClassName={s.backdrop}
      dialogClassName={s.dialogMargins}
    >
      <button
        onClick={() => setShow(false)}
        className={cx(`btn-link`, s.closeButton)}
      >
        <img alt="" src={Less} />
      </button>
      <div className={`bg-white py-4 px-3 container d-flex flex-column`}>
        <h5>{activeTab.label}</h5>
        {activeTab.productList.map((product, index) => (
          <p key={index}>{product}</p>
        ))}
      </div>
    </Modal>
  );
};

export default HomeMobileModal;
