import { format, parseISO, setHours, setMinutes } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { getQueryParams } from "../../utils";
import get from "lodash/get";

export const convertDateTimeToMeetingRoomTz = (date = null, tz = null) => {
  if (!date || !tz) return null;

  return zonedTimeToUtc(date, tz);
};

const getMeetingRoomReservationData = (
  user = {
    name: "N/A",
    email: "",
  },
  profile,
  mrData,
) => {
  const params = getQueryParams();

  const timezone = mrData?.location?.timezone || "America/Toronto";

  const start = params.get("start");
  const end = params.get("end");

  const from = parseISO(start);
  const to = parseISO(end);

  if (!start || !end) {
    return { startDateTime: null, endDateTime: null };
  }

  let startDateTime = start
    ? convertDateTimeToMeetingRoomTz(from, timezone).toISOString()
    : null;
  let endDateTime = end
    ? convertDateTimeToMeetingRoomTz(to, timezone).toISOString()
    : null;

  if (params.get("type") !== "hourly" && start && end) {
    startDateTime = convertDateTimeToMeetingRoomTz(
      setHours(setMinutes(from, 0), 9),
      timezone,
    ).toISOString();
    endDateTime = convertDateTimeToMeetingRoomTz(
      setHours(setMinutes(to, 0), 17),
      timezone,
    ).toISOString();
  }
  return {
    soId: process.env.GATSBY_SO_ID,
    spaceId: get(profile, "id", ""),
    user: {
      name: user.name,
      email: user.email,
    },
    startDateTime,
    endDateTime,
    resourceId: params.get("meeting_room_id"),
    resourceName: params.get("meeting_room_name"),
    locationId: params.get("rnd_location_id"),
    locationName: mrData?.location?.name || profile?.space_name || "N/A",
    timezone,
    fromDate: format(from, "yyyy-MM-dd"),
    toDate: format(to, "yyyy-MM-dd"),
    fromTime: format(from, "hh:mm a"),
    toTime: format(to, "hh:mm a"),
    source: "iqoffices.com",
    taxRate: get(mrData, "rateDetails[0].tax.taxRate", 0),
  };
};

export default getMeetingRoomReservationData;
