import { getOptionsPhoneNumberCountry } from "./PhoneInputs";

export const optionsPhoneNumberCountry = getOptionsPhoneNumberCountry();

export const optionsCompanySize = [
  { value: "1", label: "1" },
  { value: "1-20", label: "1-20" },
  { value: "20-100", label: "20-100" },
  { value: "100+", label: "100+" },
];

export const optionsNumberOfGuests = [
  { value: "1", label: "1" },
  { value: "1-20", label: "1-20" },
  { value: "20-100", label: "20-100" },
  { value: "100+", label: "100+" },
];

export const optionsNumberOfPeople = [
  { value: "1", label: "1" },
  { value: "1-20", label: "1-20" },
  { value: "20-100", label: "20-100" },
  { value: "100+", label: "100+" },
];

export const optionsCity = [
  { value: "Vancouver", label: "Vancouver" },
  { value: "Calgary", label: "Calgary" },
  { value: "Edmonton", label: "Edmonton" },
  { value: "Winnipeg", label: "Winnipeg" },
  { value: "Toronto", label: "Toronto" },
  { value: "Montreal", label: "Montreal" },
  { value: "Ottawa", label: "Ottawa" },
  { value: "Other", label: "Other" },
];

export const optionsLocationInterest = [
  { value: "Toronto | 150 King St. W", label: "Toronto | 150 King St. W" },
  { value: "Toronto | 545 King St. W", label: "Toronto | 545 King St. W" },
  { value: "Toronto | 140 Yonge St.", label: "Toronto | 140 Yonge St." },
  {
    value: "Toronto | 250 University Ave",
    label: "Toronto | 250 University Ave",
  },
  {
    value: "Vancouver | 1055 West Georgia",
    label: "Vancouver | 1055 West Georgia",
  },
  { value: "Ottawa | 222 Queen St.", label: "Ottawa | 222 Queen St." },
  {
    value: "Montreal | 1155 Rue Metcalfe",
    label: "Montreal | 1155 Rue Metcalfe",
  },
];

export const optionsProductInterest = {
  en: [
    { value: "Private Offices", label: "Private Offices" },
    { value: "HQ by iQ", label: "HQ by iQ" },
    { value: "Dedicated Desks", label: "Dedicated Desks" },
    { value: "Meeting Rooms", label: "Meeting Rooms" },
    { value: "Hybrid Desks", label: "Hybrid Desks" },
    { value: "Day Passes", label: "Day Passes" },
    { value: "Virtual Offices", label: "Virtual Offices" },
    { value: "Event Venues", label: "Event Venues" },
  ],
  fr: [
    { value: "Bureaux privés", label: "Bureaux privés" },
    { value: "Bureau central par iQ", label: "Bureau central par iQ" },
    { value: "Espace collaboratif", label: "Espace collaboratif" },
    { value: "Salles de réunion", label: "Salles de réunion" },
    { value: "Bureaux hybrids", label: "Bureaux hybrids" },
    { value: "Forfait journalier", label: "Forfait journalier" },
    { value: "Bureaux virtuels", label: "Bureaux virtuels" },
    { value: "Espaces événementiels", label: "Espaces événementiels" },
  ],
};

export const optionsFoundOutIQ = {
  en: [
    { value: "Recommended by a friend", label: "Recommended by a friend" },
    {
      value: "Search engine (Google, Yahoo, etc.)",
      label: "Search engine (Google, Yahoo, etc.)",
    },
    { value: "Online Ad", label: "Online Ad" },
    { value: "Social Media", label: "Social Media" },
    { value: "Blog or publication", label: "Blog or publication" },
    { value: "Other", label: "Other" },
  ],
  fr: [
    { value: "Recommandé par un ami", label: "Recommandé par un ami" },
    {
      value: "Moteur de recherche (Google, Yahoo, etc.)",
      label: "Moteur de recherche (Google, Yahoo, etc.)",
    },
    { value: "Publicité en ligne", label: "Publicité en ligne" },
    { value: "Médias sociaux", label: "Médias sociaux" },
    { value: "Blog ou publication", label: "Blog ou publication" },
    { value: "Autre", label: "Autre" },
  ],
};

export const optionsPaymentType = {
  en: [
    { value: "Cash", label: "Cash" },
    { value: "Credit - Monthly Invoice", label: "Credit - Monthly Invoice" },
    { value: "Meeting Room Coins", label: "Meeting Room Coins" },
  ],
  fr: [
    { value: "Cash", label: "En espèces" },
    { value: "Credit - Monthly Invoice", label: "Crédit - Facture mensuelle" },
    { value: "Meeting Room Coins", label: "Jetons de salle de réunion" },
  ],
};
