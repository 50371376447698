/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";
import { CardElement } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#1a1a1a",
      fontFamily: "'Arial', sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#777",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const StripeCard = ({
  setValidCard = () => null,
  setCardError = () => null,
  setStatusMessage = () => null,
}) => {
  const handleChange = (e) => {
    setStatusMessage("");
    if (e.error && typeof e.error === "object") {
      setCardError(e.error.message);
    } else {
      setCardError("");
    }
    setValidCard(e.complete);
  };
  return (
    <>
      <CardElement options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
    </>
  );
};

export default StripeCard;
