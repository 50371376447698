import React from "react";

const nl2br = (str = "") => {
  if (!str) return "";
  return str.split("\n").map((line, idx) => {
    return (
      <React.Fragment key={idx}>
        {idx !== 0 ? <br /> : ""}
        {line}
      </React.Fragment>
    );
  });
};

export default nl2br;
