import React from "react";
import { bottomMargin, productTitle } from "./ProductTitle.module.scss";
import get from "lodash/get";

const ProductTitle = ({ product }) => {
  const hasDescription = !!get(product, "description", false);
  return (
    <div
      className={`container-xxl ${productTitle} ${
        !hasDescription ? bottomMargin : ""
      }`}
    >
      <div className={`row justify-content-center`}>
        <div className={`col-lg-10`}>
          <h1>{product.title}</h1>

          {hasDescription && <p>{product.description}</p>}
        </div>
      </div>
    </div>
  );
};

export default ProductTitle;
