import React from "react";
import { getPricePerDesk, getTeamSize } from "../util";
import i18n from "../../../utils/i18n";
import * as s from "./index.module.scss";
import get from "lodash/get";
import { getQueryParams, isDesk } from "../../../utils";
import formatPrice from "../../../utils/formarPrice";

const SummaryList = ({ selectedOptions = [], profile }) => {
  if (typeof window === "undefined") return null;

  const params = getQueryParams();

  const type = params.get("space_type") || "Private Office";

  const teamSize = getTeamSize();
  const officeIdsString = params.get("selected_blocks") || "";
  const officeIds = officeIdsString.split(",");

  const term = isDesk(type)
    ? get(profile, "dedicated_desks_term_commitment", "")
    : get(profile, "office_term_commitment", "");

  return (
    <div>
      <div className={`space-profile-intro api`}>
        <div className="row">
          <div className="col-lg-12">
            <div className={`workplace-one-space-summary text-left`}>
              <h3 className={"mt-5 text-left mb-0"}>
                {i18n.t("locationPage.SearchResults")}
              </h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <ul className={s.list}>
              {type && (
                <li>
                  <span>{i18n.t("profilePage.Type")}</span>
                  <span>{type}</span>
                </li>
              )}
              {isDesk(type) && teamSize && (
                <li>
                  <span>{i18n.t("profilePage.SeatCount")}</span>
                  <span>{teamSize}</span>
                </li>
              )}
              {!isDesk(type) && teamSize && (
                <li>
                  <span>{i18n.t("profilePage.SeatCount")}</span>
                  <span>{i18n.t("profilePage.UpTo") + " " + teamSize}</span>
                </li>
              )}
              {term && (
                <li>
                  <span>{i18n.t("profilePage.MinTerm")}</span>
                  <span>{term}</span>
                </li>
              )}
              {isDesk(type) &&
                getPricePerDesk(selectedOptions, teamSize) !== "N/A" && (
                  <li>
                    <span>{i18n.t("profilePage.PricePerDesk")}</span>
                    <span>
                      {getPricePerDesk(selectedOptions, teamSize)}/
                      {i18n.t("month")}
                    </span>
                  </li>
                )}
              <li>
                <span>
                  {isDesk(type) ? `${i18n.t("profilePage.Total")} ` : ""}{" "}
                  {i18n.t("profilePage.Price")}
                </span>
                <span>
                  $
                  {params.get("price_min")
                    ? formatPrice(params.get("price_min")) +
                      "+/" +
                      i18n.t("month")
                    : i18n.t("profilePage.ContactForPricing")}
                </span>
              </li>
              {!isDesk(type) && officeIds.length > 0 && (
                <li>
                  <span>{i18n.t("profilePage.NoOptions")}</span>
                  <span>{officeIds.length}</span>
                </li>
              )}

              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryList;
