const SM = 640;
const MD = 1200;
const LG = 1920;

const resizeImage = (image, sm, md, lg, xlg, fitWidth = true) => {
  if (typeof window === "undefined") return image;

  if (window.innerWidth <= SM) {
    return optimize(image, sm, fitWidth);
  } else if (window.innerWidth <= MD) {
    return optimize(image, md || sm, fitWidth);
  } else if (window.innerWidth <= LG) {
    return optimize(image, lg || md || sm, fitWidth);
  } else {
    return optimize(image, xlg || lg || md || sm, fitWidth);
  }
};

export default resizeImage;

const BASE_URL = "https://d331na0x6n3vy8.cloudfront.net/";
const optimize = (url, width, fitWidth = true) => {
  if (!url || !width) {
    return;
  }
  const URL_CONSTANT = "https://images.worksimply.ca/";
  const key = url.replace(URL_CONSTANT, "").trim();

  const payload = {
    bucket: "images.worksimply.ca",
    key,
    edits: {
      resize: {
        fit: "cover",
      },
    },
  };

  if (fitWidth) {
    payload.edits.resize.width = width;
  } else {
    payload.edits.resize.height = width;
  }

  return BASE_URL + btoa(JSON.stringify(payload));
};
