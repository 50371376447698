import React from "react";
import get from "lodash/get";
import Title from "../../../../components/FooterPages/Title";
import SubTitle from "../../../../components/FooterPages/SubTitle";
import * as s from "../../../../components/FooterPages/SubTitle.module.scss";
import * as titleStyles from "../../../../components/FooterPages/Title.module.scss";

const TitleSection = ({ slice }) => {
  const title = get(slice, "primary.title", []);
  const subtitle = get(slice, "primary.subtitle", []);
  return (
    <>
      <div className={`container-xxl ${titleStyles.titleSection}`}>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {title && title.length > 0 && <Title title={title} />}
          </div>
        </div>
      </div>
      {subtitle && subtitle.length > 0 && (
        <div
          className={`container-fluid bg-offwhite px-0 ${s.subTitleSection}`}
        >
          <div className={`container-xxl`}>
            <div className={`row  justify-content-center`}>
              <div className={`col-lg-10`}>
                <SubTitle subTitle={subtitle} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TitleSection;
