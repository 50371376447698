import worksimplyApi from "../../../services/worksimplyApi";
import { format, isSameDay } from "date-fns";

const sendNotificationEmail = async (
  formData,
  profile,
  userName = "",
  withCcError = false,
) => {
  const params = new URLSearchParams(window.location.search);

  const from = params.get("start");
  const to = params.get("end");

  const start = new Date(from);
  const end = new Date(to);

  let date = "";

  if (isSameDay(start, end)) {
    date = `${format(start, "MMM d, h:mm a")} - ${format(end, "h:mm a")}`;
  } else {
    date = `${format(start, "MMM d")} - ${format(end, "MMM d")}`;
  }

  const emailData = [
    {
      label: "User's Name",
      value: userName || formData?.fullName || "N/A",
    },
    {
      label: "User's Email",
      value: formData?.email || "N/A",
    },
    {
      label: "Meeting Room",
      value: params.get("meeting_room_name") || "N/A",
    },
    {
      label: "Location",
      value: profile?.space_name || "N/A",
    },
    {
      label: "Team size",
      value: params.get("team_size") || "N/A",
    },
    {
      label: "Date",
      value: date,
    },
  ];

  const recipients = profile?.on_demand_emails?.split(",") || [];

  if (withCcError && process.env.GATSBY_STAGE === "production") {
    console.log("adding more");
    recipients.push("hello@worksimply.com");
    recipients.push("mahesh@ensodigital.ca");
    recipients.push("marketing@iqoffices.com");
    recipients.push("cvandeneynde@iqoffices.com");
  }
  const formattedData = {
    to: recipients,
    userEmail: formData.email,
    sp_slug: "iq",
    replyTo: formData.email,
    emailTitle: "New meeting room booking",
    emailData: [...emailData],
  };

  if (withCcError) {
    formattedData.emailBodyDescription =
      "<span style='color: red'>Please note the following prospect was unable to complete their registration due to invalid payment card.</span> " +
      `<br><br>` +
      "They’ve been directed to contact sales@iqoffices.com for support, OR to input their" +
      " correct CC on the Billing Page of the Member portal." +
      `<br><br>` +
      "Please monitor the booking to ensure payment gets confirmed. If payment is not completed within 24 hours, please cancel the booking and notify the member.";
  }
  await worksimplyApi("handle-form", formattedData);

  return true;
};

export default sendNotificationEmail;
