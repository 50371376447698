import React, { useState } from "react";
import { Formik } from "formik";
import FormikInput from "./formik-input";
import i18n from "../../utils/i18n";
import SubmitButton from "../../SubmitButton";
import { CustomSelectSingle } from "../CustomSelect/CustomSelect";
import { optionsPhoneNumberCountry } from "../FormContact/options";
import { phoneNumber as phoneNumberStyles } from "../FormContact/FormContact.module.scss";
import * as Yup from "yup";
import { phoneRegExp } from "../../utils/forms/submitContactForm";
import submitFormToHubSpot from "../../utils/submitFormToHubSpot";

const CustomHubSpotForm = ({ slice }) => {
  const formId = slice?.primary?.hubspot_form_id;

  const [status, setStatus] = useState("");

  const [validationSchema] = useState(() => {
    const validationObject = {
      firstname: Yup.string().required(i18n.t("Required")),
      lastname: Yup.string().required(i18n.t("Required")),
      email: Yup.string()
        .email(i18n.t("InvalidEmailAddress"))
        .required(i18n.t("Required")),
      phone: Yup.string().matches(phoneRegExp, i18n.t("PhoneNumberIsNotValid")),
    };

    for (let i = 0; i < slice?.items?.length; i++) {
      const { is_required_field, hubspot_field_id, type } = slice?.items?.[i];

      if (is_required_field) {
        switch (type) {
          case "select":
            validationObject[hubspot_field_id] = Yup.object()
              .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
              .required(i18n.t("Required"));
            break;
          case "number":
            validationObject[hubspot_field_id] = Yup.number().required(
              i18n.t("Required"),
            );
            break;
          default:
            validationObject[hubspot_field_id] = Yup.string().required(
              i18n.t("Required"),
            );
            break;
        }
      }
    }

    return Yup.object(validationObject);
  });

  if (!validationSchema) return null;

  return (
    <div className={`container-xxl`}>
      <div className={"row"}>
        <div className="col-sm-10 col-md-9 col-lg-10 offset-lg-1 col-xl-9 col-xxl-8">
          <Formik
            initialValues={{
              phoneNumberCountry: "CA",
              firstname: "",
              lastname: "",
              phone: "",
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, ...others }) => {
              const fields = [
                "firstname",
                "lastname",
                "email",
                "phone",
                "message",
                ...slice?.items?.map((f) => f.hubspot_field_id),
              ];

              const data = [];

              for (let i = 0; i < fields?.length; i++) {
                const name = fields[i];
                const value = values?.[name]?.value || values?.[name] || "";
                data.push({ name, value });
              }

              data.push({
                name: "most_recent_source",
                value: slice.primary.most_recent_source || "",
              });

              submitFormToHubSpot(formId, data)
                .then((res) => {
                  setSubmitting(false);
                  setStatus("success");
                })
                .catch((e) => {
                  setStatus("failed");
                });
            }}
          >
            {({
              values,
              handleSubmit,
              touched,
              isSubmitting,
              getFieldProps,
              setFieldValue,
              errors,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <FormikInput
                      name={"firstname"}
                      label={i18n.t("contactPage.FirstName")}
                    />
                    <FormikInput
                      name={"lastname"}
                      label={i18n.t("contactPage.LastName")}
                    />

                    <div className="col-lg-6 my-2">
                      <div className="position-relative">
                        <div className={phoneNumberStyles}>
                          <span></span>
                          <CustomSelectSingle
                            // isMenuOpen
                            phoneNumber
                            options={optionsPhoneNumberCountry}
                            value={values?.phoneNumberCountry}
                            onChange={(value) =>
                              setFieldValue("phoneNumberCountry", value.value)
                            }
                          />
                        </div>

                        <input
                          id="phone"
                          name="phone"
                          type="phone"
                          className="form-control"
                          placeholder={i18n.t("contactPage.PhoneNumber")}
                          {...getFieldProps("phone")}
                        />
                      </div>
                    </div>

                    <FormikInput
                      name={"email"}
                      type={"email"}
                      label={i18n.t("contactPage.Email")}
                    />

                    {slice?.items?.map(
                      ({
                        hubspot_field_id,
                        input_label,
                        options,
                        type,
                        is_required_field,
                      }) => {
                        if (type === "select") {
                          const formattedOptions = options
                            ?.split("\n")
                            ?.filter((line) => !!line && line.includes("|"))
                            ?.map((o) => {
                              const optionArray = o?.split("|");
                              return {
                                value: optionArray?.[0] || "",
                                label:
                                  optionArray?.[1] || optionArray?.[0] || "",
                              };
                            });
                          return (
                            <div
                              className="col-lg-6 my-2 position-relative"
                              key={hubspot_field_id}
                            >
                              <div>
                                <CustomSelectSingle
                                  options={formattedOptions}
                                  value={values.foundOutIQ}
                                  hasError={
                                    touched?.[hubspot_field_id] &&
                                    !!errors?.[hubspot_field_id]
                                  }
                                  onChange={async (value) => {
                                    await setFieldValue(
                                      hubspot_field_id,
                                      value,
                                    );
                                  }}
                                  placeholder={`${input_label} ${
                                    is_required_field ? "*" : ""
                                  }`}
                                />
                              </div>
                            </div>
                          );
                        }
                        return (
                          <FormikInput
                            key={hubspot_field_id}
                            name={hubspot_field_id}
                            type={type}
                            label={`${input_label} ${
                              is_required_field ? "*" : ""
                            }`}
                          />
                        );
                      },
                    )}

                    <div className="col-12 my-2">
                      <textarea
                        id="message"
                        name="message"
                        className="form-control"
                        rows={3}
                        style={{ resize: "vertical" }}
                        placeholder={
                          slice?.primary?.message_field_label ||
                          i18n.t("AdditionalInformationOrQuestions")
                        }
                        {...getFieldProps("message")}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div
                      className={`d-flex flex-md-row flex-column align-items-md-center mt-5`}
                    >
                      <SubmitButton
                        label={i18n.t("profilePage.GetInTouch")}
                        successLabel={"Submitted"}
                        progress={isSubmitting}
                        success={!!status}
                        type={"submit"}
                      />
                      {!!status && (
                        <p
                          className={`fst-italic ms-md-5 mb-0 mt-3 mt-md-0 ${
                            status === "success"
                              ? "text-success"
                              : "text-danger"
                          }`}
                        >
                          {status === "success"
                            ? slice?.primary?.success_message ||
                              i18n.t("ThankYouWeWillBeInTouchSoon")
                            : ""}

                          {status === "failed"
                            ? slice?.primary?.failure_message ||
                              i18n.t("ThankYouWeWillBeInTouchSoon")
                            : ""}
                        </p>
                      )}
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CustomHubSpotForm;
