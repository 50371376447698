import React, { useState } from "react";
import SVG from "react-inlinesvg";
import PropTypes from "prop-types";
// import priceRange from "../../../utils/priceRange";
import floorDetails from "./floorDetails";
import "./direction-styles.scss";
import resizeImage from "../../services/resizeImage";
import NorthIcon from ".././../images/profile/north.svg";
import get from "lodash/get";
import ProgressDots from "../ProgressDots";

import "./styles.scss";

const Floor = ({
  spaces = [],
  optionStart = 0,
  selectedBlockClassName = "",
  forPdf = false,
}) => {
  const floorId = get(spaces, "[0].floor_id", "");
  const svg = get(spaces, "[0].map_svg_url", "");
  const bg = get(spaces, "[0].map_background_url", "");

  const directionData =
    floorId && floorDetails[floorId] ? floorDetails[floorId] : null;

  const [loaded, setLoaded] = useState(false);
  const floorBG = resizeImage(bg, 1200, 1200, 1200, 1200);
  // const floorBG = typeof window !== "undefined" ? customImage : bg;

  return (
    <div className={"row space-layout-wrapper"}>
      <div className={"col-12"}>
        {loaded || forPdf ? (
          ""
        ) : (
          <p className={"text-center py-5"}>
            <ProgressDots active />
          </p>
        )}
        <div
          className={`iq-floor-plan-wrapper ${
            !directionData ? "iq-default-floor-plans" : ""
          } floor-${floorId} ${
            directionData &&
            directionData.streets
              .map(({ position }) => `iq-floor-street-position-${position}`)
              .join(" ")
          } ${loaded || forPdf ? "" : "iq-floor-plan-hidden"}`}
        >
          <div className={`space-layout ${forPdf ? "" : "mr-0"}`}>
            {directionData && (
              <>
                <img
                  src={NorthIcon}
                  alt="North"
                  className={`floor-${floorId} iq-floor-plan-north`}
                />
                {directionData.streets &&
                  directionData.streets.length > 0 &&
                  directionData.streets.map(({ name, position }) => {
                    return (
                      <span
                        key={`${floorId}-${name}`}
                        className={`iq-floor-street-position iq-floor-street-position-${position}`}
                      >
                        {name}
                      </span>
                    );
                  })}
              </>
            )}
            <img src={floorBG} alt="Floor plan" />
            <SVG
              src={`${
                process.env.GATSBY_API_URL
              }/upload/getSvg?url=${encodeURIComponent(svg)}`}
              onLoad={() => {
                setLoaded(true);
                const indexedSvgs = {};
                const allSvgElements =
                  document.querySelectorAll("svg path, svg rect");

                for (let j = 0; j < spaces.length; j++) {
                  const selectedSpace = spaces[j];

                  for (let index = 0; index < allSvgElements.length; index++) {
                    const element = allSvgElements[index];
                    const svgId = getIdentifierFromSvg(element);
                    indexedSvgs[svgId] = element;
                    element.setAttribute("fill", "transparent");
                    element.setAttribute("stroke", "transparent");

                    if (
                      selectedSpace &&
                      selectedSpace.svg_identifier === svgId
                    ) {
                      element.setAttribute("fill-opacity", 1);
                      if (forPdf) {
                        element.setAttribute("fill", "#478261");
                        element.setAttribute(
                          "class",
                          `active color-${
                            optionStart + j
                          } ${selectedBlockClassName}`,
                        );
                      } else {
                        element.setAttribute(
                          "class",
                          `active animate color-${
                            optionStart + j
                          } ${selectedBlockClassName}`,
                        );
                      }
                    }
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Floor.propTypes = {
  svg: PropTypes.string,
  bg: PropTypes.string,
  spaces: PropTypes.array,
  bundle: PropTypes.bool,
  handleModal: PropTypes.func,
  optionStart: PropTypes.number,
};

export default Floor;

const getIdentifierFromSvg = (element) => {
  let whitelistedAttributes = [];
  let identifier = [];
  switch (element.tagName) {
    case "rect":
      whitelistedAttributes = ["width", "x", "y", "height"];
      break;
    case "path":
      whitelistedAttributes = ["d"];
      break;
    default:
      break;
  }

  for (let i = 0; i < element.attributes.length; i++) {
    for (let j = 0; j < whitelistedAttributes.length; j++) {
      if (element.attributes[i].nodeName === whitelistedAttributes[j]) {
        identifier.push(
          whitelistedAttributes[j] + ":" + element.attributes[i].nodeValue,
        );
      }
    }
  }

  return identifier.join("|");
};
