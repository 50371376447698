import React from "react";
import * as s from "./ProductHero.module.scss";
import CarouselComponent from "../Carousel/Carousel";
import { CarouselMulti } from "../CarouselMulti/CarouselMulti";
import cx from "classnames";

const ProductHero = ({ images = [], venueSubPage, title = "" }) => {
  if (venueSubPage) {
    return (
      <>
        <div className={`${s.subpageHero}`}>
          <div className={cx(`container-xxl`)}>
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <CarouselMulti primary images={images} />
              </div>
            </div>
          </div>
          <div className={`container-xxl mt-5`}>
            <div className={`row`}>
              <div className={`col-lg-10 offset-lg-1`}>
                <h2>{title}</h2>
              </div>
            </div>
          </div>
        </div>
        {/*<div className={cx(`d-block d-lg-none`)}>*/}
        {/*  <Introduction*/}
        {/*    image={Placeholder}*/}
        {/*    title={title}*/}
        {/*    venueSubPage={venueSubPage}*/}
        {/*  />*/}
        {/*  <div className={`container-xxl d-none d-md-block mt-5`}>*/}
        {/*    <h2>{title}</h2>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </>
    );
  } else {
    return (
      <div className={`container-xxl ${s.productHeroImageContainerMargins}`}>
        <div className={`row`}>
          <div className={`offset-lg-1 col-lg-10 mb-4`}>
            <CarouselComponent images={images} productHeroSection />
          </div>
        </div>
      </div>
    );
  }
};

export default ProductHero;
