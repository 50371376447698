import React, { useEffect, useState } from "react";
import get from "lodash/get";
import ProductFeaturedVenue from "../../../components/ProductFeaturedVenue";
import * as s from "../../../components/ProductFeaturedVenue/ProductFeatureVenue.module.scss";
import { PrismicRichText } from "@prismicio/react";

const EventVenueList = ({ slice, context }) => {
  const eventVenueList = get(context, "eventVenueList", []);

  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    const selectedVenues = get(slice, "items", []);

    const pickedItems = [];

    for (let i = 0; i < selectedVenues.length; i++) {
      const ev = get(selectedVenues, `[${i}].event_venue`, {});
      const data = eventVenueList.find((item) => {
        return (
          get(item, "uid", "") === ev.uid && ev.lang === get(item, "lang", "")
        );
      });
      if (data) {
        pickedItems.push(data);
      }
    }
    setSelectedItems(pickedItems);
  }, [slice, eventVenueList]);

  return (
    <div className={`${s.pfvSection}`}>
      <div className={`container-xxl`}>
        <div className={`row justify-content-center`}>
          <div className={`col-md-10`}>
            <PrismicRichText field={get(slice, "primary.title", [])} />
          </div>
        </div>
      </div>
      {selectedItems.map((item, idx) => {
        const productFeaturesSection = get(item, "dataRaw.body", []).find(
          (item) => item.slice_type === "product_features",
        );
        const images = get(item, "dataRaw.images", []).map((img) => {
          return {
            image: {
              url: get(img, "image.url", ""),
            },
          };
        });
        const infoItems = get(productFeaturesSection, "items", [])
          .map((item) => {
            return {
              infoTitle: item.title,
              infoDescList: get(item, "text", "").split("\n"),
            };
          })
          .slice(0, 4);
        return (
          <ProductFeaturedVenue
            key={idx}
            images={images}
            infoItems={infoItems}
            btnLink={get(item, "url", "")}
            title={get(item, "dataRaw.hero_section_title[0].text", "")}
          />
        );
      })}
    </div>
  );
};

export default EventVenueList;
