import React from "react";
import { Accordion, Button } from "react-bootstrap";
import {
  faq,
  faq__accordion,
  faq__accordion__locationFaq,
  hideBg,
  margins,
  sectionTitle,
} from "./Faq.module.scss";
import { navigate } from "gatsby";
import get from "lodash/get";
import { PrismicRichText } from "@prismicio/react";
import resolvePrismicLink from "../../utils/resolvePrismicLink";
import WhiteArrow from "../../images/design-components/Down_WHT.svg";
import BlueArrow from "../../images/design-components/Down.svg";
import cx from "classnames";

import "./custom.scss";

const Faq = ({ locationFaq, faqItems = [], slice }) => {
  const items = get(slice, "items", faqItems);

  const buttonLabel = get(slice, "primary.button_label", null);
  const title = get(slice, "primary.title", null);
  const buttonLinkObject = get(slice, "primary.button_link", null);

  const { link, target } = resolvePrismicLink(buttonLinkObject);

  const hasButton = link && target;

  return (
    <>
      <div
        className={`container-fluid overflow-hidden p-0 ${faq} ${
          hasButton ? "" : margins
        }`}
      >
        {!hasButton && title ? (
          <div className="container-xxl">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <h2 className={sectionTitle}>{title}</h2>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <Accordion
          bsPrefix={faq__accordion__locationFaq}
          className={hasButton ? "" : hideBg}
        >
          <Accordion.Item>
            {hasButton && title && (
              <Accordion.Header
                as="div"
                className={`custom-faq-page-container-header`}
              >
                <div className="container-xxl">
                  <div className="row justify-content-center">
                    <div className="col-md-10">
                      <div
                        className={cx(
                          `d-flex align-items-center position-relative`,
                          `negative-margin-bottom`,
                        )}
                      >
                        <img src={WhiteArrow} alt="" />
                        {title}
                        {link && (
                          <Button
                            to={link}
                            onClick={async (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              await navigate(link);
                            }}
                            className="btn btn-flex btn-outline-light ms-auto d-none d-md-inline-block"
                            target={target}
                          >
                            <span>{buttonLabel}</span>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
            )}
            <Accordion.Body className="pe-0 ps-0">
              <Accordion bsPrefix={faq__accordion}>
                {items.map((item, index) => (
                  <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header
                      as="div"
                      className={"custom-faq-container-sub-question"}
                    >
                      <div className="container-xxl">
                        <div className="row justify-content-center">
                          <div className="col-md-10">
                            <div className="position-relative">
                              <img src={BlueArrow} alt="" />
                              <span>{get(item, "question", "")}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="container-xxl">
                        <div className="row justify-content-center">
                          <div className="col-md-10">
                            <PrismicRichText field={get(item, "answer", [])} />
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/*)}*/}
      </div>
    </>
  );
};

export default Faq;
