import React, { useEffect, useState } from "react";
import SearchVirtualOffices from "../../pages/locations/_VirtualOffices";
import VirtualOfficeAddOns from "./AddOns";
import VirtualOfficeCheckout from "./Checkout";
import iqApi from "../../services/iqApi";
import get from "lodash/get";
import sum from "lodash/sum";
import { hstConfig } from "../BookingComponent/BookingComponentContent";
import { getQueryParams, isBrowser } from "../../utils";

const VirtualOffices = ({ cmsLocations = [] }) => {
  const [step, setStep] = useState(0);
  const [office, setOffice] = useState({});

  const [addOns, setAddOns] = useState([]);
  const [plan, setPlan] = useState([]);

  const [selectedAddOns, setSelectedAddons] = useState([]);

  const params = getQueryParams();

  useEffect(() => {
    if (!isBrowser) return;
    window.scroll(0, 0);
  }, [step]);

  useEffect(() => {
    iqApi("get-plans").then((res) => {
      const type = get(res, "type", "error");
      if (type === "success") {
        setPlan(get(res, "plan", {}));
        const addOns = get(res, "plan.addOns", []);
        const formattedAddons = addOns.map((addOn) => {
          const titleArray = get(addOn, "name", "").split(":");
          const isAdditionLocationAddress =
            get(titleArray, "[0]", "") === "ALA";
          const title = isAdditionLocationAddress
            ? get(titleArray, "[1]", "")
            : get(titleArray, "[0]", "");
          const description = isAdditionLocationAddress
            ? get(titleArray, "[2]", "")
            : get(titleArray, "[1]", "");
          return {
            ...addOn,
            title,
            description,
            isAdditionLocationAddress,
          };
        });
        setAddOns(formattedAddons);
      }
    });
  }, []);

  const taxRate = get(hstConfig, `${params.get("location") || "Toronto"}`, 0);

  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(0);

  useEffect(() => {
    const t = sum(selectedAddOns.map((a) => a.price)) + get(plan, "price", 0);
    setSubtotal(t);
    const tx = (t * taxRate) / 100;
    setTax(tx);
    setTotal(t + tx);
  }, [selectedAddOns, plan, taxRate]);

  const back = () => setStep(step - 1);

  if (step === 0) {
    return (
      <SearchVirtualOffices
        next={(value) => {
          if (!value) return;
          setOffice(value);
          setStep(1);
        }}
      />
    );
  }

  if (step === 1) {
    return (
      <VirtualOfficeAddOns
        office={office}
        setSelectedAddons={setSelectedAddons}
        selectedAddOns={selectedAddOns}
        addOns={addOns}
        plan={plan}
        back={() => {
          back();
          setOffice({});
        }}
        next={() => {
          setStep(2);
        }}
        billing={{ total, taxRate, tax, subtotal }}
      />
    );
  }
  if (step === 2) {
    return (
      <VirtualOfficeCheckout
        office={office}
        plan={plan}
        addOns={selectedAddOns}
        back={back}
        billing={{ total, taxRate, tax, subtotal }}
        cmsLocations={cmsLocations}
      />
    );
  }
  return <div></div>;
};

export default VirtualOffices;
