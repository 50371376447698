import get from "lodash/get";
import { getQueryParams } from "../index";
import getFinalPrice from "./getFinalPrice";

const pStyles =
  'color: #00255B !important; font-family: "Avenir Next", sans-serif !important;';

const getEmailListForBookTour = (
  profile,
  cmsProfile,
  user,
  selectedOption,
  tourDate,
  time,
) => {
  const params = getQueryParams();

  const finalPrice = getFinalPrice(selectedOption);

  return `
  <ul>
      <li style='${pStyles}'>Submitted from: <a href="${
        window.location.href
      }">${get(cmsProfile, "location_title[0].text", "")}</a></li>
      <li style='${pStyles}'>Full Name: ${user.fullName}</li>
      <li style='${pStyles}'>Email: ${user.email}</li>
      <li style='${pStyles}'>Phone: ${user.phoneNumber}</li>
      <li style='${pStyles}'>Company: ${user.companyName}</li>
      <li style='${pStyles}'>Industry: ${user.industry}</li>
      <li style='${pStyles}'>Interested in: ${
        get(user, "productInterest.label", null) ||
        params.get("space_type") ||
        "Private office"
      }</li>
      <li style='${pStyles}'>Team size: ${
        get(user, "customTeamSize.label", null) ||
        params.get("team_size") ||
        "N/A"
      }</li>
      <li style='${pStyles}'>Tour date : ${tourDate}</li>
      <li style='${pStyles}'>Tour time : ${time}</li>
      <li style='${pStyles}'>Price: ${finalPrice}</li>
      <li style='${pStyles}'>Floor name: ${
        get(selectedOption, "floor_name", "N/A") || "N/A"
      }</li>
      <li style='${pStyles}'>Office ID: ${
        get(selectedOption, "office_label", "N/A") || "N/A"
      }</li>
      </ul >`;
};
export default getEmailListForBookTour;
