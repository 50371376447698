import worksimplyApi from "../../../services/worksimplyApi";
import get from "lodash/get";
import { getQueryParams } from "../../../utils";
import getSearchTimeWithBuffer from "../../../utils/getSearchTimeWithBuffer";

const checkMeetingRoomAvailability = async (mrData = {}) => {
  const params = getQueryParams();

  const timezone = mrData?.location?.timezone || "America/Toronto";

  const start = params.get("start");
  const end = params.get("end");

  const reqData = {
    name: mrData?.name || params.get("meeting_room_name") || null,
    locationId: params.get("rnd_location_id") || null,
    teamSize: params.get("team_size") || 1,
    from: getSearchTimeWithBuffer(start, timezone, -15),
    to: getSearchTimeWithBuffer(end, timezone, 15),
    soId: process.env.GATSBY_SO_ID,
  };

  const data = await worksimplyApi("meeting-rooms/search", reqData);

  const list = get(data, "meetingRooms", []);

  const isAvailable = list.find((item) => {
    const meetingRooms = get(item, "meeting_rooms", []);
    if (!meetingRooms || meetingRooms.length === 0) return false;
    return meetingRooms.find(
      ({ meetingRoomId = "" }) =>
        meetingRoomId === params.get("meeting_room_id"),
    );
  });
  return !!isAvailable;
};

export default checkMeetingRoomAvailability;
