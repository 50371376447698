import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import mapStyles from "./mapStyles";
import MapMarker from "./map-marker.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { markerLogo, markerPrice } from "./marker.module.scss";
import { isBrowser } from "../../utils";

const K_CIRCLE_SIZE = 200;
const K_STICK_SIZE = 220;

function mapOptionsCreator(map) {
  return {
    zoomControlOptions: {
      position: map.ControlPosition.LEFT_TOP,
    },
    styles: mapStyles,
  };
}

function GoogleMap({ children = null, center, zoom = 15 }) {
  const distanceToMouse = (markerPos, mousePos, markerProps) => {
    const { x } = markerPos;
    const y = markerPos.y - K_STICK_SIZE - K_CIRCLE_SIZE / 2;

    const distanceKoef = markerProps.text !== "A" ? 1.5 : 1;

    return (
      distanceKoef *
      Math.sqrt(
        (x - mousePos.x) * (x - mousePos.x) +
          (y - mousePos.y) * (y - mousePos.y),
      )
    );
  };

  const isMobile = isBrowser && window.innerWidth <= 768;

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
      hoverDistance={30}
      distanceToMouse={distanceToMouse}
      options={mapOptionsCreator}
      defaultCenter={{ lat: 43.6508797, lng: -79.3834781 }}
      defaultZoom={13}
      center={center}
      zoom={isMobile ? zoom - 2 : zoom}
    >
      {children}
    </GoogleMapReact>
  );
}

function Marker({ name = "", price = "", show = false }) {
  const [tooltipShow, setTooltipShow] = useState(show);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {name}
    </Tooltip>
  );

  if (price) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={renderTooltip}
        show={tooltipShow || show}
      >
        <span
          className={markerPrice}
          onMouseEnter={() => setTooltipShow(true)}
          onMouseLeave={() => setTooltipShow(false)}
        >
          {price}
        </span>
      </OverlayTrigger>
    );
  }
  return (
    <OverlayTrigger
      placement="top"
      overlay={renderTooltip}
      show={tooltipShow | show}
    >
      <img
        className={markerLogo}
        src={MapMarker}
        alt={name}
        onMouseEnter={() => setTooltipShow(true)}
        onMouseLeave={() => setTooltipShow(false)}
      />
    </OverlayTrigger>
  );
}

export { GoogleMap, Marker };
