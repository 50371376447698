import moment from "moment-timezone";
import { format, getDay, isValid } from "date-fns";

const getTimeSlotsForDate = (
  date = new Date(),
  calendar = {},
  busyTimes = [],
) => {
  const { duration, buffer, available_times, notice, time_zone } =
    calendar || {};

  if (!isValid(date) || !available_times || !duration || !buffer) return [];

  const day = getDay(date);
  const selectedDate = format(date, "yyyy-MM-dd");
  if (!calendar || !date || day === 0 || day === 6) {
    return [];
  }

  const activeTime = available_times
    ? available_times.find((time) => time.day === format(date, "EEEE"))
    : [];

  const noticeEndString = moment(new Date())
    .tz(time_zone)
    .add(notice, "hours")
    .add(30 - moment(new Date()).tz(time_zone).format("mm"), "minutes")
    .format("LLL");

  const noticeEnd = moment(noticeEndString, "MMMM D, YYYY HH:mm a");

  let startTime =
    date && activeTime && activeTime.from
      ? moment(selectedDate + " " + activeTime.from, "YYYY-MM-DD HH:mm a")
      : moment(selectedDate + " 9:00 am", "YYYY-MM-DD HH:mm a");

  const endTime =
    date && activeTime && activeTime.to
      ? moment(selectedDate + " " + activeTime.to, "YYYY-MM-DD h:mm a")
      : moment(selectedDate + " 4:00 pm", "YYYY-MM-DD h:mm a");

  if (startTime.isBefore(noticeEnd) && noticeEnd.isBefore(endTime)) {
    startTime = noticeEnd;
  } else if (startTime.isBefore(noticeEnd) && noticeEnd.isAfter(endTime)) {
    startTime = endTime;
  }

  let currentTime = startTime;
  let tempTimeSlots = [];
  while (currentTime.isBefore(endTime)) {
    const existingEvents = busyTimes.find(
      (event) => event.date === selectedDate,
    );

    if (existingEvents && existingEvents.events.length > 0) {
      let conflicts = false;
      for (let j = 0; j < existingEvents.events.length; j++) {
        const currentEvent = existingEvents.events[j];
        if (currentEvent.isAllDay) {
          currentTime = endTime;
        } else {
          const eventStart = moment(
            currentEvent.start ? currentEvent.start : null,
          ).subtract(buffer, "minutes");
          const eventEnd = moment(new Date(currentEvent.end)).add(
            buffer,
            "minutes",
          );

          if (
            pastEvent(eventStart, eventEnd, currentTime) ||
            futureEvent(eventStart, eventEnd, currentTime, duration)
          ) {
            // pass
          } else {
            conflicts = true;
          }
        }
      }
      if (!conflicts) {
        tempTimeSlots.push(currentTime.format("h:mm a"));
      }
      currentTime.add(duration, "minutes");
    } else {
      // if no existing events events
      tempTimeSlots.push(currentTime.format("h:mm a"));
      currentTime.add(duration, "minutes");
    }
  }
  return tempTimeSlots;
};

export default getTimeSlotsForDate;

const pastEvent = (eventStart, eventEnd, currentTime) => {
  return (
    eventEnd.isSameOrBefore(currentTime) && eventStart.isBefore(currentTime)
  );
};

const futureEvent = (eventStart, eventEnd, currentTime, duration) => {
  return (
    eventEnd.isAfter(currentTime.clone().add(duration, "minutes")) &&
    eventStart.isSameOrAfter(currentTime.clone().add(duration, "minutes"))
  );
};
