// extracted by mini-css-extract-plugin
export var accordion = "MembershipsPerksModal-module--accordion--ab278";
export var accordion__perkBody = "MembershipsPerksModal-module--accordion__perk-body--89517";
export var accordion__perkIcon = "MembershipsPerksModal-module--accordion__perk-icon--9f719";
export var accordion__perkItem = "MembershipsPerksModal-module--accordion__perk-item--1cbf1";
export var accordion__perkTitle = "MembershipsPerksModal-module--accordion__perk-title--c96a0";
export var escape = "MembershipsPerksModal-module--escape--8d52a";
export var mePerksSection = "MembershipsPerksModal-module--me-perks-section--beb72";
export var mePerksSection__content = "MembershipsPerksModal-module--me-perks-section__content--6e736";
export var mePerksSection__title = "MembershipsPerksModal-module--me-perks-section__title--66e7d";
export var membershipsPerksModalContent = "MembershipsPerksModal-module--memberships-perks-modal-content--950f2";