import React, { forwardRef, useEffect, useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import {
  customDatepickerButton,
  customDatepickerButtonLocationProfile,
  customDatepickerButtonProductWidget,
  customDatepickerButtonProductWidgetTitle,
  customFormDatepickerButton,
  customFormDatepickerButton__selected,
  datepickerError,
  dhButton,
  dhButtonActive,
  seButton,
  seButtonItem,
  seContainer,
} from "./DropdownDatePicker.module.scss";
import { Dropdown, DropdownButton } from "react-bootstrap";
import ArrowRight from "../../images/design-components/Arrow_R.svg";
import { format, getDay } from "date-fns";
import DatePickerIcon from "../../images/design-components/datepicker_icon.svg";
import Down from "../../images/design-components/Down.svg";
import i18n from "../../utils/i18n";
import classnames from "classnames";

const DropdownDatePicker = ({
  meetingRooms,
  meetingRoomType,
  setMeetingRoomType,
  date,
  setDate,
  setMeetingDate,
  meetingStartTime,
  setMeetingStartTime,
  meetingEndTime,
  setMeetingEndTime,
  isInvalid = false,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  // FormDatePicker
  onChange,
  selected,
  placeholderText,
  // ProductWidget
  productWidget,
  // ProductForm
  productForm,
  disabled = false,
}) => {
  // ============= UI UTILITIES =============
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const handleHourlyButtonClick = () => {
    setMeetingRoomType("hourly");
    // As we're transitioning to hourly, we can reset values from "daily"
    setStartDateObject(new Date());
    setEndDateObject(new Date());
  };

  const handleDailyButtonClick = () => {
    setMeetingRoomType("daily");
    // As we're transitioning to daily, we can reset values from "hourly"
    setMeetingStartTime("");
    setStartTimeIndex(0);
    setMeetingEndTime("");
    setDate(new Date());
    setMeetingDate(format(new Date(), "yyyy-MM-dd"));
  };

  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const [startDateObject, setStartDateObject] = useState("");
  const [endDateObject, setEndDateObject] = useState("");
  const [dateRangeChange, setDateRangeChange] = useState(false);
  const onDateRangeRange = (dates) => {
    const [start, end] = dates;
    setStartDateObject(start);
    setEndDateObject(end);
    if (start !== null && end !== null) {
      setStartDate(format(start, "yyyy-MM-dd"));
      setEndDate(format(end, "yyyy-MM-dd"));
      setDateRangeChange(!dateRangeChange);
    }
  };

  // ============= HOURLY MEETING ROOMS ===============
  const meetingRoomTimes = [
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
  ];
  const [startTimeIndex, setStartTimeIndex] = useState(0);
  const handleStartTime = (time, index) => {
    setMeetingStartTime(time);
    setStartTimeIndex(index);
    setMeetingEndTime("");
  };
  const handleEndTime = (time, index) => {
    setMeetingEndTime(time);
  };

  const handleHourlyDateSelect = (date) => {
    setDate(date);
    setMeetingDate(format(date, "yyyy-MM-dd"));
    setMeetingStartTime("");
    setStartTimeIndex(0);
    setMeetingEndTime("");
  };

  useEffect(() => {
    setDatePickerOpen(false);
  }, [meetingEndTime, dateRangeChange]);

  const CustomContainer = ({ className, children }) => {
    return (
      <div>
        {meetingRooms ? (
          <CalendarContainer className={`${className} d-flex flex-column`}>
            <div>
              <button
                className={
                  meetingRoomType === "hourly" ? dhButtonActive : dhButton
                }
                onClick={handleHourlyButtonClick}
                onKeyDown={handleHourlyButtonClick}
              >
                Hourly
              </button>
              <button
                className={
                  meetingRoomType === "daily" ? dhButtonActive : dhButton
                }
                onClick={handleDailyButtonClick}
                onKeyDown={handleDailyButtonClick}
              >
                Daily
              </button>
            </div>
            <div className="position-relative">{children}</div>
            <div
              className={meetingRoomType === "hourly" ? seContainer : "d-none"}
            >
              <div>
                <DropdownButton
                  title={
                    meetingStartTime === ""
                      ? i18n.t("StartTime")
                      : meetingStartTime
                  }
                  className={seButton}
                >
                  {meetingRoomTimes
                    .slice(0, meetingRoomTimes.length - 1)
                    .map((time, index) => (
                      <Dropdown.Item
                        key={index}
                        as={"div"}
                        className={seButtonItem}
                        onClick={() => handleStartTime(time, index)}
                      >
                        {time}
                      </Dropdown.Item>
                    ))}
                </DropdownButton>
              </div>
              <div>
                <img alt="right arrow" src={ArrowRight} />
              </div>
              <div>
                <DropdownButton
                  title={
                    meetingEndTime === "" ? i18n.t("EndTime") : meetingEndTime
                  }
                  className={seButton}
                >
                  {meetingRoomTimes
                    .slice(1, meetingRoomTimes.length)
                    .map((time, index) => (
                      <Dropdown.Item
                        key={index}
                        as={"div"}
                        className={seButtonItem}
                        disabled={
                          index <= startTimeIndex - 1 || meetingStartTime === ""
                            ? true
                            : false
                        }
                        onClick={() => handleEndTime(time, index)}
                      >
                        {time}
                      </Dropdown.Item>
                    ))}
                </DropdownButton>
              </div>
            </div>
          </CalendarContainer>
        ) : (
          <CalendarContainer className={`${className} d-flex flex-column`}>
            <div className="position-relative">{children}</div>
          </CalendarContainer>
        )}
      </div>
    );
  };

  const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className={classnames({
        [customDatepickerButton]: true,
      })}
      onClick={onClick}
      ref={ref}
    >
      <span>{i18n.t("StartAndEndDate")}</span>
    </button>
  ));

  const CustomDatePickerInputProductWidget = forwardRef(
    ({ value, onClick }, ref) => (
      <div className={customDatepickerButtonProductWidget}>
        <button onClick={onClick} ref={ref}>
          <div className={customDatepickerButtonProductWidgetTitle}>
            <span>{i18n.t("Time")}</span>
            <div className={`d-flex`}>
              <img alt="" src={DatePickerIcon} />
              <h6>{i18n.t("StartAndEndTime")}</h6>
            </div>
          </div>
        </button>
      </div>
    ),
  );

  const CustomDatePickerInputProductForm = forwardRef(
    ({ value, onClick }, ref) => (
      <button className={`btn-link p-0 w-100`} onClick={onClick} ref={ref}>
        <div
          className={`form-control d-flex justify-content-start ${customDatepickerButtonLocationProfile}`}
        >
          <img alt="" src={Down} />
          <span>{i18n.t("StartAndEndDate")}</span>
        </div>
      </button>
    ),
  );

  const CustomFormDatePickerInput = forwardRef(
    ({ value, onClick, disabled, ...others }, ref) => {
      return (
        <button
          disabled={disabled}
          className={classnames({
            [customFormDatepickerButton]: true,
            [customFormDatepickerButton__selected]: !!value,
            [datepickerError]: isInvalid,
          })}
          onClick={onClick}
          ref={ref}
        >
          <span>{value === "" ? placeholderText : value}</span>
        </button>
      );
    },
  );

  if (meetingRooms) {
    if (meetingRoomType === "hourly") {
      return (
        <>
          <span
            style={{ background: "none", border: "none" }}
            onClick={() => setDatePickerOpen(true)}
          >
            <DatePicker
              formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 1)}
              open={datePickerOpen}
              customInput={
                productForm ? (
                  <CustomDatePickerInputProductForm />
                ) : productWidget ? (
                  <CustomDatePickerInputProductWidget />
                ) : (
                  <CustomDatePickerInput />
                )
              }
              dateFormat="yyyy-MM-dd"
              showPopperArrow={false}
              filterDate={isWeekday}
              placeholderText="Start & End Date"
              minDate={new Date()}
              onClickOutside={() => setDatePickerOpen(false)}
              calendarContainer={CustomContainer}
              shouldCloseOnSelect={false}
              disabled={disabled}
              // ^^^^ common to both hourly & daily
              selected={date}
              onChange={(date) => handleHourlyDateSelect(date)}
              calendarClassName={"customIQ"}
            />
          </span>
        </>
      );
    } else if (meetingRoomType === "daily") {
      return (
        <>
          <span
            style={{ background: "none", border: "none" }}
            onClick={() => setDatePickerOpen(true)}
          >
            <DatePicker
              formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 1)}
              customInput={
                productForm ? (
                  <CustomDatePickerInputProductForm />
                ) : productWidget ? (
                  <CustomDatePickerInputProductWidget />
                ) : (
                  <CustomDatePickerInput />
                )
              }
              disabled={disabled}
              dateFormat="yyyy-MM-dd"
              showPopperArrow={false}
              filterDate={isWeekday}
              placeholderText="Start & End Date"
              minDate={new Date()}
              onClickOutside={() => setDatePickerOpen(false)}
              calendarContainer={CustomContainer}
              shouldCloseOnSelect={false}
              // ^^^^ common to both hourly & daily
              selected={startDateObject}
              startDate={startDateObject}
              endDate={endDateObject}
              selectsRange
              onChange={onDateRangeRange}
              calendarClassName={"customIQ"}
            />
          </span>
        </>
      );
    }
  } else {
    return (
      <DatePicker
        formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 1)}
        customInput={<CustomFormDatePickerInput />}
        dateFormat="yyyy-MM-dd"
        showPopperArrow={false}
        filterDate={isWeekday}
        disabled={disabled}
        placeholderText="Start & End Date"
        minDate={new Date()}
        selected={selected}
        onChange={onChange}
        calendarContainer={CustomContainer}
        calendarClassName={"customIQ"}
      />
    );
  }
};

export default DropdownDatePicker;
