import { getQueryParams } from "../index";

const isLargerTeamSize = () => {
  const params = getQueryParams();
  let minTs = params.get("team_size") || 0;

  if (!minTs) {
    return false;
  }

  const tsArray = minTs.split("-");

  return tsArray.length > 1 && Number(tsArray[0]) >= 26;
};

export default isLargerTeamSize;
