import React from "react";
import OverviewItem from "./OverviewItem";
import { PrismicRichText } from "@prismicio/react";
import resolvePrismicLink from "../../utils/resolvePrismicLink";
import get from "lodash/get";
import i18n from "../../utils/i18n";

const OverviewItems = ({
  slice,
  products = [],
  categories = [],
  descriptionTabletRow = false,
}) => {
  const items = get(slice, "items", []);

  if (products && products.length > 0 && categories && categories.length > 0) {
    return (
      <div className={""}>
        {categories.map((category, idx) => (
          <div
            id={`section_${category
              .toLowerCase()
              .replaceAll(" ", "_")
              .replaceAll("-", "_")}`}
            name={`section_${category
              .toLowerCase()
              .replaceAll(" ", "_")
              .replaceAll("-", "_")}`}
            key={idx}
          >
            {products.map((product, index) => {
              if (get(product, "data.product_type", "") !== category)
                return null;
              return (
                <OverviewItem
                  key={index}
                  image={get(product, "data.link_image.url", "")}
                  title={get(product, "data.menu_link_name", "")}
                  titleIcon={null}
                  description={get(product, "data.menu_link_description", "")}
                  descriptionTabletRow={
                    descriptionTabletRow ||
                    get(product, "data.different_tablet_layout", false)
                  }
                  imageRight={index % 2 === 1}
                  ctaLabel={i18n.t("ReadMore")}
                  ctaLink={product.url}
                  ctaTarget={"_self"}
                  isPlainText
                  switchToPadding
                />
              );
            })}
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      {items.map((item, idx) => {
        const { link, target } = resolvePrismicLink(
          get(item, "button_link", {}),
        );
        return (
          <OverviewItem
            key={idx}
            image={get(item, "image.url", "")}
            title={<PrismicRichText field={get(item, "title", "")} />}
            titleIcon={get(item, "title_icon.url", "")}
            description={
              <PrismicRichText field={get(item, "description", "")} />
            }
            imageRight={idx % 2 === 1}
            ctaLabel={get(item, "button_label", "")}
            ctaLink={link}
            ctaTarget={target}
            modalLabel={get(item, "modal_trigger_button_label", "")}
            modalTitle={get(item, "modal_title", "")}
            modalDescription={get(item, "modal_description", "")}
            modalButtonLabel={get(item, "modal_button_label", "")}
            modalImage={get(item, "modal_image.url", false)}
            modalButtonLink={resolvePrismicLink(
              get(item, "modal_button_link", {}),
            )}
            descriptionTabletRow={get(item, "different_tablet_layout", false)}
            maintainImageAspectRatio={get(
              item,
              "maintain_image_aspect_ratio",
              false,
            )}
          />
        );
      })}
    </>
  );
};

export default OverviewItems;
