import React from "react";
import * as s from "./index.module.scss";
import cx from "classnames";

const ProgressDots = ({ darkBg = false }) => {
  return (
    <span className={cx({ [s.progressDots]: true, [s.darkBg]: darkBg })}>
      <span />
      <span />
      <span />
    </span>
  );
};

export default ProgressDots;
