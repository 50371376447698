import { getQueryParams } from "./index";
import { navigate } from "gatsby";

const updateQueryString = async (newParams) => {
  const params = getQueryParams();

  for (const param of newParams) {
    if (param.key) params.set(param.key, param.value);
  }
  await navigate(window.location.pathname + "?" + params.toString());
  return false;
};

export default updateQueryString;
