import React from "react";
import PropTypes from "prop-types";
import Floor from "./Floor";
import { getSelectedDesksOrOffices } from "./util";
import OptionsListItems from "./OptionsListItems";
import get from "lodash/get";
import SummaryList from "./SummaryList";
import ProgressDots from "../ProgressDots";
import * as s from "./FloorPlan.module.scss";

// import LocationsProfileInlineForm from "../LocationsProfileInlineForm/LocationsProfileInlineForm";
import "./styles.scss";
import { getQueryParams } from "../../utils";
import LocationsProfileInlineForm from "../LocationsProfileInlineForm/LocationsProfileInlineForm";

const FloorPlan = ({
  profile = {},
  user,
  optionText = false,
  displayPrice,
  calendar = null,
  setSelectedOption = () => null,
  busyTimes = [],
  cmsProfile,
  availableProducts,
}) => {
  const params = getQueryParams();

  if (!params.get("team_size")) return null;

  if (!params.get("selected_blocks")) return null;

  const { categorizedOptions, allOptions } = getSelectedDesksOrOffices(profile);

  if (categorizedOptions.length === 0) {
    return (
      <div className={"container-xxl min-vh-100 d-flex flex-column"}>
        <div className={"my-auto text-center"}>
          <ProgressDots />
        </div>
      </div>
    );
  }

  return (
    <div className={`container-xxl ${s.container}`}>
      <SummaryList profile={profile} selectedOptions={allOptions} />
      <div className="space-profile-layout bottom-line">
        <div className={"row justify-content-center"}>
          <div className={"col-lg-10"}>
            <ul className={`floor-option-list mb-5`}>
              {categorizedOptions.map((space, idx) => {
                let optionStart = 0;
                if (idx === 1) {
                  const existingItems = get(
                    categorizedOptions,
                    `[${idx - 1}].items.length`,
                    1,
                  );
                  optionStart += existingItems;
                }
                if (idx === 2) {
                  const existingItems1 = get(
                    categorizedOptions,
                    `[${idx - 1}].items.length`,
                    1,
                  );
                  const existingItems2 = get(
                    categorizedOptions,
                    `[${idx - 1}].items.length`,
                    1,
                  );
                  optionStart += existingItems1;
                  optionStart += existingItems2;
                }
                return (
                  <OptionsListItems
                    key={idx}
                    floorIndex={idx}
                    spaces={space}
                    user={user}
                    displayPrice={true}
                    optionStart={optionStart}
                    floorId={space.floor_id}
                    setSelectedOption={setSelectedOption}
                  />
                );
              })}
            </ul>

            {categorizedOptions.map((spaces, idx) => {
              let optionStart = 0;
              if (idx === 1) {
                const existingItems = get(
                  categorizedOptions,
                  `[${idx - 1}].items.length`,
                  1,
                );
                optionStart += existingItems;
              }
              if (idx === 2) {
                const existingItems1 = get(
                  categorizedOptions,
                  `[${idx - 1}].items.length`,
                  1,
                );
                const existingItems2 = get(
                  categorizedOptions,
                  `[${idx - 1}].items.length`,
                  1,
                );
                optionStart += existingItems1;
                optionStart += existingItems2;
              }
              return (
                <Floor
                  key={idx}
                  floorIndex={idx}
                  svg={get(spaces, "[0].map_svg_url", "")}
                  bg={get(spaces, "[0].map_background_url", "")}
                  spaces={spaces}
                  user={user}
                  optionText={optionText}
                  displayPrice={displayPrice}
                  optionStart={optionStart}
                  floorId={get(spaces, "[0].floor_id", "")}
                />
              );
            })}
          </div>
        </div>
      </div>

      {get(profile, "calendar", "") && (
        <LocationsProfileInlineForm
          profile={profile}
          busyTimes={busyTimes}
          cmsProfile={cmsProfile}
          availableProducts={availableProducts}
        />
      )}
    </div>
  );
};

export default FloorPlan;

FloorPlan.propTypes = {
  profile: PropTypes.object,
  queryString: PropTypes.string,
  activeModal: PropTypes.string,
  setActiveModal: PropTypes.func,
  index: PropTypes.string,
  selectedSpaceId: PropTypes.string,
};
