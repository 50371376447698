import React from "react";
import get from "lodash/get";
import {
  hpClientCarouselSection,
  hpClientCarouselSection__title,
  hpClientCarouselSection__item,
  hpClientCarouselSection__carouselContainer,
  hpClientCarouselSection__leftButton,
  hpClientCarouselSection__rightButton,
} from "../../index.module.scss";
import Carousel from "react-multi-carousel";
import Left from "../../../../images/design-components/Left.svg";
import Right from "../../../../images/design-components/Right.svg";

const HomePageClientCarousel = ({ slice }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1092 },
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1092, min: 768 },
      items: 4,
      slidesToSlide: 4,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 4,
      slidesToSlide: 4, // optional, default to 1.
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div>
        <button
          className={`btn-link ${hpClientCarouselSection__leftButton}`}
          onClick={previous}
        >
          <img alt="" src={Left} />
        </button>
        <button
          className={`btn-link ${hpClientCarouselSection__rightButton}`}
          onClick={next}
        >
          <img alt="" src={Right} />
        </button>
      </div>
    );
  };

  return (
    <div
      className={`${hpClientCarouselSection} container-xxl position-relative`}
    >
      <h3 className={hpClientCarouselSection__title}>
        {get(slice, "primary.title", [])}
      </h3>
      <div className="row justify-content-center">
        <div className="col-xxl-8 col-md-8 position-relative">
          <div className={hpClientCarouselSection__carouselContainer}>
            <Carousel
              autoPlay
              autoPlaySpeed={4000}
              itemClass={"text-center"}
              responsive={responsive}
              arrows={false}
              renderButtonGroupOutside={true}
              customButtonGroup={<ButtonGroup />}
              infinite
            >
              {get(slice, "items", []).map((item, index) => (
                <img
                  className={hpClientCarouselSection__item}
                  key={index}
                  alt=""
                  src={get(item, "logo.url")}
                  loading={"lazy"}
                />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageClientCarousel;
