import * as React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
// import { SliceZone } from "@prismicio/react";
import get from "lodash/get";

import Layout from "../../components/Layout";
import activeDocMeta from "../utils/activeDocMeta";
import OverviewItems from "../../components/OverviewItem/OverviewItems";
import FaqSection from "../WhyIqPages/components/Faq";
import { SliceZone } from "@prismicio/react";

import ProductWidget from "../../components/Products/ProductWidget";

import ProductHero from "../../components/Products/ProductHero";
import ProductTitle from "../../components/Products/ProductTitle";
import ProductInfo from "../../components/Products/ProductInfo";
import HeroFooter from "../../components/Hero/HeroFooter";
import ProductHowItWorks from "../../components/Products/ProductHowItWorks";
import ProductAddOns from "../../components/Products/ProductAddOns";
import ProductWhatsIncluded from "../../components/Products/ProductWhatsIncluded";
import ProductSummary from "../../components/Products/ProductSummary";
import FullWidthTextSection from "./components/FullWidthTextSection";
import EventVenueList from "./components/EventVenueList";
import AdditionalServices, {
  AdditionalServicesFooter,
} from "../../components/AdditionalServices/AdditionalServices";
import StickyContact from "../../components/StickyContact/StickyContact";
import MeetingRoomPopup from "./components/MeetingRoomPopup";

const ProductPageTemplate = ({ data = {}, pageContext }) => {
  const pageData = get(data, "prismicProductPages.dataRaw", {});
  const eventVenueList = get(data, "allPrismicEventVenue.nodes", []);

  const pageType = get(data, "prismicProductPages.uid", "");

  const slices = pageData.body;

  const productHeroImages = get(pageData, "images").filter((image) =>
    get(image, "image.url", null),
  );

  return (
    <Layout
      activeDocMeta={activeDocMeta(get(data, "prismicProductPages", {}))}
      pages={{
        whyIqPages: get(data, "allPrismicWhyIqPages.nodes", []),
        productPages: get(data, "allPrismicProductPages.nodes", []),
        customPages: get(data, "allPrismicCustomPage.nodes", []),
        whyIqLandingPage: get(data, "allPrismicWhyIqLanding.nodes", []),
        productsLandingPage: get(
          data,
          "allPrismicProductsLandingPage.nodes",
          [],
        ),
      }}
      subscriptionModalData={data?.prismicHomePage?.data || {}}
    >
      {productHeroImages.length > 0 && (
        <ProductHero images={productHeroImages} />
      )}
      <ProductTitle
        product={{
          title: get(
            data,
            "prismicProductPages.data.hero_section_title.text",
            "",
          ),
          description: get(
            data,
            "prismicProductPages.data.hero_section_description.text",
            "",
          ),
        }}
      />
      <SliceZone
        slices={slices}
        context={{
          eventVenueList,
        }}
        components={{
          product_features: ProductInfo,
          image_block: OverviewItems,
          faqs: FaqSection,
          image: HeroFooter,
          how_it_works: ProductHowItWorks,
          "add-ons_list": ProductAddOns,
          features_with_image_carousel: ProductWhatsIncluded,
          search_component: ProductWidget,
          features_list: ProductSummary,
          full_width_text_section: FullWidthTextSection,
          event_venues: EventVenueList,
          additional_services_list: AdditionalServices,
          "additional_services_-_footer_description": AdditionalServicesFooter,
          contact_bar: StickyContact,
          modal_on_load: MeetingRoomPopup,
        }}
      />
    </Layout>
  );
};

export const query = graphql`
  query productSubPageQuery($lang: String, $uid: String) {
    allPrismicEventVenue {
      nodes {
        id
        uid
        lang
        url
        dataRaw
      }
    }
    prismicHomePage(lang: { eq: $lang }) {
      _previewable
      data {
        subscription_form_title
        subscription_form_description
        subscription_form_button_label
        subscription_footer_button_label
        blog_page_modal_display_delay
      }
    }
    prismicProductPages(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      lang
      type
      url
      uid
      id
      alternate_languages {
        uid
        type
        lang
      }
      dataRaw
      data {
        hero_section_description {
          text
        }
        hero_section_title {
          text
        }
        meta_title
        meta_description
        meta_image {
          url
        }
      }
    }
    allPrismicWhyIqLanding(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicProductsLandingPage(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicProductPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        _previewable
        data {
          menu_link_name
          menu_link_description
          product_type
          display_in_header_menu
        }
      }
    }
    allPrismicWhyIqPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        _previewable
        data {
          display_in_main_menu
          menu_link_name
          menu_link_description
          menu_link_image {
            url
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 192, formats: AUTO, height: 128)
              }
            }
          }
        }
      }
    }
    allPrismicCustomPage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        uid
        lang
        url
        dataRaw
        data {
          main_menu_order
          link_label
          show_in_main_menu
        }
      }
    }
  }
`;

export default withPrismicPreview(ProductPageTemplate);
