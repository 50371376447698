import React from "react";
import { Accordion, Button } from "react-bootstrap";
import {
  body_content_1,
  body_content_2,
  customAccordionTitle,
  faq,
  faq__accordion__locationFaq,
  hideBg,
  margins,
  section_heading,
  title_button,
  yellow_bg_text,
} from "./AdditionalServices.module.scss";
import { navigate } from "gatsby";
import get from "lodash/get";
import resolvePrismicLink from "../../utils/resolvePrismicLink";
import cx from "classnames";
import splitNl2Array from "../../utils/splitNl2Array";
import nl2br from "../../utils/nl2br";
import i18n from "../../utils/i18n";

const AdditionalServices = ({ faqItems = [], slice }) => {
  const items = get(slice, "items", faqItems);

  const buttonLabel = get(slice, "primary.button_label", null);
  const title = get(slice, "primary.title", null);
  const buttonLinkObject = get(slice, "primary.button_link", null);

  const { link, target } = resolvePrismicLink(buttonLinkObject);

  const isOneLiner =
    items &&
    items.length === 1 &&
    get(items, "[0].service", false) &&
    !get(items, "[0].price", "default text");

  return (
    <>
      <div
        className={`container-fluid overflow-hidden p-0 ${faq} ${
          title ? "" : margins
        }`}
      >
        <Accordion
          bsPrefix={faq__accordion__locationFaq}
          className={title ? "" : hideBg}
        >
          <Accordion.Item>
            {title && (
              <Accordion.Header
                className={`container-xxl px-md-0 ${customAccordionTitle}`}
              >
                <div className="w-100 row justify-content-center">
                  <div className="col-lg-10 ps-0 d-flex align-items-center">
                    {title}
                    {link && target ? (
                      <div
                        role={"presentation"}
                        onClick={async (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          await navigate(link);
                        }}
                        className={`btn btn-outline-light bg-primary ms-auto d-none d-md-inline-block ${title_button}`}
                      >
                        <span className={"text-white"}>{buttonLabel}</span>
                      </div>
                    ) : (
                      <div
                        className={
                          "pe-none opacity-0 invisible d-md-block d-none h-0 p-0 m-0 border-0"
                        }
                        style={{ height: "0" }}
                      >
                        {title}
                      </div>
                    )}
                  </div>
                </div>
              </Accordion.Header>
            )}
            <Accordion.Body className="pe-0 ps-0">
              <div>
                {!isOneLiner && (
                  <div className={"border-bottom-primary d-none d-md-block"}>
                    <div className={`container-xxl`}>
                      <div
                        className={`row justify-content-center py-2 py-md-3`}
                      >
                        <div className="col-lg-10">
                          <div className="row">
                            <div className={cx(`col-md-8`, "text-uppercase")}>
                              <strong className={section_heading}>
                                {i18n.t("Service")}
                              </strong>
                            </div>
                            <div className={cx(`col-md-4`, "text-uppercase")}>
                              <strong>{i18n.t("Price")}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {items.map((item, index) => {
                  return (
                    <div key={index} className={"border-bottom-primary"}>
                      <div className={`container-xxl`}>
                        <div
                          className={`row justify-content-center py-2 py-md-3 `}
                        >
                          <div className="col-lg-10">
                            <div className="row">
                              <div className={cx(`col-md-8`)}>
                                <span className={body_content_1}>
                                  {nl2br(item.service)}
                                </span>
                              </div>
                              <div className={cx(`col-md-4`)}>
                                <span className={body_content_2}>
                                  {nl2br(item.price)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {isOneLiner && (
                        <div className={`container-xxl mb-3 d-block d-md-none`}>
                          <div className={`col-12 ps-3`}>
                            <Button
                              to={link}
                              onClick={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                await navigate(link);
                              }}
                              className="btn btn-primary d-inline-block ms-1"
                              target={target}
                            >
                              <span>{buttonLabel}</span>
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default AdditionalServices;

export const AdditionalServicesFooter = ({ slice }) => {
  const text = get(slice, "primary.description", "") || "";
  const formattedText = splitNl2Array(text);
  return (
    <div className={`bg-offwhite py-lg-3 py-0`}>
      <div className={`container-xxl py-5`}>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className={yellow_bg_text}>
              {formattedText.map((text, idx) => (
                <p key={idx}>{text}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
