import React from "react";
import { PrismicRichText } from "@prismicio/react";
import * as s from "./index.module.scss";
import get from "lodash/get";

const RichText = ({ slice }) => {
  const richTextContent = get(slice, "primary.content", []);

  return (
    <div className={`container-xxl ${s.contentSection}`}>
      <div className="row justify-content-center">
        <div className={`col-lg-10`}>
          <div className={s.rich_text}>
            <PrismicRichText field={richTextContent} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RichText;
