import React, { useEffect, useState } from "react";
import {
  lpiSection,
  lpiSection__back,
  lpiSection__column1,
  lpiSection__column2,
  lpiSection__column2__bookATourForm,
  lpiSection__scrollForMoreInfo,
  lpiSection__subtitle,
  lpiSection__title,
  lpiSection__tourDuration,
} from "./LocationsProfileInlineForm.module.scss";
import InlineDatePicker from "../InlineDatePicker/InlineDatePicker";
import DownArrow from "../../images/design-components/Down.svg";
import BookATourForm from "../BookATour/BookATourForm";
import get from "lodash/get";
import i18n from "../../utils/i18n";
import getTimeSlotsForDate from "../../utils/locationProfile/getTimeSlotsForDate";
import { Button } from "react-bootstrap";
import { timezoneString } from "../BookATour/BookATour";
import { addHours, addWeeks, endOfWeek, format } from "date-fns";

const LocationsProfileInlineForm = ({
  profile = {},
  busyTimes,
  cmsProfile,
  setConfirmTour = () => null,
  availableProducts,
}) => {
  const [tourDate, setDate] = useState(null);
  const [tourTime, setTourTime] = useState("");
  const [availableTimes, setAvailableTimes] = useState([]);

  const [inlineFormShow, setInlineFormShow] = useState(false);

  const availableWeeks = get(profile, "calendar.available_dates", 1);
  const notice = get(profile, "calendar.notice", 1);

  useEffect(() => {
    const timeSlots = getTimeSlotsForDate(
      tourDate,
      get(profile, "calendar", {}),
      busyTimes,
    );
    setAvailableTimes(timeSlots);
  }, [tourDate]);

  return (
    <div className={`${lpiSection}`}>
      <div className={`row justify-content-center`}>
        <div className="col-lg-10">
          <div className="row">
            <div
              className={`[ col-lg-3 ] [ col-md-4 ] d-flex flex-column ${lpiSection__column1}`}
            >
              <div>
                <h2 className={`${lpiSection__title}`}>
                  {i18n.t("profilePage.ExperienceTheSpace")}
                </h2>
                <h6 className={`${lpiSection__subtitle}`}>
                  {i18n.t("profilePage.BookATour")}
                </h6>
                <p className={`mt-auto mb-4 ${lpiSection__tourDuration}`}>
                  {i18n.t("profilePage.TourDuration")}:{" "}
                  {get(profile, "calendar.duration", "30")} minutes <br />
                  {!inlineFormShow ? (
                    i18n.t(
                      "Please select a date and time that works best for you",
                    )
                  ) : (
                    <>
                      Meet with {get(profile, "contact_name", "")} at{" "}
                      {get(cmsProfile, "location_title[0].text", "")}
                      <br />
                      {tourTime}, {format(tourDate, "EEEE, MMMM dd, yyyy")}
                    </>
                  )}
                </p>
                {!inlineFormShow ? (
                  ""
                ) : (
                  <button
                    className={`${lpiSection__back} btn-link`}
                    onClick={() => setInlineFormShow(false)}
                    role={"button"}
                  >
                    <span className={`arrow-left-primary`}></span>{" "}
                    {i18n.t("Back")}
                  </button>
                )}
              </div>
            </div>
            <div
              className={`[ offset-xl-1 col-lg-9 col-xl-8 ] [ col-md-8 ] ${lpiSection__column2}`}
            >
              {!inlineFormShow ? (
                <div className={"mb-5"}>
                  <div className="row">
                    <div className="col-sm-8 col-md-9 col-lg-8 col-xl-7 col-xxl-6">
                      <InlineDatePicker
                        selectedDate={tourDate}
                        setSelectedDate={setDate}
                        minDate={addHours(new Date(), notice)}
                        maxDate={endOfWeek(
                          addWeeks(new Date(), availableWeeks || 1),
                        )}
                      />
                    </div>
                    <div className="mt-4 mt-sm-0 col-sm-4 col-md-3 ps-md-0 ps-lg-2 col-lg-4 col-xl-3">
                      <div className={"row"}>
                        {availableTimes.length > 0 ? (
                          availableTimes.map((time, index) => (
                            <div
                              className="col-6 col-sm-12 col-lg-12"
                              key={index}
                            >
                              <Button
                                variant="outline-primary"
                                className={"d-block mb-3 w-100 px-2"}
                                onClick={() => {
                                  // Change the layout to the contact form with the fields
                                  // tourDate & time
                                  setTourTime(time);
                                  setInlineFormShow(true);
                                }}
                              >
                                <span>
                                  {time}{" "}
                                  {timezoneString(
                                    get(profile, "timezone", "America/Toronto"),
                                  )}
                                </span>
                              </Button>
                            </div>
                          ))
                        ) : (
                          <small className={"text-muted d-block mb-5"}>
                            {tourDate
                              ? i18n.t("NoTimeslotsAvailableForThisDate")
                              : i18n.t("PleasePickADateFirst")}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={`${lpiSection__column2__bookATourForm}`}>
                  <BookATourForm
                    inline
                    profile={profile}
                    cmsProfile={cmsProfile}
                    setConfirmTour={setConfirmTour}
                    tourDate={tourDate ? format(tourDate, "yyyy-MM-dd") : null}
                    time={tourTime}
                    availableProducts={availableProducts}
                    lightBg
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`d-flex justify-content-center align-items-center flex-column ${lpiSection__scrollForMoreInfo}`}
      >
        <p className={`text-center`}>
          {i18n.t("KeepScrolling")} <br />
          {get(cmsProfile, "location_title[0].text", "")}
        </p>
        <img alt="View Profile" src={DownArrow} />
      </div>
    </div>
  );
};

export default LocationsProfileInlineForm;
