import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import get from "lodash/get";
import * as s from "../../index.module.scss";
import cx from "classnames";
import CloseButton from "../../../../images/design-components/Less_1.svg";
import { Link } from "gatsby";
import resolvePrismicLink from "../../../../utils/resolvePrismicLink";
import { PrismicRichText } from "@prismicio/react";
import CustomPrismicLink from "../../../../components/CustomPrismicLink";

const PromoModal = ({ promoBar, setPromoBar, modalData }) => {
  const hasModal = get(modalData, "has_modal", false);

  const { link, target } = resolvePrismicLink(
    get(modalData, "modal_button_link", {}),
  );

  const { link: barLink = "", target: barTarget } = resolvePrismicLink(
    get(modalData, "promo_banner_button_link", {}),
  );

  const [promoModal, setPromoModal] = useState(false);

  return (
    <div className={`d-print-none container-fluid p-0 ${s.hpPromoModalSticky}`}>
      <div
        className={cx(
          `container-fluid position-relative`,
          `flex-lg-row justify-content-lg-center`,
          `flex-column align-items-center`,
          s.hpPromoModalSection,
          promoBar ? `d-flex` : `d-none`,
        )}
      >
        <h6 className={s.hpPromoModalSection__text}>
          {get(modalData, "top_bar_text", "")}
        </h6>
        {hasModal && (
          <button
            className={`btn-link`}
            onClick={() => setPromoModal(true)}
            onKeyDown={() => setPromoModal(true)}
          >
            <p className={`mb-0`}>
              {get(modalData, "promo_banner_button_label", "Learn more")}
            </p>
          </button>
        )}
        {!hasModal && barLink && (
          <>
            {barTarget === "_blank" || barLink.indexOf("://") > -1 ? (
              <a href={barLink} target={barTarget} className={"btn-link"}>
                <p className={`mb-0`}>
                  {get(modalData, "promo_banner_button_label", "Learn more")}
                </p>
              </a>
            ) : (
              <Link to={barLink} target={barTarget} className={"btn-link"}>
                <p className={`mb-0`}>
                  {get(modalData, "promo_banner_button_label", "Learn more")}
                </p>
              </Link>
            )}
          </>
        )}
        <button
          className={`btn-link ${s.hpPromoModalSection__closeButton}`}
          onClick={() => setPromoBar(false)}
          onKeyDown={() => setPromoBar(false)}
        >
          <img alt="" src={CloseButton} />
        </button>
      </div>

      {hasModal && (
        <Modal
          centered
          show={promoModal}
          onHide={() => setPromoModal(false)}
          className={`${s.hpPromoModalSection__modal} modal-z-index`}
        >
          <Modal.Body>
            <div
              className={`container ${s.hpPromoModalSection__modal__container}`}
            >
              <button
                className={`btn-link ${s.hpPromoModalSection__modal__closeButton}`}
                onClick={() => setPromoModal(false)}
                onKeyDown={() => setPromoModal(false)}
              >
                <img alt="" src={CloseButton} />
              </button>
              <div className={`row`}>
                <div className={`offset-lg-1 col-lg-5`}>
                  <img
                    className={`w-100`}
                    alt=""
                    src={get(modalData, "modal_image.url", "")}
                  />
                </div>
                <div
                  className={`offset-lg-1 col-lg-4 d-flex flex-column justify-content-center`}
                >
                  <div className="my-auto">
                    <div className={s.hpPromoModalSection__modal__title}>
                      <PrismicRichText
                        field={get(modalData, "modal_title", [])}
                      />
                    </div>
                    <div className={s.hpPromoModalSection__modal__description}>
                      <PrismicRichText
                        field={get(modalData, "modal_description", [])}
                      />
                    </div>
                  </div>

                  <div>
                    <CustomPrismicLink
                      className={"btn btn-outline-primary"}
                      to={link}
                      target={target}
                      title={get(modalData, "modal_button_label", "Learn More")}
                    >
                      <span>
                        {get(modalData, "modal_button_label", "Learn More")}
                      </span>
                    </CustomPrismicLink>
                    <button
                      className={`btn-link text-decoration-underline mt-1`}
                      onClick={() => setPromoModal(false)}
                      onKeyDown={() => setPromoModal(false)}
                    >
                      <p className={`mt-2 mb-0`}>
                        {get(
                          modalData,
                          "modal_cancel_text",
                          "No thanks, continue to site",
                        )}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default PromoModal;
