import React from "react";
import * as s from "./ProductHowItWorks.module.scss";
import resolvePrismicLink from "../../utils/resolvePrismicLink";
import get from "lodash/get";
import { PrismicRichText } from "@prismicio/react";
import cx from "classnames";
import i18n from "../../utils/i18n";
import CustomPrismicLink from "../CustomPrismicLink";

const ProductHowItWorks = ({ slice }) => {
  const { link, target } = resolvePrismicLink(
    get(slice, "primary.button_link", {}),
  );
  const list = get(slice, "items", []);
  return (
    <div className={`container-xxl ${s.howItWorks}`}>
      <div className={`row`}>
        <div
          className={cx(
            `d-flex flex-column`,
            `offset-2 col-8`,
            `offset-sm-3 col-sm-6`,
            `offset-md-2 col-md-8`,
          )}
        >
          <h4 className={s.howItWorks__text}>{i18n.t("HowItWorks")}</h4>
          <div className={s.howItWorks__itemsContainer}>
            {list.map((item, idx) => (
              <div key={idx} className={s.howItWorks__itemsContainer__item}>
                <img src={get(item, "icon.url", "")} />

                <div
                  className={`w-100 d-none d-md-block mb-4 ${s.borderBeachSand}`}
                ></div>
                <div
                  className={cx(
                    `d-flex align-items-center`,
                    `ms-4 ms-md-0`,
                    `flex-md-column justify-content-md-center`,
                  )}
                >
                  <p className={cx(`fw-bold me-4`, `me-md-0`)}>{idx + 1}</p>
                  <PrismicRichText field={get(item, "text", [])} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={"text-center"}>
          <CustomPrismicLink
            title={get(slice, "primary.button_label", "")}
            to={link}
            target={target}
            className={`btn btn-outline-primary`}
          >
            <span>{get(slice, "primary.button_label", "")}</span>
          </CustomPrismicLink>
        </div>
      </div>
    </div>
  );
};

export default ProductHowItWorks;
