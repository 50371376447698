import React from "react";
import useBambooHr from "./_useBambooHr";

const BambooHrEmbedCode = () => {
  useBambooHr();

  return (
    <>
      <div className={"container-xxl my-5"}>
        <div className="row">
          <div className="col-lg-8 offset-lg-1">
            <div className="mt-3 mb-5">
              <div id="BambooHR" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BambooHrEmbedCode;
