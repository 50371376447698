import React from "react";
import {
  hpFeaturedSection,
  hpFeaturedSection__button,
  hpFeaturedSection__description,
  hpFeaturedSection__featured,
  hpFeaturedSection__image,
  hpFeaturedSection__title,
} from "../../index.module.scss";
import get from "lodash/get";
import { CarouselHomeFeatured } from "../../../../components/CarouselMulti/CarouselMulti";
import resolvePrismicLink from "../../../../utils/resolvePrismicLink";
import { PrismicRichText } from "@prismicio/react";
import cx from "classnames";
import i18n from "../../../../utils/i18n";
import CustomPrismicLink from "../../../../components/CustomPrismicLink";

const HomeFeaturedCarousel = ({ slice }) => {
  const items = get(slice, "items", []);

  return (
    <div className={`container-fluid ${hpFeaturedSection}`}>
      <div className={`container-xxl`}>
        {/*<h6 className={hpFeaturedSection__featured}>Featured</h6>*/}
        <CarouselHomeFeatured>
          {items.map((item, idx) => {
            const { link, target } = resolvePrismicLink(
              get(item, "button_link"),
            );
            return (
              <div className={`row`} key={idx}>
                <div
                  className={`[ col-lg-7 ] [ col-md-7 ] [ order-lg-first order-last ]`}
                >
                  <div className={hpFeaturedSection__image}>
                    <img
                      alt=""
                      src={get(item, "image.url", "")}
                      loading={"lazy"}
                    />
                  </div>
                </div>
                <div
                  className={`[ offset-lg-1 col-lg-4 ] [ col-md-8 ] [ d-flex flex-column ]`}
                >
                  <h4 className={hpFeaturedSection__featured}>
                    {i18n.t("Featured")}
                  </h4>
                  <div className={cx(hpFeaturedSection__title, `mt-0 mt-lg-5`)}>
                    <PrismicRichText field={get(item, "title", [])} />
                  </div>
                  <div
                    className={cx(
                      hpFeaturedSection__description,
                      `mb-3 mb-lg-auto`,
                    )}
                  >
                    <PrismicRichText field={get(item, "description", [])} />
                  </div>
                  <CustomPrismicLink
                    to={link}
                    title={get(item, "button_label", "")}
                    target={target}
                    className={`${hpFeaturedSection__button} btn btn-flex btn-outline-light`}
                  >
                    <span>{get(item, "button_label", "")}</span>
                  </CustomPrismicLink>
                </div>
              </div>
            );
          })}
        </CarouselHomeFeatured>
      </div>
    </div>
  );
};

export default HomeFeaturedCarousel;
