import React from "react";
import * as s from "./SubTitle.module.scss";

const SubTitle = ({ subTitle }) => {
  return (
    <>
      <h3 className={s.subTitle}>{subTitle}</h3>
    </>
    /*<PrismicRichText field={subTitle} />*/
  );
};

export default SubTitle;
