import React from "react";
import get from "lodash/get";
import { Link } from "gatsby";
import * as s from "../../../pages/locations/index.module.scss";
import {
  locationsEntry,
  locationsEntryImage,
} from "../../../pages/locations/index.module.scss";
import { isInArray } from "../../../utils/isInArray";
import teamSizeToMatch from "../../../utils/teamSizeToMatch";
import resizeImage from "../../../services/resizeImage";
import { getQueryParams } from "../../../utils";
import i18n from "../../../utils/i18n";
import formatPrice from "../../../utils/formarPrice";

const LocationBlock = ({
  setLocationHovered = () => null,
  location,
  cmsLocations = [],
}) => {
  const existingParams = getQueryParams();
  let newParams = new URLSearchParams();
  const results = get(location, "results", []);

  const prismicLocation = cmsLocations.find(
    (cmsLocation) =>
      location.space_id === get(cmsLocation, "data.location_id", ""),
  );
  if (!prismicLocation) return null;

  const dataBody = get(prismicLocation, "dataRaw.body", []);

  const cmsImagesBlock = dataBody.find(
    (item) => item.slice_type === "location_images",
  );

  const cmsImages = get(cmsImagesBlock, "items", []);

  const cmsImage = get(cmsImages, "[0].image.thumbnail.url", null);

  if (existingParams.get("team_size"))
    newParams.set("team_size", existingParams.get("team_size") || "");

  if (existingParams.get("space_type"))
    newParams.set("space_type", existingParams.get("space_type") || "");

  if (get(location, "price_min", 0))
    newParams.set("price_min", get(location, "price_min", 0));

  if (get(location, "price_min", 0))
    newParams.set("price_max", get(location, "price_min", 0));

  if (results.length > 0)
    newParams.set(
      "selected_blocks",
      results.map((result) => result.id).join(","),
    );

  const teamSize = existingParams.get("team_size") || 0;

  let url = get(prismicLocation, "url", "");

  if (existingParams.get("team_size")) {
    url += "?" + newParams.toString();
  }

  const options = get(location, "results", []).length;

  const image = resizeImage(
    location.image || location.cover_image,
    "600, 600, 600, 600",
  );

  const suTeamSize = get(location, "su_team_size", 1);
  const priceMin = get(location, "price_min", 0);
  const priceMax = get(location, "price_max", 0);

  let ddPrice = 0;

  if (location?.results) {
    ddPrice = Math.min(
      location?.results.map((item) => item.price_per_desk) || 0,
    );
  }

  const isDd = get(location, "type", "") === "dedicated desk";

  const hasPlusIcon = priceMax > priceMin ? "+" : "";

  return (
    <Link
      to={url}
      key={location.space_id}
      className={`location-box location-profile-page-link location-profile-link-${get(
        prismicLocation,
        "uid",
        "",
      )} ${locationsEntry}`}
      onMouseEnter={() => {
        setLocationHovered(location.space_id);
      }}
      onMouseLeave={() => {
        setLocationHovered("");
      }}
    >
      <div className="row">
        <div className="col-sm-6">
          <div className={locationsEntryImage}>
            <img alt="" src={cmsImage || image} />
          </div>
        </div>
        <div className="col-sm-6 d-flex flex-column">
          <h5>{get(prismicLocation, "data.location_title.text")}</h5>
          {teamSize ? (
            <p
              className={
                teamSize === "Team size" ||
                !isInArray(teamSizeToMatch, teamSize.toString())
                  ? "d-none"
                  : ""
              }
            >
              {isDd ? (
                <span className={"text-lowercase"}>
                  {suTeamSize}{" "}
                  {suTeamSize === 1
                    ? i18n.t("DedicatedDesk")
                    : i18n.t("DedicatedDesks")}
                </span>
              ) : (
                i18n.t("PrivateOffice")
              )}

              {!isDd ? (
                <>
                  &nbsp;&#8226; {options} option{options === 1 ? "" : "s"}{" "}
                  &#8226;{" "}
                  {location.capacity > 1 ? i18n.t("profilePage.UpTo") : ""}{" "}
                  {location.capacity}{" "}
                  {location.capacity === 1 ? "person" : "people"}
                </>
              ) : null}
            </p>
          ) : null}
          {teamSize ? (
            <h5
              className={
                teamSize === "Team size" ||
                !isInArray(teamSizeToMatch, teamSize.toString())
                  ? "d-none"
                  : `${s.price_min}`
              }
            >
              {isDd ? (
                <>
                  ${formatPrice(ddPrice)}
                  {hasPlusIcon}/{i18n.t("locationPage.desk")}
                  <br />
                </>
              ) : (
                ""
              )}
              ${isDd ? formatPrice(priceMin) : formatPrice(priceMin)}
              {hasPlusIcon}/{i18n.t("month")}
            </h5>
          ) : null}
        </div>
      </div>
    </Link>
  );
};

export default LocationBlock;
