import React from "react";
import * as s from "./Title.module.scss";

const Title = ({ title }) => {
  return (
    <div className={`${s.title} `}>
      <h1>{title}</h1>
    </div>
  );
};

export default Title;
