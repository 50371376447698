import React from "react";
import { aboutGallery } from "../../../../pages/whyiq/whyiq.module.scss";
import get from "lodash/get";
import { CarouselMulti } from "../../../../components/CarouselMulti/CarouselMulti";

const ImageCarousel = ({ slice }) => {
  return (
    <div>
      <div className={`container-fluid bg-primary ${aboutGallery}`}>
        <div className="container">
          <h3 className="text-white">{get(slice, "primary.title", "")}</h3>
        </div>
        <div className="container">
          <div className={`row`}>
            <div className={`offset-lg-1 col-lg-10`}>
              <CarouselMulti
                images={get(slice, "items", []).map(({ image }) => image.url)}
              ></CarouselMulti>
            </div>
          </div>
        </div>
        <div className="container">
          <h3 className="text-white pb-0">
            {get(slice, "primary.subtitle", "")}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
