import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import validator from "validator";
import { validationBookTour } from "../../utils/forms/submitBookATourForm";
import get from "lodash/get";
import { formError } from "../../styles/forms/forms.module.scss";
import { phoneNumber } from "../FormContact/FormContact.module.scss";
import { CustomSelectSingle } from "../CustomSelect/CustomSelect";
import { optionsPhoneNumberCountry } from "../FormContact/options";
import SubmitButton from "../../SubmitButton";
import { batForm, buttonDiv } from "./BookATourForm.module.scss";
import getBookTourCalendarInvitationBody from "../../utils/locationProfile/getBookTourCalendarInvitationBody";
import { format, parse } from "date-fns";
import getEmailListForBookTour from "../../utils/locationProfile/getEmailListForBookTour";
import getHubSpotContactData from "../../utils/locationProfile/getHubSpotContactData";
import gtm from "../../services/gtm";
import worksimplyApi from "../../services/worksimplyApi";
import { getQueryParams } from "../../utils";
import i18n from "../../utils/i18n";
import resolvePrismicLink from "../../utils/resolvePrismicLink";
import {
  bookTourProductTypes,
  industries,
  teamSizes,
} from "../../constants/hubSpotFieldValues";
import submitFormToHubSpot from "../../utils/submitFormToHubSpot";

const BookATourForm = ({
  setConfirmTour = () => null,
  time,
  tourDate,
  inline,
  profile,
  cmsProfile,
  selectedOption = {},
  lightBg = false,
}) => {
  const params = getQueryParams();
  const teamSize = params.get("team_size");

  const isDev = params.get("isDev");

  const [sent, setSent] = useState(false);
  const [progress, setProgress] = useState(false);

  const formik = useFormik({
    initialValues: {
      fullName: isDev ? "" : "",
      firstname: isDev ? "" : "",
      lastname: isDev ? "" : "",
      email: isDev ? "" : "",
      phoneNumberCountry: "CA",
      phoneNumber: isDev ? "" : "",
      companyName: isDev ? "" : "",
      industry: "",
      date: tourDate,
      time: time,
      tourOption: "",
      teamSize: teamSize,
      productInterest: "",
      additionalNotes: isDev ? "" : "",
      customTeamSize: "",
    },
    validationSchema: validationBookTour,
    onSubmit: (values) => {},
  });

  const isDisabled =
    !formik.values.firstname ||
    !formik.values.lastname ||
    !formik.values.email ||
    !validator.isEmail(formik.values.email) ||
    !formik.values.phoneNumber ||
    !formik.values.companyName ||
    !formik.values.customTeamSize?.value ||
    !formik.values.industry;

  const { link: redirectUrlAfterBookTour } = resolvePrismicLink(
    cmsProfile?.redirect_url_after_book_tour_form_submission || {},
  );

  const bookTour = async () => {
    const hsFormId = cmsProfile?.book_tour_hubspot_form_id;

    setProgress(true);

    const formattedValues = getFormattedValues(
      formik?.values,
      cmsProfile?.most_recent_source || "",
    );

    await submitFormToHubSpot(hsFormId, formattedValues);

    const move_in_date = "N/A";

    const payload = {
      space_id: get(profile, "id", ""),
      user_id: get(profile, "user_id", ""),
      calendar_type: get(profile, "calendar.calendar_type", ""),
      calendar_id: get(profile, "calendar.id", ""),
      name: `${formik?.values?.firstname} ${formik?.values?.lastname}`,
      email: get(formik, "values.email", "N/A"),
      company: get(formik, "values.companyName", "N/A"),
      industry: get(formik, "values.industry", "N/A"),
      tourOption: get(formik, "values.tourOption", "N/A"),
      date: tourDate,
      time,
      message: get(formik, "values.additionalNotes", "N/A"),
      time_zone: get(profile, "calendar.time_zone", ""),
      subject: `${get(profile, "contact_name", "N/A")} meets ${
        formik?.values?.firstname
      } ${formik?.values?.lastname} on ${format(
        parse(`${tourDate} ${time}`, `yyyy-MM-dd hh:mm a`, new Date()),
        "MMMM d, p",
      )} `,
      location: `${get(cmsProfile, "location_title[0].text", "")} - ${get(
        profile,
        "office_address.short_address",
      )}`,
      htmlBody: getBookTourCalendarInvitationBody(
        profile,
        selectedOption,
        formik.values,
        false,
      ),
      hubSpotAccount: "iq",
      price: 0,
      team_size:
        params.get("team_size") ||
        get(formik, "values.customTeamSize.label", "N/A"),
      is_team_size_set: !!params.get("team_size"),
      space_type: params.get("space_type") || "N/A",
      move_in_date,
      emailList: getEmailListForBookTour(
        profile,
        cmsProfile,
        formik.values,
        selectedOption,
        tourDate,
        time,
      ),
    };

    await worksimplyApi(`sp/book-tour`, {
      payload,
      maskSlotAsFree: true,
      additionalInvitees: cmsProfile.additional_book_tour_invitees,
      hbContact: getHubSpotContactData(
        selectedOption,
        formik.values,
        profile,
        "",
      ),
      hbNote: getBookTourCalendarInvitationBody(
        profile,
        selectedOption,
        formik.values,
        true,
      ),
    })
      .then(() => {
        // alert(result)
      })
      .catch((e) => {
        console.log(e);
      });

    gtm("ws_profile_book_tour", {
      team_size: params.get("team_size") || "N/A",
      location: get(cmsProfile, "location_title[0].text", "N/A"),
      space_type:
        params.get("space_type") || params.get("team_size")
          ? "Private Office"
          : "N/A",
    });
    setProgress(false);
    if (process.env.NODE_ENV !== "development" && redirectUrlAfterBookTour) {
      setSent(true);
      if (redirectUrlAfterBookTour) {
        window.location.href = redirectUrlAfterBookTour;
      }
    }
  };

  return (
    <form
      className={`d-flex flex-column w-100 pb-5 mb-5 ${batForm}`}
      onSubmit={formik.handleSubmit}
    >
      <div>
        <input
          id="firstname"
          name="firstname"
          type="text"
          className={`form-control ${
            formik.touched.firstname && formik.errors.firstname ? formError : ""
          }`}
          placeholder={i18n.t("contactPage.FirstName")}
          {...formik.getFieldProps("firstname")}
        />
      </div>
      <div className={"overlap-top overlap-bottom"}>
        <input
          id="lastname"
          name="lastname"
          type="text"
          className={`form-control ${
            formik.touched.lastname && formik.errors.lastname ? formError : ""
          }`}
          placeholder={i18n.t("contactPage.LastName")}
          {...formik.getFieldProps("lastname")}
        />
      </div>
      <div className={""}>
        <input
          id="email"
          name="email"
          type="text"
          className={`form-control  ${
            formik.touched.email && formik.errors.email ? formError : ""
          }`}
          placeholder={i18n.t("contactPage.Email")}
          {...formik.getFieldProps("email")}
        />
      </div>
      <div className="position-relative overlap-top">
        <div className={phoneNumber}>
          <span></span>
          <CustomSelectSingle
            // isMenuOpen
            phoneNumber
            options={optionsPhoneNumberCountry}
            value={formik.values.phoneNumberCountry}
            onChange={(value) =>
              formik.setFieldValue("phoneNumberCountry", value.value)
            }
          />
        </div>
        <input
          id="phoneNumber"
          name="phoneNumber"
          type="phoneNumber"
          className={`form-control ${
            formik.touched.phoneNumber && formik.errors.phoneNumber
              ? formError
              : ""
          }`}
          placeholder={i18n.t("contactPage.PhoneNumber")}
          {...formik.getFieldProps("phoneNumber")}
        />
      </div>
      <div className={""}>
        <input
          id="companyName"
          name="companyName"
          type="text"
          className={`form-control ${
            formik.touched.companyName && formik.errors.companyName
              ? formError
              : ""
          }`}
          placeholder={i18n.t("CompanyName")}
          {...formik.getFieldProps("companyName")}
        />
      </div>
      <div className={"overlap-top overlap-bottom"}>
        <CustomSelectSingle
          options={industries()}
          value={formik.values.industry}
          onChange={(value) => formik.setFieldValue("industry", value.value)}
          placeholder={i18n.t("profilePage.Industry")}
        />
      </div>
      <div>
        <CustomSelectSingle
          options={bookTourProductTypes()}
          value={formik.values.productInterest}
          onChange={(value) => {
            formik.setFieldValue("productInterest", value);
          }}
          placeholder={i18n.t("ProductInterestedIn")}
        />
      </div>

      <div className={"mb-3 overlap-top"}>
        <CustomSelectSingle
          options={teamSizes()}
          value={formik.values.customTeamSize}
          onChange={(value) => {
            formik.setFieldValue("customTeamSize", value);
          }}
          placeholder={i18n.t("locationPage.teamSize")}
        />
      </div>

      <div className={"mb-3"}>
        <textarea
          id="additionalNotes"
          name="additionalNotes"
          className={`form-control overlap-bottom ${
            formik.touched.companyName && formik.errors.companyName
              ? formError
              : ""
          }`}
          style={{ minHeight: lightBg ? "100px" : "200px" }}
          placeholder={i18n.t("profilePage.AdditionalNotes")}
          {...formik.getFieldProps("additionalNotes")}
        />
      </div>
      {!inline ? (
        <div className={`d-flex justify-content-between`}>
          <SubmitButton
            className={"mb-3 bookatourclasses"}
            label={"Submit"}
            variant={"light"}
            onClick={bookTour}
            progress={progress}
            disabled={isDisabled}
            success={sent}
          />
          <Button
            variant="outline-light"
            className={"mb-3 d-block"}
            onClick={() => setConfirmTour(false)}
          >
            Back
          </Button>
        </div>
      ) : (
        <div className={`${buttonDiv}`}>
          <SubmitButton
            label={"Submit"}
            onClick={bookTour}
            variant={"outline-primary"}
            progress={progress}
            disabled={isDisabled}
            success={sent}
            className={"bookatourclasses"}
          />
        </div>
      )}
    </form>
  );
};

export default BookATourForm;

const getFormattedValues = (values = {}, mostRecentSource = "") => {
  const {
    firstname,
    lastname,
    email,
    phoneNumber,
    companyName,
    industry,
    productInterest,
    customTeamSize,
    additionalNotes,
  } = values;

  return [
    { name: "firstname", value: firstname },
    { name: "lastname", value: lastname },
    { name: "email", value: email },
    { name: "phone", value: phoneNumber },
    { name: "company", value: companyName },
    { name: "company_industry", value: industry?.value || "" },
    { name: "membership_product", value: productInterest?.value || "" },
    { name: "teamsize", value: customTeamSize?.value || "" },
    { name: "message", value: additionalNotes },
    { name: "most_recent_source", value: mostRecentSource },
  ];
};
