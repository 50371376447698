import React, { useEffect, useState } from "react";
import HorizontalLogo from "../../../images/logo-horizontal-white.svg";
import SquareLogo from "../../../images/logo-square-white.svg";
import HorizontalLogoFr from "../../../images/logos/fr-logos/EspaceiQ_horizontal_white.svg";
import SquareLogoFr from "../../../images/logos/fr-logos/EspaceiQ_square_white.svg";
import { Container, Image, Navbar } from "react-bootstrap";
import HeaderTabletMobile from "./HeaderTabletMobile";
import HeaderDesktop from "./HeaderDesktop";
import get from "lodash/get";
import {
  backgroundPrimary,
  navbar,
  navbar__sticky,
  navbar__stickyNoPromoBar,
  navbar__stickyWithPromoBar,
  navHome,
  navLogo,
} from "./Nav.module.scss";
import { Link } from "gatsby";
import cx from "classnames";

const Header = ({ activeDocMeta, pages, home, promoBar, bookTour }) => {
  const [isActive, setIsActive] = useState(false);

  // const isEnglish = i18n.t("lang") !== "fr";
  const isEnglish = get(activeDocMeta, "lang", "") !== "fr-ca";

  const [isTransparentMenu, setIsTransparentMenu] = useState(false);
  const handleScroll = () => {
    // setIsTransparentMenu(window.scrollY === 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const desktopLogo =
    get(activeDocMeta, "lang", "") === "en-us"
      ? HorizontalLogo
      : HorizontalLogoFr;
  const mobileLogo =
    get(activeDocMeta, "lang", "") === "en-us" ? SquareLogo : SquareLogoFr;

  return (
    <Navbar
      className={cx(navbar, {
        [`${navbar} ${isTransparentMenu ? navHome : ""} ${
          isActive ? backgroundPrimary : ""
        } ${promoBar ? navbar__stickyWithPromoBar : navbar__stickyNoPromoBar}`]:
          home,
        [navbar__sticky]: !home,
        "d-print-none": true,
      })}
      // sticky={width >= breakpoints.md ? "top" : false}
      // fixed={width < breakpoints.md ? false : "bottom"}
      expand={false}
      onToggle={() => setIsActive(!isActive)}
    >
      <Container fluid={"xxl"}>
        <Navbar.Brand as="div">
          <Link to={isEnglish ? "/" : "/fr"} title={"Home page"}>
            <img
              src={desktopLogo}
              className={cx({
                "order-xl-first order-2 d-none d-xxl-block": true,
                [navLogo]: true,
              })}
              alt="iQ office logo"
            />

            <Image
              src={mobileLogo}
              className={`order-xl-first order-2 d-block d-xxl-none ${navLogo}`}
              width={52}
              alt="iQ office logo"
            />
          </Link>
        </Navbar.Brand>

        <div className={"d-none d-xl-block"}>
          <HeaderDesktop
            pages={pages}
            activeDocMeta={activeDocMeta}
            bookTour={bookTour}
          />
        </div>
        <HeaderTabletMobile
          isActive={isActive}
          pages={pages}
          activeDocMeta={activeDocMeta}
          promoBar={promoBar}
          bookTour={bookTour}
        />
      </Container>
    </Navbar>
  );
};

export default Header;
