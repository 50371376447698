import React from "react";
import get from "lodash/get";
import {
  poppyInterviewBanner,
  poppyInterviewContent,
  poppyInterviewContent__video,
} from "../../../../pages/whyiq/whyiq.module.scss";
import { PrismicRichText } from "@prismicio/react";

const VideoSection = ({ slice }) => {
  const videos = get(slice, "items", []);
  return (
    <div className={poppyInterviewBanner}>
      <div className={`container-xxl text-center`}>
        <div className={` ${poppyInterviewContent}`}>
          <PrismicRichText field={get(slice, "primary.title", [])} />
          {videos.map((video, idx) => (
            <div className={`row justify-content-center`} key={idx}>
              <div className={`col-xl-6 col-lg-7 col-md-8 col-sm-10`}>
                <div className={poppyInterviewContent__video}>
                  <iframe
                    src={`https://www.youtube-nocookie.com/embed/${get(
                      video,
                      "youtube_video_id",
                    )}`}
                  />
                </div>
              </div>
              <h5 className="text-center">{get(video, "name", "")}</h5>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
