import React from "react";
import { Button, Modal } from "react-bootstrap";
import * as s from "./SuccessModal.module.scss";
import i18n from "../../utils/i18n";
import { Link } from "gatsby";
import ProgressDots from "../ProgressDots";

const SuccessModal = ({
  show,
  hide,
  loginLink = null,
  isMeetingRoomsSuccessModal = false,
  creditCardError = false,
  creditCardErrorMessage,
  mrNoCardLink,
  mrNoCard,
  hasCheckoutErrors = false,
  timeoutError = false,
}) => {
  return (
    <Modal
      show={show}
      centered
      size={"lg"}
      className={`${s.modal} modal-z-index`}
      contentClassName={s.modalContent}
      keyboard={false}
    >
      <Modal.Body>
        <div className={`container-xxl`}>
          {isMeetingRoomsSuccessModal ? (
            <MeetingRoomsContent
              hide={hide}
              creditCardError={creditCardError}
              loginLink={loginLink}
              mrNoCardLink={mrNoCardLink}
              mrNoCard={mrNoCard}
              hasCheckoutErrors={hasCheckoutErrors}
              timeoutError={timeoutError}
            />
          ) : (
            <VoContent
              loginLink={loginLink}
              hide={hide}
              creditCardError={creditCardError}
              creditCardErrorMessage={creditCardErrorMessage}
              timeoutError={timeoutError}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;

const MeetingRoomsContent = ({
  hide = () => null,
  creditCardError = false,
  loginLink = null,
  mrNoCardLink = null,
  mrNoCard = false,
  hasCheckoutErrors = false,
}) => {
  if (hasCheckoutErrors) {
    return (
      <div className={`${s.modalContent__thankYouDiv} text-center`}>
        <h2 className={s.modalContent__title}>
          {i18n.t("mr.checkoutErrors.title")}
        </h2>
        <p>{i18n.t("mr.checkoutErrors.message")}</p>
        <br />
        <a className={"btn btn-outline-light"} href={mrNoCardLink}>
          <span>{i18n.t("mr.checkoutErrors.buttonLabel")}</span>
        </a>
      </div>
    );
  }
  // if (mrNoCardLink) {
  //   return (
  //     <div className={`${s.modalContent__thankYouDiv} text-center`}>
  //       <h2 className={s.modalContent__title}>{i18n.t("mr.noCard.title")}</h2>
  //       <p>{i18n.t("mr.noCard.message")}</p>
  //       <br />
  //       <a className={"btn btn-outline-light"} href={mrNoCardLink}>
  //         <span>{i18n.t("mr.noCard.buttonLabel")}</span>
  //       </a>
  //     </div>
  //   );
  // }
  return (
    <div className={s.modalContent__thankYouDiv}>
      {!loginLink && (
        <h2 className={s.modalContent__creatingOrderInvoice}>
          {i18n.t(mrNoCard ? "Redirecting" : "CreatingOrderInvoice")}
          <div className="position-relative mt-3">
            <ProgressDots darkBg={true} />
          </div>
        </h2>
      )}
      {loginLink && (
        <>
          <h2 className={s.modalContent__title}>
            {creditCardError
              ? i18n.t("mr.cardError.popUpTitle")
              : i18n.t("mr.success.popUpTitle")}
          </h2>
          <div
            className={s.modalContent__description}
            dangerouslySetInnerHTML={{
              __html: creditCardError
                ? i18n.t("mr.cardError.popUpMessage")
                : i18n.t("mr.success.popUpMessage"),
            }}
          />

          {!creditCardError ? (
            <>
              {/*<div className="mt-3 text-center">*/}
              {/*  <Link className={"btn btn-outline-light"} to={redirectUrl}>*/}
              {/*    <span>Go to Homepage</span>*/}
              {/*  </Link>*/}
              {/*</div>*/}
              <div className="text-center mt-3">
                <Link
                  to={`${window?.location?.pathname || "why-iq"}`}
                  variant={"link"}
                  size={"sm"}
                  className={"py-0 text-white"}
                >
                  Close
                </Link>
              </div>
            </>
          ) : (
            <a
              className={"btn btn-outline-light"}
              href={mrNoCardLink || loginLink}
              target={"_black"}
              onClick={hide}
            >
              <span>{i18n.t("vo.popUpButtonLabel")}</span>
            </a>
          )}
        </>
      )}
    </div>
  );
};

const VoContent = ({
  loginLink = null,
  hide = () => null,
  creditCardError = false,
  timeoutError = false,
}) => {
  if (timeoutError) {
    return (
      <div>
        <div className={s.modalContent__thankYouDiv}>
          <h2 className={s.modalContent__title}>
            {creditCardError
              ? i18n.t("vo.cardError.popUpTitle")
              : i18n.t("vo.success.popUpTitle")}
          </h2>
          <div
            className={s.modalContent__description}
            dangerouslySetInnerHTML={{
              __html: i18n.t("TimeoutErrorMessage"),
            }}
          />
          <div className="text-center">
            <a
              className={"btn btn-outline-light"}
              href={`https://iq-offices.officernd.com/billing/details/`}
              target={"_blank"}
              rel={"noreferrer"}
              onClick={hide}
            >
              <span>{i18n.t("vo.popUpButtonLabel")}</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      {loginLink ? (
        <div className={s.modalContent__thankYouDiv}>
          <h2 className={s.modalContent__title}>
            {creditCardError
              ? i18n.t("vo.cardError.popUpTitle")
              : i18n.t("vo.success.popUpTitle")}
          </h2>
          <div
            className={s.modalContent__description}
            dangerouslySetInnerHTML={{
              __html: creditCardError
                ? i18n.t("vo.cardError.popUpMessage")
                : i18n.t("vo.success.popUpMessage"),
            }}
          />
          <div className="text-center">
            {!!creditCardError ? (
              <a
                className={"btn btn-outline-light"}
                href={loginLink}
                target={"_blank"}
                rel={"noreferrer"}
                onClick={hide}
              >
                <span>{i18n.t("vo.popUpButtonLabel")}</span>
              </a>
            ) : (
              <div className="text-center mt-3">
                <Button
                  onClick={hide}
                  variant={"link text-white"}
                  size={"sm"}
                  className={"py-0"}
                >
                  Close
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <h2 className={s.modalContent__creatingOrderInvoice}>
          {i18n.t("CreatingOrderInvoice")}
          <div className="position-relative mt-3">
            <ProgressDots darkBg={true} />
          </div>
        </h2>
      )}
    </>
  );
};
