import * as React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"; // import { SliceZone } from "@prismicio/react";
import get from "lodash/get";

import Layout from "../../components/Layout";
import activeDocMeta from "../utils/activeDocMeta";
import OverviewItems from "../../components/OverviewItem/OverviewItems";
import FaqSection from "../WhyIqPages/components/Faq";
import { SliceZone } from "@prismicio/react";

import ProductWidget from "../../components/Products/ProductWidget";

import ProductHero from "../../components/Products/ProductHero";
import BookNow from "./components/BookNow";
import ProductInfo from "../../components/Products/ProductInfo";
import HeroFooter from "../../components/Hero/HeroFooter";
import ProductWhatsIncluded from "../../components/Products/ProductWhatsIncluded";
import ProductSummary from "../../components/Products/ProductSummary";
import FullWidthTextSection from "../Products/components/FullWidthTextSection";
import StickyContact from "../../components/StickyContact/StickyContact";
import VrSection from "./components/VrSection";

const EventVenuePageTemplate = ({ data = {}, pageContext }) => {
  const pageData = get(data, "prismicEventVenue.dataRaw", {});

  const slices = pageData.body;

  return (
    <Layout
      activeDocMeta={activeDocMeta(get(data, "prismicEventVenue", {}))}
      pages={{
        whyIqPages: get(data, "allPrismicWhyIqPages.nodes", []),
        productPages: get(data, "allPrismicProductPages.nodes", []),
        customPages: get(data, "allPrismicCustomPage.nodes", []),
        whyIqLandingPage: get(data, "allPrismicWhyIqLanding.nodes", []),
        productsLandingPage: get(
          data,
          "allPrismicProductsLandingPage.nodes",
          [],
        ),
      }}
      subscriptionModalData={data?.prismicHomePage?.data || {}}
    >
      <ProductHero
        images={get(pageData, "images", []).map((item) =>
          get(item, "image.url", ""),
        )}
        venueSubPage
        title={get(pageData, "hero_section_title[0].text", "")}
      />
      <SliceZone
        slices={slices}
        context={{ eventVenues: true }}
        components={{
          product_features: ProductInfo,
          image_block: OverviewItems,
          faqs: FaqSection,
          image: HeroFooter,
          features_with_image_carousel: ProductSummary,
          search_component: ProductWidget,
          features_list: ProductWhatsIncluded,
          full_width_text_section: FullWidthTextSection,
          book_now_section: BookNow,
          contact_bar: StickyContact,
          vr_section: VrSection,
        }}
      />
    </Layout>
  );
};

export const query = graphql`
  query eventVenuePageTemplateQuery($lang: String, $uid: String) {
    prismicEventVenue(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      lang
      type
      url
      uid
      id
      alternate_languages {
        uid
        type
        lang
      }
      dataRaw
    }
    prismicHomePage(lang: { eq: $lang }) {
      _previewable
      data {
        subscription_form_title
        subscription_form_description
        subscription_form_button_label
        subscription_footer_button_label
        blog_page_modal_display_delay
      }
    }
    allPrismicWhyIqLanding(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicProductsLandingPage(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicProductPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        _previewable
        data {
          menu_link_name
          menu_link_description
          product_type
          display_in_header_menu
        }
      }
    }
    allPrismicWhyIqPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        _previewable
        data {
          display_in_main_menu
          menu_link_name
          menu_link_description
          menu_link_image {
            url
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 192, formats: AUTO, height: 128)
              }
            }
          }
        }
      }
    }
    allPrismicCustomPage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        uid
        lang
        url
        dataRaw
        data {
          main_menu_order
          link_label
          show_in_main_menu
        }
      }
    }
  }
`;

export default withPrismicPreview(EventVenuePageTemplate);
