import React from "react";
import {
  bgOffwhite,
  imageC2c,
  imageC2c2,
  imageMobileReady,
  imageMobileReady2,
  meOviSection,
  meOviSection__description,
  meOviSection__iconsContainer,
  meOviSection__iconsContainer__iconContainer,
  meOviSection__title,
} from "./MembershipsOverviewItem.module.scss";
import * as s from "./MembershipsOverviewItem.module.scss";
import get from "lodash/get";
import { PrismicRichText } from "@prismicio/react";
import cx from "classnames";

const MembershipsOverviewItem = ({ slice }) => {
  const list = get(slice, "items", []);

  const secondary = get(slice, "slice_type", "") === "features_with_images";

  const title = get(slice, "primary.title", []);

  const frontImage = get(slice, "primary.front_image.url", "");
  const rearImage = get(slice, "primary.rear_image.url", "");

  const description = get(slice, "primary.description", []);

  return (
    <div
      className={`container-xxl ${meOviSection} ${secondary ? bgOffwhite : ""}`}
    >
      <div className={"d-none d-lg-block"}>
        <div className={`row`}>
          <div
            className={` position-relative ${
              !secondary
                ? `col-lg-6`
                : `col-lg-5 order-lg-2 offset-lg-1 d-flex align-items-center`
            }`}
          >
            {secondary ? (
              <div className={s.imageContainer}>
                <img alt="" src={rearImage} />
                {/*<img className={imageC2c2} alt="" src={frontImage} />*/}
              </div>
            ) : (
              <>
                <img className={imageMobileReady} alt="" src={rearImage} />
                <img className={imageMobileReady2} alt="" src={frontImage} />
              </>
            )}
          </div>
          <div
            className={` [ d-flex flex-column justify-content-center ] ${
              !secondary ? `offset-lg-1 col-lg-5 ` : ` col-lg-4 offset-lg-1 `
            }`}
          >
            {/*<h2 className={meOviSection__title}>{title}</h2>*/}
            <div className={meOviSection__title}>
              <PrismicRichText field={title} />
            </div>

            {/*<p className={meOviSection__description}>{description}</p>*/}
            <div className={meOviSection__description}>
              <PrismicRichText field={description} />
            </div>
            <div className={`d-flex ${meOviSection__iconsContainer}`}>
              <IconList list={list} />
            </div>
          </div>
        </div>
      </div>
      <div className={"d-block d-lg-none"}>
        <div className={`d-flex flex-column`}>
          <div className={meOviSection__title}>
            <PrismicRichText field={title} />
          </div>
          <div className={meOviSection__description}>
            <PrismicRichText field={description} />
          </div>
          <div className={`row`}>
            <div className={`col-md-6 position-relative`}>
              {secondary ? (
                <div className={s.imageContainer}>
                  <img alt="" src={rearImage} />
                  {/*<img className={imageC2c2} alt="" src={frontImage} />*/}
                </div>
              ) : (
                <>
                  <img className={imageMobileReady} alt="" src={rearImage} />
                  <img className={imageMobileReady2} alt="" src={frontImage} />
                </>
              )}
            </div>
            <div
              className={`[ offset-md-1 col-md-5 ] [ d-flex flex-column justify-content-center align-items-center ] [ order-first order-md-last ]`}
            >
              <IconList list={list} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipsOverviewItem;

const IconList = ({ list = [] }) => {
  return (
    <>
      {list.map((item, index) => (
        <div
          className={meOviSection__iconsContainer__iconContainer}
          key={index}
        >
          <img alt={get(item, "text", "")} src={get(item, "icon.url", "")} />
          <p>{get(item, "text", "")}</p>
        </div>
      ))}
    </>
  );
};
