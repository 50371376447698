import * as React from "react";
import { Link } from "gatsby";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";
import { PrismicProvider } from "@prismicio/react";
import { repositoryConfigs } from "./src/utils/prismic/previews";
import { linkResolver } from "./src/utils/prismic/linkResolver";

import "./src/utils/i18n";

import "./src/styles/fonts/fonts.css";
import "./src/styles/base.scss";

import "react-image-lightbox/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "simplebar-react/dist/simplebar.min.css";
import "./src/styles/forms/datepicker.overrides.scss";

export const WrapWithProviders = ({ children }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      {children}
    </PrismicPreviewProvider>
  </PrismicProvider>
);

export default WrapWithProviders;
