import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import get from "lodash/get";
import Layout from "../../components/Layout";
import activeDocMeta from "../utils/activeDocMeta";
import HomePageCarousel from "./components/HomePageCarousel";
import HomeHeroSection from "./components/HomeHeroSection";
import HomeFeaturedCarousel from "./components/FeaturedCarousel";
import HomeProductsSection from "./components/ProductsSection";
import HomeAboutSection from "./components/HomeAboutSection";
import HomeMapSection from "./components/HomeMapSection";
import HomePageClientCarousel from "./components/HomePageClientCarousel";

const HomepageTemplate = ({ data = {} }) => {
  const slices = get(data, "prismicHomePage.dataRaw.body", []);

  return (
    <Layout
      activeDocMeta={activeDocMeta(get(data, "prismicHomePage", {}))}
      home
      modalData={get(data, "prismicHomePage.dataRaw", {})}
      pages={{
        whyIqPages: get(data, "allPrismicWhyIqPages.nodes", []),
        productPages: get(data, "allPrismicProductPages.nodes", []),
        customPages: get(data, "allPrismicCustomPage.nodes", []),
        whyIqLandingPage: get(data, "allPrismicWhyIqLanding.nodes", []),
        productsLandingPage: get(
          data,
          "allPrismicProductsLandingPage.nodes",
          [],
        ),
      }}
      subscriptionModalData={data?.prismicHomePage?.data || {}}
    >
      <HomeHeroSection
        title={
          <PrismicRichText
            field={get(data, "prismicHomePage.dataRaw.hero_section_title", [])}
          />
        }
        bgImage={get(
          data,
          "prismicHomePage.dataRaw.hero_section_image.url",
          null,
        )}
        bgImage2={get(
          data,
          "prismicHomePage.dataRaw.hero_section_image_2.url",
          null,
        )}
      />
      <SliceZone
        slices={slices}
        components={{
          map_section: HomeMapSection,
          clients_carousel: HomePageClientCarousel,
          why_choose_us_section: HomeAboutSection,
          featured_carousel: HomeFeaturedCarousel,
          featured_products_section: HomeProductsSection,
          image_carousel: HomePageCarousel,
        }}
      />
    </Layout>
  );
};

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomePage(lang: { eq: $lang }) {
      _previewable
      lang
      type
      url
      alternate_languages {
        uid
        type
        lang
      }
      dataRaw
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        subscription_form_title
        subscription_form_description
        subscription_form_button_label
        subscription_footer_button_label
        blog_page_modal_display_delay
      }
    }
    allPrismicWhyIqLanding(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicProductsLandingPage(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicProductPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        _previewable
        data {
          menu_link_name
          menu_link_description
          product_type
          display_in_header_menu
        }
      }
    }
    allPrismicWhyIqPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        _previewable
        data {
          display_in_main_menu
          menu_link_name
          menu_link_description
          menu_link_image {
            url
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 192, formats: AUTO, height: 128)
              }
            }
          }
        }
      }
    }
    allPrismicCustomPage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        uid
        lang
        url
        dataRaw
        data {
          main_menu_order
          link_label
          show_in_main_menu
        }
      }
    }
  }
`;

export default withPrismicPreview(HomepageTemplate);
