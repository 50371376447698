import React from "react";
import * as st from "./Hero.module.scss";
import get from "lodash/get";
import cx from "classnames";
import * as s from "../../templates/WhyIqPages/components/FullWidthText/index.module.scss";

const HeroFooter = ({ image, slice }) => {
  const sliceImage = get(slice, "primary.image.url", null);
  if (!sliceImage && !image) return null;
  return (
    <div
      className={cx(
        {
          [s.addTopMargin]: get(slice, "primary.add_top_margin", true),
          [s.addBottomMargin]: get(slice, "primary.add_bottom_margin", true),
        },
        `container-lg px-0 px-lg-3`,
      )}
    >
      <div className={st.heroFooterImageContainer}>
        <img src={sliceImage || image} alt="" loading={"lazy"} />
      </div>
    </div>
  );
};

export default HeroFooter;
