import get from "lodash/get";
import worksimplyApi from "../../services/worksimplyApi";
import { getQueryParams } from "../index";
import isLargerTeamSize from "./isLargerTeamSize";
import gtm from "../../services/gtm";

const getLocationsList = async (cmsLocations = []) => {
  if (typeof window === "undefined") return [];
  const params = getQueryParams();

  let teamSize = params.get("team_size") || 0;

  if (isLargerTeamSize()) {
    teamSize = 0;
  }

  const reqData = {
    space_operator_id: process.env.GATSBY_SO_ID,
    params: {
      current_staff: teamSize,
      location: params.get("location") || "Toronto",
      space_type: params.get("space_type") || "Private Office",
    },
  };

  const apiResponse = await worksimplyApi("results", reqData);

  if (apiResponse.scores.length === 0) {
    return [];
  }

  const matchedResults = get(apiResponse, "scores", []);

  const filteredScores = matchedResults
    .map((apiLocation) => {
      const cmsLocation = cmsLocations.find(
        (cmsLocation) =>
          apiLocation.space_id === get(cmsLocation, "data.location_id", ""),
      );

      if (!cmsLocation) return null;

      return {
        ...apiLocation,
        cmsLocation,
      };
    })
    .filter((item) => item);

  gtm("ws_locations_search", {
    team_size: params.get("team_size") || "N/A",
    city: params.get("location") || "N/A",
    space_type: params.get("space_type") || "N/A",
    number_of_results: filteredScores ? filteredScores.length : 0,
  });

  return filteredScores;
};

export default getLocationsList;
