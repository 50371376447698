import React, { useEffect, useState } from "react";
import { batBody, batTimeButtonsContainer } from "./BookATour.module.scss";
import DatePicker from "react-datepicker";
import { Button } from "react-bootstrap";
import BookATourForm from "./BookATourForm";
import { addHours, addWeeks, endOfWeek, format, isWeekend } from "date-fns";
import getTimeSlotsForDate from "../../utils/locationProfile/getTimeSlotsForDate";
import get from "lodash/get";
import i18n from "../../utils/i18n";

export const timezoneString = (tz = "America/Toronto") => {
  switch (tz) {
    case "America/Toronto":
      return "EST";
    case "America/Vancouver":
      return "PDT";
    default:
      return "EST";
  }
};

const BookATour = ({
  profile,
  busyTimes,
  selectedOption,
  cmsProfile,
  availableProducts,
}) => {
  const availableWeeks = get(profile, "calendar.available_dates", 1);
  const notice = get(profile, "calendar.notice", 1);
  const [tourDate, setTourDate] = useState("");
  const [tourTime, setTourTime] = useState("");
  const [availableTimes, setAvailableTimes] = useState([]);

  const [confirmTour, setConfirmTour] = useState(false);

  const profileTimezone = get(profile, "timezone", "America/Toronto");

  useEffect(() => {
    const timeSlots = getTimeSlotsForDate(
      tourDate,
      get(profile, "calendar", {}),
      busyTimes,
    );
    setAvailableTimes(timeSlots);
  }, [tourDate]);

  return (
    <div className={batBody}>
      <h5 className={"m-0 text-white"}>
        {i18n.t("profilePage.TourDuration")}:{" "}
        {get(profile, "calendar.duration", "30")} minutes
      </h5>
      <p className={"mt-1 mb-4"}>
        {!confirmTour ? (
          i18n.t("Please select a date and time that works best for you")
        ) : (
          <>
            Meet with {get(profile, "contact_name", "")} at{" "}
            {get(cmsProfile, "location_title[0].text", "")}
            <br />
            {tourTime}, {format(tourDate, "EEEE, MMMM dd, yyyy")}
          </>
        )}
      </p>

      <div className="d-md-flex">
        {!confirmTour ? (
          <>
            <div>
              <DatePicker
                formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 1)}
                minDate={addHours(new Date(), notice)}
                maxDate={endOfWeek(addWeeks(new Date(), availableWeeks || 1))}
                inline
                selected={tourDate}
                onChange={(date) => {
                  setTourDate(date);
                }}
                calendarClassName={"customIQ py-0 px-2"}
                filterDate={(date) => !isWeekend(date)}
              />
              <p className={"mt-1 mb-3"}>
                {i18n.t("Timezone")}: {timezoneString(profileTimezone)}
              </p>
            </div>
            <div className={`pb-5 ${batTimeButtonsContainer}`}>
              {availableTimes.length > 0 ? (
                availableTimes.map((time, index) => (
                  <Button
                    variant="light"
                    key={index}
                    onClick={() => {
                      // Change the layout to the contact form with the fields
                      // tourDate & time
                      setTourTime(time);
                      setConfirmTour(true);
                    }}
                  >
                    <span>{time}</span>
                  </Button>
                ))
              ) : (
                <small className={"text-muted d-block mb-5"}>
                  {tourDate
                    ? i18n.t("NoTimeslotsAvailableForThisDate")
                    : i18n.t("PleasePickADateFirst")}
                </small>
              )}
            </div>
          </>
        ) : (
          <BookATourForm
            profile={profile}
            cmsProfile={cmsProfile}
            selectedOption={selectedOption}
            setConfirmTour={setConfirmTour}
            tourDate={tourDate ? format(tourDate, "yyyy-MM-dd") : null}
            time={tourTime}
            availableProducts={availableProducts}
          />
        )}
      </div>
    </div>
  );
};

export default BookATour;
