import React from "react";
import {
  divider,
  dividerFull,
  dividerFullSection,
} from "./Divider.module.scss";

const Divider = ({ fullWidth, fluidWidth, slice }) => {
  return (
    <>
      {!fluidWidth ? (
        <div className={"container-xxl"}>
          <div className={fullWidth ? dividerFull : divider}></div>
        </div>
      ) : (
        <div className={`container-fluid ${dividerFullSection}`}>
          <div className={dividerFull}></div>
        </div>
      )}
    </>
  );
};

export default Divider;
