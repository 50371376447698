import React, { useState } from "react";
import MembershipsPerksModal from "../MembershipsPerksModal/MembershipsPerksModal";
import {
  omSection,
  omSection__description,
  omSection__footerNote,
  omSection__iconsContainer,
  omSection__iconsContainer__iconContainer,
  omSection__image,
  omSection__imageDesc,
  omSection__perksLink,
  omSection__productsContainer,
  omSection__productsContainer__productButton,
  omSection__title,
} from "./index.module.scss";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";
import resolvePrismicLink from "../../utils/resolvePrismicLink";
import { Link } from "gatsby";
import RightArrowWhite from "../../images/design-components/Right_White.svg";

const MembershipSection = ({ slice }) => {
  const [perksModalShow, setPerksModalShow] = useState(false);

  const link1 = resolvePrismicLink(get(slice, "primary.secondary_link_1", {}));
  const link1Label = get(slice, "primary.secondary_link_1_label", "");

  const link2 = resolvePrismicLink(get(slice, "primary.secondary_link_2", {}));
  const link2Label = get(slice, "primary.secondary_link_2_label", "");

  const link3 = resolvePrismicLink(get(slice, "primary.secondary_link_3", {}));
  const link3Label = get(slice, "primary.secondary_link_3_label", "");

  const link4 = resolvePrismicLink(get(slice, "primary.secondary_link_4", {}));
  const link4Label = get(slice, "primary.secondary_link_4_label", "");

  const list = get(slice, "items", []);

  return (
    <>
      <div className={`container-lg`}>
        <div className={omSection}>
          <div className={`row justify-content-center`}>
            <div className="col-11 col-xl-10">
              <div className={omSection__title}>
                <PrismicRichText field={get(slice, "primary.title", [])} />
              </div>
              <div className={`row`}>
                <div className={`col-lg-6 pe-lg-5 d-flex flex-column`}>
                  <div className={omSection__description}>
                    <PrismicRichText
                      field={get(slice, "primary.description", [])}
                    />
                  </div>
                  <div className={`${omSection__iconsContainer}`}>
                    <div className="row">
                      {list.slice(0, 6).map((item, idx) => (
                        <div className="col-sm-6" key={idx}>
                          <div
                            className={omSection__iconsContainer__iconContainer}
                          >
                            <img alt={""} src={get(item, "icon.url", "")} />
                            <p>{get(item, "title")}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={omSection__perksLink}>
                    <button
                      className={`btn-link`}
                      onClick={() => setPerksModalShow(true)}
                    >
                      <div className={`d-flex`}>
                        {/*<span className="arrow-right-white"></span>*/}
                        <img alt="" src={RightArrowWhite} />
                        <p>{get(slice, "primary.feature_list_label", "")}</p>
                      </div>
                    </button>
                  </div>

                  <MembershipsPerksModal
                    show={perksModalShow}
                    setPerksModalShow={setPerksModalShow}
                    items={get(slice, "items", [])}
                    onHide={() => setPerksModalShow(false)}
                    modalText={get(slice, "primary.feature_modal_text", "")}
                  />

                  <span
                    className={`d-none d-lg-inline-block ${omSection__footerNote}`}
                  >
                    {get(slice, "primary.footer_text", "")}
                  </span>
                </div>
                <div
                  className={`col-lg-5 offset-lg-1 d-flex flex-column justify-content-between`}
                >
                  <img
                    className={omSection__image}
                    alt=""
                    src={get(slice, "primary.cover_image.url", "")}
                  />
                  <div>
                    <h6 className={omSection__imageDesc}>
                      {get(slice, "primary.secondary_text", "")}
                    </h6>
                    <div className={omSection__productsContainer}>
                      {link1Label && get(link1, "link", false) && (
                        <Link
                          to={link1.link}
                          title={link1Label}
                          target={link1.target}
                          className={`${omSection__productsContainer__productButton}`}
                        >
                          {link1Label}
                        </Link>
                      )}
                      {link2Label && get(link2, "link", false) && (
                        <Link
                          to={link2.link}
                          title={link2Label}
                          target={link2.target}
                          className={`${omSection__productsContainer__productButton}`}
                        >
                          {link2Label}
                        </Link>
                      )}
                      {link3Label && get(link3, "link", false) && (
                        <Link
                          to={link3.link}
                          title={link3Label}
                          target={link3.target}
                          className={`${omSection__productsContainer__productButton}`}
                        >
                          {link3Label}
                        </Link>
                      )}
                      {link4Label && get(link4, "link", false) && (
                        <Link
                          to={link4.link}
                          title={link4Label}
                          target={link4.target}
                          className={`btn btn-outline-light ${omSection__productsContainer__productButton}`}
                        >
                          {link4Label}
                        </Link>
                      )}
                    </div>
                  </div>

                  <span
                    className={`d-inline-block d-lg-none ${omSection__footerNote}`}
                  >
                    {get(slice, "primary.footer_text", "")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MembershipSection;
