import React, { useMemo, useState } from "react";
import { formError } from "../../../styles/forms/forms.module.scss";
import {
  bookMeetingRoomsForm,
  bookVirtualOfficeForm,
} from "./BookingComponentForm.module.scss";
import { phoneNumber } from "../../FormContact/FormContact.module.scss";
import { CustomSelectSingle } from "../../CustomSelect/CustomSelect";
import { optionsPhoneNumberCountry } from "../../FormContact/options";
import i18n from "../../../utils/i18n";
import { format, isSameDay } from "date-fns";
import iqApi from "../../../services/iqApi";
import SubmitButton from "../../../SubmitButton";
import { getQueryParams } from "../../../utils";

export const BookMeetingRoomsForm = ({
  teamSizes = [],
  formik = {},
  hasHourly = false,
  hasDaily = false,
  setIsAlreadyRegistered = () => null,
  isAlreadyRegistered = false,
  isAutomaticUserValidation = false,
  emailCheckCompleted = false,
  setEmailCheckCompleted = () => null,
  hasValidPaymentMethod,
  setHasValidPaymentMethod = () => null,
}) => {
  const [progress, setProgress] = useState(false);

  const params = getQueryParams();

  const checkUser = async () => {
    if (!formik.values.email || isAlreadyRegistered) return;

    if (formik.touched.email && formik.errors.email) return;

    setProgress(true);

    const response = await iqApi("fetch-user-by-email", {
      email: formik.values.email,
    });

    if (response?.user?._id) {
      setProgress(false);
      setIsAlreadyRegistered();
    }
    setHasValidPaymentMethod(response?.hasPaymentMethod || false);
    setProgress(false);
    setEmailCheckCompleted(true);
  };

  if (!emailCheckCompleted) {
    return (
      <>
        <div>
          <input
            id="email"
            name="email"
            type="email"
            className={`form-control   ${
              formik.touched.email && formik.errors.email ? formError : ""
            }`}
            placeholder={i18n.t("contactPage.Email")}
            {...formik.getFieldProps("email")}
          />
        </div>
        <SubmitButton
          label={i18n.t("Continue")}
          variant={"primary"}
          className={"my-3"}
          progress={progress}
          onClick={checkUser}
        />
        <DateAndTs />
      </>
    );
  }

  return (
    <form className={bookMeetingRoomsForm} onSubmit={(e) => e.preventDefault()}>
      <div>
        <input
          id="email"
          name="email"
          type="email"
          className={`form-control   ${
            formik.touched.email && formik.errors.email ? formError : ""
          }`}
          placeholder={i18n.t("contactPage.Email")}
          {...formik.getFieldProps("email")}
          disabled
        />
      </div>
      <div>
        <input
          id="password"
          name="password"
          type="password"
          className={`form-control overlap-top ${
            formik.touched.password && formik.errors.password ? formError : ""
          }`}
          placeholder={i18n.t("contactPage.Password")}
          {...formik.getFieldProps("password")}
        />
        {!isAlreadyRegistered && (
          <small className={"d-block mt-2"}>
            (Must be at least 8 characters, including a number, uppercase letter
            and a lowercase letter)
          </small>
        )}
      </div>
      {}
      {!isAlreadyRegistered && (
        <>
          <div>
            <input
              id="company"
              name="company"
              type="text"
              className={`form-control   ${
                formik.touched.company && formik.errors.company ? formError : ""
              }`}
              placeholder={`${i18n.t("CompanyName")} *`}
              {...formik.getFieldProps("company")}
            />
          </div>
          <div>
            <input
              id="fullName"
              name="fullName"
              type="text"
              className={`form-control overlap-bottom ${
                formik.touched.fullName && formik.errors.fullName
                  ? formError
                  : ""
              }`}
              placeholder={i18n.t("profilePage.FullName")}
              {...formik.getFieldProps("fullName")}
            />
          </div>
          <div className={"position-relative"}>
            <div className={phoneNumber}>
              <span></span>
              <CustomSelectSingle
                // isMenuOpen
                disabled={false}
                phoneNumber
                options={optionsPhoneNumberCountry}
                value={formik.values.phoneNumberCountry}
                onChange={(value) =>
                  formik.setFieldValue("phoneNumberCountry", value.value)
                }
              />
            </div>
            <input
              id="phoneNumber"
              name="phoneNumber"
              disabled={false}
              type="phoneNumber"
              className={`form-control ${
                formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? formError
                  : ""
              }`}
              placeholder={`${i18n.t("contactPage.PhoneNumber")} *`}
              {...formik.getFieldProps("phoneNumber")}
            />
          </div>
        </>
      )}

      {isAutomaticUserValidation && hasValidPaymentMethod && (
        <p className={"my-2"}>{i18n.t("mr.AlreadyRegistered")}</p>
      )}

      {isAutomaticUserValidation && !hasValidPaymentMethod && (
        <div
          className={"my-2"}
          dangerouslySetInnerHTML={{
            __html: i18n.t("mr.AlreadyRegisteredWithoutPayment"),
          }}
        />
      )}

      {emailCheckCompleted && isAlreadyRegistered && (
        <p className={"my-2"}>
          <a
            href="https://members.iqoffices.com/reset-password"
            target={"_blank"}
            rel={"noreferrer"}
          >
            <u>{i18n.t("ForgotPassword")}</u>
          </a>
        </p>
      )}

      <DateAndTs />
    </form>
  );
};

const DateAndTs = () => {
  const params = getQueryParams();

  const teamSize = params.get("team_size");
  const type = params.get("type");
  const startString = params.get("start");
  const endString = params.get("end");

  const formattedDate = useMemo(() => {
    if (!startString || !endString) return i18n.t("DateNotSelected");
    const start = new Date(startString);
    const end = new Date(endString);
    let dateString = format(start, "MMM dd");
    if (!isSameDay(start, end) || type === "hourly") {
      dateString += `, ${format(start, "h:mm a")} - ${format(end, "h:mm a")}`;
    }
    return dateString;
  }, [startString, endString]);

  let tsString = i18n.t("TsNotSelected");

  if (teamSize) {
    tsString = Number(teamSize) === 1 ? "1 person" : `${teamSize} people`;
  }

  return (
    <>
      <hr />

      <div className={"overlap-bottom"}>
        <input
          id="date"
          name="date"
          type="text"
          className={`form-control px-3`}
          value={formattedDate}
          disabled
        />
      </div>
      <div>
        <input
          id="teamSize"
          name="teamSize"
          type="text"
          className={`form-control px-3`}
          value={tsString}
          disabled
        />
      </div>
    </>
  );
};

export const BookVirtualOfficeForm = ({
  formik,
  subscriptionError = false,
}) => {
  return (
    <form
      className={bookVirtualOfficeForm}
      onSubmit={(e) => e.preventDefault()}
    >
      <div>
        <input
          id="fullName"
          name="fullName"
          type="text"
          disabled={subscriptionError}
          className={`form-control ${
            formik.touched.fullName && formik.errors.fullName ? formError : ""
          }`}
          placeholder={i18n.t("profilePage.FullName")}
          {...formik.getFieldProps("fullName")}
        />
      </div>
      <div>
        <input
          id="email"
          name="email"
          disabled={subscriptionError}
          type="text"
          className={`form-control overlap-top overlap-bottom ${
            formik.touched.email && formik.errors.email ? formError : ""
          }`}
          placeholder={i18n.t("contactPage.Email")}
          {...formik.getFieldProps("email")}
        />
      </div>
      <div>
        <input
          id="password"
          name="password"
          type="password"
          disabled={subscriptionError}
          className={`form-control overlap-bottom ${
            formik.touched.password && formik.errors.password ? formError : ""
          }`}
          placeholder={i18n.t("contactPage.Password")}
          {...formik.getFieldProps("password")}
        />
        <small className={"text-muted mt-2 d-block"}>
          (Must be at least 8 characters, including a number, uppercase letter
          and a lowercase letter)
        </small>
      </div>
      <div className={"position-relative"}>
        <div className={phoneNumber}>
          <span></span>
          <CustomSelectSingle
            // isMenuOpen
            disabled={subscriptionError}
            phoneNumber
            options={optionsPhoneNumberCountry}
            value={formik.values.phoneNumberCountry}
            onChange={(value) =>
              formik.setFieldValue("phoneNumberCountry", value.value)
            }
          />
        </div>
        <input
          id="phoneNumber"
          name="phoneNumber"
          disabled={subscriptionError}
          className={`form-control overlap-bottom ${
            formik.touched.phoneNumber && formik.errors.phoneNumber
              ? formError
              : ""
          }`}
          type="phoneNumber"
          placeholder={`${i18n.t("contactPage.PhoneNumber")} *`}
          {...formik.getFieldProps("phoneNumber")}
        />
      </div>
      <div>
        <input
          id="companyName"
          name="companyName"
          type="text"
          disabled={subscriptionError}
          className={`form-control overlap-bottom ${
            formik.touched.companyName && formik.errors.companyName
              ? formError
              : ""
          }`}
          placeholder={`${i18n.t("CompanyName")} *`}
          {...formik.getFieldProps("companyName")}
        />
      </div>
      {/*<div>*/}
      {/*  <DropdownDatePicker*/}
      {/*    placeholderText={"Start date *"}*/}
      {/*    selected={formik.values.startDate}*/}
      {/*    disabled={subscriptionError}*/}
      {/*    onChange={(date) => {*/}
      {/*      formik.setFieldValue("startDate", date);*/}
      {/*    }}*/}
      {/*    isInvalid={!!(formik.touched.startDate && formik.errors.startDate)}*/}
      {/*  />*/}
      {/*</div>*/}
    </form>
  );
};
