import { format } from "date-fns"; // email: get(formik, "values.email", ""),

// email: get(formik, "values.email", ""),
//   password: get(formik, "values.password", ""),
//   name: get(formik, "values.fullName", ""),
//   company: get(formik, "values.companyName", ""),
//   phone: get(formik, "values.phoneNumber", ""),
//   office: office._id,

const getEmailData = (userData, stripeToken, reason) => {
  const fields = [
    {
      label: "User's Name",
      value: userData?.fullName || "N/A",
    },
    {
      label: "Email",
      value: userData?.email || "N/A",
    },
    {
      label: "Phone number",
      value: userData?.phoneNumber || "N/A",
    },
    {
      label: "Company",
      value: userData?.companyName || "N/A",
    },
    {
      label: "Start date",
      value: userData?.startDate
        ? format(userData?.startDate, "yyyy MMM dd")
        : "N/A",
    },
    {
      label: "Card type",
      value: stripeToken?.card?.brand,
    },
    {
      label: "Last 4",
      value: stripeToken?.card?.last4,
    },
    {
      label: "Reason",
      value: reason || "N/A",
    },
  ];

  return fields;
};

export default getEmailData;
