import { parseISO, setHours, setMinutes } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { getQueryParams } from "../../utils";

export const convertDateTimeToMeetingRoomTz = (date = null, tz = null) => {
  if (!date || !tz) return null;

  return zonedTimeToUtc(date, tz);
};

const getMeetingRoomBookingDataForOfficeRnD = (
  user = {
    name: "N/A",
    email: "",
    company: "",
    password: "",
    phoneNumber: "",
  },
  cardData,
  isAlreadyRegistered,
  profile,
) => {
  const params = getQueryParams();

  const start = params.get("start");
  const end = params.get("end");

  const from = parseISO(start);
  const to = parseISO(end);

  if (!start) {
    return { startDateTime: null };
  }

  let startDateTime = convertDateTimeToMeetingRoomTz(
    from,
    profile?.timezone || "America/Toronto",
  ).toISOString();
  let endDateTime = convertDateTimeToMeetingRoomTz(
    to,
    profile?.timezone || "America/Toronto",
  ).toISOString();

  if (params.get("type") !== "hourly") {
    startDateTime = convertDateTimeToMeetingRoomTz(
      setHours(setMinutes(from, 0), 9),
      profile?.timezone || "America/Toronto",
    ).toISOString();
    endDateTime = convertDateTimeToMeetingRoomTz(
      setHours(setMinutes(to, 0), 17),
      profile?.timezone || "America/Toronto",
    ).toISOString();
  }
  return {
    name: user.name,
    email: user.email,
    password: user.password,
    company: user.company,
    phone: user.phoneNumber,
    start: startDateTime,
    end: endDateTime,
    meetingRoomId: params.get("meeting_room_id"),
    resourceName: params.get("meeting_room_name"),
    office: params.get("rnd_location_id"),
    cardData,
    isAlreadyRegistered,
  };
};

export default getMeetingRoomBookingDataForOfficeRnD;
