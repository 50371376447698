import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Placeholder from "./placeholder.jpg";
import cx from "classnames";
import * as s from "./CarouselMulti.module.scss";
import {
  carouselContainerMatterport,
  customDotsContainerCarouselMulti,
  customDotsContainerHomeFeatured,
  customDotsContainerMultiInfos,
  imageContainer,
  imageContainerMatterport,
  infoItemContainer,
  infoItemTitleIcon,
} from "./CarouselMulti.module.scss";
import {
  customButtonGroupHomeFeatured,
  customButtonGroupHomeFuture,
  customButtonGroupMultiInfos,
} from "./Custom/Custom.module.scss";
import {
  CustomRightArrow,
  CustomRightArrowChevron,
  CustomRightArrowPrimary,
} from "./Custom/CustomRightArrow";
import {
  CustomLeftArrow,
  CustomLeftArrowChevron,
  CustomLeftArrowPrimary,
} from "./Custom/CustomLeftArrow";
import { CustomDotsPrimary, CustomDotsWhite } from "./Custom/CustomDots";
import CustomButtonGroup from "./Custom/CustomButtonGroup";
import * as productsFeatureVenue from "../ProductFeaturedVenue/ProductFeatureVenue.module.scss";
import { Button } from "react-bootstrap";
import Left from "../../images/design-components/Left.svg";
import Right from "../../images/design-components/Right.svg";
import i18n from "../../utils/i18n";

export const CarouselMulti = ({
  primary,
  images,
  infinite,
  afterChange,
  onClick,
}) => {
  const filteredImages =
    !images || images.length === 0
      ? [Placeholder, Placeholder, Placeholder]
      : images;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1092 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 80,
    },
    tablet: {
      breakpoint: { max: 1092, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 80,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 30,
    },
  };

  const isActiveFn = typeof onClick === "function";

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div>
        {primary ? (
          <CustomLeftArrowPrimary onClick={previous} />
        ) : (
          <CustomLeftArrow onClick={previous} />
        )}
        {primary ? (
          <CustomRightArrowPrimary onClick={next} />
        ) : (
          <CustomRightArrow onClick={next} />
        )}
      </div>
    );
  };

  return (
    <div className="position-relative overflow-hidden">
      <Carousel
        arrows={false}
        autoPlay={false}
        autoPlaySpeed={5000}
        shouldResetAutoplay={false}
        centerMode={false}
        // customLeftArrow={
        //   primary ? <CustomLeftArrowPrimary /> : <CustomLeftArrow />
        // }
        // customRightArrow={
        //   primary ? <CustomRightArrowPrimary /> : <CustomRightArrow />
        // }
        customButtonGroup={<ButtonGroup />}
        showDots
        customDot={
          primary ? (
            <CustomDotsPrimary />
          ) : (
            <CustomDotsWhite images={filteredImages} />
          )
        }
        renderDotsOutside={true}
        dotListClass={customDotsContainerCarouselMulti}
        infinite={infinite}
        renderButtonGroupOutside={true}
        partialVisible={true}
        responsive={responsive}
        swipeable={true}
        draggable={true}
        afterChange={afterChange ? afterChange : ""}
      >
        {filteredImages.map((img, index) => (
          <div className={imageContainer} key={index}>
            <div>
              <img
                className={cx({
                  "cursor-pointer": isActiveFn && img,
                })}
                role={"presentation"}
                alt=""
                src={img}
                onClick={isActiveFn ? () => onClick(index) : () => null}
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export const CarouselMultiMatterport = ({ carouselItems, setActiveItem }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 992, min: 576 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div>
        <CustomLeftArrowChevron onClick={previous} />
        <CustomRightArrowChevron onClick={next} />
      </div>
    );
  };

  return (
    <div className="position-relative">
      <Carousel
        arrows={false}
        autoPlay={false}
        autoPlaySpeed={10000}
        centerMode={false}
        className={carouselContainerMatterport}
        customButtonGroup={<ButtonGroup />}
        draggable={false}
        infinite
        renderButtonGroupOutside={true}
        responsive={responsive}
      >
        {carouselItems.map((item, index) => (
          <div
            role="button"
            className={imageContainerMatterport}
            key={index}
            tabIndex={index}
            onClick={() => setActiveItem(index)}
            onKeyDown={() => setActiveItem(index)}
          >
            <img alt="" src={item.image} />
            <h6>{item.name}</h6>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export const CarouselMultiInfos = ({ infoItems = [], btnLink = false }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
      // partialVisibilityGutter: 80,
    },
    sm_desktop: {
      breakpoint: { max: 1200, min: 992 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 992, min: 767 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 80,
    },
    lg_mobile: {
      breakpoint: { max: 767, min: 565 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 20,
    },
    mobile: {
      breakpoint: { max: 565, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 80,
    },
  };

  return (
    <>
      <div className="position-relative">
        <div className={"d-none d-xl-block"}>
          <Carousel
            arrows={false}
            // autoPlay={}
            // autoPlaySpeed={5000}
            shouldResetAutoplay={false}
            centerMode={false}
            // className={carouselContainerInfo}
            // customLeftArrow={<CustomLeftArrowPrimary />}
            // customRightArrow={<CustomRightArrowPrimary />}
            customButtonGroup={
              <CustomButtonGroup className={customButtonGroupMultiInfos} />
            }
            showDots={false}
            customDot={<CustomDotsPrimary images={infoItems} />}
            dotListClass={customDotsContainerMultiInfos}
            renderButtonGroupOutside={true}
            renderDotsOutside={true}
            removeArrowOnDeviceType={["desktop"]}
            partialVisible={true}
            responsive={responsive}
            swipeable={true}
            draggable={true}
          >
            {infoItems.map((infoItem, index) => (
              <div key={index} className={infoItemContainer}>
                <h6 className={infoItem.infoTitleIcon ? infoItemTitleIcon : ""}>
                  {infoItem.infoTitle}
                </h6>
                {infoItem.infoDescList.map((descItem, idx) => (
                  <p key={idx}>{descItem}</p>
                ))}
              </div>
            ))}
          </Carousel>
        </div>
        <div className={"d-block d-xl-none"}>
          <Carousel
            arrows={false}
            // autoPlay={}
            // autoPlaySpeed={5000}
            shouldResetAutoplay={false}
            centerMode={false}
            // className={carouselContainerInfo}
            // customLeftArrow={<CustomLeftArrowPrimary />}
            // customRightArrow={<CustomRightArrowPrimary />}
            customButtonGroup={
              <CustomButtonGroup className={customButtonGroupMultiInfos} />
            }
            showDots={true}
            customDot={<CustomDotsPrimary images={infoItems} />}
            dotListClass={customDotsContainerMultiInfos}
            renderButtonGroupOutside={true}
            renderDotsOutside={true}
            removeArrowOnDeviceType={["desktop"]}
            partialVisible={true}
            responsive={responsive}
            swipeable={true}
            draggable={true}
          >
            {infoItems.map((infoItem, index) => (
              <div key={index} className={infoItemContainer}>
                <h6 className={infoItem.infoTitleIcon ? infoItemTitleIcon : ""}>
                  {infoItem.infoTitle}
                </h6>
                {infoItem.infoDescList.map((descItem, idx) => (
                  <p key={idx}>{descItem}</p>
                ))}
              </div>
            ))}
          </Carousel>
        </div>

        <Button
          variant="primary"
          className={cx(
            { "d-none": !btnLink },
            { ["d-none d-xl-block"]: btnLink },
            productsFeatureVenue.button,
          )}
        >
          <span>{i18n.t("MoreInfo")}</span>
        </Button>
      </div>
      <Button
        variant="primary"
        className={cx(
          { "d-none": !btnLink },
          { ["d-block d-xl-none"]: btnLink },
          productsFeatureVenue.button,
        )}
      >
        <span>{i18n.t("MoreInfo")}</span>
      </Button>
    </>
  );
};

export const CarouselHomeFeatured = ({ children }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  };
  return (
    <div className={`position-relative`}>
      <Carousel
        responsive={responsive}
        arrows={false}
        shouldResetAutoplay={false}
        centerMode={false}
        customButtonGroup={
          <CustomButtonGroup className={customButtonGroupHomeFeatured} />
        }
        showDots={true}
        customDot={<CustomDotsPrimary />}
        dotListClass={customDotsContainerHomeFeatured}
        renderButtonGroupOutside={true}
        renderDotsOutside={true}
        swipeable={true}
      >
        {children}
      </Carousel>
    </div>
  );
};

export const CarouselHomeFutureOfWorkspace = ({
  children,
  activeIndex,
  setActiveIndex,
  maxIndex,
}) => {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 1,
      partialVisibilityGutter: 50,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 767,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: {
        max: 767,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 1,
    },
  };
  return (
    <div className={`position-relative`}>
      <Carousel
        afterChange={(previousSlide, { currentSlide }) => {
          const gap = currentSlide - previousSlide;
          // next
          if (gap === 1 || gap < -1) {
            if (previousSlide - 1 === maxIndex) {
              setActiveIndex(0);
            } else {
              setActiveIndex(previousSlide - 1);
            }
          }

          // prev
          if (gap === -1 || gap > 3) {
            if (currentSlide === 1) {
              setActiveIndex(maxIndex - 1);
            } else {
              setActiveIndex(currentSlide - 2);
            }
          }
        }}
        responsive={responsive}
        arrows={false}
        shouldResetAutoplay={false}
        centerMode
        infinite
        slidesToSlide={1}
        customButtonGroup={
          <CustomButtonGroup
            white
            className={customButtonGroupHomeFuture}
            // prevIndex={() => {
            //   if (activeIndex === 0) {
            //     setActiveIndex(maxIndex - 1);
            //   } else {
            //     setActiveIndex(activeIndex - 1);
            //   }
            // }}
            // nextIndex={() => {
            //   if (activeIndex >= maxIndex - 1) {
            //     setActiveIndex(0);
            //   } else {
            //     setActiveIndex(activeIndex + 1);
            //   }
            // }}
          />
        }
        showDots={false}
        // customDot={<CustomDotsPrimary />}
        // dotListClass={customDotsContainerHomeFeatured}
        renderButtonGroupOutside={true}
        // renderDotsOutside={true}
        swipeable={true}
      >
        {children}
      </Carousel>
    </div>
  );
};

export const CarouselDedicatedTeam = ({ children }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1092 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1092, min: 768 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div>
        <button
          className={`btn-link ${s.dedicatedTeamLeftButton}`}
          onClick={previous}
        >
          <img alt="" src={Left} />
        </button>
        <button
          className={`btn-link ${s.dedicatedTeamRightButton}`}
          onClick={next}
        >
          <img alt="" src={Right} />
        </button>
      </div>
    );
  };

  return (
    <div className={`container-xxl position-relative`}>
      <div>
        <Carousel
          autoPlay
          autoPlaySpeed={4000}
          itemClass={"text-center"}
          responsive={responsive}
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
          // infinite
        >
          {children}
        </Carousel>
      </div>
    </div>
  );
};
