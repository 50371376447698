// extracted by mini-css-extract-plugin
export var carouselContainerMatterport = "CarouselMulti-module--carousel-container-matterport--278ca";
export var customDotsContainerCarouselMulti = "CarouselMulti-module--custom-dots-container-carousel-multi--1b1e0";
export var customDotsContainerHomeFeatured = "CarouselMulti-module--custom-dots-container-home-featured--3839f";
export var customDotsContainerMultiInfos = "CarouselMulti-module--custom-dots-container-multi-infos--ea401";
export var dedicatedTeamLeftButton = "CarouselMulti-module--dedicated-team-left-button--f5ded";
export var dedicatedTeamRightButton = "CarouselMulti-module--dedicated-team-right-button--88277";
export var imageContainer = "CarouselMulti-module--image-container--b7109";
export var imageContainerMatterport = "CarouselMulti-module--image-container-matterport--253ea";
export var infoItemContainer = "CarouselMulti-module--info-item-container--7a641";
export var infoItemTitleIcon = "CarouselMulti-module--infoItemTitleIcon--7ad1a";