import React from "react";
import {
  lpbSection,
  lpbSection__title,
} from "./LocationsProfileBoC.module.scss";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";
import { Link } from "gatsby";
import resolvePrismicLink from "../../utils/resolvePrismicLink";

const LocationsProfileBoC = ({ data }) => {
  const { link, target } = resolvePrismicLink(
    get(data, "primary.button_link", {}),
  );

  if (!link || !get(data, "primary.title[0].text", null)) return null;

  return (
    <div className={`container-xxl`}>
      <div
        className={lpbSection}
        style={{
          backgroundImage: `url(${get(
            data,
            "primary.background_image.url",
            "",
          )})`,
        }}
      >
        <div className={`d-flex justify-content-center ${lpbSection__title}`}>
          <PrismicRichText field={get(data, "primary.title", [])} />
        </div>
        {link && (
          <div className={`text-center`}>
            <Link
              title={get(data, "primary.button_label", "")}
              className={"btn btn-outline-light"}
              to={link}
              target={target}
            >
              <span>{get(data, "primary.button_label", "")}</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationsProfileBoC;
