import React, { useState } from "react";
import {
  hpProductsSection,
  hpProductsSection__active,
} from "../../index.module.scss";
import * as s from "./index.module.scss";
import get from "lodash/get";
import { navigate } from "gatsby";
import resolvePrismicLink from "../../../../utils/resolvePrismicLink";
import cx from "classnames";

const HomeProductsSection = ({ slice }) => {
  const list = get(slice, "items", []);

  const [hovered, setHovered] = useState("");

  return (
    <div className={hpProductsSection}>
      <div className={`container`}>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
            <ul className={`m-0 p-0 list-unstyled ${hovered ? s.hovered : ""}`}>
              {list.map((product, index) => {
                const { link } = resolvePrismicLink(product.link);
                return (
                  <li key={index}>
                    <button
                      onClick={async () => {
                        if (window.innerWidth > 1024) {
                          await navigate(link);
                        } else if (hovered === product.title) {
                          await navigate(link);
                        } else {
                          setHovered(product.title);
                        }
                      }}
                      key={index}
                      className={`btn-link w-100`}
                      onMouseEnter={() => {
                        if (window.innerWidth > 1024) {
                          setHovered(product.title);
                        }
                      }}
                      onMouseLeave={() => {
                        if (window.innerWidth > 1024) {
                          setHovered("");
                        }
                      }}
                    >
                      <h2
                        className={cx({
                          "py-2": true,
                          [hpProductsSection__active]:
                            hovered === product.title,
                          "text-nowrap": true,
                        })}
                      >
                        {product.title}
                      </h2>
                      <div
                        className={s.mobile_image}
                        style={{
                          maxHeight: hovered === product.title ? "200px" : "0",
                        }}
                      >
                        <img
                          src={get(product, "left_image.url", "")}
                          style={{
                            opacity: hovered === product.title ? "100%" : "0%",
                          }}
                          loading={"lazy"}
                        />
                      </div>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="d-none d-md-flex col-md-6">
            <ul className={s.desktop_images}>
              {list.map((product, index) => {
                return (
                  <li key={index}>
                    <img
                      src={get(product, "left_image.url", "")}
                      style={{
                        opacity: hovered === product.title ? "100%" : "0%",
                      }}
                      loading={"lazy"}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeProductsSection;
