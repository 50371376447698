import i18n from "../utils/i18n";

const withLang = (en, fr) => {
  const isEnglish = i18n.t("lang") !== "fr";
  return isEnglish ? en : fr;
};

export const bookTourProductTypes = () => {
  return [
    {
      label: withLang("Private Office", "Bureau privé"),
      value: "Private Office",
    },
    {
      label: withLang("Dedicated Desk", "Espace collboratif"),
      value: "Dedicated Desk",
    },
  ];
};

export const membershipProductTypes = () => {
  return [
    {
      label: withLang("Private Office", "Bureau privé"),
      value: "Private Office",
    },
    {
      label: withLang("Virtual Office", "Bureau virtuel"),
      value: "Virtual Office",
    },
    {
      label: withLang("Dedicated Desk", "Espace collboratif"),
      value: "Dedicated Desk",
    },
    {
      label: withLang("Day Pass", "Forfait journalier"),
      value: "Day Pass",
    },
    {
      label: withLang("Meeting Room", "Salle de réunion"),
      value: "Meeting Room",
    },
    {
      label: withLang("Event Venue", "Espace événementiel"),
      value: "Venue",
    },
  ];
};

export const industries = () => {
  return [
    {
      label: withLang("Computers Software", "Logiciels informatiques"),
      value: "Computers Software",
    },
    {
      label: withLang("Construction", "Construction"),
      value: "Construction",
    },
    {
      label: withLang("Consulting", "Conseil"),
      value: "Consulting",
    },
    {
      label: withLang("Education", "Éducation"),
      value: "Education",
    },
    {
      label: withLang("Environmental Services", "Services environnementaux"),
      value: "Environmental Services",
    },
    {
      label: withLang("Events", "Événements"),
      value: "Events",
    },
    {
      label: withLang("Financial Services", "Services financiers"),
      value: "Financial Services",
    },
    {
      label: withLang("Food Industry", "Industrie alimentaire"),
      value: "Food Industry",
    },
    {
      label: withLang("Health & Wellness", "Santé et bien-être"),
      value: "Health & Wellness",
    },
    {
      label: withLang("Hospitality Industry", "Industrie de l'hôtellerie"),
      value: "Hospitality Industry",
    },
    {
      label: withLang(
        "IT Services and IT Consulting",
        "Services informatiques et conseil",
      ),
      value: "IT Services and IT Consulting",
    },
    {
      label: withLang("Insurance", "Assurance"),
      value: "Insurance",
    },
    {
      label: withLang("Law", "Droit"),
      value: "Law",
    },
    {
      label: withLang("Logistics & Transportation", "Logistique et transport"),
      value: "Logistics & Transportation",
    },
    {
      label: withLang("Manufacturing", "Fabrication"),
      value: "Manufacturing",
    },
    {
      label: withLang("Marketing & Advertising", "Marketing et publicité"),
      value: "Marketing & Advertising",
    },
    {
      label: withLang("Mining", "Mines"),
      value: "Mining",
    },
    {
      label: withLang("Non-profit", "Organisation à but non lucratif"),
      value: "Non-profit",
    },
    {
      label: withLang("Public Administration", "Administration publique"),
      value: "Public Administration",
    },
    {
      label: withLang("Real Estate", "Immobilier"),
      value: "Real Estate",
    },
    {
      label: withLang("Research", "Recherche"),
      value: "Research",
    },
    {
      label: withLang("Retail", "Commerce de détail"),
      value: "Retail",
    },
    {
      label: withLang(
        "Staffing & Recruiting",
        "Recrutement et dotation en personnel",
      ),
      value: "Staffing & Recruiting",
    },
    {
      label: withLang("Technology", "Technologie"),
      value: "Technology",
    },
    {
      label: withLang("Travel & Tourism", "Voyages et tourisme"),
      value: "Travel & Tourism",
    },
  ];
};

export const teamSizes = () => {
  return [
    {
      label: "1-5",
      value: "1-5",
    },
    {
      label: "6-10",
      value: "6-10",
    },
    {
      label: "11-20",
      value: "11-20",
    },
    {
      label: "21-30",
      value: "21-30",
    },
    {
      label: "31-50",
      value: "31-50",
    },
    {
      label: "51-70",
      value: "51-70",
    },
    {
      label: "71-100",
      value: "71-100",
    },
    {
      label: "101+",
      value: "101+",
    },
  ];
};

export const areYouLookingForOfficeSpace = () => {
  return [
    {
      label: withLang("Yes, right away!", "Oui, toute suite!"),
      value: "Yes, right away!",
    },
    {
      label: withLang("In the near future", "Dans un futur proche"),
      value: "In the near future",
    },
    {
      label: withLang("Not at the moment", "Pas pour le moment"),
      value: "Not at the moment ",
    },
  ];
};

export const iqLocations = () => {
  return [
    {
      label: withLang(
        "150 King Street West (TOR)",
        "150 King Street West (TOR)",
      ),
      value: "150 King Street West (TOR)",
    },
    {
      label: withLang(
        "250 University Avenue (TOR)",
        "250 University Avenue (TOR)",
      ),
      value: "250 University Avenue (TOR)",
    },
    {
      label: withLang("140 Yonge Street (TOR)", "140 Yonge Street (TOR)"),
      value: "140 Yonge Street (TOR)",
    },
    {
      label: withLang(
        "545 King Street West (TOR)",
        "545 King Street West (TOR)",
      ),
      value: "545 King Street West (TOR)",
    },
    {
      label: withLang(
        "1055 West Georgia Street (VAN)",
        "1055 West Georgia Street (VAN)",
      ),
      value: "1055 West Georgia Street (VAN)",
    },
    {
      label: withLang("1155 Rue Metcalfe (MTL)", "1155 Rue Metcalfe (MTL)"),
      value: "1155 Rue Metcalfe (MTL)",
    },
    {
      label: withLang("222 Queen Street (OTT)", "222 Queen Street (OTT)"),
      value: "222 Queen Street (OTT)",
    },
    {
      label: withLang("302 Bay Street (TOR)", "302 Bay Street (TOR)"),
      value: "302 Bay Street (TOR)",
    },
  ];
};

export const currentWorkingEnvironment = () => {
  return [
    {
      label: withLang("Work from home", "Travail à domicile"),
      value: "Work from home",
    },
    {
      label: withLang(
        "Traditional office space",
        "Espace de bureau traditionnel",
      ),
      value: "Traditional office space",
    },
    {
      label: withLang("Other coworking space", "Autre espace de coworking"),
      value: "Other coworking space",
    },
    {
      label: withLang("Hybrid", "Hybride"),
      value: "Hybrid",
    },
  ];
};
