const splitNl2Array = (string = "") => {
  if (!string) return [];
  const textArray = string.split("\n");

  if (!textArray || textArray.length === 0) return [];

  return textArray;
};

export default splitNl2Array;
