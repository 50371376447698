import React from "react";
import get from "lodash/get";
import { PrismicRichText } from "@prismicio/react";
import { subheroButtonContainer, subheroSection } from "./SubHero.module.scss";
import resolvePrismicLink from "../../utils/resolvePrismicLink";
import cx from "classnames";
import CustomPrismicLink from "../CustomPrismicLink";

const SubHero = ({ text, ctaLabel, slice }) => {
  const title = get(slice, "primary.title", []);
  const buttonLabel = get(slice, "primary.button_label", []);
  const { link, target } = resolvePrismicLink(
    get(slice, "primary.button_link", []),
  );

  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center ${subheroSection}`}
    >
      <div className={`container-xxl`}>
        <div className={`row`}>
          <div className={`col-lg-6 offset-lg-3 text-center`}>
            <PrismicRichText field={title} />
          </div>
        </div>
      </div>
      {buttonLabel && link && (
        <div className={cx(subheroButtonContainer, `mt-4`)}>
          <CustomPrismicLink
            className="container-xxl btn btn-flex btn-outline-primary mt-2"
            target={target}
            to={link}
            title={buttonLabel}
          >
            <span>{buttonLabel}</span>
          </CustomPrismicLink>
        </div>
      )}
    </div>
  );
};

export default SubHero;
