// extracted by mini-css-extract-plugin
export var benefitsOfCoworking = "index-module--benefits-of-coworking--268de";
export var bookATour = "index-module--book-a-tour--e05e3";
export var dropdownMegaMenuBox = "index-module--dropdown-mega-menu-box--99138";
export var hamburger = "index-module--hamburger--c3350";
export var headerTabMobBody = "index-module--header-tab-mob-body--5ca50";
export var headerTabMobFooter = "index-module--header-tab-mob-footer--bb8c2";
export var headerTabMobFooterLinks = "index-module--header-tab-mob-footer-links--3d4ef";
export var iconContainer = "index-module--icon-container--3cff7";
export var iconFrench = "index-module--icon-french--9ca84";
export var iconMember = "index-module--icon-member--be8de";
export var iconPhone = "index-module--icon-phone--7a1ed";
export var iconPhoneTooltip = "index-module--icon-phone-tooltip--7f117";
export var imageContainer = "index-module--image-container--2e179";
export var item2ImageContainer = "index-module--item2-image-container--dbaa6";
export var languageSwitcher = "index-module--languageSwitcher--d3712";
export var menuItem1Products = "index-module--menu-item1-products--5d03b";
export var menuItem1Whyiq = "index-module--menu-item1-whyiq--0b479";
export var menuItem2Products = "index-module--menu-item2-products--99929";
export var menuItem2Whyiq = "index-module--menu-item2-whyiq--bbc33";
export var mobile_top_icons = "index-module--mobile_top_icons--4acc2";
export var overlay = "index-module--overlay--b9550";
export var overviewOfIq = "index-module--overview-of-iq--eb0b8";
export var overviewOfProducts = "index-module--overview-of-products--b2c0f";
export var productDesc = "index-module--product-desc--1840d";
export var productLink = "index-module--product-link--b595b";
export var productTitle = "index-module--product-title--0260b";