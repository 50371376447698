import React, { useEffect, useState } from "react";
import {
  locationsListContainer,
  locationsMapContainer,
} from "./index.module.scss";
import ProgressDots from "../../components/ProgressDots";
import { GoogleMap, Marker } from "../../components/GoogleMap";
import { StringParam, useQueryParam } from "use-query-params";
import {
  LocationsContactFormBottom,
  LocationsContactFormNoResult,
} from "../../components/LocationsContactForm/LocationsContactForm";
import getLocationsList from "../../utils/locationsRequests/getLocationsList";
import LocationSelect from "../../components/LocationsPage/Select";
import LocationBlock from "../../components/LocationsPage/LocationBlock";
import get from "lodash/get";
import { locationCenters } from "./_config";
import isLargerTeamSize from "../../utils/locationsRequests/isLargerTeamSize";
import formatPrice from "../../utils/formarPrice";
import cx from "classnames";

const LocationsLanding = ({ locations, cta = {} }) => {
  const [location = "Toronto"] = useQueryParam("location", StringParam);
  const [mapCenter, setCenter] = useState({
    lat: 43.6508797,
    lng: -79.3834781,
  });
  const [zoom, setZoom] = useState(15);

  useEffect(() => {
    const match = locationCenters.find(
      (center) => center.location === location,
    );
    if (match) {
      setCenter(match.center);
      setZoom(match.zoom || 15);
    }
  }, [location]);

  const [teamSize = "Team size"] = useQueryParam("team_size", StringParam);
  const [spaceType = "Private office"] = useQueryParam(
    "space_type",
    StringParam,
  );

  const [locationList, setLocationsList] = useState([]);
  const [locationHovered, setLocationHovered] = useState("");

  const [teamSizeSpaceType, setTeamSizeSpaceType] = useState(false);

  const [inProgress, setInProgress] = useState(true);

  useEffect(() => {
    setInProgress(true);
    getLocationsList(locations).then((locationsList) => {
      setLocationsList(locationsList);
      setInProgress(false);
    });
  }, [location, teamSize, spaceType]);

  return (
    <div>
      <LocationSelect
        setTeamSizeSpaceType={setTeamSizeSpaceType}
        teamSizeSpaceType={teamSizeSpaceType}
      />

      {/*Map + LocationsList START*/}
      <div className={`container-xxl`}>
        <div className={`row`}>
          <div
            className={cx(
              locationsMapContainer,
              `col-lg-6 offset-lg-0 order-lg-1`,
              `col-md-8 offset-md-2`,
            )}
          >
            <GoogleMap center={mapCenter} zoom={zoom}>
              {!inProgress &&
                locationList.map((loc) => {
                  const prismicLocation = locations.find(
                    (cmsLocation) =>
                      loc.space_id === get(cmsLocation, "data.location_id", ""),
                  );

                  return (
                    <Marker
                      key={loc.space_id}
                      lat={get(loc, "location.lat", 0)}
                      lng={get(loc, "location.lng", 0)}
                      name={
                        get(
                          prismicLocation,
                          "data.location_title.text",
                          loc.space_name,
                        ) || loc.space_name
                      }
                      price={
                        teamSize === "Team size" || isLargerTeamSize()
                          ? ""
                          : `$${formatPrice(loc.price_min)}+`
                      }
                      show={loc.space_id === locationHovered ? true : ""}
                    />
                  );
                })}
            </GoogleMap>
          </div>
          <div
            className={`col-lg-6 order-lg-0 d-flex flex-column ${locationsListContainer}`}
          >
            {!inProgress ? (
              <>
                {locationList.length > 0 &&
                  locationList.map((loc) => {
                    return (
                      <LocationBlock
                        location={loc}
                        cmsLocations={locations}
                        key={loc.space_id}
                        setLocationHovered={setLocationHovered}
                      />
                    );
                  })}
                <LocationsContactFormNoResult
                  show={
                    !inProgress &&
                    (locationList.length === 0 || isLargerTeamSize())
                  }
                />
              </>
            ) : (
              <div>
                <ProgressDots />
              </div>
            )}
          </div>
        </div>
      </div>
      {/*Map + LocationsList END*/}

      {/* LocationsContactForm START */}
      {!inProgress && locationList.length > 0 ? (
        <LocationsContactFormBottom cta={cta} />
      ) : (
        ""
      )}
    </div>
  );
};

export default LocationsLanding;
