import React, { useEffect, useState } from "react";
import {
  amenityItem,
  productSummary,
  productSummaryContainer,
  productSummaryDescList,
} from "./ProductSummary.module.scss";
import get from "lodash/get";
import { PrismicRichText } from "@prismicio/react";
import cx from "classnames";
import CarouselComponent from "../Carousel/Carousel";

const ProductSummary = ({ slice, context }) => {
  const eventVenues = get(context, "eventVenues", false);

  const [images, setImages] = useState([]);

  useEffect(() => {
    const tempImages = [];

    for (let i = 0; i < 5; i++) {
      tempImages.push({
        image: {
          url: get(slice, `primary.carousel_image_${i + 1}.url`, ""),
        },
      });
    }

    setImages(tempImages.filter((item) => !!item.image.url));
  }, [slice]);

  return (
    <div
      className={`container-fluid ${productSummaryContainer} ${
        eventVenues ? "mb-0" : ""
      }`}
    >
      <div className={`container-xxl ${productSummary}`}>
        <div className="text-center">
          <PrismicRichText field={get(slice, "primary.title", [])} />
        </div>
        <div className={`row`}>
          <div
            className={cx(
              [`order-lg-2 offset-lg-1 col-lg-5 position-relative`],
              [`offset-md-2 col-md-8`],
              [`order-lg-last d-flex align-items-center`],
            )}
          >
            <div className={"position-relative w-100 min-w-100 pb-5"}>
              <CarouselComponent images={images} />
            </div>
          </div>
          <div
            className={cx(
              productSummaryDescList,
              [`col-lg-4 offset-lg-1`],
              [`col-md-8 offset-md-2`],
              [`col-12`],
              [`order-lg-first`],
              [`d-flex flex-column justify-content-start align-items-start`],
            )}
          >
            <div className="row justify-content-start">
              {get(slice, "items", []).map((item, idx) => (
                <div key={idx} className={"col-12 col-sm-6 col-lg-12"}>
                  <div
                    className={`d-flex align-items-center my-2 ${amenityItem}`}
                  >
                    {get(item, "icon.url", null) && (
                      <img
                        alt={get(item, "text", "")}
                        src={get(item, "icon.url", null)}
                      />
                    )}
                    <p className={get(item, "icon.url", null) ? "" : "p-0"}>
                      {get(item, "text", "")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <span className="fst-italic float-end mt-auto ps-0 text-primary">
              {get(slice, "primary.footer_text", "")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSummary;
