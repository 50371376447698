import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import cx from "classnames";
import {
  notSelected,
  spaceTypeBox,
  teamSizeButton,
  teamSizeButtonProductForm,
  teamSizeButtonProductForm__div,
  teamSizeButtonProductForm__div__selected,
  teamSizeButtonProductWidget,
  teamSizeButtonProductWidgetTitle,
  teamSizeDropdownMenu,
  teamSizeDropdownMenu__col2,
  teamSizeDropdownMenu__col6,
  teamSizeOption1,
  teamSizeOption2,
  teamSizeOption3,
  teamSizeOption4,
  teamSizeSelected,
} from "./DropdownTeamSize.module.scss";
import useQueryParams from "../../utils/useQueryParams";
import TeamSizeIcon from "../../images/design-components/team_size_icon.svg";
import { StringParam, useQueryParam } from "use-query-params";
import Down from "../../images/design-components/Down.svg";
import i18n from "../../utils/i18n";
import updateQueryString from "../../utils/updateQueryString";

const DropdownTeamSize = ({
  teamSizeSpaceType,
  setTeamSizeSpaceType,
  withSpaceTypePrompt,
  spaceType,
  setSpaceType,
  spaceTypeAfterTeamSize,
  setSpaceTypeAfterTeamSize,
  teamSizes,
  // ProductWidget
  productWidget,
  // ProductForm
  productForm,
  closeLocationDropDown = () => null,
  disabled = false,
}) => {
  const [teamSizeDropdownShow, setTeamSizeDropdownShow] = useState(false);
  const [teamSize = i18n.t("locationPage.TeamSize"), setTeamSize] =
    useQueryParam("team_size", StringParam);

  useEffect(() => {
    closeLocationDropDown();
  }, [teamSizeDropdownShow]);

  return (
    <DropdownButton
      drop={"down"}
      align={"start"}
      autoClose={withSpaceTypePrompt ? "outside" : ""}
      onToggle={(isOpen, event) => {
        setTeamSizeDropdownShow(!teamSizeDropdownShow);
      }}
      show={teamSizeDropdownShow}
      defaultShow={false}
      title={
        !productWidget ? (
          !productForm ? (
            <span>{teamSize}</span>
          ) : (
            <div
              className={cx(`form-control`, teamSizeButtonProductForm__div, {
                [teamSizeButtonProductForm__div__selected]: teamSize !== "",
              })}
            >
              <img alt="" src={Down} />
              <span>
                {teamSize === "" ? i18n.t("NumberOfAttendees") : teamSize}
              </span>
            </div>
          )
        ) : (
          <div className={teamSizeButtonProductWidgetTitle}>
            <h6>{i18n.t("locationPage.TeamSize")}</h6>
            <div className={`d-flex`}>
              <img alt="" src={TeamSizeIcon} />{" "}
              <p
                className={cx({
                  [notSelected]: teamSize === i18n.t("locationPage.TeamSize"),
                })}
              >
                {teamSize}
              </p>
            </div>
          </div>
        )
      }
      id="dropdown"
      className={cx({
        [`${teamSizeButton} ${
          teamSize === i18n.t("locationPage.TeamSize")
            ? ``
            : `${teamSizeSelected}`
        }`]: !productForm && !productWidget,
        [`${teamSizeButtonProductWidget} ${
          teamSize === i18n.t("locationPage.TeamSize")
            ? ``
            : `${teamSizeButtonProductWidget}`
        }`]: productWidget,
        [`${teamSizeButtonProductForm}`]: productForm,
      })}
      bsPrefix={"dropdown-teamsize-button"}
    >
      <div
        className={`${teamSizeDropdownMenu} ${
          teamSizes.length > 2
            ? teamSizeDropdownMenu__col6
            : teamSizeDropdownMenu__col2
        }`}
      >
        <Dropdown.Item as="div">
          {withSpaceTypePrompt ? (
            <TeamSizeWithSpaceType
              spaceType={spaceType}
              setSpaceType={setSpaceType}
              teamSizeSpaceType={teamSizeSpaceType}
              setTeamSizeSpaceType={setTeamSizeSpaceType}
              spaceTypeAfterTeamSize={spaceTypeAfterTeamSize}
              setSpaceTypeAfterTeamSize={setSpaceTypeAfterTeamSize}
              setTeamSize={setTeamSize}
              setTeamSizeDropdownShow={setTeamSizeDropdownShow}
            />
          ) : (
            <TeamSizeWithoutSpaceType
              teamSizes={teamSizes}
              setTeamSize={setTeamSize}
            />
          )}
        </Dropdown.Item>
      </div>
    </DropdownButton>
  );
};

export default DropdownTeamSize;

const TeamSizeWithSpaceType = ({
  teamSizeSpaceType,
  setTeamSizeSpaceType,
  spaceTypeAfterTeamSize,
  setSpaceTypeAfterTeamSize,
  setTeamSizeDropdownShow,
}) => {
  const [isHq] = useQueryParams("isHq", "");

  const [tempTeamSize, setTempTeamSize] = useState("1");
  const teamSizeSingleDigitOptions = [
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
  ];
  return (
    <>
      {spaceTypeAfterTeamSize ? (
        <div className={spaceTypeBox}>
          <p className={`mb-2`}>{i18n.t("locationPage.WorkspaceLookingFor")}</p>
          <Button
            onClick={async () => {
              setTeamSizeDropdownShow(false);
              setSpaceTypeAfterTeamSize(false);
              setTeamSizeSpaceType(!teamSizeSpaceType);
              await updateQueryString([
                {
                  key: "space_type",
                  value: "Private office",
                },
                {
                  key: "team_size",
                  value: tempTeamSize,
                },
              ]);
            }}
          >
            <span>{i18n.t("PrivateOffices")}</span>
          </Button>
          <Button
            onClick={async () => {
              setTeamSizeDropdownShow(false);
              setSpaceTypeAfterTeamSize(false);
              setTeamSizeSpaceType(!teamSizeSpaceType);
              await updateQueryString([
                {
                  key: "space_type",
                  value: "Dedicated desks",
                },
                {
                  key: "team_size",
                  value: tempTeamSize,
                },
              ]);
            }}
          >
            <span>{i18n.t("DedicatedDesk")}</span>
          </Button>
          <div onClick={() => setSpaceTypeAfterTeamSize(false)}>
            <span className="arrow-left-primary"></span>
            <p className={`d-inline-block ms-2`}>{i18n.t("Back")}</p>
          </div>
        </div>
      ) : (
        <>
          {!isHq && (
            <>
              <div
                onClick={async () => {
                  setSpaceTypeAfterTeamSize(true);
                  setTempTeamSize(`1`);
                }}
                className={`${teamSizeOption1}`}
              >
                <span>1</span>
              </div>
              <div
                onClick={async () => {
                  setSpaceTypeAfterTeamSize(true);
                  setTempTeamSize(`2`);
                }}
                className={`${teamSizeOption1}`}
              >
                <span>2</span>
              </div>
            </>
          )}

          {!isHq && (
            <>
              {teamSizeSingleDigitOptions.map((digit, index) => (
                <div
                  key={index}
                  onClick={async () => {
                    setTeamSizeDropdownShow(false);
                    setTeamSizeSpaceType(!teamSizeSpaceType);
                    await updateQueryString([
                      { key: "space_type", value: "Private office" },
                      { key: "team_size", value: digit },
                    ]);
                  }}
                  className={`${teamSizeOption1}`}
                >
                  <span>{digit}</span>
                </div>
              ))}
              <div
                onClick={async () => {
                  setTeamSizeDropdownShow(false);
                  setTeamSizeSpaceType(!teamSizeSpaceType);
                  await updateQueryString([
                    {
                      key: "space_type",
                      value: "Private office",
                    },
                    {
                      key: "team_size",
                      value: "21-25",
                    },
                  ]);
                }}
                className={`${teamSizeOption2}`}
              >
                <span>21-25</span>
              </div>
              <div
                onClick={async () => {
                  setTeamSizeDropdownShow(false);
                  setTeamSizeSpaceType(!teamSizeSpaceType);
                  await updateQueryString([
                    {
                      key: "space_type",
                      value: "Private office",
                    },
                    {
                      key: "team_size",
                      value: "26-30",
                    },
                  ]);
                }}
                className={`${teamSizeOption2}`}
              >
                <span>26-30</span>
              </div>
            </>
          )}

          <div
            onClick={async () => {
              setTeamSizeDropdownShow(false);
              setTeamSizeSpaceType(!teamSizeSpaceType);
              await updateQueryString([
                {
                  key: "space_type",
                  value: "Private office",
                },
                {
                  key: "team_size",
                  value: isHq ? "41-50" : "31-50",
                },
              ]);
            }}
            className={`${teamSizeOption3}`}
          >
            <span>{isHq ? "41-50" : "31-50"}</span>
          </div>
          <div
            onClick={async () => {
              setTeamSizeDropdownShow(false);
              setTeamSizeSpaceType(!teamSizeSpaceType);
              await updateQueryString([
                {
                  key: "space_type",
                  value: "Private office",
                },
                {
                  key: "team_size",
                  value: "51-100",
                },
              ]);
            }}
            className={`${teamSizeOption3}`}
          >
            <span>51-100</span>
          </div>
          <div
            onClick={async () => {
              setTeamSizeDropdownShow(false);
              setTeamSizeSpaceType(!teamSizeSpaceType);
              await updateQueryString([
                {
                  key: "space_type",
                  value: "Private office",
                },
                {
                  key: "team_size",
                  value: "101-150",
                },
              ]);
            }}
            className={`${teamSizeOption4}`}
          >
            <span>101-150</span>
          </div>
        </>
      )}
    </>
  );
};

const TeamSizeWithoutSpaceType = ({ teamSizes }) => {
  const [teamSize, setTeamSize] = useQueryParams("team_size", "Team size");

  return (
    <>
      {teamSizes.map(({ size, label }, index) => {
        return (
          <div
            onClick={async () => {
              await setTeamSize(label);
            }}
            key={index}
            className={cx({
              [teamSizeOption1]: size === 1,
              [teamSizeOption2]: size === 2,
              [teamSizeOption3]: size === 3,
              [teamSizeOption4]: size === 4,
            })}
          >
            <span>{label}</span>
          </div>
        );
      })}
    </>
  );
};
