import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import * as s from "./Carousel.module.scss";
import ArrowLeft from "../../images/icons/Arrow_L.svg";
import ArrowRight from "../../images/icons/Arrow_R.svg";
import ArrowLeftWhite from "../../images/design-components/Arrow_L_WHT.png";
import ArrowRightWhite from "../../images/design-components/Arrow_R_WHT.png";
import cx from "classnames";
import get from "lodash/get";

const CarouselComponent = ({
  images,
  white = false, // @john is this white prop really needed?
  productHeroSection = false,
}) => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setCarouselIndex(selectedIndex);
  };

  return (
    <>
      <Carousel
        className={`${white ? "" : s.carouselMain}`}
        activeIndex={carouselIndex}
        onSelect={handleSelect}
        nextIcon={
          <span>
            <img alt="" src={white ? ArrowRightWhite : ArrowRight} />
          </span>
        }
        prevIcon={
          <span>
            <img alt="" src={white ? ArrowLeftWhite : ArrowLeft} />
          </span>
        }
      >
        {images.map((item, idx) => {
          const text = get(item, "descriptive_text", null);
          const left = get(item, "x", null);
          const top = get(item, "y", null);
          return (
            <Carousel.Item key={idx}>
              <div
                className={cx({
                  [s.carouseItemWrapper]: true,
                  [s.productHeroSection]: productHeroSection,
                })}
              >
                <img
                  alt=""
                  src={get(item, "image.url", "")}
                  className={`w-100 d-block ${s.carouselImage}`}
                />
                {text && left && top ? (
                  <span style={{ left: `${left}%`, top: `${top}%` }}>
                    <span />
                    <span>{text}</span>
                  </span>
                ) : null}
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
};

export default CarouselComponent;
