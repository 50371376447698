const LEFT = { position: "left" };
const RIGHT = { position: "right" };
const TOP = { position: "top" };
const BOTTOM = { position: "bottom" };

export default {
  "0f70027d-dda2-4629-b770-94d7c18fef8f": {
    streets: [
      { name: "René-Lévesque Blvd W", ...RIGHT },
      { name: "Metcalfe Street", ...BOTTOM },
    ],
  },
  "54a0662e-cd74-47ed-801b-cfd6fa553d08": {
    streets: [
      { name: "Bank Street", ...RIGHT },
      { name: "Queen Street", ...BOTTOM },
    ],
  },
  "33452100-c93c-4439-ade8-ea2d5c9a64f3": {
    streets: [
      { name: "Burrard Street", ...RIGHT },
      { name: "West Georgia Street", ...BOTTOM },
    ],
  },
  "57e6ac67-fea9-4044-9fea-2e1968c02498": {
    streets: [
      { name: "Burrard Street", ...RIGHT },
      { name: "West Georgia Street", ...BOTTOM },
    ],
  },
  "de407a15-0a37-4652-af3f-2383a393a95f": {
    streets: [
      { name: "Robson Street", ...TOP },
      { name: "Seymour Street", ...LEFT },
    ],
  },
  "a43cf00b-7640-4167-bf6c-905d6ad5927d": {
    streets: [
      { name: "King St. West", ...BOTTOM },
      { name: "University Avenue", ...LEFT },
    ],
  },
  "b3e2d1e2-6fd3-49e5-965e-5f9ec21c334f": {
    streets: [
      { name: "King St. West", ...BOTTOM },
      { name: "University Avenue", ...LEFT },
    ],
  },
  "0e889cf3-7270-4918-9b22-b803ce905c65": {
    streets: [
      { name: "Portland Street", ...BOTTOM },
      { name: "King St. West", ...LEFT },
    ],
  },
  "e42559ed-87de-40ef-a59a-b5b1d6cef016": {
    streets: [
      { name: "Portland Street", ...BOTTOM },
      { name: "King St. West", ...LEFT },
    ],
  },
  "750a2808-cf4e-48dd-a8d0-ad94ade365c3": {
    streets: [
      { name: "Portland Street", ...BOTTOM },
      { name: "King St. West", ...LEFT },
    ],
  },
  "9868fe7b-84c5-45be-ad71-414eedb90140": {
    streets: [
      { name: "University Avenue", ...BOTTOM },
      { name: "Queen Street West", ...RIGHT },
    ],
  },
  "7053413d-1a3e-49da-83e5-e6438eb2898a": {
    streets: [
      { name: "Temperance Street", ...BOTTOM },
      { name: "Yonge Street", ...RIGHT },
    ],
  },
  "1ace08d8-6638-4a90-bf83-9690ad470558": {
    streets: [
      { name: "Temperance Street", ...BOTTOM },
      { name: "Yonge Street", ...RIGHT },
    ],
  },
  "52e0a50b-80e8-4ebc-a775-1316602082fc": {
    streets: [
      { name: "Temperance Street", ...BOTTOM },
      { name: "Yonge Street", ...RIGHT },
    ],
  },
};
