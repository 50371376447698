import React from "react";
import { footerSlogan } from "./FooterSlogan.module.scss";
import get from "lodash/get";
import DesktopEn from "./slogan_en_desktop.svg";
import MobileEn from "./slogan_en_mobile.svg";
import DesktopFr from "./slogan_fr_desktop.svg";
import MobileFr from "./slogan_fr_mobile.svg";

const FooterSlogan = ({ activeDocMeta }) => {
  const desktopSlogan =
    get(activeDocMeta, "lang", "") === "fr-ca" ? DesktopFr : DesktopEn;
  const mobileSlogan =
    get(activeDocMeta, "lang", "") === "fr-ca" ? MobileFr : MobileEn;
  return (
    <div className={`container-xxl ${footerSlogan}`}>
      <img alt="" className={"d-none d-md-block"} src={desktopSlogan} />
      <img alt="" className={"d-block d-md-none"} src={mobileSlogan} />
    </div>
  );
};

export default FooterSlogan;
