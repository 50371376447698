import React from "react";
import cx from "classnames";
import { useFormikContext } from "formik";
import { formError } from "../../styles/forms/forms.module.scss";

const FormikInput = ({
  name,
  label,
  type = "text",
  className = "col-lg-6 my-2",
}) => {
  const formik = useFormikContext();

  return (
    <div className={className}>
      <input
        type={type}
        className={` ${cx({
          [formError]: formik?.touched?.[name] && formik?.errors?.[name],
          "form-control": true,
        })}`}
        placeholder={label}
        {...formik.getFieldProps(name)}
      />
    </div>
  );
};

export default FormikInput;
