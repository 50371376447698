import React from "react";
import {
  addBottomPadding,
  addTopPadding,
} from "../../../../pages/whyiq/whyiq.module.scss";
import * as s from "./index.module.scss";
import get from "lodash/get";
import { PrismicRichText } from "@prismicio/react";
import cx from "classnames";

const FullWidthText = ({ slice }) => {
  return (
    <div>
      <div
        className={cx("container-fluid text-center", {
          [addTopPadding]: !get(slice, "primary.remove_top_margin", true),
          [addBottomPadding]: !get(slice, "primary.remove_bottom_margin", true),
        })}
        style={{
          backgroundColor:
            get(slice, "primary.background_color", "#ffffff") || "#ffffff",
        }}
      >
        <div className={s.text_styles}>
          <PrismicRichText field={get(slice, "primary.title", null)} />
          <PrismicRichText field={get(slice, "primary.description", null)} />
        </div>
      </div>
    </div>
  );
};

export default FullWidthText;
