import React from "react";
import BookingComponentContent from "./BookingComponentContent";
import { Elements } from "@stripe/react-stripe-js";

const BookingComponent = (props) => {
  return (
    <div>
      <Elements stripe={props.stripePromise} options={{}}>
        <BookingComponentContent {...props} />
      </Elements>
    </div>
  );
};

export default BookingComponent;
