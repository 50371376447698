import React from "react";
import * as s from "./LocationsProfileReviews.module.scss";
import get from "lodash/get";
import { ImStarFull as FullStar, ImStarHalf as HalfStar } from "react-icons/im";
import truncate from "lodash/truncate";
import cx from "classnames";

const Stars = ({ rating = 4.5 }) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating - fullStars > 0;
  return (
    <div className={s.starsContainer}>
      {Array.from({ length: fullStars }, (v, i) => i).map((i) => (
        <FullStar className={s.star} key={i} />
      ))}
      {halfStar && <HalfStar className={s.star} />}
    </div>
  );
};

const LocationsProfileReviews = ({ data, profile }) => {
  const title = get(profile, "reviews_section_title[0].text", "");

  const customReviewUrl = get(profile, "write_a_review_url", "");

  const reviewScore = get(data, "rating", 0);
  const reviewScoreString = Number(reviewScore).toFixed(1);

  if (!title || !get(data, "rating", false)) return null;

  return (
    <div className={`container-xxl ${s.lprSection}`}>
      <h2 className={s.lprSection__title}>{title}</h2>

      <div className="row justify-content-center">
        <div className="col-xl-10">
          <div className={cx("row")}>
            <div className={"col"}>
              <div className={cx(s.header, s.border)}>
                <div
                  className={"d-flex align-items-center justify-content-start"}
                >
                  <p className={s.rating}>{reviewScoreString}</p>{" "}
                  <Stars rating={get(data, "rating", 0)} />
                </div>
                <p className={s.total}>
                  Based on {get(data, "total", 0)} reviews
                </p>
                <a
                  className={s.seeReviews}
                  href={`https://search.google.com/local/reviews?placeid=${get(
                    data,
                    "placeId",
                    "",
                  )}`}
                  target={"_blank"}
                >
                  See all reviews
                </a>{" "}
                {customReviewUrl ? (
                  <a
                    className={s.writeReview}
                    href={customReviewUrl}
                    target={"_blank"}
                  >
                    Write a review
                  </a>
                ) : (
                  <a
                    className={s.writeReview}
                    href={`https://search.google.com/local/writereview?placeid=${get(
                      data,
                      "placeId",
                      "",
                    )}`}
                    target={"_blank"}
                  >
                    Write a review
                  </a>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            {get(data, "items", [])
              .filter((item) => Number(get(item, "rating", 0)) >= 4)
              .slice(0, 4)
              .map((item, idx) => (
                <div
                  className={"col-sm-6 col-xl-3 mt-4 mt-xl-2 d-flex"}
                  key={idx}
                >
                  <div className={cx(s.border)}>
                    <p>{get(item, "author_name", "")}</p>
                    <Stars rating={get(item, "rating", 0)} />
                    <span>{get(item, "relative_time_description", "")}</span>
                    <p>
                      {truncate(get(item, "text", ""), {
                        length: 175,
                        separator: " ",
                      })}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationsProfileReviews;
