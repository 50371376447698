import React from "react";
import * as s from "./MultiInfos.module.scss";
import SimpleBar from "simplebar-react";

const MultiInfos = ({ isContactPage, infoItems = [], btnLink = "" }) => {
  return (
    <>
      <div className={`container-fluid p-0`}>
        <div className={`position-relative`}>
          <SimpleBar style={{ width: "100%" }} autoHide={false}>
            <div className={s.scrollContainer}>
              {infoItems.map((infoItem, index) => (
                <div key={index} className={s.infoItemContainer}>
                  <h6
                    className={
                      infoItem.infoTitleIcon ? s.infoItemTitleIcon : ""
                    }
                  >
                    {infoItem.infoTitle}
                  </h6>
                  {infoItem.infoDescList.map((descItem, idx) => {
                    if (descItem.includes("@iqoffices.com")) {
                      return (
                        <p key={idx}>
                          <a href={`mailto:${descItem}`}>{descItem}</a>
                        </p>
                      );
                    }
                    if (descItem === "1-800-933-3176") {
                      return (
                        <p key={idx}>
                          <a href={`tel:18009333176`}>{descItem}</a>
                        </p>
                      );
                    }
                    if (isContactPage) {
                      return <p key={idx}>{descItem}</p>;
                    }

                    return (
                      <p className={s.bulletPoint} key={idx}>
                        {descItem}
                      </p>
                    );
                  })}
                </div>
              ))}
            </div>
          </SimpleBar>
        </div>
      </div>
    </>
  );
};

export default MultiInfos;
