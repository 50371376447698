import React from "react";
import ArrowRightPrimary from "../../../images/design-components/Arrow_R.svg";
import ArrowRightWhite from "../../../images/design-components/Arrow_R_WHT.svg";
import ArrowLeftPrimary from "../../../images/design-components/Arrow_L.svg";
import ArrowLeftWhite from "../../../images/design-components/Arrow_L _WHT.svg";

const CustomButtonGroup = ({
  next,
  previous,
  goToSlide,
  className,
  white,
  prevIndex = () => null,
  nextIndex = () => null,
  ...rest
}) => {
  return (
    <div className={className}>
      <div
        tabIndex="0"
        role="button"
        onKeyDown={() => {
          previous();
          prevIndex();
        }}
        onClick={() => {
          previous();
          prevIndex();
        }}
      >
        <img alt="" src={white ? ArrowLeftWhite : ArrowLeftPrimary} />
      </div>
      <div
        tabIndex="0"
        role="button"
        onKeyDown={() => {
          next();
          nextIndex();
        }}
        onClick={() => {
          next();
          nextIndex();
        }}
      >
        <img alt="" src={white ? ArrowRightWhite : ArrowRightPrimary} />
      </div>
    </div>
  );
};

export default CustomButtonGroup;
