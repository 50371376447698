import React from "react";
import * as s from "./index.module.scss";
import resolvePrismicLink from "../../utils/resolvePrismicLink";

const LocationProfileCta = ({ data }) => {
  const blocks = data?.items || [];
  const blockCount = blocks.length;
  return (
    <div className={`container-xxl ${s.customContainer}`}>
      <div className="row g-0 justify-content-center">
        {blocks.map((block, idx) => {
          const { link, target } = resolvePrismicLink(block.button_link);
          return (
            <div
              className={`${
                blockCount === 1 ? " col-12" : "col-md-6"
              } mt-2 mt-md-3 ${idx === 0 ? `pe-md-2` : `ps-md-2`}`}
              key={idx}
            >
              <div
                className={`d-flex align-items-center justify-content-center ${s.block}`}
              >
                {block?.image?.url && (
                  <img src={block?.image?.url || ""} alt={block.title} />
                )}
                <div className="d-flex flex-column align-items-center">
                  <h3>{block.title}</h3>
                  <a
                    className={`btn btn-outline-light mt-3 mt-lg-4 ${s.button}`}
                    href={link}
                    target={target}
                  >
                    {block.button_label}
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LocationProfileCta;
