export const isBrowser = typeof window !== "undefined";

export const isDev = process.env.NODE_ENV === "development";

export const formValue = (devValue, defaultValue = "") =>
  isDev ? devValue : defaultValue;

export const isStaging =
  typeof window !== "undefined" &&
  window.location.host === "iq-staging.netlify.app";

export const isProd = process.env.GATSBY_STAGE === "production";

export const getQueryParams = () => {
  if (typeof window === "undefined") {
    return new URLSearchParams();
  }
  return new URLSearchParams(window.location.search);
};

export const isDesk = (type = "") => type.toLocaleString().indexOf("desk") > -1;

export const hsSource = "DIRECT_TRAFFIC";
export const hsOwnerId =
  process.env.GATSBY_STAGE === "production" ? 37633877 : 54627858;

export const isOffice = (type = "") =>
  type.toLocaleString().indexOf("office") > -1;
