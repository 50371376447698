import React, { useState } from "react";
import * as s from "./OverviewItem.module.scss";
import get from "lodash/get";
import { Link } from "gatsby";
import OverviewItemModal from "../OverviewItemModal";
import cx from "classnames";

const Title = ({ isPlainText = false, title = "", titleIcon = false }) => {
  if (!titleIcon) {
    return (
      <div className={s.oviTitle}>{isPlainText ? <h3>{title}</h3> : title}</div>
    );
  } else {
    return <>{isPlainText ? <h3>{title}</h3> : title}</>;
  }
};

const OverviewItem = ({
  image,
  title,
  titleIcon,
  description,
  descriptionList,
  descriptionTabletRow,
  variesPerLocation,
  ctaLabel,
  ctaLink,
  ctaTarget = "_self",
  imageRight,
  imageFirstOnMobile,
  imageRightOnTablet,
  isPlainText = false,
  modalLabel = false,
  modalTitle = "",
  modalDescription = "",
  modalButtonLabel = "",
  modalButtonLink,
  modalImage = "",
  switchToPadding = false,
  maintainImageAspectRatio = false,
}) => {
  const [modal, setModal] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setModal(true);
  };

  return (
    <div
      className={`container-xxl overflow-hidden  ${
        switchToPadding ? s.oviPaddingSection : s.oviSection
      }`}
    >
      <OverviewItemModal
        show={modal}
        setShow={setModal}
        title={modalTitle}
        description={modalDescription}
        image={modalImage}
        button={{
          label: modalButtonLabel,
          link: get(modalButtonLink, "link", "") || null,
          target: get(modalButtonLink, "target", "") || "_self",
        }}
      />

      {/*DESKTOP*/}
      <div className={"d-lg-block d-none"}>
        <div className={s.oviContainer}>
          <div className="row">
            <div
              className={`${
                imageRight
                  ? `col-lg-4 offset-lg-1 d-flex flex-column justify-content-center`
                  : `col-lg-4 offset-lg-1 d-flex flex-column justify-content-center order-last`
              } `}
            >
              <div className="d-flex align-items-center mb-3">
                <Title isPlainText={isPlainText} title={title} />

                {titleIcon ? (
                  <img alt="" className={s.oviTitleIcon} src={titleIcon} />
                ) : (
                  ""
                )}
              </div>

              <div
                className={`${descriptionList ? `mb-3` : ``} ${
                  s.oviDescription
                }`}
              >
                {description}
              </div>

              {descriptionList ? (
                <ul className={s.oviDescriptionList}>
                  {descriptionList.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              ) : (
                ""
              )}

              {variesPerLocation ? (
                <p className="mb-0">* varies per location</p>
              ) : (
                ""
              )}

              {ctaLabel ? (
                <Link
                  to={ctaLink}
                  className={`btn btn-fixed btn-outline-primary ${s.oviCtaButton}`}
                  target={ctaTarget}
                  title={ctaLabel}
                >
                  <span>{ctaLabel}</span>
                </Link>
              ) : (
                ""
              )}
              {modalLabel && modalTitle && (
                <>
                  <a
                    href={"#modal"}
                    className={s.modalTriggerButton}
                    onClick={openModal}
                  >
                    {modalLabel}
                  </a>
                </>
              )}
            </div>
            <div
              className={
                imageRight
                  ? `col-lg-5 offset-lg-1 d-flex align-items-center`
                  : `col-lg-5 offset-lg-1 d-flex align-items-center order-first`
              }
            >
              <div className={s.oviImageContainer}>
                <img
                  alt=""
                  src={image}
                  className={
                    maintainImageAspectRatio ? s.object_fit_contain : ""
                  }
                  loading={"lazy"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*TABLET*/}
      <div className={"container-xxl d-none d-md-block d-lg-none"}>
        <div className={cx(s.oviContainer, `row`)}>
          {!descriptionTabletRow ? (
            <div className="row">
              <div
                className={`${
                  imageRight ? `col-md-6 ` : `order-last col-md-6 `
                } d-flex flex-column justify-content-center`}
              >
                {titleIcon ? (
                  <img alt="" className={s.oviTitleIcon} src={titleIcon} />
                ) : (
                  ""
                )}

                <Title isPlainText={isPlainText} title={title} />

                <div
                  className={`${descriptionList ? `mb-3` : ``} ${
                    s.oviDescription
                  }`}
                >
                  {description}
                </div>

                {descriptionList ? (
                  <ul className={s.oviDescriptionList}>
                    {descriptionList.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}

                {variesPerLocation ? (
                  <p className="mb-0">* varies per location</p>
                ) : (
                  ""
                )}
              </div>
              <div className={imageRight ? `col-md-6` : `order-first col-md-6`}>
                {image && (
                  <>
                    <div className={s.oviImageContainer}>
                      <img alt="" src={image} loading={"lazy"} />
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div
                  className={cx(
                    `d-flex flex-column justify-content-end col-4`,
                    { [`col-md-4 offset-md-1`]: imageRight },
                    { [`col-md-4`]: !imageRight },
                  )}
                >
                  {titleIcon ? (
                    <img alt="" className={s.oviTitleIcon} src={titleIcon} />
                  ) : (
                    ""
                  )}

                  <div className={`my-auto`}>
                    <Title isPlainText={isPlainText} title={title} />
                  </div>

                  {ctaLabel ? (
                    <div>
                      <Link
                        to={ctaLink}
                        className={`btn btn-fixed btn-outline-primary ${s.oviCtaButtonRow}`}
                        target={ctaTarget}
                        title={ctaLabel}
                      >
                        <span>{ctaLabel}</span>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={cx(`col-8 col-md-7`, {
                    [`order-first`]: !imageRight,
                  })}
                >
                  <img
                    alt=""
                    src={image}
                    className={s.oviImage}
                    loading={"lazy"}
                  />
                </div>
              </div>

              <div className="row">
                <div
                  className={cx({
                    [`order-last col-md-12`]: imageRight,
                    [`col-md-12`]: !imageRight,
                  })}
                >
                  <div
                    className={cx(s.oviDescription, {
                      [`mb-3`]: descriptionList,
                    })}
                  >
                    {description}
                  </div>

                  {descriptionList ? (
                    <ul className={s.oviDescriptionList}>
                      {descriptionList.map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}

                  {modalLabel && modalTitle && (
                    <>
                      <a
                        href={"#modal"}
                        className={s.modalTriggerButton}
                        onClick={openModal}
                      >
                        {modalLabel}
                      </a>
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          {ctaLabel && !descriptionTabletRow ? (
            <div className={`row`}>
              <div
                className={cx(`col-md-6 d-flex`, {
                  [`offset-md-6`]: imageRight,
                })}
              >
                <Link
                  to={ctaLink}
                  className={cx(
                    s.oviCtaButton,
                    `btn btn-fixed btn-outline-primary`,
                    { [`ms-auto`]: imageRight },
                  )}
                  // className={`btn btn-fixed btn-outline-primary ${s.oviCtaButton}`}
                  target={ctaTarget}
                  title={ctaLabel}
                >
                  <span>{ctaLabel}</span>
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {/*PHONE*/}
      <div className={"d-block d-md-none"}>
        <div className={s.oviContainer}>
          <div className="d-flex flex-column">
            {titleIcon ? (
              <div
                className={`d-flex justify-content-between align-items-end mb-4`}
              >
                <Title
                  isPlainText={isPlainText}
                  title={title}
                  titleIcon={titleIcon}
                />
                <img alt="" className={s.oviTitleIcon} src={titleIcon} />
              </div>
            ) : (
              <Title isPlainText={isPlainText} title={title} />
            )}

            <div
              className={`${s.oviImageContainer} ${
                imageFirstOnMobile && `order-first mb-3`
              }`}
            >
              <img alt="" src={image} />
            </div>

            {descriptionList ? (
              <ul className={s.oviDescriptionList}>
                {descriptionList.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            ) : (
              <div
                className={`${descriptionList ? `mb-3` : ``} ${
                  s.oviDescription
                }`}
              >
                {description}
              </div>
            )}

            {modalLabel && modalTitle && (
              <>
                <a
                  href={"#modal"}
                  className={s.modalTriggerButton}
                  onClick={openModal}
                >
                  {modalLabel}
                </a>
              </>
            )}

            {ctaLabel ? (
              <Link
                to={ctaLink}
                target={ctaTarget}
                className={`btn btn-fixed btn-outline-primary ${
                  descriptionTabletRow ? s.oviCtaButtonRow : s.oviCtaButton
                }`}
                title={ctaLabel}
              >
                <span>{ctaLabel}</span>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewItem;
