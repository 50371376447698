import React from "react";
import * as s from "./ProductWhatsIncluded.module.scss";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";
import splitNl2Array from "../../utils/splitNl2Array";
import { Button } from "react-bootstrap";
import resolvePrismicLink from "../../utils/resolvePrismicLink";
import CustomPrismicLink from "../CustomPrismicLink";

const ProductWhatsIncluded = ({ slice }) => {
  const list = get(slice, "items", []);

  const { link, target } = resolvePrismicLink(
    get(slice, "primary.button_link", {}),
  );

  return (
    <div className={`container-fluid ${s.wiSection}`}>
      <div className={`container-xxl`}>
        <div className={`row`}>
          <div className={`offset-lg-2 col-lg-8`}>
            <div className={`${s.wiSection__title}`}>
              <PrismicRichText field={get(slice, "primary.title", [])} />
            </div>
          </div>
        </div>
        <div className={`row justify-content-center`}>
          <div className={`[ col-lg-10 col-xl-8 ] [ col-12 ] `}>
            {list.map((item, idx) => {
              const icon = get(item, "icon.url", "");
              return (
                <div className={s.wiSection__item} key={idx}>
                  {/*<div className={s.wiSection__item__imgContainer}>*/}
                  {/*  {icon && <img alt="" src={icon} width={32} height={32} />}*/}
                  {/*</div>*/}
                  {icon && <img alt="" src={icon} width={32} height={32} />}
                  <div>
                    <h5>{get(item, "text", "")}</h5>
                    {get(item, "description", "") && (
                      <p>
                        {splitNl2Array(get(item, "description", "")).map(
                          (text, idx) => (
                            <React.Fragment key={idx}>
                              {text}
                              <br />
                            </React.Fragment>
                          ),
                        )}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {link && get(slice, "primary.button_label", "") && (
          <CustomPrismicLink to={link} target={target}>
            <Button className={`mx-auto mt-4`}>
              <span>{get(slice, "primary.button_label", "")}</span>
            </Button>
          </CustomPrismicLink>
        )}
      </div>
    </div>
  );
};

export default ProductWhatsIncluded;
