import React, { useState } from "react";
import { Button } from "react-bootstrap";
import cx from "classnames";
import i18n from "../../utils/i18n";
import SubmitButton from "../../SubmitButton";
import * as Yup from "yup";
import { navigate } from "gatsby";
import { Formik } from "formik";
import submitFormToHubSpot from "../../utils/submitFormToHubSpot";
import FormikInput from "../CustomHubSpotForm/formik-input";
import CustomModal from "../CustomModal";

const ModalForm = ({ slice }) => {
  const [showModalPopup, setShowModalPopup] = useState(false);

  const [sent, setSent] = useState(false);

  return (
    <>
      <Button
        onClick={() => setShowModalPopup(true)}
        className={"ms-2 px-3 px-lg-4"}
        variant={"primary"}
      >
        <span>{slice?.primary?.button_label || i18n.t("GetInTouch")}</span>
      </Button>
      <CustomModal
        show={showModalPopup}
        onHide={() => setShowModalPopup(false)}
      >
        <h3 className={"mb-4 pb-2"}>{slice?.primary?.modal_title}</h3>
        <p className={"mb-4"}>{slice?.primary?.modal_description}</p>

        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            companyName: "",
            message: "",
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required(i18n.t("Required")),
            lastName: Yup.string().required(i18n.t("Required")),
            email: Yup.string()
              .email(i18n.t("InvalidEmailAddress"))
              .required(i18n.t("Required")),
            companyName: Yup.string().required(i18n.t("Required")),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (process.env.NODE_ENV === "development") {
              setSubmitting(false);
              setSent(true);
              navigate("?submitted=true")
                .then(() => null)
                .catch(() => null);
              console.log("local");
              return;
            }
            const data = [
              { name: "firstname", value: values?.firstName },
              { name: "lastname", value: values?.lastName },
              { name: "email", value: values?.email },
              { name: "company", value: values?.companyName },
              {
                name: "message",
                value: values?.message,
              },
              {
                name: "most_recent_source",
                value: slice.primary.most_recent_source || "",
              },
            ];

            const formId = slice?.primary?.hubspot_form_id;

            submitFormToHubSpot(formId, data)
              .then((res) => {
                //
              })
              .catch((e) => {})
              .finally(() => {
                setSubmitting(false);
                setSent(true);
                navigate("?submitted=true")
                  .then(() => null)
                  .catch(() => null);
              });
          }}
        >
          {({ values, handleSubmit, isSubmitting, getFieldProps }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FormikInput
                  name={"firstName"}
                  label={`${i18n.t("profilePage.FirstName")}*`}
                  className={"col-lg-12 my-3"}
                />
                <FormikInput
                  name={"lastName"}
                  label={`${i18n.t("profilePage.LastName")}*`}
                  className={"col-lg-12 my-3"}
                />
                <FormikInput
                  name={"email"}
                  label={`${i18n.t("profilePage.EmailAddress")}*`}
                  className={"col-lg-12 my-3"}
                />
                <FormikInput
                  name={"companyName"}
                  label={`${i18n.t("profilePage.CompanyName")}*`}
                  className={"col-lg-12 my-3"}
                />

                <textarea
                  id="message"
                  name="message"
                  className={cx("form-control mb-3")}
                  placeholder={i18n.t(
                    "Additional information about your inquiry",
                  )}
                  {...getFieldProps("message")}
                />

                <SubmitButton
                  variant={"outline-light"}
                  label={slice?.primary?.modal_button_label}
                  className={""}
                  progress={isSubmitting}
                  success={sent}
                  type={"submit"}
                />

                {sent && (
                  <p className="mt-4">
                    {slice?.primary?.form_success_message ||
                      i18n.t("SubscriptionSuccessMessage")}
                  </p>
                )}
              </form>
            );
          }}
        </Formik>
      </CustomModal>
    </>
  );
};

export default ModalForm;
