import React, { useEffect, useState } from "react";
import {
  lpfaSection,
  lpfaSection__amenities,
  lpfaSection__button,
  lpfaSection__carousel,
  lpfaSection__carouselImageTitle,
  lpfaSection__title,
} from "./LocationsProfileFeaturedAmenities.module.scss";
import { CarouselMulti } from "../CarouselMulti/CarouselMulti";
import RightArrow from "../../images/design-components/Right.svg";
import LocationsProfileFeaturedAmenitiesModal from "../LocationsProfileFeaturedAmenitiesModal/LocationsProfileFeaturedAmenitiesModal";
import i18n from "../../utils/i18n";
import cx from "classnames";

const LocationsProfileFeaturedAmenities = ({
  carouselImages,
  carouselImagesTitles,
  modalTitle,
  slices,
  modalButtonLabel,
  locationName,
  locationCity,
  cmsData,
}) => {
  const [activeCarouselItem, setActiveCarouselItem] = useState(0);
  const carouselCb = (previousSlide, { currentSlide, onMove }) => {
    setActiveCarouselItem(currentSlide);
  };

  const [amenitiesModalShow, setAmenitiesModalShow] = useState(false);

  if (!carouselImages || carouselImages.length === 0 || !carouselImages[0])
    return null;

  return (
    <div className={`container-fluid px-0 ${lpfaSection}`}>
      <div className={`container-xxl`}>
        <h2 className={lpfaSection__title}>
          {cmsData?.amenities_title?.[0]?.text || i18n.t("FeaturedAmenities")}
        </h2>
      </div>
      <div
        className={`[ d-none d-md-flex ] container-xxl ${lpfaSection__amenities}`}
      >
        <div className={`row position-relative px-4 px-lg-0 mx-0`}>
          <div
            className={`position-absolute h-100 bg-primary px-0`}
            style={{ top: "40px", zIndex: "-1", left: "0" }}
          ></div>
          <div
            className={cx(
              `d-flex justify-content-between`,
              `col-lg-10 offset-lg-1`,
            )}
          >
            {carouselImages.map((image, index) => (
              <div
                className={cx(`d-flex flex-column`, { [`mx-3`]: index === 1 })}
                key={index}
              >
                <img alt="" src={image} key={index} />
                <h5>{carouselImagesTitles[index]}</h5>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`[ d-md-none d-block ] container-fluid position-relative ${lpfaSection__carousel}`}
      >
        <div
          className={`position-absolute h-100 bg-primary w-100`}
          style={{ top: "102px", zIndex: "-1", left: "0" }}
        ></div>
        <CarouselMulti
          images={carouselImages}
          afterChange={carouselCb}
          infinite={false}
        />
      </div>
      <div className="container-xxl">
        <h4
          className={`[ d-md-none d-block ] ${lpfaSection__carouselImageTitle}`}
        >
          {carouselImagesTitles[activeCarouselItem]}
        </h4>
      </div>
      {modalButtonLabel && (
        <div className={`container-xxl ${lpfaSection__button}`}>
          <button
            className={`btn-link d-flex px-0 px-lg-3`}
            onClick={() => setAmenitiesModalShow(true)}
          >
            <img alt={""} src={RightArrow} className={`me-3`} />
            <h5>{modalButtonLabel}</h5>
          </button>

          <LocationsProfileFeaturedAmenitiesModal
            show={amenitiesModalShow}
            setAmenitiesModalShow={setAmenitiesModalShow}
            onHide={() => setAmenitiesModalShow(false)}
            slices={slices}
            modalTitle={modalTitle}
            locationName={locationName}
            locationCity={locationCity}
          />
        </div>
      )}
    </div>
  );
};

export default LocationsProfileFeaturedAmenities;
