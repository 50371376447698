import moment from "moment-timezone";

const getBusyTimes = (events, calendar) => {
  let newDates = [];

  for (let i = 0; i < events.length; i++) {
    const event = events[i];
    if (event.isCancelled || event.showAs === "free") {
      continue;
    }
    const eventStartDateTime =
      calendar.calendar_type === "microsoft"
        ? event.start.dateTime
        : event.start;
    const startDate = moment(eventStartDateTime)
      .tz("UTC")
      .clone()
      .tz(calendar.time_zone)
      .format("YYYY-MM-DD");

    if (!newDates.find((item) => item.date === startDate)) {
      newDates.push({ date: startDate, events: [] });
    }

    let { isFullDay, isCancelled } = event;

    const start =
      calendar.calendar_type === "microsoft"
        ? event.start.dateTime
        : event.start;

    const end =
      calendar.calendar_type === "microsoft" ? event.end.dateTime : event.end;

    newDates
      .find((item) => item.date === startDate)
      .events.push({
        start: formattedTime(start, calendar.time_zone, calendar.calendar_type),
        end: formattedTime(end, calendar.time_zone, calendar.calendar_type),
        isFullDay,
        isCancelled,
      });
  }
  return newDates;
};

const formattedTime = (datetime, timezone, calendar_type) => {
  const time =
    calendar_type === "google"
      ? moment
          .utc(new Date(Date.parse(datetime)))
          .tz(timezone)
          .format("YYYY-MM-DD hh:mm:ss a")
      : moment
          .tz(datetime, "YYYY-MM-DDTHH:mm:ss.SSSSSSS", "UTC")
          .tz(timezone)
          .format("YYYY-MM-DD hh:mm:ss a");
  return moment(time, "YYYY-MM-DD hh:mm:ss a").format();
};

export default getBusyTimes;
