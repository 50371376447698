/**
 *
 * @param string - input string
 * @returns {string|*} - returns the string without the Company Name - IQ Offices
 */

const removeCompanyName = (string) => {
  if (typeof string === "undefined" || !string) {
    return "";
  }
  let finalName = string.replace(`IQ Offices `, "");
  finalName = finalName.replace(`IQ Offices`, "");
  finalName = finalName.replace(`iQ Offices `, "");
  finalName = finalName.replace(`iQ Offices`, "");
  if (!finalName) return string;
  return finalName;
};

export default removeCompanyName;
