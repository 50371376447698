import get from "lodash/get";
import groupBy from "lodash/groupBy";
import { getQueryParams } from "../../utils";

export const getSelectedDesksOrOffices = (spProfile = {}) => {
  const params = getQueryParams();

  const ids = params.get("selected_blocks");

  if (!ids) return [];

  const idArray = ids.split(",");

  let offices = [];
  let desks = [];
  const allOffices = get(spProfile, "offices", []);
  const allDesks = get(spProfile, "dedicatedDesks", []);
  idArray.forEach((id) => {
    const item = allOffices.find((item) => item.id === id);
    if (item) {
      offices.push(item);
    }
  });

  idArray.forEach((id) => {
    const item = allDesks.find((item) => item.id === id);
    if (item) {
      desks.push(item);
    }
  });

  const allOptions = [...offices, ...desks];

  const grouped = groupBy(allOptions, "floor_id");

  const categorizedOptions = [];

  for (const key in grouped) {
    if (grouped.hasOwnProperty(key)) {
      categorizedOptions.push(grouped[key]);
    }
  }

  return { categorizedOptions, allOptions };
};

export const getTeamSize = () => {
  const params = getQueryParams();
  const teamSizeString = params.get("team_size");
  if (!teamSizeString) return 0;
  const teamSizeArray = teamSizeString.split("-");
  if (!teamSizeArray || teamSizeArray.legnth === 0) return 0;
  return Number(teamSizeArray[0]);
};

export const getPricePerDesk = (space, team_size = 1) => {
  const item = get(space, "[0]", {});
  const ts = Number(team_size);
  const p_min = item.price_min ? Number(item.price_min) : item.price_per_desk;
  const p_max = item.price_max ? Number(item.price_max) : item.price_per_desk;
  if (p_max <= p_min) {
    return `$${p_min.toLocaleString("en")}`;
  }
  return `$${p_min.toLocaleString("en")}+`;
};
