import axios from "axios";
import get from "lodash/get";

const getProfile = async (spaceId) => {
  const options = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      id: spaceId,
    }),
    url: `${process.env.GATSBY_API_URL}/view-sp`,
  };

  return axios(options)
    .then((res) => get(res, "data.space", {}))
    .catch(() => {});
};

export default getProfile;
