import React from "react";
import {
  customDotActivePrimary,
  customDotActiveWhite,
  customDotPrimary,
  customDotWhite,
} from "./Custom.module.scss";

export const CustomDotsWhite = ({ onClick, images, ...rest }) => {
  const { active } = rest;
  return (
    <button
      className={`${
        active ? `${customDotActiveWhite}` : "inactive"
      } ${customDotWhite}`}
      onClick={() => onClick()}
    >
      <span></span>
    </button>
  );
};

export const CustomDotsPrimary = ({ onClick, images, ...rest }) => {
  const { active } = rest;
  return (
    <button
      className={`${
        active ? `${customDotActivePrimary}` : "inactive"
      } ${customDotPrimary}`}
      onClick={() => onClick()}
    >
      <span></span>
    </button>
  );
};
