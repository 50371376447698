// extracted by mini-css-extract-plugin
export var addOnsColumn = "AddOns-module--add-ons-column--0460b";
export var addOnsColumn__backButton = "AddOns-module--add-ons-column__back-button--ef658";
export var addOnsColumn__item = "AddOns-module--add-ons-column__item--4e7e3";
export var addOnsColumn__item__additionalLocations = "AddOns-module--add-ons-column__item__additional-locations--dc0a6";
export var addOnsColumn__item__additionalLocations__location = "AddOns-module--add-ons-column__item__additional-locations__location--04f28";
export var addOnsColumn__item__description = "AddOns-module--add-ons-column__item__description--e772a";
export var addOnsColumn__item__header = "AddOns-module--add-ons-column__item__header--00c4d";
export var addOnsColumn__item__header2 = "AddOns-module--add-ons-column__item__header-2--aa2c7";
export var addOnsColumn__item__headerAdditionalLocations = "AddOns-module--add-ons-column__item__header-additional-locations--50f7d";
export var addOnsColumn__location = "AddOns-module--add-ons-column__location--08519";
export var addOnsColumn__title = "AddOns-module--add-ons-column__title--85a46";
export var summaryColumn = "AddOns-module--summary-column--03a62";
export var summaryColumn__amenitiesButton = "AddOns-module--summary-column__amenities-button--50e11";
export var summaryColumn__pricingSection = "AddOns-module--summary-column__pricing-section--74d19";
export var summaryColumn__pricingSection__priceItems = "AddOns-module--summary-column__pricing-section__price-items--2ecb6";
export var summaryColumn__pricingSection__priceTotal = "AddOns-module--summary-column__pricing-section__price-total--6aa67";
export var summaryColumn__pricingSection__priceTotalDiv = "AddOns-module--summary-column__pricing-section__price-total-div--9e686";
export var summaryColumn__summaryButton = "AddOns-module--summary-column__summary-button--a318d";
export var summaryColumn__title = "AddOns-module--summary-column__title--be47c";