// extracted by mini-css-extract-plugin
export var notSelected = "DropdownTeamSize-module--notSelected--05158";
export var spaceTypeBox = "DropdownTeamSize-module--space-type-box--a348c";
export var teamSizeButton = "DropdownTeamSize-module--team-size-button--2b3a9";
export var teamSizeButtonProductForm = "DropdownTeamSize-module--team-size-button-product-form--a89c0";
export var teamSizeButtonProductForm__div = "DropdownTeamSize-module--team-size-button-product-form__div--8ee8d";
export var teamSizeButtonProductForm__div__selected = "DropdownTeamSize-module--team-size-button-product-form__div__selected--772e3";
export var teamSizeButtonProductWidget = "DropdownTeamSize-module--team-size-button-product-widget--0be22";
export var teamSizeButtonProductWidgetTitle = "DropdownTeamSize-module--team-size-button-product-widget-title--df9a8";
export var teamSizeDropdownMenu = "DropdownTeamSize-module--team-size-dropdown-menu--ab2ba";
export var teamSizeDropdownMenu__col2 = "DropdownTeamSize-module--team-size-dropdown-menu__col-2--162c8";
export var teamSizeDropdownMenu__col6 = "DropdownTeamSize-module--team-size-dropdown-menu__col-6--92b4f";
export var teamSizeOption1 = "DropdownTeamSize-module--team-size-option-1--17c6b";
export var teamSizeOption2 = "DropdownTeamSize-module--team-size-option-2--99eaa";
export var teamSizeOption3 = "DropdownTeamSize-module--team-size-option-3--9a48e";
export var teamSizeOption4 = "DropdownTeamSize-module--team-size-option-4--a5411";
export var teamSizeSelected = "DropdownTeamSize-module--team-size-selected--aa833";
export var teamSizeSelectedProductWidget = "DropdownTeamSize-module--team-size-selected-product-widget--92ffb";