import worksimplyApi from "../../../services/worksimplyApi";

const getMeetingRoomData = async (id = null) => {
  if (!id) return {};
  const data = await worksimplyApi("meeting-rooms/get", {
    id,
    soId: process.env.GATSBY_SO_ID,
  });
  return data;
};

export default getMeetingRoomData;
