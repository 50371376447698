import React from "react";
import { Accordion, ListGroup, Nav, Navbar, Offcanvas } from "react-bootstrap";
import breakpoints from "../../../utils/breakpoints";
import {
  bookATour,
  headerTabMobBody,
  headerTabMobFooter,
  headerTabMobFooterLinks,
  languageSwitcher,
  mobile_top_icons,
} from "./index.module.scss";
import Member from "../../../images/header/Member.svg";
import * as s from "./Nav.module.scss";
import { tabmobAccordionHeader } from "./Nav.module.scss";
import get from "lodash/get";
import { graphql, Link, useStaticQuery } from "gatsby";
import { SocialLinks } from "../Footer";
import { useTranslation } from "react-i18next";
import useViewport from "../../../utils/useViewport";
import { productPageCategories } from "./HeaderDesktop";
import Close from "../../../images/design-components/Less_1_WHT.svg";
import { linkResolver } from "../../../utils/prismic/linkResolver";
import { memberPortalUrl } from "../../../../config";

function HeaderTabletMobile({
  isActive,
  pages,
  activeDocMeta,
  promoBar,
  bookTour,
}) {
  const { t, i18n } = useTranslation();

  // const isEnglish = t("lang") !== "fr";

  const isEnglish = get(activeDocMeta, "lang", "") !== "fr-ca";

  const landingPageData = useStaticQuery(graphql`
    query {
      allPrismicWhyIqLanding {
        nodes {
          uid
          url
          lang
          data {
            mobile_main_menu_label
          }
        }
      }
      allPrismicProductsLandingPage {
        nodes {
          uid
          url
          lang
          data {
            mobile_main_menu_label
          }
        }
      }
    }
  `);

  const whyIqLandingPage = get(
    landingPageData,
    "allPrismicWhyIqLanding.nodes",
    [],
  ).find((item) => item.lang === get(activeDocMeta, "lang", "en-us"));

  const productsLandingPageUrl = get(
    landingPageData,
    "allPrismicProductsLandingPage.nodes",
    [],
  ).find((item) => item.lang === get(activeDocMeta, "lang", "en-us"));

  const { width } = useViewport();

  const innerWidth = typeof window === "undefined" ? 420 : window.innerWidth;

  const whyIqPages = get(pages, "whyIqPages", []);
  const productPages = get(pages, "productPages", []).filter((pg) =>
    get(pg, "data.display_in_header_menu", false),
  );
  const customPages = get(pages, "customPages", []);
  const menuPages = customPages
    .filter((page) => get(page, "data.show_in_main_menu", false))
    .sort(function (a, b) {
      return (
        get(a, "data.main_menu_order", 0) - get(b, "data.main_menu_order", 0)
      );
    });

  const mobileMenuPages = customPages
    .filter((page) => get(page, "dataRaw.show_in_mobile_submenu", false))
    .sort(function (a, b) {
      return (
        get(a, "dataRaw.mobile_submenu_order", 0) -
        get(b, "dataRaw.mobile_submenu_order", 0)
      );
    });

  const mobileMenuPagesList1 = mobileMenuPages.slice(0, 3);
  const mobileMenuPagesList2 = mobileMenuPages.slice(3, 5);
  const mobileMenuPagesList3 = mobileMenuPages.slice(5, 10);

  return (
    <div
      className={`d-flex w-100 d-xl-none pt-xl-2 ${
        isEnglish ? `pt-0` : `pt-2`
      }`}
    >
      <Navbar.Toggle className={`order-1 me-auto`}>
        {isActive ? (
          <img
            src={Close}
            alt=""
            className={"d-block"}
            style={{ width: "27px" }}
          />
        ) : (
          <>
            <MobileMenuIcon />
          </>
        )}
      </Navbar.Toggle>
      <Nav.Link className={`order-3 ${bookATour}`} as={"span"}>
        <Link
          to={`${isEnglish ? "" : "/fr"}/locations`}
          className={"d-none d-md-block text-uppercase"}
          title={t("profilePage.BookATourMenuText")}
        >
          {t("profilePage.BookATourMenuText")}
        </Link>
        <div className="text-center d-md-none">
          <Link
            className={"text-white tour-btn bookatourclasses"}
            title={isEnglish ? "BOOK NOW" : "RÉSERVEZ"}
            to={`${isEnglish ? "" : "/fr"}/locations`}
            onClick={(e) => {
              if (typeof bookTour === "function") {
                e.preventDefault();
                bookTour();
              }
            }}
          >
            {isEnglish ? (
              <>
                BOOK <br /> NOW
              </>
            ) : (
              "RÉSERVEZ"
            )}
          </Link>
        </div>
      </Nav.Link>
      <Navbar.Offcanvas
        placement={width >= breakpoints.md ? "top" : "bottom"}
        style={{
          display: innerWidth >= breakpoints.xl && "none",
          paddingTop: promoBar && "64px",
          paddingBottom:
            innerWidth >= breakpoints.md && (promoBar ? "180px" : "96px"),
          // marginBottom: promoBar && "64px",
        }}
      >
        <Offcanvas.Body className="d-flex flex-column justify-content-start position-relative tabmob-offcanvas">
          <div className={`container ${mobile_top_icons}`}>
            <Link
              to={linkResolver(get(activeDocMeta, "alternateLanguages[0]", {}))}
              className={`lang-toggle ${
                isEnglish ? "lang-fr" : "lang-en"
              } ${languageSwitcher}`}
              title={`Switch to ${isEnglish ? "FR" : "EN"}`}
              onClick={() => {
                if (get(activeDocMeta, "alternateLanguages[0].type", false)) {
                  i18n.changeLanguage(isEnglish ? "fr" : "en");
                } else {
                  i18n.changeLanguage("en");
                }

                // i18n.changeLanguage(isEnglish ? "fr" : "en");
              }}
            >
              {isEnglish ? "FR" : "EN"}
            </Link>
            <a
              href={memberPortalUrl}
              target="_blank"
              className={"ms-2"}
              title={`Portal`}
            >
              <img src={Member} alt="Portal" />
            </a>
          </div>
          <div className={headerTabMobBody}>
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  className={`container header-menu-why-iq ${tabmobAccordionHeader}`}
                >
                  <h4 className={"header-menu-why-iq"}>{t("whyIq")}</h4>
                </Accordion.Header>
                <Accordion.Body className="container">
                  <ListGroup>
                    {whyIqPages.map((page) => (
                      <ListGroup.Item className={"border-0"} key={page.id}>
                        <Link
                          to={page.url}
                          activeClassName={s.active_menu_mobile}
                          className={"text-white"}
                          title={get(page, "data.menu_link_name", "")}
                        >
                          {get(page, "data.menu_link_name", "")}
                        </Link>
                      </ListGroup.Item>
                    ))}
                    {get(whyIqLandingPage, "url", "") && (
                      <ListGroup.Item className={"border-0"}>
                        <Link
                          to={get(whyIqLandingPage, "url", "")}
                          activeClassName={s.active_menu_mobile}
                          className={"text-white fst-italic"}
                          title={get(
                            whyIqLandingPage,
                            "data.mobile_main_menu_label",
                            "",
                          )}
                        >
                          {get(
                            whyIqLandingPage,
                            "data.mobile_main_menu_label",
                            "",
                          )}
                        </Link>
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header
                  className={`container ${tabmobAccordionHeader}`}
                >
                  <h4 className={"header-menu-products"}>
                    {i18n.t("Products")}
                  </h4>
                </Accordion.Header>
                <Accordion.Body className="container">
                  <ListGroup>
                    {productPageCategories.map((category) => (
                      <React.Fragment key={category}>
                        <ListGroup.Item className={"border-0"}>
                          <h5>{category}</h5>
                        </ListGroup.Item>
                        {productPages
                          .filter((page) => page.data.product_type === category)
                          .map((page) => (
                            <ListGroup.Item
                              className="list-group-item2 border-0"
                              key={page.id}
                            >
                              <Link
                                activeClassName={s.active_menu_mobile}
                                to={page.url}
                                className={`text-white header-nav-link-${page.uid}`}
                                title={get(page, "data.menu_link_name", "")}
                              >
                                {get(page, "data.menu_link_name", "")}
                              </Link>
                            </ListGroup.Item>
                          ))}
                      </React.Fragment>
                    ))}
                    {get(productsLandingPageUrl, "url", "") && (
                      <ListGroup.Item className={"border-0"}>
                        <Link
                          to={get(productsLandingPageUrl, "url", "")}
                          activeClassName={s.active_menu_mobile}
                          className={"text-white fst-italic"}
                          title={get(
                            productsLandingPageUrl,
                            "data.mobile_main_menu_label",
                            "",
                          )}
                        >
                          {get(
                            productsLandingPageUrl,
                            "data.mobile_main_menu_label",
                            "",
                          )}
                        </Link>
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {menuPages.map((page) => (
              <Accordion
                key={page.id}
                flush
                className="tabmob-accordion-no-expand"
              >
                <Accordion.Header className="container">
                  <h4>
                    <Link
                      activeClassName={s.active_menu_mobile}
                      to={page.url}
                      className={"text-white"}
                      title={get(page, "data.link_label", "")}
                    >
                      {get(page, "data.link_label", "")}
                    </Link>
                  </h4>
                </Accordion.Header>
              </Accordion>
            ))}

            <div className={`container ${headerTabMobFooter}`}>
              <div className="row">
                <div className="col-sm-4">
                  {mobileMenuPagesList1.map((page, idx) => (
                    <Link
                      to={page.url}
                      activeClassName={s.active_menu_mobile}
                      className={headerTabMobFooterLinks}
                      key={idx}
                      title={get(page, "data.link_label", "")}
                    >
                      {get(page, "data.link_label", "")}
                    </Link>
                  ))}
                </div>
                <div className="col-sm-4">
                  {mobileMenuPagesList2.map((page, idx) => (
                    <Link
                      to={page.url}
                      activeClassName={s.active_menu_mobile}
                      className={headerTabMobFooterLinks}
                      key={idx}
                      title={get(page, "data.link_label", "")}
                    >
                      {get(page, "data.link_label", "")}
                    </Link>
                  ))}
                </div>
                <div className="col-sm-4">
                  {mobileMenuPagesList3.map((page, idx) => (
                    <Link
                      to={page.url}
                      activeClassName={s.active_menu_mobile}
                      className={headerTabMobFooterLinks}
                      key={idx}
                      title={get(page, "data.link_label", "")}
                    >
                      {get(page, "data.link_label", "")}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <SocialLinks />
                {/*<a href="adsfasdf" target={"_blank"}>*/}
                {/*  <img alt="" src={Twitter} className={iconContainer} />*/}
                {/*</a>*/}
                {/*<a href="adsfasdf" target={"_blank"}>*/}
                {/*  <img alt="" src={LinkedIn} className={iconContainer} />*/}
                {/*</a>*/}
                {/*<a href="adsfasdf" target={"_blank"}>*/}
                {/*  <img alt="" src={Instagram} className={iconContainer} />*/}
                {/*</a>*/}
                {/*<a href="adsfasdf" target={"_blank"}>*/}
                {/*  <img alt="" src={Facebook} className={iconContainer} />*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </div>
  );
}

export default HeaderTabletMobile;

const MobileMenuIcon = () => {
  return <span className={s.mobile_menu_icon} />;
};
