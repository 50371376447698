import React, { useState } from "react";
import Marker from "../../images/design-components/Pin_2.svg";
import MarkerTurquoise from "../../images/design-components/Pin_2_Turq.svg";
import { marker, tooltip } from "./HomeMapMarker.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const HomeMapMarker = ({ location, hovered }) => {
  const renderTooltip = (props) => (
    <Tooltip className={tooltip} {...props}>
      <p>{location}</p>
    </Tooltip>
  );

  const [tooltipShow, setTooltipShow] = useState(false);

  return (
    <>
      <OverlayTrigger
        placement={`bottom-start`}
        overlay={renderTooltip}
        show={tooltipShow || hovered}
      >
        <img
          className={marker}
          src={tooltipShow || hovered ? MarkerTurquoise : Marker}
          onMouseEnter={() => setTooltipShow(true)}
          onMouseLeave={() => setTooltipShow(false)}
        />
      </OverlayTrigger>
    </>
  );
};

export default HomeMapMarker;
