import React from "react";
import {
  footerNav,
  footerNavLeft,
  footerNavRight,
  footerNavUp,
} from "./FooterNav.module.scss";
import { Link } from "gatsby";
import i18n from "../../../utils/i18n";

const FooterNav = ({ previousLabel, previousLink, nextLabel, nextLink }) => {
  return (
    <div className={`${footerNav}`}>
      <div className="container-xxl d-flex position-relative justify-content-between align-items-start">
        <Link
          to={previousLink}
          className={`d-flex flex-column justify-content-center align-items-start ${footerNavLeft}`}
          title={`Previous: ${previousLabel}`}
        >
          <div>
            <span className="arrow-left-primary"></span>
            <h5 className={"ms-4 text-start"}>
              {i18n.t("FooterNav.Previous")}
            </h5>
          </div>
          <div>
            <span className="arrow-invisible"></span>
            <p className={"ms-4 text-start"}>{previousLabel}</p>
          </div>
        </Link>

        <div
          className={`d-flex cursor-pointer flex-column justify-content-center align-items-center ${footerNavUp}`}
          onClick={() => {
            window.scroll(0, 0);
          }}
          role={"presentation"}
        >
          <div>
            <span className="arrow-up-primary"></span>
          </div>
          <div>
            <h5>{i18n.t("FooterNav.BackToTop")}</h5>
          </div>
        </div>

        {nextLabel ? (
          <Link
            className={`d-flex flex-column justify-content-center align-items-end ${footerNavRight}`}
            to={nextLink}
            title={`Next: ${nextLabel}`}
          >
            <div>
              <h5 className={"me-4 text-end"}>{i18n.t("FooterNav.Next")}</h5>
              <span className="arrow-right-primary"></span>
            </div>
            <div>
              <p className={"me-4 text-end"}>{nextLabel}</p>
              <span className="arrow-invisible"></span>
            </div>
          </Link>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default FooterNav;
