// extracted by mini-css-extract-plugin
export var body_content_1 = "AdditionalServices-module--body_content_1--8541e";
export var body_content_2 = "AdditionalServices-module--body_content_2--d612d";
export var customAccordionTitle = "AdditionalServices-module--customAccordionTitle--3b8ad";
export var faq = "AdditionalServices-module--faq--bf1d4";
export var faq__accordion__locationFaq = "AdditionalServices-module--faq__accordion__location-faq--6cb94";
export var hideBg = "AdditionalServices-module--hideBg--f8779";
export var margins = "AdditionalServices-module--margins--2bbe0";
export var sectionTitle = "AdditionalServices-module--sectionTitle--0d2c6";
export var section_heading = "AdditionalServices-module--section_heading--4638f";
export var title_button = "AdditionalServices-module--title_button--134c8";
export var yellow_bg_text = "AdditionalServices-module--yellow_bg_text--913eb";