import React, { useState } from "react";
import {
  aamSignInButton,
  dropdownsMobile,
  dropdownsMobile__text,
  locationsSelectorDesktop,
  locationsSelectorDesktop__buttonsSection,
  locationsSelectorSection,
} from "../../../pages/locations/index.module.scss";
import CityList from "../CityList";
import Filters from "../Filters";
import { Button } from "react-bootstrap";
import { memberPortalUrl } from "../../../../config";
import DropdownLocation from "../../DropdownLocation/DropdownLocation";
import DropdownTeamSize from "../../DropdownTeamSize/DropdownTeamSize";
import useQueryParams from "../../../utils/useQueryParams";
import teamSizes from "../Filters/teamSizes";
import i18n from "../../../utils/i18n";

const locationOptions = [
  { name: "Vancouver", value: "1" },
  { name: "Toronto", value: "2" },
  { name: "Ottawa", value: "3" },
  { name: "Montreal", value: "4" },
];

const LocationSelect = ({
  setTeamSizeSpaceType = () => null,
  teamSizeSpaceType,
}) => {
  const [spaceTypeAfterTeamSize, setSpaceTypeAfterTeamSize] = useState(false);
  const [location, setLocation] = useQueryParams("location", "Toronto");
  const [teamSize, setTeamSize] = useQueryParams("team_size", "Team size");
  const [spaceType, setSpaceType] = useQueryParams(
    "space_type",
    "Private offices",
  );

  const [isHq] = useQueryParams("isHq", "");

  return (
    <>
      <div className={`container-xxl ${locationsSelectorSection}`}>
        <h5>
          <span> {i18n.t(`locationPage.DedicatedWorkspaces`)}:</span>
          {isHq ? (
            <span> {i18n.t(`homePage.HqByIq`)}</span>
          ) : (
            <span>
              {" "}
              {i18n.t(`locationPage.PrivateOffices`)} /{" "}
              {i18n.t(`locationPage.DedicatedDesks`)}
            </span>
          )}
        </h5>

        <div className={"d-md-block d-none"}>
          {/*// DESKTOP - 4 locations, team size dropdown + start & end date**/}
          <div className={`${locationsSelectorDesktop}`}>
            {/*& > div:nth-child(1)*/}
            <div>
              <CityList />
            </div>
            <div
              className={`d-flex justify-content-center ${locationsSelectorDesktop__buttonsSection}`}
            >
              <Filters
                setTeamSizeSpaceType={setTeamSizeSpaceType}
                teamSizeSpaceType={teamSizeSpaceType}
                spaceTypeAfterTeamSize={spaceTypeAfterTeamSize}
                setSpaceTypeAfterTeamSize={setSpaceTypeAfterTeamSize}
              />

              <Button
                onClick={() => window.open(memberPortalUrl, "_blank").focus()}
                className={`d-xl-inline-block d-none ${aamSignInButton}`}
                variant="info"
              >
                <span>{i18n.t("AlreadyMember")}</span>
              </Button>
            </div>
            <p className="d-xl-none d-sm-inline-block d-none text-center w-100 mt-3 mb-0">
              {i18n.t("SelectTeamSizeToViewPrices")}
            </p>
          </div>
        </div>

        <div className={"d-block d-md-none"}>
          <div className={`${dropdownsMobile}`}>
            <div className="w-100 mb-2">
              <DropdownLocation
                location={location}
                setLocation={setLocation}
                locationOptions={locationOptions}
              />
            </div>
            <div className="w-100">
              <DropdownTeamSize
                teamSize={teamSize}
                setTeamSize={setTeamSize}
                teamSizeSpaceType={teamSizeSpaceType}
                setTeamSizeSpaceType={setTeamSizeSpaceType}
                withSpaceTypePrompt
                spaceType={spaceType}
                setSpaceType={setSpaceType}
                spaceTypeAfterTeamSize={spaceTypeAfterTeamSize}
                setSpaceTypeAfterTeamSize={setSpaceTypeAfterTeamSize}
                teamSizes={teamSizes}
              />
            </div>
            <div>
              <p className={dropdownsMobile__text}>
                {i18n.t("SelectTeamSizeToViewPrices")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationSelect;
