import * as React from "react";
import { graphql } from "gatsby"; // import { PrismicRichText, SliceZone } from "@prismicio/react";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import activeDocMeta from "../utils/activeDocMeta";
import Layout from "../../components/Layout";
import get from "lodash/get";
import HeroFooter from "../../components/Hero/HeroFooter";
import ProductInfo from "../../components/Products/ProductInfo";
import OverviewItems from "../../components/OverviewItem/OverviewItems";
import Hero from "../../components/Hero/Hero";
import ProductLandingMenu from "../Products/components/ProductLandingMenu";
import BenefitsOfCoworking from "../../components/BenefitsOfCoworking/BenefitsOfCoworking";
import SubHero from "../../components/SubHero/SubHero";
import { SliceZone } from "@prismicio/react";

const WhyIqLandingPageTemplate = ({ data = {}, pageContext }) => {
  const pageData = get(data, "prismicWhyIqLanding", {});

  const slices = get(pageData, "dataRaw.body", []);

  return (
    <Layout
      activeDocMeta={activeDocMeta(get(data, "prismicWhyIqLanding", {}))}
      pages={{
        whyIqPages: get(data, "allPrismicWhyIqPages.nodes", []),
        productPages: get(data, "allPrismicProductPages.nodes", []),
        customPages: get(data, "allPrismicCustomPage.nodes", []),
        whyIqLandingPage: get(data, "allPrismicWhyIqLanding.nodes", []),
        productsLandingPage: get(
          data,
          "allPrismicProductsLandingPage.nodes",
          [],
        ),
      }}
      subscriptionModalData={data?.prismicHomePage?.data || {}}
    >
      {slices.length > 0 && (
        <SliceZone
          slices={slices}
          components={{
            full_width_text_section: SubHero,
            image: HeroFooter,
            product_features: ProductInfo,
            image_block: OverviewItems,
            hero_section: Hero,
            product_categories_headings: ProductLandingMenu,
            cta_with_features: BenefitsOfCoworking,
          }}
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query WhyIqLandingPageTemplate($lang: String, $uid: String) {
    prismicWhyIqLanding(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      lang
      type
      url
      uid
      alternate_languages {
        id
        uid
        type
        lang
      }
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
      }
      dataRaw
    }
    prismicHomePage(lang: { eq: $lang }) {
      _previewable
      data {
        subscription_form_title
        subscription_form_description
        subscription_form_button_label
        subscription_footer_button_label
        blog_page_modal_display_delay
      }
    }
    allPrismicProductsLandingPage(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicWhyIqLanding(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicProductPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        uid
        _previewable
        data {
          menu_link_name
          menu_link_description
          product_type
          display_in_header_menu
        }
      }
    }
    allPrismicWhyIqPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        uid
        _previewable
        data {
          display_in_main_menu
          menu_link_name
          menu_link_description
          menu_link_image {
            url
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 192, formats: AUTO, height: 128)
              }
            }
          }
        }
      }
    }
    allPrismicCustomPage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        uid
        lang
        url
        dataRaw
        data {
          main_menu_order
          link_label
          show_in_main_menu
        }
      }
    }
  }
`;

export default withPrismicPreview(WhyIqLandingPageTemplate);
