import React from "react";
import * as s from "./LocationsProfileTeam.module.scss";
import get from "lodash/get";
import { PrismicRichText } from "@prismicio/react";
import { CarouselDedicatedTeam } from "../CarouselMulti/CarouselMulti";

const LocationsProfileTeam = ({ team }) => {
  if (!team || team.length === 0) return null;
  return (
    <>
      <div className={`container-xxl`}>
        <div className={s.lptSection}>
          <div className={`${s.lptSection__title}`}>
            <PrismicRichText field={get(team, "primary.title", [])} />
          </div>
          <div className={`row`}>
            <div className={`col-lg-8 offset-lg-2`}>
              <CarouselDedicatedTeam>
                {get(team, "items", []).map((item, index) => (
                  <div
                    className={`${s.lptSection__personContainer}`}
                    key={index}
                  >
                    <img alt="" src={get(item, "image.url", "")} />
                    <h5>{get(item, "name", "")}</h5>
                    <p>{get(item, "title", "")}</p>
                  </div>
                ))}
              </CarouselDedicatedTeam>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationsProfileTeam;
