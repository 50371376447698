import React from "react";
import { productsTitle } from "../../../pages/products/index.module.scss";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";

const FullWidthTextSection = ({ slice }) => {
  return (
    <div className={productsTitle}>
      <div className={`container text-md-center text-start`}>
        <div className={`row justify-content-center`}>
          <div className={`col-lg-7 col-md-9`}>
            <PrismicRichText field={get(slice, "primary.title", [])} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullWidthTextSection;
