import React, { useEffect, useState } from "react";
import LeftArrow from "../../images/design-components/Left.svg";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import * as s from "./BookMeetingRooms.module.scss";
import {
  formColumn,
  formColumn__alreadyAMember,
  formColumn__contactSection,
  formColumn__location,
  formColumn__locationImageSection,
  formColumn__locationName,
  formColumn__price,
  formColumn__priceSection,
  formColumn__title,
  summaryColumn,
  summaryColumn__content,
  summaryColumn__content__cancellationPolicy,
  summaryColumn__content__paySection,
  summaryColumn__content__paySection__poweredByStripe,
  summaryColumn__content__pricingSection,
  summaryColumn__content__pricingSection__priceItems,
  summaryColumn__content__pricingSection__priceTotal,
  summaryColumn__content__selectTeamSize,
  summaryColumn__content__termsAndConditions,
  summaryColumn__content__title,
} from "./BookMeetingRooms.module.scss";
import { formatMeetingRoomName } from "../../pages/locations/_MeetingRooms";
import { BookMeetingRoomsForm, BookVirtualOfficeForm } from "./Form";
import { Button } from "react-bootstrap";
import Info from "../../images/design-components/Info_1.svg";
import Checkbox from "../../images/design-components/Checkbox_1.svg";
import CheckboxChecked from "../../images/design-components/Checkbox_2.svg";
import PoweredByStripe from "../../images/design-components/powered_by_stripe.svg";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import getMeetingRoomData from "./utils/getMeetingRoomData";
import ProgressDots from "../ProgressDots";
import getFormattedData from "./getFormattedData";
import { memberPortalUrl } from "../../../config";
import checkMeetingRoomAvailability from "./utils/checkMeetingRoomAvailability";
import { StringParam, useQueryParam } from "use-query-params";
import calculateGapInDaysOrHours from "../../utils/calculateGapInDaysOrHours";
import {
  addMonths,
  differenceInDays,
  endOfMonth,
  format,
  getDaysInMonth,
  isValid,
  parseISO,
  startOfMonth,
} from "date-fns";
import StripeCard from "./StripeCard";
import { getQueryParams, isProd } from "../../utils";
import i18n from "../../utils/i18n";
import { setNestedObjectValues, useFormik } from "formik";
import * as Yup from "yup";
import cx from "classnames";
import SuccessModal from "../SuccessModal";
import gtm from "../../services/gtm";
import { v4 as uuidv4 } from "uuid";
import getMeetingRoomBookingDataForOfficeRnD from "./getMeetingRoomBookingDataForOfficeRnD";
import iqApi from "../../services/iqApi";
import sendNotificationEmail from "./utils/sendNotificationEmail";
import { navigate } from "gatsby";
import redirectToOfficeRndCalendar from "./Form/utils/redirectToOfficeRndCalendar";

const meetingRoomLink = (id, teamSize) => {
  if (!id || !teamSize) return null;
  return `https://members.iqoffices.com/public/calendar/meeting_room?office=${id}&participants=${teamSize},${teamSize}&start=${format(
    new Date(),
    "yyyy-MM-dd",
  )}T00:00:00.000Z`;
};

export const hstConfig = {
  Montreal: 14.975,
  Ottawa: 13,
  Vancouver: 5,
  Toronto: 13,
};

const uuid = uuidv4();

const devName = () => {
  if (process.env.NODE_ENV === "development") {
    return "John Smith" || "";
  }
  return "";
};

const devEmail = () => {
  if (process.env.NODE_ENV === "development") {
    return `hello${uuid}@worksimply.com` || "";
  }
  return "";
};

const devPassword = () => {
  if (process.env.NODE_ENV === "development") {
    return "Pass@123";
  }
  return "";
};

const devCompany = () => {
  if (process.env.NODE_ENV === "development") {
    return "Worksimply";
  }
  return "";
};

const devPhone = () => {
  if (process.env.NODE_ENV === "development") {
    return "7147147147";
  }
  return "";
};

const BookingComponentContent = ({
  back = () => null,
  voData = {},
  profile = {},
  submitButtonClass = "",
  redirectUrlAfterMeetingRoomBooking = null,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const params = getQueryParams();

  const [hasValidPaymentMethod, setHasValidPaymentMethod] = useState(true);

  const isFrench = i18n.t("lang") === "fr";

  const voSelectedDate =
    get(voData, "formik.values.startDate", new Date()) || new Date();
  const nextMonth = addMonths(voSelectedDate, 1);

  const voErrors = voData?.formik?.errors;

  const isHourly = params.get("type") === "hourly";

  const meetingRoomId = params.get("meeting_room_id") || null;

  const isVo = get(voData, "office._id", false);

  const [statusMessage, setStatusMessage] = useState("");

  const [start] = useQueryParam("start", StringParam);
  const [end] = useQueryParam("end", StringParam);

  const [reservationCompleted, setReservationCompleted] = useState(false);
  const [mrSuccessModal, setMrSuccessModal] = useState(false);

  const [checkoutDisabled, setCheckoutDisabled] = useState(false);
  const [meetingRoomCheckInProgress, setMeetingRoomCheckInProgress] =
    useState(false);
  const [isMeetingRoomAvailable, setIsMeetingRoomAvailable] = useState(false);

  const [termsAndCond, setTermsAndCond] = useState(false);

  const [mrData, setMrData] = useState({});

  const [unitPrice, setUnitPrice] = useState(0);
  const [units, setUnits] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [taxRate, setTaxRate] = useState(0);
  const [total, setTotal] = useState(0);

  const pricingList = [
    { label: `$${unitPrice} x ${units}`, subTotal: `$${subtotal.toFixed(2)}` },
    {
      label: `Tax (${taxRate * 100}%)`,
      subTotal: `$${(subtotal * taxRate).toFixed(2)}`,
    },
  ];

  const [validCard, setValidCard] = useState(false);

  const data = getFormattedData(mrData, null);

  useEffect(() => {
    if (meetingRoomId && !mrData._id) {
      getMeetingRoomData(meetingRoomId).then((res) => {
        const type = get(res, "message", "Error");
        if (type !== "Success") return;
        const data = get(res, "data", {});
        setMrData(data);
      });
    }
  }, [meetingRoomId]);

  useEffect(() => {
    if (!mrData || !mrData.office) return;
    const from = parseISO(start);
    const to = parseISO(end);
    if (!isValid(from) || !isValid(to)) {
      return;
    }

    const city = params.get("location") || profile?.city || "Toronto";

    const gap = calculateGapInDaysOrHours(from, to, isHourly);

    const hourlyRate = get(mrData, `rateDetails[0].hourlyRate.price`, 0);
    const dailyRate = get(mrData, `rateDetails[0].dailyRate.price`, 0);
    const tempUnitPrice = get(
      mrData,
      `rateDetails[0].${isHourly ? "hourlyRate" : "dailyRate"}.price`,
      0,
    );

    const tax = get(mrData, "rateDetails[0].tax.taxRate", 0);

    const taxRate = get(hstConfig, `${city}`, 0);

    setTaxRate(taxRate / 100);

    if (isHourly && dailyRate > 0 && gap * tempUnitPrice >= dailyRate) {
      setUnitPrice(dailyRate);
      setSubtotal(dailyRate);
      setTotal(dailyRate * (1 + tax));
      setUnits(1);
      return;
    }

    setUnits(gap);
    setUnitPrice(tempUnitPrice);
    setSubtotal(gap * tempUnitPrice);
    setTotal(gap * tempUnitPrice * (1 + tax));
  }, [mrData, start, end]);

  useEffect(() => {
    if (!mrData?._id) return;
    setIsMeetingRoomAvailable(false);
    checkAvailability().then(() => null);
  }, [start, end, mrData?._id]);

  const checkAvailability = async () => {
    if (!mrData?._id) return;
    setMeetingRoomCheckInProgress(true);
    if (!profile?.timezone) return;
    checkMeetingRoomAvailability(mrData, profile).then((res) => {
      setIsMeetingRoomAvailable(!!res);
      setMeetingRoomCheckInProgress(false);
    });
  };

  const formik = useFormik({
    initialValues: {
      fullName: devName(),
      email: devEmail(),
      password: devPassword(),
      company: devCompany(),
      phoneNumberCountry: "CA",
      phoneNumber: devPhone(),
    },
    validateOnMount: false,
    validationSchema: Yup.object({
      fullName: Yup.string().required(i18n.t("NameRequired")),
      email: Yup.string()
        .email(i18n.t("InvalidEmail"))
        .required(i18n.t("EmailRequired")),
      company: Yup.string().required(i18n.t("CompanyNameRequired")),
      phoneNumber: Yup.string().required(i18n.t("PhoneRequired")),
      password: Yup.string()
        .min(8, i18n.t("PasswordValidationText"))
        .required(i18n.t("PasswordRequired"))
        .matches(/^(?=.*[a-z])/, i18n.t("PasswordValidationText"))
        .matches(/^(?=.*[A-Z])/, i18n.t("PasswordValidationText"))
        .matches(/^(?=.*[0-9])/, i18n.t("PasswordValidationText")),
    }),
    onSubmit: () => null,
  });

  const [cardError, setCardError] = useState(false);
  const [hasCheckoutErrors, setHasCheckoutErrors] = useState(false);
  const [mrLoginLink, setMrLoginLink] = useState("");
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false);
  const [emailCheckCompleted, setEmailCheckCompleted] = useState(false);
  const [isAutomaticUserValidation, setIsAutomaticUserValidation] =
    useState(false);

  const meetingRoomDisabled =
    checkoutDisabled ||
    !isMeetingRoomAvailable ||
    reservationCompleted ||
    !total ||
    !emailCheckCompleted;

  const [inProgress, setInProgress] = useState(false);
  const [mrNoCardLink, setMrNoCardLink] = useState(null);

  const reserveMeetingRoom = async () => {
    setStatusMessage("");
    if (!termsAndCond) {
      if (!(isAlreadyRegistered && !hasValidPaymentMethod)) {
        setStatusMessage(i18n.t("PleaseAgreeToTerms"));
        return;
      }
    }

    const formErrors = await formik.validateForm();

    if (Object.keys(formErrors).length > 0) {
      await formik.setTouched(setNestedObjectValues(formErrors, true));
      if (isAlreadyRegistered) {
        if (formErrors.email || formErrors.password) {
          return;
        }
      } else {
        return;
      }
    }

    const user = {
      name: formik?.values?.fullName || "",
      email: formik?.values?.email || "",
      password: formik?.values?.password || "",
      company: formik?.values?.company || "",
      phoneNumber: formik?.values?.phoneNumber || "",
    };

    setCheckoutDisabled(true);
    setInProgress(true);

    const { token, error } = isAlreadyRegistered
      ? { token: {}, error: null }
      : await stripe.createToken(elements?.getElement(CardElement));

    if (error) {
      setStatusMessage(i18n.t("FailedToValidateTheCreditCard"));
      console.log(error);
      setInProgress(false);
      setCheckoutDisabled(false);
      return;
    }

    const reqData = getMeetingRoomBookingDataForOfficeRnD(
      user,
      {
        id: token?.id,
        stripe_token: token?.id,
        last4: token?.card?.last4,
        brand: token?.card?.brand,
      },
      isAlreadyRegistered,
      profile,
    );

    const response = await iqApi("book-meeting-room", reqData);

    if (response?.checkoutErrors?.length > 0) {
      setHasCheckoutErrors(true);
      redirectToOfficeRndCalendar(
        profile.timezone,
        response?.tokenForRedirect,
        response?.officeRndSlug,
        setMrNoCardLink,
        false,
      );
      return;
    }

    if (response?.tokenForRedirect) {
      redirectToOfficeRndCalendar(
        profile.timezone,
        response?.tokenForRedirect,
        response?.officeRndSlug,
        setMrNoCardLink,
        !response?.loginLink,
      );
      if (!response?.loginLink) {
        return;
      }
    }

    if (response?.type === "error") {
      setCheckoutDisabled(false);
      setInProgress(false);
      setStatusMessage(
        response?.message ||
          "Failed to create the reservation. Please refresh the page and try again. If this" +
            " persists, please contact us.",
      );
      return;
    }

    setMrLoginLink(response?.loginLink);

    if (response.creditCardError) {
      // display credit card error and ask the user to go to home page
      setCardError(true);
    }

    if (isProd) {
      const { bookingId, reference, createdAt } = response;
      gtm("ws_meeting_room_reservation", { bookingId, reference, createdAt });
    }

    await sendNotificationEmail(
      formik.values,
      profile,
      response?.user?.name || "",
      response.creditCardError,
    );

    setStatusMessage("");

    setReservationCompleted(true);
    setMrSuccessModal(true);
  };

  useEffect(() => {
    setStatusMessage("");
  }, [formik.values]);

  const handleVoCheckout = async () => {
    if (!termsAndCond) {
      setStatusMessage(i18n.t("PleaseAgreeToVoTerms"));
      return;
    }
    const { token, error } = await stripe.createToken(
      elements?.getElement(CardElement),
    );
    setStatusMessage("");
    if (!token || error) {
      setStatusMessage(i18n.t("FailedToValidateTheCreditCard"));
      return;
    }
    await voData.checkout(token);
  };

  useEffect(() => {
    if (termsAndCond) {
      setStatusMessage("");
    }
  }, [termsAndCond]);

  const voImage = get(voData, "office.image", "")
    ? `https:${get(voData, "office.image", "")}`
    : null;

  const hasHourly = !!get(data, "hourlyRate.price", false);
  const hasDaily = !!get(data, "dailyRate.price", false);

  if (!meetingRoomId && !isVo) return null;

  if (meetingRoomId && !get(mrData, "_id", null)) {
    return (
      <div className={"container-xxl min-vh-100 d-flex flex-column"}>
        <div className={`row`}>
          <div className={`col-md-7 ${formColumn}`}>
            <div>
              <button
                className={`btn-link mt-5 d-flex opacity-0`}
                onClick={back}
              >
                <img alt="Back" src={LeftArrow} />
                <h5>{i18n.t("button.Back")}</h5>
              </button>
            </div>
          </div>
        </div>
        <div className={"my-auto text-center"}>
          <ProgressDots />
        </div>
      </div>
    );
  }

  return (
    <div className={`container-xxl`}>
      <div className={`row`}>
        <div className={`col-md-7 ${formColumn}`}>
          <div>
            <button className={`btn-link mt-5 d-flex`} onClick={back}>
              <img alt="Back" src={LeftArrow} />
              <h5 className={`ps-3`}>{i18n.t("button.Back")}</h5>
            </button>
          </div>
          <h1 className={formColumn__title}>
            {meetingRoomId ? i18n.t("BookNow") : i18n.t("YourDetails")}
          </h1>
          <div className={`row`}>
            <div className={`col-xl-5 d-flex`}>
              <div className="position-relative">
                <h6 className={formColumn__location}>
                  {isVo
                    ? get(voData, "office.address", null)
                    : get(data, "address", null)}
                </h6>
                <h4 className={formColumn__locationName}>
                  {isVo
                    ? get(voData, "office.title", null)
                    : formatMeetingRoomName(get(data, "title", null))}
                </h4>
                <div className={formColumn__locationImageSection}>
                  <img
                    alt={data.name}
                    src={meetingRoomId ? get(data, "image", null) : voImage}
                  />
                </div>
              </div>
            </div>
            <div className={`col-xl-7`}>
              {meetingRoomId ? (
                <>
                  {isVo ? (
                    <button
                      onClick={() => {
                        setIsAlreadyRegistered(!isAlreadyRegistered);
                        setIsAutomaticUserValidation(false);
                      }}
                      className={`btn btn-info ${formColumn__alreadyAMember} ${
                        meetingRoomLink(
                          get(mrData, "office"),
                          get(mrData, "size", null),
                        )
                          ? ""
                          : "opacity-0 pe-none"
                      }`}
                      rel="noreferrer"
                    >
                      <span>
                        {!isAlreadyRegistered
                          ? i18n.t("AlreadyMember")
                          : i18n.t("NewUser")}
                      </span>
                    </button>
                  ) : null}
                  <BookMeetingRoomsForm
                    teamSizes={Array.from(Array(mrData.size)).map((item, i) => {
                      return { label: `${i + 1}`, size: 1 };
                    })}
                    formik={formik}
                    hasHourly={hasHourly}
                    hasDaily={hasDaily}
                    setIsAlreadyRegistered={() => {
                      setIsAutomaticUserValidation(true);
                      setIsAlreadyRegistered(true);
                    }}
                    isAlreadyRegistered={isAlreadyRegistered}
                    isAutomaticUserValidation={isAutomaticUserValidation}
                    emailCheckCompleted={emailCheckCompleted}
                    setEmailCheckCompleted={setEmailCheckCompleted}
                    setHasValidPaymentMethod={setHasValidPaymentMethod}
                    hasValidPaymentMethod={hasValidPaymentMethod}
                  />
                  <br />
                  {meetingRoomCheckInProgress && (
                    <small className={"text-warning"}>
                      {i18n.t("CheckingAvailability")}
                    </small>
                  )}
                  {!meetingRoomCheckInProgress && !isMeetingRoomAvailable && (
                    <small className={"text-danger"}>
                      This meeting room is not available for the selected date
                      range. Please change the dates to check availability.
                    </small>
                  )}
                </>
              ) : (
                <>
                  <a
                    href={memberPortalUrl}
                    target={"_blank"}
                    className={cx(
                      formColumn__alreadyAMember,
                      [`btn btn-info`],
                      { [`px-2 w-100`]: isFrench },
                    )}
                    rel="noreferrer"
                  >
                    <span>{i18n.t("AlreadyMember")}</span>
                  </a>
                  <BookVirtualOfficeForm
                    formik={voData.formik}
                    subscriptionError={voData?.subscriptionError}
                  />
                  {get(voData, "errorMessage", false) ? (
                    <small className={"text-danger d-block mt-3"}>
                      {get(voData, "errorMessage", "")}
                    </small>
                  ) : null}
                </>
              )}
            </div>
          </div>
          <div className={`d-flex ${formColumn__priceSection}`}>
            {meetingRoomId ? (
              <>
                {get(data, "hourlyRate.price", false) && (
                  <div className={formColumn__price}>
                    <h6 className={`mb-2`}>{i18n.t("Hourly")}</h6>
                    <h5>
                      ${data.hourlyRate.price}/{i18n.t("hour")}
                    </h5>
                  </div>
                )}
                {get(data, "dailyRate.price", false) && (
                  <div className={formColumn__price}>
                    <h6 className={`mb-2`}>{i18n.t("Daily")}</h6>
                    <h5>
                      ${data.dailyRate.price}/{i18n.t("day")}
                    </h5>
                  </div>
                )}
              </>
            ) : null}
          </div>
          <div className={`d-none d-md-block ${formColumn__contactSection}`}>
            <p>
              {i18n.t("DontHesitate")}{" "}
              <a
                href={`${i18n.t("/lang/")}contact-us`}
                target={"_blank"}
                rel={"noreferrer"}
              >
                {i18n.t("ContactUs")}
              </a>
            </p>
          </div>
        </div>
        <div className={`col-md-4 offset-md-1 ${summaryColumn}`}>
          <div className={`container ${summaryColumn__content}`}>
            <h5 className={`${summaryColumn__content__title}`}>
              {i18n.t("voPricing.Summary")}
            </h5>
            {!meetingRoomId && (
              <>
                <p className={`mb-4`}>
                  <strong>{i18n.t("IncludedAddons")}</strong>
                </p>
                <ul className={"list-unstyled"}>
                  <li className={"d-flex w-100 justify-content-between"}>
                    <p>{get(voData, "plan.title", "Virtual Offices")}</p>
                    <p>
                      ${get(voData, "plan.price", 0)} /{i18n.t("month")}
                    </p>
                  </li>
                  {voData.addOns
                    .filter((a) => !a.isAdditionLocationAddress)
                    .map((addon) => {
                      const { title, price, _id } = addon;
                      return (
                        <li
                          key={_id}
                          className={"d-flex w-100 justify-content-between"}
                        >
                          <p>{title}</p>
                          <p>
                            ${price} /{i18n.t("month")}
                          </p>
                        </li>
                      );
                    })}
                  {voData.addOns.filter((a) => a.isAdditionLocationAddress)
                    .length > 0 ? (
                    <li>
                      <p>Additional Location Address</p>
                      <ul className={"list-unstyled ps-2 w-100"}>
                        {voData.addOns
                          .filter((a) => a.isAdditionLocationAddress)
                          .map((addon) => {
                            const { title, price, _id } = addon;
                            return (
                              <li
                                key={_id}
                                className={
                                  "d-flex w-100 justify-content-between"
                                }
                              >
                                <p>{title}</p>
                                <p>
                                  ${price} /{i18n.t("month")}
                                </p>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  ) : null}
                  <li className={"d-flex w-100 justify-content-between"}>
                    <p>
                      {i18n.t("voPricing.Tax")}{" "}
                      {get(voData, "billing.taxRate", 0)}%
                    </p>
                    <p>${get(voData, "billing.tax", 0).toFixed(2)}</p>
                  </li>
                </ul>
              </>
            )}
            <div
              className={`d-flex ${summaryColumn__content__cancellationPolicy}`}
            >
              {meetingRoomId && (
                <>
                  <img alt={""} src={Info} />
                  <div>
                    <h6>{i18n.t("CancellationPolicy")}</h6>
                    <p>{i18n.t("FreeCancellation")}</p>
                  </div>
                </>
              )}
            </div>
            {!(isAlreadyRegistered && !hasValidPaymentMethod) && (
              <div className={`${summaryColumn__content__termsAndConditions}`}>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setTermsAndCond(!termsAndCond);
                  }}
                  href={"#"}
                >
                  <img
                    alt={""}
                    src={termsAndCond ? CheckboxChecked : Checkbox}
                  />
                </a>
                <p>
                  {i18n.t("iHaveReadAndAgreedToThe")}{" "}
                  {meetingRoomId ? (
                    <a
                      href={`${i18n.t(
                        "/lang/",
                      )}meeting-rooms-terms-and-conditions`}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                    >
                      {i18n.t("MeetingRoomsTerms")}
                    </a>
                  ) : (
                    <a
                      href={`${i18n.t("/lang/")}virtual-service-agreement`}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                    >
                      {i18n.t("VirtualOfficesTerms")}
                    </a>
                  )}
                </p>
              </div>
            )}
            {meetingRoomId ? (
              <>
                {!start || !end ? (
                  <div className={`${summaryColumn__content__selectTeamSize}`}>
                    <p>{i18n.t("SelectTeamSizeToViewPrices")}</p>
                  </div>
                ) : null}
                <div className={`${summaryColumn__content__pricingSection}`}>
                  {pricingList.map((item, index) => (
                    <div
                      className={`d-flex ${summaryColumn__content__pricingSection__priceItems}`}
                      key={index}
                    >
                      <span>{item.label}</span>
                      <span className={`ms-auto`}>{item.subTotal}</span>
                    </div>
                  ))}
                  <div
                    className={`d-flex ${summaryColumn__content__pricingSection__priceTotal}`}
                  >
                    <span>Total</span>
                    <span className={`ms-auto`}>${total.toFixed(2)}</span>
                  </div>
                </div>
              </>
            ) : (
              <div className={`${summaryColumn__content__pricingSection}`}>
                <div
                  className={`d-flex ${summaryColumn__content__pricingSection__priceTotal}`}
                >
                  <span>
                    {i18n.t("voPricing.totalForThisPeriod")}{" "}
                    {format(voSelectedDate, "MMM d")} -{" "}
                    {format(endOfMonth(voSelectedDate), "MMM d")}
                  </span>
                  <span className={`ms-auto`}>
                    $
                    {(
                      (get(voData, "billing.total", 0) *
                        (differenceInDays(
                          endOfMonth(voSelectedDate),
                          voSelectedDate,
                        ) +
                          1)) /
                      getDaysInMonth(voSelectedDate)
                    ).toFixed(2)}
                  </span>
                </div>
                <div
                  className={`d-flex ${summaryColumn__content__pricingSection__priceItems}`}
                >
                  <span>
                    {i18n.t("voPricing.monthlyTotalStarting")}{" "}
                    {format(startOfMonth(nextMonth), "MMM do")}
                  </span>
                  <span className={`ms-auto`}>
                    ${get(voData, "billing.total", 0).toFixed(2)}
                  </span>
                </div>
              </div>
            )}

            {isVo && (
              <div className={`${summaryColumn__content__paySection}`}>
                <div className={"w-100"}>
                  <StripeCard setValidCard={setValidCard} />
                </div>
              </div>
            )}

            {meetingRoomId ? (
              <div className={`${summaryColumn__content__paySection}`}>
                {!isAlreadyRegistered && (
                  <div className={"w-100 mb-4"}>
                    <StripeCard setValidCard={setValidCard} />
                  </div>
                )}
                {/*<div*/}
                {/*  className={`${summaryColumn__content__paySection__youWontBeChargedYet}`}*/}
                {/*>*/}
                {/*  {creditsAvailable ? (*/}
                {/*    <p>{i18n.t("UserWithCreditsMessage")}</p>*/}
                {/*  ) : (*/}
                {/*    <p>You won't be charged yet</p>*/}
                {/*  )}*/}
                {/*</div>*/}
                <Button
                  variant={"outline-primary"}
                  className={`w-100 px-2`}
                  disabled={meetingRoomDisabled}
                  onClick={reserveMeetingRoom}
                >
                  {!isAlreadyRegistered || hasValidPaymentMethod ? (
                    <span className={"text-wrap"}>
                      {reservationCompleted
                        ? i18n.t("profilePage.Confirmed")
                        : i18n.t("ConfirmAndPay")}
                    </span>
                  ) : (
                    <span className={"text-wrap"}>{i18n.t("Continue")}</span>
                  )}
                </Button>
                <img
                  className={
                    summaryColumn__content__paySection__poweredByStripe
                  }
                  alt=""
                  src={PoweredByStripe}
                />
                {statusMessage && (
                  <small
                    className={"d-block w-100 my-2 text-danger underline-links"}
                    dangerouslySetInnerHTML={{ __html: statusMessage }}
                  />
                )}
                {/* Meeting Room Errors*/}
                {isAlreadyRegistered &&
                  formik.touched.password &&
                  formik.errors.password && (
                    <ul className="mt-3 text-danger">
                      <li>{formik.errors.password}</li>
                    </ul>
                  )}
                {!isEmpty(formik.errors) &&
                  !isEmpty(formik.touched) &&
                  !isAlreadyRegistered && (
                    <>
                      <ul className={"mt-3 text-danger"}>
                        {formik.errors?.email && formik.touched.email && (
                          <li>{formik.errors.email}</li>
                        )}
                        {formik.errors?.password && formik.touched.password && (
                          <li>{formik.errors.password}</li>
                        )}{" "}
                        {formik.errors?.company && formik.touched.company && (
                          <li>{formik.errors.company}</li>
                        )}
                        {formik.errors?.fullName && formik.touched.fullName && (
                          <li>{formik.errors.fullName}</li>
                        )}
                        {formik.errors?.phoneNumber &&
                          formik.touched.phoneNumber && (
                            <li>{formik.errors.phoneNumber}</li>
                          )}{" "}
                      </ul>
                    </>
                  )}
                <div
                  className={`d-block d-md-none ${formColumn__contactSection}`}
                >
                  <p>
                    {i18n.t("DontHesitate")}{" "}
                    <a href="/contact-us" target={"_blank"} rel={"noreferrer"}>
                      {i18n.t("ContactUs")}
                    </a>
                  </p>
                </div>
              </div>
            ) : (
              <div className={summaryColumn__content__paySection}>
                <Button
                  variant={"outline-primary"}
                  className={`w-100 px-4 ${submitButtonClass} ${s.summaryColumn__content__paySection__submitButton}`}
                  disabled={
                    get(voData, "loginLink", false) ||
                    get(voData, "progress", false)
                  }
                  onClick={handleVoCheckout}
                >
                  <span className={"text-wrap"}>
                    {get(voData, "loginLink", false)
                      ? i18n.t("Completed")
                      : i18n.t("ConfirmAndPay")}
                  </span>
                </Button>
                <img
                  className={
                    summaryColumn__content__paySection__poweredByStripe
                  }
                  alt=""
                  src={PoweredByStripe}
                />
                {statusMessage && (
                  <small
                    className={
                      "d-block w-100 my-2 text-danger  underline-links"
                    }
                    dangerouslySetInnerHTML={{ __html: statusMessage }}
                  />
                )}
                {!isEmpty(voErrors) && (
                  <>
                    <ul className={"mt-3"}>
                      {voErrors?.fullName &&
                        voData?.formik?.touched?.fullName && (
                          <li className={"text-danger"}>{voErrors.fullName}</li>
                        )}
                      {voErrors?.email && voData?.formik?.touched?.email && (
                        <li className={"text-danger"}>{voErrors.email}</li>
                      )}
                      {voErrors?.password &&
                        voData?.formik?.touched?.password && (
                          <li className={"text-danger"}>{voErrors.password}</li>
                        )}{" "}
                      {voErrors?.phoneNumber &&
                        voData?.formik?.touched?.phoneNumber && (
                          <li className={"text-danger"}>
                            {voErrors.phoneNumber}
                          </li>
                        )}{" "}
                      {voErrors?.companyName &&
                        voData?.formik?.touched?.companyName && (
                          <li className={"text-danger"}>
                            {voErrors.companyName}
                          </li>
                        )}{" "}
                      {voErrors?.startDate &&
                        voData?.formik?.touched?.startDate && (
                          <li className={"text-danger"}>
                            Start date is required
                          </li>
                        )}
                    </ul>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <SuccessModal
        show={inProgress || mrSuccessModal || mrNoCardLink}
        hide={async () => {
          setMrSuccessModal(false);
          await navigate(`${window.location.pathname}`);
        }}
        isMeetingRoomsSuccessModal
        hasCheckoutErrors={hasCheckoutErrors}
        creditCardError={cardError}
        loginLink={mrLoginLink}
        mrNoCardLink={mrNoCardLink}
        mrNoCard={isAlreadyRegistered && !hasValidPaymentMethod}
      />
    </div>
  );
};

export default BookingComponentContent;
