import iqApi from "../services/iqApi";

const submitFormToHubSpot = async (formId, formattedFields) => {
  let hutk = document.cookie.replace(
    /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
    "$1",
  );

  const context = {
    pageUri: window.location.href || "https://iqoffices.com",
    pageName: document.title || "iQ Offices",
  };

  if (hutk) {
    context.hutk = hutk;
  }

  return await iqApi("handle-form", {
    formId,
    data: {
      context,
      fields: formattedFields,
    },
  });
};

export default submitFormToHubSpot;
