import * as React from "react";
import { graphql } from "gatsby";
// import { PrismicRichText, SliceZone } from "@prismicio/react";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import activeDocMeta from "../utils/activeDocMeta";
import Layout from "../../components/Layout";
import get from "lodash/get";
import ProductInfo from "../../components/Products/ProductInfo";
import OverviewItems from "../../components/OverviewItem/OverviewItems";
import ProductLandingMenu from "./components/ProductLandingMenu";
import { SliceZone } from "@prismicio/react";
import HeroFooter from "../../components/Hero/HeroFooter";
import Hero from "../../components/Hero/Hero";
import TextSection from "./components/TextSection";
import { productPageCategories } from "../../components/Layout/Header/HeaderDesktop";

const ProductsLandingPageTemplate = ({ data = {}, pageContext }) => {
  const pageData = get(data, "prismicProductsLandingPage", {});

  const productPages = get(data, "allPrismicProductPages.nodes", []);

  // const productPageCategories = uniq(
  //   productPages.map((page) => page.data.product_type)
  // );

  const slices = get(pageData, "dataRaw.body", []);

  return (
    <Layout
      activeDocMeta={activeDocMeta(get(data, "prismicProductsLandingPage", {}))}
      pages={{
        whyIqPages: get(data, "allPrismicWhyIqPages.nodes", []),
        productPages: get(data, "allPrismicProductPages.nodes", []),
        customPages: get(data, "allPrismicCustomPage.nodes", []),
        whyIqLandingPage: get(data, "allPrismicWhyIqLanding.nodes", []),
        productsLandingPage: get(
          data,
          "allPrismicProductsLandingPage.nodes",
          [],
        ),
      }}
      subscriptionModalData={data?.prismicHomePage?.data || {}}
    >
      <div>
        {slices.length > 0 && (
          <SliceZone
            slices={slices}
            context={{
              productPageCategories,
              productPages,
            }}
            components={{
              full_width_text_section: TextSection,
              image: HeroFooter,
              product_features: ProductInfo,
              image_block: OverviewItems,
              hero_section: Hero,
              product_categories_headings: ProductLandingMenu,
            }}
          />
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ProductsLandingPageTemplate($lang: String, $uid: String) {
    prismicProductsLandingPage(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      id
      lang
      type
      url
      uid
      alternate_languages {
        id
        uid
        type
        lang
      }
      dataRaw
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
      }
    }
    prismicHomePage(lang: { eq: $lang }) {
      _previewable
      data {
        subscription_form_title
        subscription_form_description
        subscription_form_button_label
        subscription_footer_button_label
        blog_page_modal_display_delay
      }
    }
    allPrismicWhyIqLanding(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicProductsLandingPage(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        lang
        type
        url
        uid
        alternate_languages {
          id
          uid
          type
          lang
        }
        dataRaw
      }
    }
    allPrismicProductPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        uid
        _previewable
        data {
          menu_link_name
          menu_link_description
          product_type
          display_in_header_menu
          link_image {
            url
          }
        }
      }
    }
    allPrismicWhyIqPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        id
        url
        uid
        _previewable
        data {
          display_in_main_menu
          menu_link_name
          menu_link_description
          menu_link_image {
            url
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 192, formats: AUTO, height: 128)
              }
            }
          }
        }
      }
    }
    allPrismicCustomPage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        uid
        lang
        url
        dataRaw
        data {
          main_menu_order
          link_label
          show_in_main_menu
        }
      }
    }
  }
`;

export default withPrismicPreview(ProductsLandingPageTemplate);
