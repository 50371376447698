import React from "react";
import {
  getReadyBadge,
  introDescription,
  introImageContainer,
  introSection,
  introTitle,
  ourStory,
} from "./Introduction.module.scss";
import Hero from "../Hero/Hero";
import GetReadyBadge from "../../images/assets-icons/GetREADYBadge.png";

const Introduction = ({
  title,
  aboutOurStory,
  committedToSafety,
  description,
  image,
  venueSubPage,
  poppyText,
}) => {
  return (
    <div>
      <Hero
        image={image}
        backdrop={true}
        title={title}
        className={"d-lg-none"}
        venueSubPage={venueSubPage}
        poppyText={poppyText}
      />
      <div className={`container-xxl ${introSection}`}>
        <div className="row">
          <div className="col-lg-4 offset-lg-1 d-flex flex-column justify-content-center">
            {/*show the SVG instead for committed to safety !! ON PHONES only*/}
            {/*otherwise, show the intro title*/}
            {committedToSafety ? (
              <div>
                <div className={`row  d-none d-md-flex d-lg-none`}>
                  <div className="col-sm-9">
                    <div className={`${introDescription}`}>{description}</div>
                  </div>
                  <div className="col-sm-3">
                    <img alt="" className={getReadyBadge} src={GetReadyBadge} />
                  </div>
                </div>
                <div className={`d-flex d-md-none flex-column`}>
                  <img alt="" className={getReadyBadge} src={GetReadyBadge} />
                  <div className={`${introDescription}`}>{description}</div>
                </div>
                <div className={"d-none d-lg-block"}>
                  <div className={`text-primary text-start ${introTitle}`}>
                    {title}
                  </div>

                  <div className={`${introDescription}`}>{description}</div>
                </div>
              </div>
            ) : (
              <>
                <div className={`text-primary text-start ${introTitle}`}>
                  {title}
                </div>
                {/*<h1 className={`text-primary text-start ${introTitle}`}>*/}
                {/*  */}
                {/*</h1>*/}

                {aboutOurStory ? <h5 className={ourStory}>OUR STORY</h5> : ""}

                <div
                  className={`${introDescription} col-lg-12 col-md-9 col-12 ${
                    !description && `d-none`
                  }`}
                >
                  {description}
                </div>
              </>
            )}
          </div>
          <div className="col-lg-5 offset-lg-1 d-none d-lg-flex position-relative">
            <div className={introImageContainer}>
              <img className="w-100" alt="" src={image} />
              {committedToSafety ? (
                <img alt="" className={getReadyBadge} src={GetReadyBadge} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
