import React from "react";
import i18n from "../../utils/i18n";
import PreferredIcon from "../../images/profile/ribbon.svg";
import { getTeamSize } from "./util";
import { isAfter, isValid } from "date-fns";
import getOfficePriceRange from "../../utils/getOfficePriceRange";
import { getQueryParams } from "../../utils";

import "./direction-styles.scss";

const currencyOptions = {
  style: "currency",
  currency: "USD",
};

const isOnDemand = () => false;

const OptionListItems = ({
  spaces = [],
  optionStart = 0,
  displayPrice,
  forPdf = false,
  floorIndex = 1,
  setSelectedOption = () => null,
}) => {
  const spaceSummary = {};
  const params = getQueryParams();

  const teamSize = params.get("team_size");

  const isDesk = params.get("space_type") === "Dedicated desks";

  return (
    <>
      {spaces.map((item, idx) => {
        let title = `${i18n.t("profilePage.Option")} ${optionStart + idx + 1}`;
        if (
          !item.space_available &&
          isValid(new Date(item.space_available_from)) &&
          isAfter(new Date(item.space_available_from), new Date())
        ) {
          title += ` (${i18n.t("spaceLayout.AvailableSoon")})`;
        }
        let price = `${
          isDesk
            ? "$" +
              (item.price_per_desk * Number(getTeamSize())).toLocaleString("en")
            : `${getOfficePriceRange(item)}`
        } ${
          isOnDemand()
            ? `/${i18n.t("profilePage.Day").toLowerCase()}`
            : isDesk
              ? ` ${i18n.t("profilePage.Total").toLowerCase()}`
              : `/${i18n.t("profilePage.Month").toLowerCase()}`
        }`;

        const ts = item.max_capacity
          ? item.max_capacity === 1
            ? `1 ${i18n.t("profilePage.Member").toLowerCase()}`
            : `${i18n.t("profilePage.UpTo")} ${item.max_capacity} ${i18n.t(
                "profilePage.UpToMembers",
              )}`
          : "";

        const per_desk = isDesk
          ? `<strong>
                          ${item.price_per_desk.toLocaleString(
                            "en",
                            currencyOptions,
                          )}
                        </strong>
                        /${i18n.t("locationPage.Desk").toLowerCase()}`
          : null;

        const dd_count = isDesk
          ? `${teamSize} ${i18n.t("locationPage.DedicatedDesk").toLowerCase()}${
              Number(teamSize) > 1 ? "s" : ""
            }`
          : null;

        return (
          <li
            key={idx}
            className={`${
              floorIndex === 0 && idx === 0 && !forPdf
                ? "iq-ws-floor-plan-recommended-option"
                : ""
            } ${forPdf ? "for-pdf" : ""} ${i18n
              .t("profilePage.Option")
              .toLowerCase()}-${optionStart + idx + 1}`}
          >
            {floorIndex === 0 && idx === 0 && !forPdf && (
              <img
                className={"preferred-option-banner"}
                src={PreferredIcon}
                alt="Preferred option"
              />
            )}
            <div className="option-desk-header">
              <>
                {optionStart === 1000 ? (
                  <span className={"option-text mb-0"}>
                    <small>{i18n.t("spaceLayout.SelectedOption")}</small>
                  </span>
                ) : (
                  <span className={"option-text"}>{title}</span>
                )}
              </>
              {item.max_capacity && <span>{ts}</span>}
              {isDesk && <span className={"main"}>{dd_count}</span>}
            </div>
            <div className={"option-desc-container"}>
              <span>{i18n.t("StartingFrom")}</span>
              <br />
              {displayPrice && isDesk && (
                <span
                  className={"main"}
                  dangerouslySetInnerHTML={{ __html: per_desk }}
                />
              )}
              {displayPrice && (
                <span
                  className={isDesk ? "main" : ""}
                  dangerouslySetInnerHTML={{ __html: price }}
                />
              )}
              {!isDesk && !forPdf && (
                <button
                  className={`btn btn-primary px-5 iq-ws-floor-option-button`}
                  onClick={() => {
                    let priceInt = 0;
                    if (item.price) priceInt = Number(item.price);
                    if (item.price_per_desk && spaceSummary.su_current_staff)
                      priceInt = Number(
                        item.price_per_desk *
                          Number(spaceSummary.su_current_staff),
                      );
                    setSelectedOption({
                      title,
                      price,
                      priceInt,
                      resource: item.asset_url,
                      ts,
                      per_desk,
                      dd_count,
                      floor_name: item.floor_name,
                      office_label: item.label,
                      images: item.images || [],
                    });
                  }}
                >
                  {i18n.t("profilePage.Explore")}
                </button>
              )}
            </div>
          </li>
        );
      })}
    </>
  );
};

export default OptionListItems;
