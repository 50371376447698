import React, { useEffect } from "react";
import { locationsSelectorDesktopLinkActive } from "../../../pages/locations/index.module.scss";
import useQueryParams from "../../../utils/useQueryParams";
import i18n from "../../../utils/i18n";
import classnames from "classnames";

export const locationOptions = [
  { name: "Vancouver", value: "1" },
  { name: "Toronto", value: "2" },
  { name: "Ottawa", value: "3" },
  { name: "Montreal", value: "4" },
];

const CityList = () => {
  const [location, setLocation] = useQueryParams("location", "");

  useEffect(() => {
    if (typeof window === "undefined") return;
    const params = new URLSearchParams(window?.location?.search);
    setLocation(params.get("location") || "Toronto");
  }, []);

  if (!location) return null;

  return (
    <>
      {locationOptions.map((loc, index) => (
        <a
          key={index}
          onClick={(e) => {
            e.preventDefault();
            setLocation(loc.name);
          }}
          className={classnames({
            [`location-page-city-filter-${loc.name}`]: true,
            [locationsSelectorDesktopLinkActive]: location === loc.name,
          })}
          href="#"
        >
          {i18n.t(`locationPage.${loc.name}`)}
        </a>
      ))}
    </>
  );
};

export default CityList;

const getLocation = () => {
  if (typeof window === "undefined") return "Toronto";
  const params = new URLSearchParams(window?.location?.search);
  return params.get("location") || "Toronto";
};
