import React from "react";
import Carousel from "../Carousel/Carousel";
import * as s from "./ProductFeatureVenue.module.scss";
import * as productsFeatureVenue from "./ProductFeatureVenue.module.scss";
import MultiInfos from "../MultiInfos";
import { Link } from "gatsby";
import cx from "classnames";
import i18n from "../../utils/i18n";

const customColumns = "col-lg-10 col-xl-8";

const ProductFeaturedVenue = ({
  images = [],
  infoItems,
  btnLink,
  title = "",
}) => {
  return (
    <div className={`container-fluid p-0 ${s.pfvContainer}`}>
      <div className={`container-xxl`}>
        <div className={`row justify-content-center`}>
          <div className={"col-md-10 col-xl-8"}>
            <Carousel images={images} />
          </div>
        </div>
      </div>

      <div className={`container-xxl`}>
        <div className={`row justify-content-center`}>
          <div className={customColumns}>
            <div className={`${s.title} d-flex justify-content-between`}>
              <h5 className={`align-self-end`}>{title}</h5>

              <Link
                to={btnLink}
                className={cx(
                  "btn btn-primary btn-flex",
                  { "d-none": !btnLink },
                  { ["d-none d-md-block"]: !!btnLink },
                  productsFeatureVenue.button,
                )}
              >
                <span>{i18n.t("MoreInfo")}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={`border-bottom-primary`}>
        <div className="container-xxl">
          <div className={`row justify-content-center`}>
            <div className={customColumns}>
              <MultiInfos infoItems={infoItems} btnLink={btnLink} />
            </div>
          </div>
        </div>
      </div>

      <div className={`container-xxl`}>
        <div className={`d-flex w-50`}>
          <Link
            to={btnLink}
            className={cx(
              "btn btn-flex btn-primary px-4",
              { "d-none": !btnLink },
              { ["d-block d-md-none"]: !!btnLink },
              productsFeatureVenue.button,
            )}
          >
            <span>{i18n.t("MoreInfo")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductFeaturedVenue;
