import * as React from "react";
import { graphql } from "gatsby";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import activeDocMeta from "../utils/activeDocMeta";
import Introduction from "../../components/Introduction/Introduction";
import OverviewItems from "../../components/OverviewItem/OverviewItems";
import HeroFooter from "../../components/Hero/HeroFooter";
import Layout from "../../components/Layout";
import get from "lodash/get";
import BlueCta from "./components/BlueCta";
import VideoSection from "./components/VideoSection";
import ImageCarousel from "./components/ImageCarousel";
import TeamSection from "./components/Team";
import FullWidthText from "./components/FullWidthText";
import SafetyInAction from "../../components/SafetyInAction/SafetyInAction";
import FaqSection from "./components/Faq";
import { additionalMargin } from "../../pages/whyiq/whyiq.module.scss";

const FooterText = () => null;

const WhyIqSubPageTemplate = ({ data = {}, pageContext }) => {
  const pageData = get(data, "prismicWhyIqPages", {});

  const slices = pageData.dataRaw.body;

  const removeHeroMargin = get(
    pageData,
    "dataRaw.remove_hero_section_s_bottom_margin",
    false,
  );

  const whyIqPages = get(data, "allPrismicWhyIqPages.nodes", []);

  let prevPage = {};
  let nextPage = {};

  for (let i = 0; i < whyIqPages.length; i++) {
    const matching =
      get(whyIqPages, `[${i}].id`, "loopId") ===
      get(pageContext, "id", "contextId");
    if (!matching) continue;
    if (i === 0) {
      prevPage = whyIqPages[whyIqPages.length - 1];
      nextPage = whyIqPages[i + 1];
    } else if (i === whyIqPages.length - 1) {
      prevPage = whyIqPages[i - 1];
      nextPage = whyIqPages[0];
    } else {
      prevPage = whyIqPages[i - 1];
      nextPage = whyIqPages[i + 1];
    }
  }

  return (
    <Layout
      activeDocMeta={activeDocMeta(get(data, "prismicWhyIqPages", {}))}
      pages={{
        whyIqPages: get(data, "allPrismicWhyIqPages.nodes", []),
        productPages: get(data, "allPrismicProductPages.nodes", []),
        customPages: get(data, "allPrismicCustomPage.nodes", []),
        whyIqLandingPage: get(data, "allPrismicWhyIqLanding.nodes", []),
        productsLandingPage: get(
          data,
          "allPrismicProductsLandingPage.nodes",
          [],
        ),
      }}
      footerNav={{
        nextPage,
        prevPage,
      }}
      subscriptionModalData={data?.prismicHomePage?.data || {}}
    >
      <Introduction
        image={get(pageData, "dataRaw.hero_section_image.url", "")}
        title={
          <PrismicRichText
            field={get(pageData, "dataRaw.hero_section_title", null)}
          />
        }
        poppyText={{
          line1: get(pageData, "dataRaw.first_line", ""),
          line2: get(pageData, "dataRaw.second_line", ""),
          line3: get(pageData, "dataRaw.third_line", ""),
        }}
        description={
          <PrismicRichText
            field={get(pageData, "dataRaw.hero_section_description", null)}
          />
        }
        committedToSafety={pageData.uid === "safety"}
      />
      {!removeHeroMargin && (
        <div className={`${additionalMargin} d-lg-block d-none`}>
          <br />
        </div>
      )}
      {slices.length > 0 && (
        <SliceZone
          slices={slices}
          components={{
            image: HeroFooter,
            image_block: OverviewItems,
            faqs: FaqSection,
            full_width_text_section: FullWidthText,
            team_section: TeamSection,
            image_carousel: ImageCarousel,
            vr_section: SafetyInAction,
            footer_text: FooterText,
            youtube_video_section: VideoSection,
            cta_section: BlueCta,
          }}
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query WhyIqSubPageTemplate($lang: String, $uid: String) {
    prismicHomePage(lang: { eq: $lang }) {
      _previewable
      data {
        subscription_form_title
        subscription_form_description
        subscription_form_button_label
        subscription_footer_button_label
        blog_page_modal_display_delay
      }
    }
    prismicWhyIqPages(lang: { eq: $lang }, uid: { eq: $uid }) {
      ...WhyIqPagesParts
    }
    allPrismicWhyIqLanding(filter: { lang: { eq: $lang } }) {
      nodes {
        ...PrismicWhyIqLandingParts
      }
    }
    allPrismicProductsLandingPage(filter: { lang: { eq: $lang } }) {
      nodes {
        ...PrismicProductsLandingPageParts
      }
    }
    allPrismicProductPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        ...PrismicProductPagesParts
      }
    }
    allPrismicWhyIqPages(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      nodes {
        ...PrismicWhyIqPagesParts
      }
    }
    allPrismicCustomPage(filter: { lang: { eq: $lang } }) {
      nodes {
        ...PrismicCustomPageParts
      }
    }
  }

  fragment WhyIqPagesParts on PrismicWhyIqPages {
    _previewable
    id
    lang
    type
    url
    uid
    alternate_languages {
      id
      uid
      type
      lang
    }
    data {
      meta_title
      meta_description
      meta_image {
        url
      }
    }
    dataRaw
  }

  fragment PrismicWhyIqLandingParts on PrismicWhyIqLanding {
    _previewable
    id
    lang
    type
    url
    uid
    alternate_languages {
      id
      uid
      type
      lang
    }
    dataRaw
  }

  fragment PrismicProductsLandingPageParts on PrismicProductsLandingPage {
    _previewable
    id
    lang
    type
    url
    uid
    alternate_languages {
      id
      uid
      type
      lang
    }
    dataRaw
  }

  fragment PrismicProductPagesParts on PrismicProductPages {
    id
    url
    uid
    _previewable
    data {
      menu_link_name
      menu_link_description
      product_type
      display_in_header_menu
    }
  }

  fragment PrismicWhyIqPagesParts on PrismicWhyIqPages {
    id
    url
    uid
    _previewable
    data {
      display_in_main_menu
      menu_link_name
      menu_link_description
      order
      menu_link_image {
        url
        localFile {
          url
          childImageSharp {
            gatsbyImageData(width: 192, formats: AUTO, height: 128)
          }
        }
      }
    }
  }

  fragment PrismicCustomPageParts on PrismicCustomPage {
    id
    uid
    lang
    url
    dataRaw
    data {
      main_menu_order
      link_label
      show_in_main_menu
    }
  }
`;

export default withPrismicPreview(WhyIqSubPageTemplate);
