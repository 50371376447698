import React from "react";
import * as s from "./index.module.scss";
import { PrismicRichText } from "@prismicio/react";
import resolvePrismicLink from "../../../../utils/resolvePrismicLink";
import CustomPrismicLink from "../../../../components/CustomPrismicLink";

const VrSection = ({ slice }) => {
  if (!slice?.primary?.vr_link?.url) return null;

  const { link, target } = resolvePrismicLink(slice?.primary?.button_link);

  return (
    <div className={s.section}>
      <div className={`container-xxl`}>
        <div className={`row`}>
          {slice?.primary?.section_title && (
            <div className="text-center">
              <PrismicRichText field={slice?.primary?.section_title} />
            </div>
          )}
          <div
            className={`col-lg-8 offset-lg-2 col-md-10 offset-md-1 text-center pb-5`}
          >
            <div className={s.vr_wrapper}>
              <iframe src={slice?.primary?.vr_link?.url} />
            </div>
            {slice?.primary?.description && (
              <p className="text-center text-white">
                {slice?.primary?.description}
              </p>
            )}
            {slice?.primary?.button_label && link && (
              <CustomPrismicLink
                to={link}
                target={target}
                className={"btn btn-outline-light"}
              >
                {slice?.primary?.button_label}
              </CustomPrismicLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VrSection;
