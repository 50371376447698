import React from "react";
import {
  aoSection,
  aoSection__addOnText,
  aoSection__col,
  aoSection__item,
  aoSection__title,
  aoSection__title__description,
} from "./ProductAddOns.module.scss";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";
import { Button } from "react-bootstrap";
import resolvePrismicLink from "../../utils/resolvePrismicLink";
import CustomPrismicLink from "../CustomPrismicLink";

const ProductAddOns = ({ slice }) => {
  const list = get(slice, "items", []);

  const { link, target } = resolvePrismicLink(
    get(slice, "primary.button_link", {}),
  );

  return (
    <div className={aoSection}>
      <div className={`container-fluid border-bottom-primary`}>
        <div className={`container-xxl`}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-11">
              <div className={aoSection__title}>
                <PrismicRichText field={get(slice, "primary.title", [])} />
              </div>
              <div className={aoSection__title__description}>
                <PrismicRichText
                  field={get(slice, "primary.header_text", [])}
                />
              </div>
              <div className={`row`}>
                <h6 className={aoSection__addOnText}>
                  {get(slice, "primary.list_title", "")}
                </h6>
                <div className={`col`}>
                  <div className={aoSection__col}>
                    {list.map((item, idx) => (
                      <div className={aoSection__item} key={idx}>
                        <img alt="" src={get(item, "icon.url", "")} />
                        <div>
                          <h5>{get(item, "title", "")}</h5>
                          <PrismicRichText
                            field={get(item, "description", [])}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {link && get(slice, "primary.button_label", "") && (
        <div className={`container-xxl`}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-11">
              <CustomPrismicLink to={link} target={target}>
                <Button className={`mt-4`}>
                  <span>{get(slice, "primary.button_label", "")}</span>
                </Button>
              </CustomPrismicLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductAddOns;
