import React, { useState } from "react";
import {
  hpWcuSection,
  hpWcuSection__productContent,
  hpWcuSection__productContent__title,
  hpWcuSection__productImage,
  hpWcuSection__products,
  hpWcuSection__title,
} from "../../index.module.scss";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";
import { Link } from "gatsby";
import resolvePrismicLink from "../../../../utils/resolvePrismicLink";
import cx from "classnames";

const HomeAboutSection = ({ slice }) => {
  const list = get(slice, "items", []);

  const [activeWCU, setActiveWCU] = useState(0);

  return (
    <div className={`container-fluid ${hpWcuSection}`}>
      <div className={`container-xxl`}>
        <div className={`row d-flex`}>
          <div className={cx(`order-first`, `col-md-11 offset-md-1`)}>
            <div className={`${hpWcuSection__title}`}>
              <PrismicRichText field={get(slice, "primary.title", [])} />
            </div>

            <div
              className={`d-flex justify-content-sm-start justify-content-between ${hpWcuSection__products}`}
            >
              {list.map((item, idx) => (
                <button
                  key={idx}
                  className={`btn-link`}
                  onClick={() => setActiveWCU(idx)}
                  onKeyDown={() => setActiveWCU(idx)}
                >
                  <h6
                    className={cx(`opacity-25`, {
                      [`opacity-100`]: activeWCU === idx,
                      [`ps-0 ps-sm-5`]: idx !== 0,
                    })}
                  >
                    {get(item, "button_label", "")}
                  </h6>
                </button>
              ))}
            </div>
          </div>
          <div
            className={cx(
              hpWcuSection__productContent,
              `offset-lg-1 col-lg-3 order-lg-1`,
              `offset-md-1 col-md-10`,
              `col-12 order-last`,
            )}
          >
            <div className={hpWcuSection__productContent__title}>
              <PrismicRichText field={get(list, `[${activeWCU}].title`, [])} />
            </div>
            <PrismicRichText
              field={get(list, `[${activeWCU}].description`, [])}
            />
            <Link
              className={"btn btn-fixed btn-outline-primary"}
              to={
                resolvePrismicLink(get(list, `[${activeWCU}].button_link`, ""))
                  .link
              }
              title={get(list, `[${activeWCU}].button_label`, "")}
            >
              <span>{get(list, `[${activeWCU}].button_label`, "")}</span>
            </Link>
          </div>
          <div
            className={cx(
              `offset-lg-1 col-lg-7 order-lg-last`,
              `offset-md-1 col-md-10`,
              `col-12 order-1`,
            )}
          >
            <div className={`${hpWcuSection__productImage}`}>
              <img
                alt=""
                src={get(list, `[${activeWCU}].image.url`, "")}
                loading={"lazy"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAboutSection;
