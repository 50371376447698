export const locationOptions = [
  { name: "Vancouver", value: "1" },
  { name: "Toronto", value: "2" },
  { name: "Ottawa", value: "3" },
  { name: "Montreal", value: "4" },
];

export const meetingRoomsTeamSizes = [
  { label: "2", size: 1 },
  { label: "3", size: 1 },
  { label: "4", size: 1 },
  { label: "5", size: 1 },
  { label: "6", size: 1 },
  { label: "7", size: 1 },
  { label: "8", size: 1 },
  { label: "9", size: 1 },
  { label: "10", size: 1 },
  { label: "11", size: 1 },
  { label: "12", size: 1 },
  { label: "13", size: 1 },
  { label: "14", size: 1 },
  { label: "16", size: 1 },
];

export const locationCenters = [
  {
    location: "Vancouver",
    center: { lat: 49.2853911, lng: -123.12155 },
    zoom: 15,
  },
  {
    location: "Toronto",
    center: { lat: 43.6480797, lng: -79.3864781 },
    zoom: 15,
  },
  {
    location: "Ottawa",
    center: { lat: 45.42028250000001, lng: -75.7010451 },
    zoom: 15,
  },
  {
    location: "Montreal",
    center: { lat: 45.5002522, lng: -73.5701842 },
    zoom: 15,
  },
];
