import React from "react";
import Placeholder from "../../../images/memberships/nationwide.svg";
import get from "lodash/get";
import {
  lpfaSection__amenityList,
  lpfaSection__amenityList__item,
} from "../LocationsProfileFeaturedAmenitiesModal.module.scss";

const WithIcons = ({ slice }) => {
  return (
    <div className={`${lpfaSection__amenityList}`}>
      <h6>{get(slice, "primary.title", "")}</h6>

      {get(slice, "items", []).map((item, idx) => (
        <div className={`${lpfaSection__amenityList__item}`} key={idx}>
          <img alt={get(item, "text", "")} src={item.icon.url} />
          <p>{get(item, "text", "")}</p>
        </div>
      ))}
    </div>
  );
};

export default WithIcons;
