// extracted by mini-css-extract-plugin
export var arrowInvisible = "OverviewItem-module--arrow-invisible--b2dc6";
export var arrowLeftPrimary = "OverviewItem-module--arrow-left-primary--b68c9";
export var arrowRightBlack = "OverviewItem-module--arrow-right-black--c0b60";
export var arrowRightMuted = "OverviewItem-module--arrow-right-muted--7a431";
export var arrowRightPrimary = "OverviewItem-module--arrow-right-primary--e72e0";
export var arrowRightSecondaryTurqoise = "OverviewItem-module--arrow-right-secondary-turqoise--74e4a";
export var arrowRightWhite = "OverviewItem-module--arrow-right-white--b2218";
export var arrowUpPrimary = "OverviewItem-module--arrow-up-primary--50b07";
export var modalTriggerButton = "OverviewItem-module--modal-trigger-button--731c6";
export var object_fit_contain = "OverviewItem-module--object_fit_contain--1983a";
export var oviContainer = "OverviewItem-module--ovi-container--96a1b";
export var oviCtaButton = "OverviewItem-module--ovi-cta-button--148a1";
export var oviCtaButtonRow = "OverviewItem-module--ovi-cta-button-row--2bd8f";
export var oviDescription = "OverviewItem-module--ovi-description--f2f54";
export var oviDescriptionList = "OverviewItem-module--ovi-description-list--5f587";
export var oviImage = "OverviewItem-module--ovi-image--30c9b";
export var oviImageContainer = "OverviewItem-module--ovi-image-container--2678f";
export var oviPaddingSection = "OverviewItem-module--oviPaddingSection--f68d8";
export var oviSection = "OverviewItem-module--ovi-section--62979";
export var oviTitle = "OverviewItem-module--ovi-title--72a2f";
export var oviTitleIcon = "OverviewItem-module--ovi-title-icon--b024a";