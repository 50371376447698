import React from "react";
import { poppyBanner } from "../../../../pages/whyiq/whyiq.module.scss";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";
import resolvePrismicLink from "../../../../utils/resolvePrismicLink";
import CustomPrismicLink from "../../../../components/CustomPrismicLink";

const BlueCta = ({ slice }) => {
  const { link, target } = resolvePrismicLink(
    get(slice, "primary.button_link", {}),
  );
  return (
    <div className={`container-fluid ${poppyBanner}`}>
      <div className="container text-center">
        <PrismicRichText field={get(slice, "primary.title", [])} />
        <PrismicRichText field={get(slice, "primary.subtitle", [])} />

        {link && (
          <div className="d-flex justify-content-center">
            <CustomPrismicLink
              title={get(slice, "primary.button_label", "Get Started")}
              className="btn btn-flex border-white"
              to={link}
              target={target}
            >
              <span>{get(slice, "primary.button_label", "Get Started")}</span>
            </CustomPrismicLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlueCta;
