import { convertDateTimeToMeetingRoomTz } from "../components/BookingComponent/getMeetingRoomReservationData";
import { addMinutes } from "date-fns";

const getSearchTimeWithBuffer = (
  datetime,
  timezone = "America/Toronto",
  buffer = 0,
) => {
  if (!datetime) return null;

  if (datetime) {
    const dateObj = convertDateTimeToMeetingRoomTz(datetime, timezone);
    const dateObjWithBuffer = addMinutes(dateObj, buffer);
    return dateObjWithBuffer.toISOString();
  }
};

export default getSearchTimeWithBuffer;
