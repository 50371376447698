import axios from "axios";

const worksimplyApi = async (slug, data) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  return axios({
    method: "post",
    url: `${process.env.GATSBY_API_URL}/${slug}`,
    headers,
    data,
  })
    .then((r) => r.data)
    .catch((e) => {
      console.log(e);
      return { type: "error" };
    });
};

export default worksimplyApi;
