// extracted by mini-css-extract-plugin
export var closeButton = "LocationsProfileModal-module--close-button--1239b";
export var content = "LocationsProfileModal-module--content--1eb45";
export var details = "LocationsProfileModal-module--details--52047";
export var formColumn = "LocationsProfileModal-module--form-column--6b178";
export var formColumn__batGitTab = "LocationsProfileModal-module--form-column__bat-git-tab--31b9c";
export var formColumn__batGitTab__tab = "LocationsProfileModal-module--form-column__bat-git-tab__tab--414eb";
export var formColumn__batGitTab__tabActive = "LocationsProfileModal-module--form-column__bat-git-tab__tab-active--6d3ac";
export var info = "LocationsProfileModal-module--info--6bb5c";
export var infoColumn = "LocationsProfileModal-module--info-column--154af";
export var locationsProfileContainer = "LocationsProfileModal-module--locations-profile-container--ba600";
export var locationsProfileModal = "LocationsProfileModal-module--locations-profile-modal--7435c";
export var pictureContainer = "LocationsProfileModal-module--picture-container--087d5";
export var title = "LocationsProfileModal-module--title--a83d1";